import React from "react";
import PropTypes from "prop-types";
import { Box, RadioGroup, Radio, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ThinkAboutIcon from "components/base_components/ThinkAboutIcon";
import KeepGoingIcon from "components/base_components/KeepGoingIcon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  beforeKeepGoing: {
    "&:not(:last-child):before": {
      content: "''",
      backgroundColor: theme.palette.keepGoing.light,
      height: "6px",
      position: "absolute",
      width: "calc(100% - 16px)",
      top: "18px",
      transform: "translateX(29px)",
    },
  },
  beforeThinkAbout: {
    "&:not(:last-child):before": {
      content: "''",
      backgroundColor: theme.palette.thinkAbout.light,
      height: "6px",
      position: "absolute",
      width: "calc(100% - 16px)",
      top: "18px",
      transform: "translateX(29px)",
    },
  },
  beforeThreshold: {
    "&:not(:last-child):before": {
      content: "''",
      background: `linear-gradient(90deg, ${theme.palette.thinkAbout.light} 50%, ${theme.palette.keepGoing.light} 50%)`,
      height: "6px",
      position: "absolute",
      width: "calc(100% - 16px)",
      top: "18px",
      transform: "translateX(29px)",
    },
  },
  keepGoing: {
    color: theme.palette.keepGoing.main,
    "&.Mui-checked": {
      color: theme.palette.keepGoing.main,
    },
  },
  thinkAbout: {
    color: theme.palette.thinkAbout.main,
    "&.Mui-checked": {
      color: theme.palette.thinkAbout.main,
    },
  },
  radioGroup: {
    justifyContent: "center",
    flexDirection: "row",
    position: "relative",
  },
}));

const RatingFeedback = ({
  selectedValue,
  handleChange,
  rating,
  thresholdRating,
  selectedSubcategory,
  errorRating,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isKeepGoing =
    rating.findIndex((item) => item === selectedValue) > thresholdRating;
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box color="thinkAbout.dark" display="flex" alignItems="center">
          <ThinkAboutIcon isLarge={true} />
          <Typography component="p" variant="body2">
            {t("thinkabout")}
          </Typography>
        </Box>
        <Box color="keepGoing.dark" display="flex" alignItems="center">
          <Typography component="p" variant="body2">
            {t("keepgoing")}
          </Typography>
          <KeepGoingIcon isLarge={true} />
        </Box>
      </Box>
      <RadioGroup
        aria-label="rating"
        name="rating"
        value={selectedValue ?? null}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        {rating &&
          rating.map((item, index) => (
            <Box
              key={index}
              width={
                index === rating.length - 1 ? "auto" : `${100 / rating.length}%`
              }
              className={
                index > thresholdRating
                  ? classes.beforeKeepGoing
                  : index === thresholdRating
                    ? classes.beforeThreshold
                    : classes.beforeThinkAbout
              }
              position="relative"
            >
              <Radio
                value={item}
                className={
                  index > thresholdRating
                    ? classes.keepGoing
                    : classes.thinkAbout
                }
              />
            </Box>
          ))}
      </RadioGroup>
      <Box display="flex" alignItems="center">
        <Box color={isKeepGoing ? "keepGoing.main" : "thinkAbout.main"} mr={4}>
          <Typography component="p" variant="h6">
            {selectedValue}
          </Typography>
        </Box>
        <Box color="grey.500">
          <Typography component="p" variant="subtitle2">
            {
              selectedSubcategory?.labels?.find(
                (item) => item.value === selectedValue,
              )?.description
            }
          </Typography>
        </Box>
      </Box>
      {errorRating && (
        <Box color="error.main" clone>
          <Typography variant="subtitle2">{errorRating}</Typography>
        </Box>
      )}
    </Box>
  );
};

RatingFeedback.propTypes = {
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  rating: PropTypes.array.isRequired,
  thresholdRating: PropTypes.number.isRequired,
  selectedSubcategory: PropTypes.object,
  errorRating: PropTypes.string,
};

export default RatingFeedback;
