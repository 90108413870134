import gql from "graphql-tag";

const CompileFeedbackMutation = gql`
  mutation compileFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: compileFeedbackInput!
  ) {
    compileFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      id
      idSubcategory
      status
      touchpoint
      category
      subCategory
      createdAt
      object
      idUserSender
      nameUserSender
      idUserRecipient
      nameUserRecipient
      emailRecipient
      nature
      rating
      description
      key
      sort
      requestDescription
      idRequestFeedback
    }
  }
`;

export default CompileFeedbackMutation;
