import { container } from "./container";
import { paper } from "./paper";
import { input } from "./input";
import { avatar } from "./avatar";
import { accordion } from "./accordion";
import { iconButton } from "./iconButton";
import { table } from "./table";
import { drawer } from "./drawer";
import { button } from "./button";
import { tabs } from "./tabs";
import { navbar } from "./navbar";
import { menu } from "./menu";
import { structureTree } from "./structureTree";
import { divider } from "./divider";
import { fab } from "./fab";
import { card } from "./card";
import { palette } from "../globals/palette";

const overrides = {
  ...container,
  ...paper,
  ...input,
  ...avatar,
  ...accordion,
  ...iconButton,
  ...table,
  ...drawer,
  ...button,
  ...fab,
  ...card,
  MuiCssBaseline: {
    "html, body, #root, .routerDiv": {
      minHeight: "100vh",
    },
    a: {
      color: palette.grey["600"],
      textDecoration: "none",
    },
    ...structureTree,
    ".MuiFab-root.MuiFab-secondary": {
      zIndex: 1000,
    },
  },
  ...tabs,
  ...navbar,
  ...menu,
  ...divider,
};

export { overrides };
