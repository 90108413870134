import gql from "graphql-tag";

const GetUserData = gql`
  query readUser(
    $activeStructure: String
    $activeGroup: String!
    $idWorkflow: String
    $isArchive: Boolean
  ) {
    readUser(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idWorkflow: $idWorkflow
      isArchive: $isArchive
    ) {
      cf
      email
      name
      profession
      surname
      urlGet
      urlPut
      nameStructureCollaborator
      nameStructureBoss
      idStructureCollaborator
      idStructureBoss
      imageBaseUrl
      language
      evaluationStatus
    }
  }
`;

export default GetUserData;
