import React, { useEffect, useState } from "react";
import { TextField, Box, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import SearchUserByKeyword from "queries/User/SearchUserByKeyword";
import { API } from "@aws-amplify/api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import GetStructure from "queries/Structure/GetStructure";
import GetUserForBoss from "queries/User/GetUserForBoss";
import { captureException } from "helpers/SentryHelper";

const SearchUserComponent = ({
  initUsers,
  activeStructure,
  activeGroup,
  initialValues,
  feedbackData,
  formik,
  isPrecompiledFeedback,
  isRequestFeedback,
  idSelectedUser,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState(initUsers);

  const getUsers = async (search) => {
    search = search.charAt(0).toUpperCase() + search.slice(1);
    try {
      const result = await API.graphql({
        query: SearchUserByKeyword,
        variables: {
          keyword: search,
        },
      });
      setUsers(result.data.listUsersByKeyword);
    } catch (error) {
      captureException("query", "search-user", error);
    }
  };

  const getManager = async () => {
    try {
      const result = await API.graphql({
        query: GetStructure,
        variables: {
          idStructure: activeStructure,
          activeStructure,
          activeGroup,
        },
      });
      const structure = result.data.getStructure;
      const manager = {
        fullname: structure.nameManager,
        email: structure.emailManager,
        idUser: structure.idManager,
        profession: structure.professionManager,
      };
      setUsers([manager]);
      setFormikValues(manager);
    } catch (error) {
      captureException("query", "structures", error);
    }
  };

  const getSelectedUser = async () => {
    try {
      const result = await API.graphql({
        query: GetUserForBoss,
        variables: {
          selectedIdUser: idSelectedUser,
          activeStructure,
          activeGroup,
        },
      });
      const userData = result.data.getUserForBoss;
      const user = {
        fullname: `${userData.name} ${userData.surname}`,
        email: userData.email,
        idUser: idSelectedUser,
        profession: userData.profession,
      };
      setUsers([user]);
      setFormikValues(user);
    } catch (error) {
      captureException("query", "user", error);
    }
  };
  useEffect(() => {
    if (isRequestFeedback) {
      getManager();
      return;
    }
    if (idSelectedUser) {
      getSelectedUser();
    }
  }, []);

  useEffect(() => {
    if (
      searchText.length === 0 &&
      users.length > 0 &&
      !initialValues.preCompiling
    ) {
      setUsers([]);
      return;
    }
    if (searchText.length !== 3) {
      return;
    }
    getUsers(searchText);
  }, [searchText]);

  const setFormikValues = (value) => {
    formik.setFieldValue("nameRecipient", value?.fullname ?? "");
    formik.setFieldValue(
      "professionalFamilyRecipient",
      value?.profession ?? "",
    );
    formik.setFieldValue("idRecipient", value?.idUser ?? "");
    formik.setFieldValue("emailRecipient", value?.email ?? "");
  };

  const onClose = () => {
    setUsers([]);
  };

  return (
    <>
      {(isRequestFeedback || idSelectedUser) && !users[0] && (
        <Skeleton height={80} animation="wave" />
      )}
      {((!isRequestFeedback && !idSelectedUser) ||
        ((isRequestFeedback || idSelectedUser) && users[0])) && (
        <Autocomplete
          options={users}
          defaultValue={
            feedbackData || isRequestFeedback || idSelectedUser ? users[0] : ""
          }
          getOptionLabel={(option) => option.fullname || ""}
          onChange={(e, value) => {
            setFormikValues(value);
          }}
          disabled={
            isPrecompiledFeedback ||
            idSelectedUser ||
            (feedbackData && feedbackData.fromRequest) ||
            isRequestFeedback
          }
          open={
            users.length > 0 &&
            !isRequestFeedback &&
            !isPrecompiledFeedback &&
            !idSelectedUser
          }
          onClose={onClose}
          renderOption={(props, option) => (
            <li {...props}>
              <Box mr={2}>
                <Typography component="p" variant="h6">
                  {option.fullname}
                </Typography>
              </Box>
              <Typography component="p" variant="body1">
                {option.email}
              </Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("recipient")}
              name="idRecipient"
              touched={formik.touched.idRecipient}
              error={!!formik.errors.idRecipient}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              formik={formik.getFieldProps("idRecipient")}
              variant="filled"
              margin="dense"
              size="small"
            />
          )}
          popupIcon={null}
        />
      )}
    </>
  );
};

SearchUserComponent.propTypes = {
  initUsers: PropTypes.array.isRequired,
  activeStructure: PropTypes.string.isRequired,
  activeGroup: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  feedbackData: PropTypes.object,
  formik: PropTypes.object.isRequired,
  isPrecompiledFeedback: PropTypes.bool,
  isRequestFeedback: PropTypes.bool,
  idSelectedUser: PropTypes.string,
};

export default SearchUserComponent;
