import React, { useState, useContext } from "react";
import {
  Paper,
  Grid,
  Box,
  TextField,
  MenuItem,
  Typography,
  Fab,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useChartNature,
  useChartType,
  useChartRead,
  useChartRequest,
  useChartTouchpoint,
  useChartFeedbackNature,
  useChartFeedbackType,
} from "../../helper/dataDashboardHelper";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { InfoContext } from "context/InfoContext";
import { AuthContext } from "components/auth/Authenticator";
import { WorkflowContext } from "context/WorkflowContext";
import { useStyles, getDataHook } from "../../helper/dashboardHelper";

const DataDashboard = ({
  language,
  numKeepGoing,
  numThinkAbout,
  numValor,
  numGoal,
  numGeneric,
  numSkill,
  numRead,
  numNotRead,
  numCompiled,
  numNotCompiled,
  numTouchpoints,
  numTouchpointsEnded,
  numTouchpointsPending,
  thinkAboutOverTime,
  keepGoingOverTime,
  genericOverTime,
  goalOverTime,
  valorOverTime,
  skillOverTime,
  numFeedbacks,
  numRequests,
  isAdmin,
  isCollab,
  singleStructureId,
  singleUserId,
  openFilters,
  period,
  simpleView,
}) => {
  const { t } = useTranslation();
  const { isMediumDesktop, isMobile, isSmartphone } = getDataHook();
  const {
    settings: {
      goalActivated,
      valorActivated,
      genericFeedback,
      skillActivated,
    },
  } = useContext(InfoContext);
  const { activeStructure } = useContext(AuthContext);
  const { currentWorkflow } = useContext(WorkflowContext);

  const showGoal = goalActivated || !!numGoal;
  const showValor = valorActivated || !!numValor;
  const showGeneric = genericFeedback || !!numGeneric;
  const showSkill = skillActivated || !!numSkill;

  const { classes } = useStyles();
  const navigation = useNavigate();
  const { natureFeedRef } = useChartNature({
    numKeepGoing,
    numThinkAbout,
    isMediumDesktop,
  });
  const { typeFeedRef } = useChartType({
    numValor,
    numGoal,
    numGeneric,
    numSkill,
    isMediumDesktop,
    settings: { showGoal, showValor, showGeneric, showSkill },
  });
  const { readFeedRef } = useChartRead({
    numRead,
    numNotRead,
    isMediumDesktop,
  });
  const { requestRef } = useChartRequest({
    numCompiled,
    numNotCompiled,
    isMediumDesktop,
  });
  const { touchpointRef } = useChartTouchpoint({
    numTouchpointsEnded,
    numTouchpointsPending,
    isMediumDesktop,
  });
  const { feedbackNatureRef } = useChartFeedbackNature({
    thinkAboutOverTime,
    keepGoingOverTime,
    numKeepGoing,
    numThinkAbout,
    language,
    numFeedbacks,
    isMediumDesktop,
    isMobile,
    isSmartphone,
    period,
    endDate: currentWorkflow.startFinalReview,
  });
  const { feedbackTypeRef } = useChartFeedbackType({
    genericOverTime,
    goalOverTime,
    valorOverTime,
    skillOverTime,
    numValor,
    numSkill,
    numGoal,
    numGeneric,
    language,
    numFeedbacks,
    isMediumDesktop,
    isMobile,
    isSmartphone,
    settings: { showGoal, showValor, showGeneric, showSkill },
    period,
    endDate: currentWorkflow.startFinalReview,
  });
  const chartTypes = ["natureGraph", "typeGraph"];
  const [chartTypesFeedback, setChartTypesFeedback] = useState(chartTypes[0]);

  const handleChartType = (event) => {
    setChartTypesFeedback(event.target.value);
  };

  const handleNavigate = (type) => {
    if (isAdmin) {
      const queryString = singleUserId
        ? `level=2&idStructure=${singleStructureId}&idUser=${singleUserId}&type=${type}`
        : `level=1&idStructure=${singleStructureId}&type=${type}`;
      navigation(`/feedbacks?${queryString}`);
      return;
    }
    switch (type) {
      case "feedback":
        navigation("/feedbacks");
        break;
      default:
        navigation(`/feedbacks?tab=${type}&fromDashboard="${true}`);
    }
  };

  const getWidthBox = (incr = 0, isSecondary) => {
    if (!isMediumDesktop) {
      if (isSmartphone) {
        return "100%";
      }
      if (isMobile) {
        return "50%";
      }
      if (isSecondary) {
        return "74%";
      }
      return "100%";
    }
    if (openFilters) {
      return `${86 + incr * 2}%`;
    }
    if (isSecondary) {
      return "58%";
    }
    return `${70 + incr}%`;
  };

  const numTypes = [showGoal, showGeneric, showValor, showSkill].reduce(
    (accumulator, item) => (accumulator += item ? 1 : 0),
    0,
  );

  return (
    <>
      {!simpleView && (
        <Box mt={4} clone>
          <Paper elevation={3}>
            <Box display="flex" px={4} py={6} justifyContent="space-between">
              <Box display="flex">
                <Box color="primary.main" mr={2}>
                  <Typography variant="h5" style={{ fontSize: "1.25rem" }}>
                    {numFeedbacks}
                  </Typography>
                </Box>
                <Typography variant="h6">{t("receivedFeedback")}</Typography>
              </Box>
              <Box>
                {numFeedbacks > 0 &&
                  ((isAdmin && singleStructureId) ||
                    (!isAdmin && singleStructureId === activeStructure) ||
                    isCollab) && (
                    <Fab
                      size="small"
                      color="primary"
                      className={classes.buttonLink}
                      onClick={() => handleNavigate("feedback")}
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </Fab>
                  )}
              </Box>
            </Box>
            {numFeedbacks > 0 && (
              <Box p={{ xs: "16px 0", sm: 4 }}>
                <Grid container spacing={0}>
                  <Grid item md={4} xs={12}>
                    <Box display="flex">
                      <Box
                        width={getWidthBox()}
                        margin={{
                          xs: "0 0 20px -20px",
                          sm: "0 0 20px 0",
                          md: "0 auto",
                        }}
                        padding={{ xs: 4, sm: 0 }}
                      >
                        <canvas ref={natureFeedRef}></canvas>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box display="flex">
                      <Box
                        width={getWidthBox(-14 + 7 * numTypes)}
                        margin={{
                          xs: "0 0 20px 0",
                          sm: "0 0 20px 20px",
                          md: "0 auto",
                        }}
                        padding={{ xs: 4, sm: 0 }}
                      >
                        <canvas ref={typeFeedRef}></canvas>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Box
                      width={getWidthBox()}
                      margin={{
                        xs: "0 0 20px 0",
                        sm: "0 0 20px 20px",
                        md: "0 auto",
                      }}
                      padding={{ xs: 4, sm: 0 }}
                    >
                      <canvas ref={readFeedRef}></canvas>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
        </Box>
      )}
      <Box mt={4}>
        <Grid container spacing={4}>
          <Grid item lg={6} xs={12}>
            <Paper elevation={3}>
              <Box display="flex" px={4} py={6} justifyContent="space-between">
                <Box display="flex">
                  <Box color="secondary.main" mr={2}>
                    <Typography variant="h5" style={{ fontSize: "1.25rem" }}>
                      {numRequests}
                    </Typography>
                  </Box>
                  <Typography variant="h6">
                    {t("requestForFeedback")}
                  </Typography>
                </Box>
                {!simpleView &&
                  numRequests > 0 &&
                  ((!isAdmin && singleStructureId === activeStructure) ||
                    (isAdmin && singleUserId) ||
                    isCollab) && (
                    <Fab
                      size="small"
                      color="secondary"
                      className={classes.buttonLink}
                      onClick={() => handleNavigate("request")}
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </Fab>
                  )}
              </Box>
              {!simpleView && numRequests > 0 && (
                <Box
                  width={getWidthBox(0, true)}
                  pb={4}
                  pl={!isMediumDesktop ? 4 : !openFilters ? 0 : 6}
                  pr={{ xs: 4, sm: 0 }}
                >
                  <canvas ref={requestRef}></canvas>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Paper elevation={3}>
              <Box display="flex" px={4} py={6} justifyContent="space-between">
                <Box display="flex">
                  <Box color="secondary.main" mr={2}>
                    <Typography variant="h5" style={{ fontSize: "1.25rem" }}>
                      {numTouchpoints}
                    </Typography>
                  </Box>
                  <Typography variant="h6">
                    {t("requestsTouchpoint")}
                  </Typography>
                </Box>
                {numTouchpoints > 0 &&
                  ((!isAdmin && singleStructureId === activeStructure) ||
                    (isAdmin && singleUserId) ||
                    isCollab) && (
                    <Fab
                      size="small"
                      color="secondary"
                      className={classes.buttonLink}
                      onClick={() => handleNavigate("touchpoint")}
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </Fab>
                  )}
              </Box>
              {!simpleView && numTouchpoints > 0 && (
                <Box
                  width={getWidthBox(0, true)}
                  pb={4}
                  pl={!isMediumDesktop ? 4 : !openFilters ? 0 : 6}
                  pr={{ xs: 4, sm: 0 }}
                >
                  <canvas ref={touchpointRef}></canvas>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Paper elevation={3}>
          <Box
            display={{ xs: "block", md: "flex" }}
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Typography variant="h6">
              {simpleView ? t("trendOfFeedbackAltText") : t("trendOfFeedback")}
            </Typography>
            <TextField
              select
              variant="outlined"
              margin="dense"
              size="small"
              onChange={handleChartType}
              value={chartTypesFeedback}
              InputLabelProps={{ shrink: false }}
            >
              {chartTypes.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {t(item)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            display={chartTypesFeedback === "natureGraph" ? "block" : "none"}
            p={4}
          >
            <canvas height="100%" ref={feedbackNatureRef}></canvas>
          </Box>
          <Box
            display={chartTypesFeedback === "typeGraph" ? "block" : "none"}
            p={4}
          >
            <canvas height="100%" ref={feedbackTypeRef}></canvas>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

DataDashboard.propTypes = {
  numRequests: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  numKeepGoing: PropTypes.number.isRequired,
  numThinkAbout: PropTypes.number.isRequired,
  numValor: PropTypes.number.isRequired,
  numSkill: PropTypes.number.isRequired,
  numGoal: PropTypes.number.isRequired,
  numGeneric: PropTypes.number.isRequired,
  numRead: PropTypes.number.isRequired,
  numNotRead: PropTypes.number.isRequired,
  numCompiled: PropTypes.number.isRequired,
  numNotCompiled: PropTypes.number.isRequired,
  numTouchpoints: PropTypes.number.isRequired,
  numTouchpointsEnded: PropTypes.number.isRequired,
  numTouchpointsPending: PropTypes.number.isRequired,
  numFeedbacks: PropTypes.number.isRequired,
  thinkAboutOverTime: PropTypes.array.isRequired,
  keepGoingOverTime: PropTypes.array.isRequired,
  genericOverTime: PropTypes.array.isRequired,
  skillOverTime: PropTypes.array.isRequired,
  goalOverTime: PropTypes.array.isRequired,
  valorOverTime: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCollab: PropTypes.bool.isRequired,
  singleStructureId: PropTypes.string,
  singleUserId: PropTypes.string,
  openFilters: PropTypes.bool,
  period: PropTypes.string.isRequired,
  simpleView: PropTypes.bool,
};

export default DataDashboard;
