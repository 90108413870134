import { Cache } from "aws-amplify";

export const getCachedItem = (key, slugTenant) => {
  return Cache.getItem(`${slugTenant}-infoData`)
    ? Cache.getItem(`${slugTenant}-infoData`)[`${key}`]
    : null;
};

export const getCachedItems = (keys, slugTenant) => {
  return keys.map((key) => Cache.getItem(`${slugTenant}-infoData`)?.[`${key}`]);
};

export const setCachedItem = (key, data, slugTenant) => {
  Cache.setItem(
    `${slugTenant}-infoData`,
    {
      ...Cache.getItem(`${slugTenant}-infoData`),
      [key]: data,
    },
    {
      expires: Date.now() + 1000 * 60 * 60 * 3,
    },
  );
};

export const setCachedItems = (warningsObject, slugTenant) => {
  Cache.setItem(
    `${slugTenant}-infoData`,
    {
      ...Cache.getItem(`${slugTenant}-infoData`),
      ...warningsObject,
    },
    {
      expires: Date.now() + 1000 * 60 * 60 * 3,
    },
  );
};

export default { getCachedItem, setCachedItem };
