import React from "react";
import Badge from "@mui/material/Badge";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "tss-react/mui";
import PropTypes from "prop-types";

const useStyles = makeStyles()((theme) => ({
  filterButton: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: theme.navbarHeight,
    right: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    padding: 0,
    color: theme.palette.common.white,
    minWidth: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.spacing(0, 0, 0, 4),
    "& ,&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiBadge-anchorOriginTopRightRectangular": {
      color: theme.palette.grey["100"],
      fontSize: theme.typography.pxToRem(8),
      height: 14,
      lineHeight: 11,
      minWidth: 14,
      width: 14,
      right: 12,
      top: 13,
    },
  },
  centerElement: {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate( -50%, -50% )",
  },
}));

const ButtonFilter = ({ numFilter, handleClick }) => {
  const { classes } = useStyles();
  return (
    <Badge
      color="secondary"
      badgeContent={numFilter}
      className={classes.filterButton}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <FilterListIcon
        className={classes.centerElement}
        onClick={() => handleClick()}
      />
    </Badge>
  );
};

ButtonFilter.propTypes = {
  numFilter: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
};

export default ButtonFilter;
