import React from "react";
import {
  Box,
  Select,
  LinearProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const StructureFilter = ({
  structures,
  singleStructure,
  handleSelectedTeam,
  activeStructure,
  isBoss,
  disabledSelect,
}) => {
  const { t } = useTranslation();
  if (isBoss && !singleStructure) {
    handleSelectedTeam(null, activeStructure);
  }
  return (
    <Box p={4}>
      <FormControl fullWidth variant="filled">
        <InputLabel>{t("selectATeam")}</InputLabel>
        <Select
          native
          variant="filled"
          disabled={structures.loading || disabledSelect}
          value={singleStructure}
          onChange={handleSelectedTeam}
        >
          <option aria-label="None" value="" />
          {structures.loading ? (
            <LinearProgress />
          ) : (
            structures.data.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

StructureFilter.propTypes = {
  structures: PropTypes.object.isRequired,
  singleStructure: PropTypes.string.isRequired,
  handleSelectedTeam: PropTypes.func.isRequired,
  activeStructure: PropTypes.string,
  isBoss: PropTypes.bool.isRequired,
  disabledSelect: PropTypes.bool.isRequired,
};

export default StructureFilter;
