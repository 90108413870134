import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import HelpIcon from "./components/HelpIcon";
import NotificationIcon from "./components/NotificationIcon";
import ProfileMenuArea from "./profile/ProfileMenuArea";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

const useStyles = makeStyles()((theme) => ({
  titleNavbar: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(23),
    },
  },
}));

const Navbar = ({
  title,
  handleNotificationsWindow,
  counter,
  openNotifications,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="fixed" elevation={3}>
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h5" noWrap className={classes.titleNavbar}>
            {t(title)}
          </Typography>
          <Box marginLeft="auto" marginRight={{ md: 6 }}>
            <ProfileMenuArea />
          </Box>
          <Box bgcolor={{ md: "primary.dark" }} height="100%">
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              px={4}
              pl={{ xs: 2, md: 6 }}
              pr={{ md: 6 }}
            >
              <Box mr={{ xs: 2, md: 5 }} display="flex">
                <HelpIcon />
              </Box>
              <Badge color="secondary" badgeContent={counter}>
                <NotificationIcon
                  action={handleNotificationsWindow}
                  openNotifications={openNotifications}
                />
              </Badge>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  handleNotificationsWindow: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
  openNotifications: PropTypes.bool,
};

export default Navbar;
