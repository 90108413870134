import Auth from "@aws-amplify/auth";
import { captureException } from "helpers/SentryHelper";

const logout = async () => {
  try {
    sessionStorage.clear();
    await Auth.signOut();
  } catch (error) {
    captureException("user", "signOut", error);
  }
};

export { logout };
