import gql from "graphql-tag";

const ListWorkflowStructures = gql`
  query listAllStructures($isArchive: Boolean, $idWorkflow: String) {
    listAllStructures(isArchive: $isArchive, idWorkflow: $idWorkflow) {
      id
      name: nameStructure
      nameManager
      idManager
      numKeepGoing
      numThinkAbout
      locked
      notCompiled
      partiallyCompiled
      compiled
      unlocked
      unlockedFinalReview
    }
  }
`;

export default ListWorkflowStructures;
