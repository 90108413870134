import gql from "graphql-tag";

const GetCdc = gql`
  query getCdc($level: String) {
    getCdc(level: $level) {
      items {
        name
        id
      }
    }
  }
`;

export default GetCdc;
