import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalHeader = ({ title, subtitle, closeButton, closeModal }) => (
  <>
    <Box
      px={4}
      py={5}
      mb={3}
      display={closeButton ? "flex" : "block"}
      justifyContent="space-between"
    >
      <Typography variant="h5" align="center">
        {title}
      </Typography>
      {closeButton && (
        <IconButton onClick={closeModal} color="inherit" size="large">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
    {subtitle && (
      <Box px={4} py={5}>
        <Typography variant="body1" align="center" paragraph>
          {subtitle}
        </Typography>
      </Box>
    )}
  </>
);

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  closeButton: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ModalHeader;
