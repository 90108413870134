export const menu = {
  MuiMenuItem: {
    root: {
      fontSize: "0.875rem",
      lineHeight: "1.187rem",
    },
  },
  MuiList: {
    padding: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
};
