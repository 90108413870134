import { createTheme } from "@mui/material/styles";
import { variables } from "./variables";
import "typeface-comfortaa";

const defaultTheme = createTheme();

export const typography = {
  fontFamily: variables.fontFamilyBody,
  h1: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 300,
    fontSize: "6.125rem",
  },
  h2: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 300,
    fontSize: "3.812rem",
  },
  h3: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 400,
    fontSize: "3rem",
  },
  h4: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 400,
    fontSize: "1.25rem",
    [defaultTheme.breakpoints.up("md")]: {
      fontSize: "2.18rem",
    },
  },
  h5: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 700,
    fontSize: "1rem",
    [defaultTheme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
  },
  h6: {
    fontFamily: variables.fontFamilyTitle,
    fontWeight: 700,
    fontSize: "1rem",
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "0.875rem",
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.375rem",
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.19rem",
  },
  button: {
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  caption: {
    fontWeight: 400,
    fontSize: "0.75rem",
  },
};
