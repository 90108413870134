import gql from "graphql-tag";

const UpdateFeedbackMutation = gql`
  mutation updateFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: updateFeedbackInput!
  ) {
    updateFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      key
      sort
      id
      createdAt
    }
  }
`;

export default UpdateFeedbackMutation;
