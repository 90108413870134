import React, { Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "pages/all/home";
import { AuthContext, useAuth } from "./components/auth/Authenticator";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AuthRoute from "./components/auth/AuthRoute";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { WorkflowContext, useWorkflow } from "./context/WorkflowContext";
import { InfoContext, useInfoContext } from "./context/InfoContext";
import getProfileComponent from "helpers/getProfileComponent";
import getFeedbacksComponent from "helpers/getFeedbacksComponent";
import Guidance from "pages/all/guidance";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from "./sentry";
import PageWrapper from "components/containers/pageWrapper";
import { Backdrop, CircularProgress } from "@mui/material";
import notFoundPageImage from "assets/img/404_img.png";
import DashboardFinalReview from "pages/all/finalReview";
import ErrorBoundary from "ErrorBoundary";
import { useTranslation } from "react-i18next";
import { matrixPhasePages } from "helpers/RoutesHelper";

const history = createBrowserHistory();
const SettingsPage = React.lazy(() => import("./pages/all/settings"));
const PaginaProcesso = React.lazy(() => import("./pages/admin/process"));
const PaginaUtenti = React.lazy(() => import("./pages/admin/users"));
const PaginaStrutture = React.lazy(() => import("./pages/admin/structures"));
const PaginaTeam = React.lazy(() => import("./pages/boss/teams"));
const PaginaValori = React.lazy(
  () => import("./pages/admin/evalutationElements/pages/valor"),
);
const PaginaCompetenze = React.lazy(
  () => import("./pages/admin/evalutationElements/pages/skill"),
);
const GetWorkflowComponent = React.lazy(
  () => import("helpers/getWorkflowComponent"),
);
const AdoptionPage = React.lazy(() => import("./pages/admin/adoption"));

Sentry.init({
  ...config,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
});

const NotFound = () => {
  return <img src={notFoundPageImage} alt="welcomeImage" width="100%"></img>;
};

const WaitComponent = ({ workflow, auth }) => {
  const navigation = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted && (workflow?.currentPhase || workflow?.isLoaded)) {
      const path =
        matrixPhasePages[`${auth.activeGroup}`][
          `${workflow.currentPhase ?? "settings#1"}`
        ] ?? "";
      navigation(`/${path}`);
    }
    return () => {
      isMounted = false;
    };
  }, [workflow.currentPhase]);
  return null;
};

const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const workflow = useWorkflow(
    auth.cognitoConfig,
    auth.activeGroup,
    auth.slugTenant,
  );
  const userInfo = {
    activeGroup: auth.activeGroup,
    activeStructure: auth.activeStructure,
  };
  const info = useInfoContext({
    ...userInfo,
    subPhase: workflow.subPhase,
    idUser: auth.user?.username,
    slugTenant: auth.slugTenant,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContext.Provider value={{ ...auth }}>
          <WorkflowContext.Provider value={{ ...workflow }}>
            <InfoContext.Provider value={{ ...info }}>
              <BrowserRouter>
                <PageWrapper auth={{ ...auth }}>
                  <ErrorBoundary t={t}>
                    <Suspense
                      fallback={
                        <Backdrop
                          open={true}
                          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                          <CircularProgress size={48} />
                        </Backdrop>
                      }
                    >
                      <AuthRoute history={history} auth={{ ...auth }} />
                      {auth.user && auth.isLoaded && (
                        <Routes>
                          <Route
                            exact
                            path="/wait"
                            element={
                              <WaitComponent workflow={workflow} auth={auth} />
                            }
                          />
                          <Route
                            exact
                            path="/"
                            element={
                              <ProtectedRoute
                                Component={Home}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/dashboard-final-review"
                            element={
                              <ProtectedRoute
                                Component={DashboardFinalReview}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/workflow"
                            element={
                              <ProtectedRoute
                                path="/workflow"
                                Component={GetWorkflowComponent}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/feedbacks"
                            element={
                              <ProtectedRoute
                                path="/feedbacks"
                                Component={getFeedbacksComponent}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/profile"
                            element={
                              <ProtectedRoute
                                Component={getProfileComponent}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupCollaborator",
                                  "UserPoolGroupBoss",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/structures"
                            element={
                              <ProtectedRoute
                                path="/structures"
                                Component={PaginaStrutture}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/users"
                            element={
                              <ProtectedRoute
                                path="/users"
                                Component={PaginaUtenti}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/valors"
                            element={
                              <ProtectedRoute
                                path="/valors"
                                Component={PaginaValori}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                                valorPage={true}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/skills"
                            element={
                              <ProtectedRoute
                                path="/skills"
                                Component={PaginaCompetenze}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                                skillPage={true}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/team"
                            element={
                              <ProtectedRoute
                                path="/team"
                                Component={PaginaTeam}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupBoss"]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/process"
                            element={
                              <ProtectedRoute
                                path="/process"
                                Component={PaginaProcesso}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/guidance"
                            element={
                              <ProtectedRoute
                                path="/guidance"
                                Component={Guidance}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/settings"
                            element={
                              <ProtectedRoute
                                path="/settings"
                                Component={SettingsPage}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                          <Route
                            exact
                            path="/adoption"
                            element={
                              <ProtectedRoute
                                path="/adoption"
                                Component={AdoptionPage}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={["UserPoolGroupAdmin"]}
                              />
                            }
                          />
                          <Route
                            path="*"
                            element={
                              <ProtectedRoute
                                path="*"
                                Component={NotFound}
                                auth={auth}
                                workflow={workflow}
                                authorizedRoles={[
                                  "UserPoolGroupAdmin",
                                  "UserPoolGroupBoss",
                                  "UserPoolGroupCoBoss",
                                  "UserPoolGroupCollaborator",
                                ]}
                              />
                            }
                          />
                        </Routes>
                      )}
                    </Suspense>
                  </ErrorBoundary>
                </PageWrapper>
              </BrowserRouter>
            </InfoContext.Provider>
          </WorkflowContext.Provider>
        </AuthContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

WaitComponent.propTypes = {
  workflow: PropTypes.shape({
    currentPhase: PropTypes.string,
    isLoaded: PropTypes.bool,
  }),
  auth: PropTypes.shape({
    activeGroup: PropTypes.string,
  }),
};

export default App;
