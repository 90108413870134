import React, { useState, useEffect, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Box, Backdrop, CircularProgress } from "@mui/material";
import InputField from "components/base_components/InputField";
import SelectField from "components/base_components/SelectField";
import {
  listValues,
  listObjectives,
  listSkills,
} from "components/feedback/listCategories";
import reducerSubcategories from "components/feedback/reducerSubcategories";
import ActionForm from "../ActionForm";
import Summary from "../Summary";
import SearchUserComponent from "../SearchUserComponent";
import {
  useGetFormikSendRequestFeedback,
  categories,
} from "../functions/formikHelper";
import { InfoContext } from "context/InfoContext";
import ElementSelect from "../ElementSelect";

const mapFunctionGetSubcategory = {
  goal: listObjectives,
  valor: listValues,
  skill: listSkills,
};

const FormRequestFeedback = (props) => {
  const {
    handleClickAlert,
    feedbackData,
    updateDataFeedbacks,
    isUpdate,
    activeStructure,
    activeGroup,
    handleClose,
    setDirty,
  } = props;
  const { t } = useTranslation();
  const [stateSubcategories, updateStateSubcategories] = useReducer(
    reducerSubcategories,
    {
      subCategories: [],
      hasSubcategories: false,
      valorsForSelect: {},
      skillsForSelect: {},
    },
  );
  const [loading, setLoading] = useState(true);
  const { settings } = useContext(InfoContext);
  const filteredCategories = categories.filter(
    (item) =>
      (settings.goalActivated || item !== "goal") &&
      (settings.valorActivated || item !== "valor") &&
      (settings.skillActivated || item !== "skill") &&
      (settings.genericFeedback || item !== "generic"),
  );
  const [allowedCategories, setAllowedCategories] =
    useState(filteredCategories);

  useEffect(() => {
    setAllowedCategories(filteredCategories);
  }, [settings]);
  let formValues = {
    recipient: "",
    object: "",
    category: "",
    subCategory: "",
    requestDescription: "",
  };
  let initUsers = [];
  if (feedbackData) {
    formValues = {
      object: feedbackData.object,
      category: allowedCategories.findIndex(
        (item) => item === feedbackData.category,
      ),
      preCompiling: true,
      idRecipient: feedbackData.idUserRecipient,
      nameRecipient: feedbackData.nameUserRecipient,
      emailRecipient: feedbackData.emailRecipient,
      professionalFamilyRecipient: feedbackData.professionalFamilyRecipient,
      requestDescription: feedbackData.requestDescription,
      subCategory: "",
    };
    initUsers = [
      {
        idUser: feedbackData.idUserRecipient,
        fullname: feedbackData.nameUserRecipient,
        email: feedbackData.emailRecipient,
        professionalFamilyRecipient: feedbackData.professionalFamilyRecipient,
      },
    ];
  }

  const formik = useGetFormikSendRequestFeedback({
    initialValues: formValues,
    stateSubcategories,
    feedbackData,
    isUpdate,
    handleClickAlert,
    updateDataFeedbacks,
    handleClose,
    allowedCategories,
    isBoss: activeGroup === "UserPoolGroupBoss",
  });

  useEffect(() => {
    formik.setFieldValue("subCategory", "");
    if (
      !["valor", "goal", "skill"].includes(
        allowedCategories[`${formik.values.category}`],
      )
    ) {
      updateStateSubcategories({ type: "resetSubcategories" });
      return;
    }
    updateStateSubcategories({
      type: "updateHasSubcategories",
      payload: {
        hasSubcategories: true,
      },
    });

    (async () => {
      setLoading(true);
      const nameCategory = allowedCategories[`${formik.values.category}`];
      const getSubcategories = mapFunctionGetSubcategory[`${nameCategory}`];

      const subCategoriesFetched = await getSubcategories({
        activeStructure,
        activeGroup,
      });

      let valorsForSelect = {};
      let skillsForSelect = {};

      const selectedCategory = formik.values.category;

      if (allowedCategories[`${selectedCategory}`] === "valor") {
        valorsForSelect = subCategoriesFetched.reduce(
          (acc, subcategory, index) => {
            if (!acc[subcategory.nameMacroArea]) {
              acc[subcategory.nameMacroArea] = [];
            }
            acc[subcategory.nameMacroArea].push({
              ...subcategory,
              selectIndex: index,
            });
            return acc;
          },
          {},
        );
      }

      if (allowedCategories[`${selectedCategory}`] === "skill") {
        skillsForSelect = subCategoriesFetched.reduce(
          (acc, subcategory, index) => {
            if (!acc[subcategory.nameMacroSkill]) {
              acc[subcategory.nameMacroSkill] = [];
            }
            acc[subcategory.nameMacroSkill].push({
              ...subcategory,
              selectIndex: index,
            });
            return acc;
          },
          {},
        );
      }

      updateStateSubcategories({
        type: "updateSubcategories",
        payload: {
          subCategories: subCategoriesFetched,
          valorsForSelect,
          skillsForSelect,
        },
      });
      if (feedbackData && formValues.preCompiling) {
        const subCategoryIndex = subCategoriesFetched.findIndex(
          (subCategory) => subCategory.title === feedbackData.subCategory,
        );
        formik.setFieldValue(
          "subCategory",
          subCategoryIndex === -1 ? "" : subCategoryIndex,
        );
      }
      setLoading(false);
    })();
  }, [formik.values.category]);

  useEffect(() => {
    setDirty(formik.dirty);
  }, [formik.dirty]);

  useEffect(() => {
    if (
      formValues.preCompiling &&
      (!isNaN(parseInt(formik.values.subCategory, 10)) ||
        allowedCategories[`${formik.values.category}`] === "generic") &&
      formik.values.nameRecipient
    ) {
      formik.resetForm({ values: formik.values });
      setLoading(false);
    }
    if (!formValues.preCompiling && formik.values.nameRecipient) {
      formik.resetForm({ values: formik.values });
      setLoading(false);
    }
  }, [formik.values.subCategory, formik.values.nameRecipient]);

  return (
    <>
      <Box overflow="hidden auto" height="100%" pt={4}>
        <Backdrop
          open={loading}
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
          <CircularProgress size={48} />
        </Backdrop>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={6}>
            <Grid item md={6} xs={12}>
              <Box mb={{ xs: 5, md: 9 }}>
                <SearchUserComponent
                  initUsers={initUsers}
                  activeStructure={activeStructure}
                  activeGroup={activeGroup}
                  initialValues={formValues}
                  feedbackData={feedbackData}
                  formik={formik}
                  isPrecompiledFeedback={false}
                  isRequestFeedback={true}
                />
              </Box>
              <InputField
                label={t("object")}
                name="object"
                touched={formik.touched.object}
                error={formik.errors.object}
                formik={formik.getFieldProps("object")}
                isFeedback={true}
              />
              <SelectField
                label={t("category")}
                name="category"
                touched={formik.touched.category}
                error={formik.errors.category}
                formik={formik.getFieldProps("category")}
                options={allowedCategories}
                isFeedback={true}
              />
              {stateSubcategories.hasSubcategories &&
                (allowedCategories[formik.values.category] === "goal" ? (
                  <SelectField
                    label={t("subCategory")}
                    name="subCategory"
                    touched={formik.touched.subCategory}
                    error={formik.errors.subCategory}
                    formik={formik.getFieldProps("subCategory")}
                    options={stateSubcategories.subCategories}
                    isFeedback={true}
                  />
                ) : (
                  <ElementSelect
                    selectableValors={
                      allowedCategories[formik.values.category] === "valor"
                        ? stateSubcategories.valorsForSelect
                        : stateSubcategories.skillsForSelect
                    }
                    touched={formik.touched.subCategory}
                    error={formik.errors.subCategory}
                    formik={formik.getFieldProps("subCategory")}
                  />
                ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display={{ xs: "none", md: "block" }}>
                <Summary
                  category={
                    formik.values.category || formik.values.category === 0
                      ? allowedCategories[formik.values.category]
                      : ""
                  }
                  titleSubCategory={
                    formik.values.subCategory || formik.values.subCategory === 0
                      ? stateSubcategories.subCategories[
                          formik.values.subCategory
                        ].title
                      : ""
                  }
                  descriptionSubCategory={
                    formik.values.subCategory || formik.values.subCategory === 0
                      ? stateSubcategories.subCategories[
                          formik.values.subCategory
                        ].description
                      : ""
                  }
                  isRequestFeedback={true}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputField
              label={t("requestorNotes")}
              name="requestorNotes"
              touched={formik.touched.requestDescription}
              error={formik.errors.requestDescription}
              formik={formik.getFieldProps("requestDescription")}
              other={{ rows: 4, multiline: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={{ xs: "block", md: "none" }}>
              <Summary
                category={
                  formik.values.category || formik.values.category === 0
                    ? allowedCategories[formik.values.category]
                    : ""
                }
                titleSubCategory={
                  formik.values.subCategory || formik.values.subCategory === 0
                    ? stateSubcategories.subCategories[
                        formik.values.subCategory
                      ].title
                    : ""
                }
                descriptionSubCategory={
                  formik.values.subCategory || formik.values.subCategory === 0
                    ? stateSubcategories.subCategories[
                        formik.values.subCategory
                      ].description
                    : ""
                }
                isRequestFeedback={true}
              />
            </Box>
          </Grid>
        </form>
      </Box>
      <Box paddingY={2} height={48}>
        <ActionForm
          isSubmitting={formik.isSubmitting}
          handleClose={() => handleClose()}
          action={formik.submitForm}
          isChanges={formik.dirty}
        />
      </Box>
    </>
  );
};

FormRequestFeedback.propTypes = {
  handleClickAlert: PropTypes.func.isRequired,
  feedbackData: PropTypes.object,
  updateDataFeedbacks: PropTypes.func,
  isUpdate: PropTypes.bool,
  activeStructure: PropTypes.string,
  activeGroup: PropTypes.string.isRequired,
  isGoalFeedback: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default FormRequestFeedback;
