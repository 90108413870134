import config from "./aws_export";
import Amplify from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { Cache } from "aws-amplify";

const initConfig = async (cognitoConfig) => {
  config.API = {
    graphql_headers: async () => {
      const session = await Amplify.Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  };

  const configCache = {
    itemMaxSize: 1048576,
    capacityInBytes: 5000000,
  };
  Cache.configure(configCache);

  const configObject = {
    ...config,
    aws_user_pools_id: cognitoConfig.aws_user_pools_id,
    aws_user_pools_web_client_id: cognitoConfig.aws_user_pools_web_client_id,
  };
  Amplify.configure(configObject);
  Auth.configure({
    oauth: { ...cognitoConfig.oauth },
  });
};

export default { initConfig };
