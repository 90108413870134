import React from "react";
import PropTypes from "prop-types";
import { Alert, Snackbar } from "@mui/material";

const AlertSnackbar = ({
  open,
  text,
  severity,
  handleClose,
  vertical,
  horizontal,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <div>
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}
        >
          {text}
        </Alert>
      </div>
    </Snackbar>
  );
};

AlertSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  severity: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
};

AlertSnackbar.defaultProps = {
  vertical: "bottom",
  horizontal: "center",
  severity: "success",
};

export default AlertSnackbar;
