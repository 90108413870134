import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, TextField, Box, FormControl, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import { useTranslation } from "react-i18next";
import CircularProgress from "components/CircularProgress";
import { WorkflowContext } from "context/WorkflowContext";
import CustomButton from "../CustomButton";

const useStyles = makeStyles()((theme) => ({
  boxCustomCss: {
    transform: "translate(-50%,-50%)",
    "&:focus": {
      outline: "none",
    },
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const ManageWorkflowModal = ({
  open,
  save,
  isLoaded,
  handleCloseModal,
  isUpdate,
  nameWorkflow,
  selectedWorkflow,
  workflows,
  type,
  handleSelectedWorkflow,
  disablePortal,
  isStartNewWorkflow,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { currentWorkflow } = useContext(WorkflowContext);
  const [titleWorkflow, setTitleWorkflow] = useState({
    error: false,
    data: "",
  });
  const [workflowId, setWorkflowId] = useState(null);
  const [searchWorkflow, setSearchWorkflow] = useState("");
  useEffect(() => {
    if (nameWorkflow) {
      setTitleWorkflow({
        error: false,
        data: nameWorkflow,
      });
    }
  }, [nameWorkflow]);

  const handleOnChange = (event) => {
    let error = false;
    if (!event.target.value) {
      error = true;
    }
    setTitleWorkflow({
      error,
      data: event.target.value,
    });
  };

  const createWorkflow = () => {
    if (!titleWorkflow.data) {
      setTitleWorkflow({
        error: true,
        data: "",
      });
      return;
    }
    save(titleWorkflow.data);
  };

  const handleWorkflow = (value) => {
    setWorkflowId(value);
  };

  const changeWorkflow = () => {
    handleSelectedWorkflow(workflowId);
    handleCloseModal();
  };

  const selectCurrentWorkflow = () => {
    handleSelectedWorkflow(currentWorkflow.id);
    handleCloseModal();
  };

  useEffect(() => {
    setWorkflowId(selectedWorkflow);
  }, [selectedWorkflow]);

  const getTitle = () => {
    const mapTitle = {
      edit: t("updateWorkflow"),
      select: t("selectReviewTitle"),
    };
    return mapTitle[`${type}`] ?? "";
  };

  const handleFilter = (item) => {
    const nameWorkflow = workflows[`${item}`]?.name.toLowerCase();
    return nameWorkflow.includes(searchWorkflow.toLowerCase());
  };

  return (
    <Modal
      open={open}
      disablePortal={disablePortal}
      style={{ position: "absolute", height: "100vh" }}
      BackdropProps={{ style: { position: "absolute" } }}
      onClose={handleCloseModal}
    >
      <Box
        bgcolor="white"
        position="absolute"
        top="50%"
        left="50%"
        className={classes.boxCustomCss}
        borderRadius="18px"
      >
        <ModalHeader
          title={isUpdate ? getTitle() : t("newWorkflow")}
          subtitle={!isUpdate ? t("newWorkflowSubtitle") : ""}
          closeModal={handleCloseModal}
          closeButton={type === "select"}
        />
        <Box>
          {type === "select" && (
            <>
              <Box bgcolor="secondary.lighter" py={4} px={8}>
                <Typography variant="h6">{t("currentWorkflow")}</Typography>
                <CustomButton
                  workflows={workflows}
                  handleWorkflow={handleWorkflow}
                  selectedId={workflowId}
                  id={currentWorkflow.id}
                />
              </Box>
              <Box py={4} px={8}>
                <Typography variant="h6">{t("oldWorkflows")}</Typography>
                {Object.keys(workflows).length > 10 && (
                  <FormControl fullWidth>
                    <TextField
                      variant="filled"
                      margin="dense"
                      size="small"
                      value={searchWorkflow}
                      id="search-workflow"
                      label={t("search")}
                      onChange={(event) =>
                        setSearchWorkflow(event.target.value)
                      }
                    />
                  </FormControl>
                )}
                <Box style={{ overflowY: "auto", maxHeight: "200px" }} mt={4}>
                  {selectedWorkflow &&
                    Object.keys(workflows).length > 0 &&
                    Object.keys(workflows)
                      .filter(
                        (item) =>
                          workflows[`${item}`].endDate && handleFilter(item),
                      )
                      .map((item, index) => (
                        <CustomButton
                          key={`${index}-${item}`}
                          workflows={workflows}
                          handleWorkflow={handleWorkflow}
                          selectedId={workflowId}
                          id={item}
                        />
                      ))}
                </Box>
              </Box>
            </>
          )}
          {type === "edit" && (
            <Box p={4}>
              <FormControl fullWidth>
                <TextField
                  variant="filled"
                  margin="dense"
                  size="small"
                  value={titleWorkflow.data}
                  id="new-workflow-id"
                  label={t("workflowTitle")}
                  onChange={handleOnChange}
                  error={titleWorkflow.error}
                  helperText={
                    titleWorkflow.error && t("nameWorkflowIsRequired")
                  }
                />
              </FormControl>
            </Box>
          )}
        </Box>
        <ModalFooter
          action={type === "select" ? changeWorkflow : createWorkflow}
          actionText={isUpdate ? t("confirm") : t("createWorkflow")}
          showCloseButton={type === "select" || isStartNewWorkflow}
          closeText={
            isStartNewWorkflow ? t("back") : t("selectCurrentWorkflow")
          }
          onClose={
            isStartNewWorkflow ? handleCloseModal : selectCurrentWorkflow
          }
          {...(type === "select" && {
            color: "primary",
            justifyContent: "space-between",
          })}
        />
        {!isLoaded && <CircularProgress />}
      </Box>
    </Modal>
  );
};

ManageWorkflowModal.propTypes = {
  open: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func,
  isUpdate: PropTypes.bool,
  nameWorkflow: PropTypes.string,
  selectedWorkflow: PropTypes.string,
  workflows: PropTypes.object,
  type: PropTypes.string.isRequired,
  handleSelectedWorkflow: PropTypes.func.isRequired,
  disablePortal: PropTypes.bool,
  isStartNewWorkflow: PropTypes.bool,
};

ManageWorkflowModal.defaultProps = {
  disablePortal: true,
};

export default ManageWorkflowModal;
