import gql from "graphql-tag";

const SaveEvaluationCard = gql`
  mutation SaveEvaluationCard(
    $activeStructure: String!
    $activeGroup: String!
    $idWorkflow: String!
    $input: SaveEvaluationCardInput!
  ) {
    saveEvaluationCard(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idWorkflow: $idWorkflow
      input: $input
    ) {
      cardStatus
      collaboratorNotification
    }
  }
`;

export default SaveEvaluationCard;
