import { shadows } from "../globals/shadows";

export const button = {
  MuiButton: {
    root: {
      borderRadius: 39,
    },
    contained: {
      boxShadow: shadows[3],
      fontWeight: 600,
    },
    containedSecondary: {
      color: "#fff",
    },
    containedPrimary: {
      color: "#fff",
    },
    text: {
      "&:hover": {
        background: "none",
      },
    },
    startIcon: {
      marginRight: 4,
    },
  },
};
