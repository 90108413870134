const sidebarItems = [
  {
    code: "dashboards",
    text: "dashboard",
    type: "macroItem",
    authorizedRoles: ["UserPoolGroupAdmin", "UserPoolGroupBoss"],
    items: [
      {
        code: "adoption",
        url: "/adoption",
        text: "adoptionPage",
        authorizedRoles: ["UserPoolGroupAdmin"],
      },
      {
        code: "home",
        url: "/",
        text: "pageHome",
        authorizedRoles: [
          "UserPoolGroupAdmin",
          "UserPoolGroupBoss",
          "UserPoolGroupCoBoss",
          "UserPoolGroupCollaborator",
        ],
      },
      {
        code: "finalReview",
        url: "/dashboard-final-review",
        text: "finalReviewPage",
        authorizedRoles: ["UserPoolGroupAdmin", "UserPoolGroupBoss"],
      },
    ],
  },
  {
    code: "home",
    url: "/",
    text: "pageHome",
    authorizedRoles: ["UserPoolGroupCollaborator"],
  },
  {
    code: "feedbacks",
    url: "/feedbacks",
    text: "pageFeedback",
    authorizedRoles: [
      "UserPoolGroupAdmin",
      "UserPoolGroupBoss",
      "UserPoolGroupCoBoss",
      "UserPoolGroupCollaborator",
    ],
  },
  {
    code: "structures",
    url: "/structures",
    text: "pageStructures",
    authorizedRoles: ["UserPoolGroupAdmin"],
  },
  {
    code: "users",
    url: "/users",
    text: "pageUsers",
    authorizedRoles: ["UserPoolGroupAdmin"],
  },
  {
    code: "evalutation-items",
    text: "pageItems",
    type: "macroItem",
    authorizedRoles: ["UserPoolGroupAdmin"],
    items: [
      {
        code: "valors",
        url: "/valors",
        text: "valors",
        authorizedRoles: ["UserPoolGroupAdmin"],
      },
      {
        code: "skills",
        url: "/skills",
        text: "skills",
        authorizedRoles: ["UserPoolGroupAdmin"],
      },
    ],
  },
  {
    code: "process",
    url: "/process",
    text: "pageProcess",
    authorizedRoles: ["UserPoolGroupAdmin"],
  },
  {
    code: "workflow",
    url: "/workflow",
    text: "pageWorkflow",
    authorizedRoles: [
      "UserPoolGroupAdmin",
      "UserPoolGroupBoss",
      "UserPoolGroupCoBoss",
      "UserPoolGroupCollaborator",
    ],
  },
  {
    code: "team",
    url: "/team",
    text: "pageTeam",
    authorizedRoles: ["UserPoolGroupBoss"],
  },
  {
    code: "settings",
    url: "/settings",
    text: "settingsPage",
    authorizedRoles: [
      "UserPoolGroupAdmin",
      "UserPoolGroupBoss",
      "UserPoolGroupCoBoss",
      "UserPoolGroupCollaborator",
    ],
  },
];

export default sidebarItems;
