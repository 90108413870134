export const resources = {
  en: {
    translation: {
      "30days": "Last month",
      "3CharsError": "Please enter at least 3 characters",
      actionErrorPageText:
        "Try to <1> <0> reload the page </0> </1> or go back to the <1> <0> home page </0> </1>",
      actionUnlockedAllUsers_plural:
        "Action performed. <strong> {{unlocked}} evaluation cards have been unlocked.</strong>",
      actionUnlockedAllUsers_singular:
        "Action performed. <strong>{{unlocked}} evaluation card</strong> has been unlocked.</strong>",
      actionUnlockedNotWork_plural:
        "It was not possible to unlock <strong> {{locked}} evaluation cards </strong> because they do not contain any evaluation items.",
      actionUnlockedNotWork_singular:
        "It was not possible to unlock <strong> {{locked}} evaluation card </strong> because it does not contain any evaluation items.",
      actionUnlockedSomeUsers_plural_plural:
        "<strong> {{unlocked}} evaluation cards </strong> have been unlocked. It was not possible, however, to unlock <strong> {{locked}} evaluation cards </strong> because these do not contain any evaluation items.",
      actionUnlockedSomeUsers_plural_singular:
        "<strong> {{unlocked}} evaluation cards </strong> have been unlocked. It was not possible, however, to unlock <strong> {{locked}} evaluation card </strong> because this does not contain any evaluation items.",
      actionUnlockedSomeUsers_singular_plural:
        "<strong> {{unlocked}} evaluation card </strong> has been unlocked. It was not possible, however, to unlock <strong> {{locked}} evaluation cards </strong> because these do not contain any evaluation items.",
      actionUnlockedSomeUsers_singular_singular:
        "<strong> {{unlocked}} evaluation card </strong> has been unlocked. It was not possible, however, to unlock <strong> {{locked}} evaluation card </strong> because this does not contain any evaluation items.",
      activeAutovalutation: "Enable self evaluation of the employees",
      admin: "Admin",
      adminApprovalOnEdit:
        "Priority change that requires deletion of previous feedback must be approved by the administrator",
      adminApprovalWarning:
        "By selecting this option, your changes will be submitted to the administrator for approval. Pending approval, your employee will not be able to receive feedback on priorities.",
      addFilter: "Add filter",
      addQuestion: "Add Question",
      adoptionPage: "Adoption",
      adoptionPageSubMenu: "Adoption",
      afterNDays: "After {{num}} days",
      aggregate: "Aggregated",
      alertUnsavedChanges:
        "Are you sure you want to leave this page? The changes that you made may not be saved.",
      alertWorkInProgress: "Updating the content of the page",
      alertWorkInProgressSkill: "Updating competence",
      alertWorkInProgressValor: "Updating the value",
      alertWorkInProgressMacroarea: "Updating the macroarea",
      alertWorkInProgressMacroskill: "Updating macro competence",
      all: "All",
      allCdc: "All facilities",
      allStructure: "Other Teams",
      allTeamFeedbacks: "Feedback from all teams",
      allTeams: "All teams",
      allTeamsDescription:
        "The data presented in the Dashboard relates to the following selected teams:",
      allUsers: "All",
      and: "and",
      answer: "Answer",
      answersValutation: "Answers to evaluation questions",
      answersAutovalutation: "Answers to self-assessment questions",
      applyFilter: "Apply filters",
      approveAllGoals: "Approve all the priorities",
      approvedAllGoalsErrorMessage:
        "An error occurred while trying to save the priorities, please try again later",
      approvedAllGoalsSuccessMessage:
        "All priorities are about to be approved, wait a few minutes",
      approveError: "Error: priorities not approved",
      approveGoals: "Approve priorities",
      approveSuccess: "Approved priorities",
      asc: "Ascending",
      assigned: "Assigned",
      assignTo: "Assign to",
      attachFile: "attach file",
      autovalutations: "Self-assessments",
      autovalutation: "Self evaluation",
      autovalutationSaveError:
        "Error saving the self evaluation, please try again later",
      autovalutationSaveSuccesfully: "Self evaluation successfully saved",
      autovalutationWorkInProgress:
        "The card is being processed. In a few minutes you will be able to proceed with your self evaluation",
      averageGoalRating: "Average score on Priorities",
      averageMacroareeRating: "Average score on Macro Areas of Values",
      averageMacroskillRating: "Average score on the Macro Areas of Competence",
      averageRatingFeedback: "Average ratings from feedback",
      averageRatingManager: "Average ratings from the manager",
      back: "Cancel",
      backEdit: "Cancel Changes",
      backToLogin: "Back to login",
      backToLogin2: "to go back to the login page",
      backToLogin3: "Back to the login page",
      backToStructures: "Back to the team list",
      backToStructure: "Back to the team page",
      backToStructureName: "Back to {{nameTeam}}",
      boss: "Manager",
      bossAnswers: "Evaluation Answers",
      bossQuestionsValutationSettings: "Questions for the manager",
      category: "Category",
      cdc: "Level structures",
      cf: "Fiscal Code",
      click: "Click",
      clickHere: "click here",
      closeProcess: "Closure",
      "closeProcess#1": "",
      closeReview: "close review",
      coboss: "Co-Manager",
      cobosses: "Co-Managers",
      collaborator: "Employee",
      collaboratorAnswers: "Self-evaluation answers",
      collaboratorAutovalutation: "Self evaluation of the employee",
      collaboratorAutovalutations: "Staff self-assessments",
      collaboratorNotes: "Notes of the employee",
      collaboratorQuestionsValutationSettings: "Questions for the employee",
      collaborators: "Employees",
      competence: "Competence",
      competences: "Competences",
      compiledCardsFinalReviewMessage: "Evaluation cards compiled",
      compiled: "Compiled",
      compiledEvaluation: "Compiled",
      compiledFeedbackNotificationText: "Feedback received",
      compiledGoalFeedbackNotificationText: "Feedback received",
      compiledRequest: "Received feedback",
      confirm: "Confirm",
      confirmChanges: "Confirm changes",
      confirmEmailMessage: "We just sent you a confirmation e-mail.",
      "confirmfinalReview#1":
        "Are you sure you want to start the Final Review?",
      "confirmfinalReview#2":
        "Are you sure you want to close the current Review?",
      "confirmfinalReview#1Subtext":
        '{{substitute}} Users have not yet reached the minimum number of feedbacks received to be eligible for the Final Review. You can always admit them manually once the Final Review has started.<br/>In addition, it will no longer be possible to change the "Final Review Type" settings on page\n<1><0>Process</0></1>.',
      "confirmfinalReview#2Subtext":
        "Once the review is closed, the evaluation cards can no longer be modified and employees will be able to view the evaluation received from their respective managers",
      confirmGoalAlert: "Are you sure you want to confirm the priorities?",
      confirmGoalAlertSubtext:
        "If you confirm the priorities, they will be submitted to the administrator for acceptance",
      confirmManualSendEmailSubtitle:
        "Are you sure you want to send welcome emails to the selected users?",
      confirmManualSendEmailTitle: "Manual sending of welcome emails",
      confirmNextPhase: "Are you sure you want to start the next stage?",
      confirmNextPhase2: "Do you want to proceed anyway?",
      confirmNotifyEndCompilation:
        "Are you sure you want to send the notification to your manager?",
      confirmNotifyEndCompilationDescription:
        "You can only send this notification once, but you can still continue to edit your self-assessment afterwards until the administrator stops the review process",
      confirmPassword: "Confirm password",
      confirmResetPasswordDescription:
        "Please, check your mailbox. A link to reset your password has been sent to you",
      confirmSettings2Subtext:
        "While proceeding to the next stage, you will no longer be able to change the settings on the pages",
      confirmTouchpoint: "Confirm touch point",
      confirmTouchpointAlert:
        "Are you sure you want to confirm the touch point?",
      confirmTouchpointGenericErrorMessage:
        "The touch point could not be confirmed",
      confirmTouchpointSuccessMessage: "The touch point has been confirmed",
      contractAll: "Collapse all",
      createdAt: "Created on",
      createMacroAreaSuccess: "The macroarea has been successfully created",
      createSkillSuccess: "The competence has been successfully created",
      createStructureGenericErrorMessage:
        "Something went wrong while creating the team. Please try again later",
      createStructureSuccessMessage: "The team has been successfully created",
      createValorSuccess: "The value has been successfully created",
      createWorkflow: "Confirm and launch",
      creationDate: "Creation date",
      ctaEvalutationForm: "Submit self evaluation",
      currentPassword: "Current password",
      currentStage: "Current stage:",
      currentWorkflow: "Current Review",
      dashboard: "Dashboard",
      dateRange: "Date range",
      delete: "Delete",
      deletedUsers: "Deleted users",
      deleteFeedbackAlert: "Are you sure you want to delete this feedback?",
      deleteFeedbackGenericErrorMessage: "The feedback could not be deleted",
      deleteFeedbackSuccessMessage: "Feedback successfully deleted",
      deleteFeedbackTimeErrorMessage:
        "The time limit to delete the feedback has expired",
      deleteMacroArea: "Delete Macroarea",
      deleteMacroSkill: "Delete Macroarea",
      deleteRequestFeedbackAlert:
        "Are you sure you want to delete the feedback request?",
      deleteRequestFeedbackGenericErrorMessage:
        "The feedback request could not be deleted",
      deleteRequestFeedbackSuccessMessage:
        "Feedback request successfully deleted",
      deleteRequestFeedbackTimeErrorMessage:
        "The time limit to delete the feedback request has expired",
      deleteSkill: "Delete Competence",
      deleteStructureAlert: "Are you sure you want to delete the team",
      deleteStructureErrorMessage: "Team not deleted",
      deleteStructureSuccessMessage: "Team successfully deleted",
      deleteUser: "Delete user",
      deleteValor: "Delete value",
      desc: "Descending",
      deselectBelow: "Deselect underlyings",
      describeYourIssue: "Describe your issue",
      description: "Description",
      detail: "Detail",
      didNotReceivedEmail: "Didn't receive the email?",
      disabled: "Disabled",
      disabledUsers: "Disabled users",
      disableUser: "Disable user",
      done: "Occurred",
      edit: "Edit",
      editImage: "Edit image",
      editMacroArea: "Edit Macroarea",
      editMacroSkill: "Edit Macroarea",
      editPassword: "Change Password",
      editSelection: "Edit Selection",
      editSkill: "Edit Competence",
      editUser: "Edit user",
      editValor: "Edit value",
      editWorkflow: "Edit title",
      elementAverageRating: "Average scores for assessment elements",
      endedTouchpoints: "Happened Touch point",
      email: "Email",
      emailUserCollabs: "Email for employees",
      emailCollabsLabel: "Enable automatic welcome email for employees",
      emailsSentSuccessfully: "Email successfully sent",
      en: "English",
      enableAll: "Enable all",
      enableAllUser: "All users enabled",
      enabled: "Enabled",
      enableGenericFeedback: "Enable generic feedback",
      enabledUsers: "Enabled users",
      enableUser: "Enable user",
      endDate: "End date",
      errorCheckPassword: "The two passwords must match",
      errorExport: "Error: export not started correctly",
      errorEmail: "Email is not valid",
      errorFormatImage: "This image format is not valid",
      errorImport: "An error occurred while importing users and teams.",
      errorNameAlreadyExist:
        "This name is already used by the team of {{manager}}. Try to choose a different name",
      errorOnUserUpdate:
        "It is not possible to execute this operation at the moment. Please try again later",
      errorPageText: "Something went wrong",
      errorPassword: "Password is not valid",
      errorPasswordFormat: "Incorrect password format",
      errorPasswordFormat2:
        "Incorrect password format, the password must have at least 8 alphanumeric characters",
      errorPasswordNotLongEnough: "Password must contain at least N characters",
      errorPasswordNumericCharacters:
        "Password must contain numeric characters",
      errorSessionIsExpired: "Error: the session has expired",
      es: "Spanish",
      evaluationCardSaveSuccessfully: "Evaluation card successfully saved",
      evaluationCardErrorSave:
        "Error saving the evaluation card, please try again later",
      evalutationCard: "Evaluation card",
      evalutationElements: "Evaluation items",
      evalutationForms: "Evaluation cards",
      evalutationIndices: "Evaluation indices",
      evalutationItems: "Evaluation items",
      expandAll: "Show all",
      export: "Export as CSV",
      exportXlsx: "Export as Excel",
      exportAllCsv: "Export all as CSV",
      exportAllMembers: "Export all members as a CSV file",
      exportAllTeams: "Export all teams as a CSV file",
      exportAllMembersXlsx: "Export all members as an Excel file",
      exportAllTeamsXlsx: "Export all members as an Excel file",
      exportCsv: "Export as CSV",
      exportDashboardError:
        "An error occurred while exporting the dashboard, please try again later",
      exportEvalutationCardError:
        "An error occurred while exporting the evaluation cards, please try again later",
      exportEvalutationCardSuccessfully:
        "Ongoing export process, you will soon receive an email",
      exportFeedbackMessage:
        "Ongoing export process, you will soon receive an email",
      exportPdf: "Export in PDF",
      exportSelected: "Export selected as a CSV file",
      exportSelectedXlsx: "Export selected as an Excel file",
      exportStructuresErrorMessage:
        "An error occurred while trying to export the teams, please try again later",
      exportStructuresSuccessMessage:
        "Ongoing teams export process, you will soon recive an E-mail with the CVS file",
      exportUsersErrorMessage:
        "An error occurred while trying to export the users, please try again later",
      exportUsersSuccessMessage:
        "Ongoing users export process, you will soon receive an email with the csv file",
      feedback: "Feedback",
      feedbackForMacroareaSettings: "N° of feedback for Macroarea",
      feedbackForMacroSkillSettings:
        "N° of feedbacks per Macro Area of Competence",
      feedbackForPrioritySettings: "N° of feedback for Priority",
      feedbackNature: "Feedback type",
      feedbackNotificationText: "Received feedback",
      feedbacksPerPerson: "Feedback sent per person",
      fileIsAlreadyExists: "You've already uploaded this file",
      filterBy: "Sort by",
      filterDate: "Date of the process",
      filterDateOrProcess: "Date or stage of the process",
      finalNotes: "Final Notes",
      finalReview: "Final Review",
      "finalReview#1": "Compilation of the evaluation cards",
      "finalReview#2": "Closing evaluation cards",
      finalReviewPage: "Final Review",
      finalReviewPageSubMenu: "Final Review",
      finalReviewSettings: "Type of Final Review",
      finalReviewSettingsMissing: "Enter the final review settings to go on",
      finalReviewLockedEvaluation: "Blocked",
      finalReviewNotCompiledEvaluation: "Not compiled",
      finalReviewSaveSuccess: "Final Review settings saved successfully",
      finalScoreManager: "Final manager's evaluation",
      finalScore: "Final score",
      finalScores: "Final scores",
      forgotPassword: "Password recovery",
      forgotPasswordDescription:
        "Please enter your email. We will send you instructions to reset your password",
      from: "From",
      fromReviewStart: "from the beginning of the Review",
      general: "General",
      generals: "General",
      generalFeedback: "General Feedbacks",
      generalSettings: "General Settings",
      generic: "Generic",
      genericError: "Sorry, an error has occurred. Please try again later",
      genericFeedback: "Generic Feedbacks",
      generics: "Generics",
      goal: "Priority",
      goalFeedback: "Priority Feedbacks",
      goalFeedbackNotificationText: "Feedback received",
      goals: "Priorities",
      google: "Log in with Google",
      helloTextDashboard: "Hi {{helloTextAttribute}}",
      HelloTextSubtitle:
        "Activity graphs will be shown at the start of the {{namePhase}}",
      here: "here",
      hideAnswers: "Hide answers",
      highestDeviation: "Higher maximum deviation",
      import: "Import",
      infoSaveAutovaluationCard:
        "It is always possible to edit the fields of your scorecard until the administrator stops the current review process. Once you have filled in the form you can also send a completion notification to your manager",
      infoSaveAutovaluationCardWithoutNotification:
        "It is always possible to edit the fields of your scorecard until the administrator stops the current review process. You have already sent the completion notification to your manager",
      infoSaveEvaluationCard:
        "It is always possible to edit the fields of your co-workers' records as long as the administrator does not interrupt the current review process. Once the review has been interrupted, the evaluations will be made visible to your co-workers.",
      insertDescription: "Enter description",
      insertNameOrEmail: "Enter username or email address",
      insertQuestionText: "Write question text",
      invalidUser: "User not found",
      invalidFormat: "Invalid file format",
      it: "Italian",
      keepgoing: "Keep Going",
      labelDescriptionFeedback: "Describe your path",
      locked: "Blocked cards",
      lockedCardsOnGoingMessage: "Cards with insufficient feedback",
      lockedCardsFinalReviewMessage: "Locked evaluation cards",
      last30days: "during the last 30 days",
      lastFeedback: "Last feedback sent",
      lastFeedbacksSent: "Sent feedback per person during the last 30 days",
      lastLogin: "Last Login",
      lastLoginMessage: "Date and time of the last Login",
      lastweek: "Last week",
      lang: "Language",
      login: "Log In",
      loginDescription: "Enter your credentials and log in to the dashboard",
      loginError: "Email or password is incorrect",
      logout: "Logout",
      lowestDeviation: "Lower maximum deviation",
      macroarea: "Macroarea",
      macroskill: "Macro Area",
      manageStructures: "Manage teams",
      manager: "Manager",
      managerNotDefined: "Undefined Manager",
      managerNotes: "Manager's Notes",
      managerRating: "Manager's rating",
      managerValutation: "Manager assessment",
      managerValutations: "Managers' assessments",
      manualSend: "Manual sending",
      manualSendSubtitle:
        "The manual sending of the welcome emails allows you to notify selected users who have never logged into the platform",
      midYearReview: "Mid Year Review",
      "midYearReview#1": "Self evaluation",
      "midYearReview#2": "Manager evaluation",
      "midYearReview#3": "Admin approval",
      "midYearReview#4": "Closing evaluation card",
      moreOne: "more than 1",
      moreTen: "more than 10",
      multipleChoiceQuestion: "Multiple choice question",
      name: "Name",
      nameMacroAreaIsAlreasyExists: "Error: the macroarea name already exists",
      nPrioritiess: "N° of priorities",
      nameStructure: "Team Name",
      nameSurname: "Name Surname",
      nameWorkflowIsRequired: "Please enter the Review name",
      nature: "Type",
      natureGraph: "Keep going/Think about",
      natureFeedback: "Feedback type",
      nPrioritySettings: "N° of priorities to be assigned to each employee",
      nCoBoss: "N° of Co-Managers",
      nCollabs: "N° of Employees",
      nEvalutationFormsToBeFilled: "{{num}} Evaluation cards to fill out",
      new: "New",
      newFeedback: "New feedback",
      newMacroArea: "New Macroarea",
      newMacroSkill: "New Macroarea",
      newPassword: "New password",
      newQuestion: "New question",
      newSkill: "New competence",
      newStructure: "New Team",
      newValor: "New value",
      newWorkflow: "Start a new Review",
      newWorkflowCanBeExecuted: "New Review can be started",
      newWorkflowIsInProcess: "Creation of the new Review is in process",
      newWorkflowSubtitle:
        "To get started, write the title of the new Review. You can also edit it later by accessing to the Review page",
      nextStage: "Go to the next stage",
      noEnabledUsers: "There are no enabled employees within this team",
      noEvalutationForm: "Evaluation card is not avaliable",
      noEvalutationForms: "Evaluation card is not avaliable",
      noEvalutationFormsMessage:
        "Selected search criteria do not match any evaluation form",
      noFeedbackSent: "No feedback sent",
      noLogin: "No Login",
      none: "No profile",
      noStructureCollaboratoreWarning: "Team of reference is not defined",
      notAssigned: "Not assigned",
      notCompiled: "Not compiled",
      notCompiledCardsOnGoingMessage:
        "Cards with reached minimum number of feedbacks",
      notCompiledCardsFinalReviewMessage: "Evaluation cards not compiled",
      notCompiledRequest: "Not received feedbacks",
      note: "Notes",
      notFoundPage: "Page not found",
      notReadsFeedback: "Unread feedbacks",
      notice: "Notifications",
      "notification#autoEvaluation_adminUnlock#description":
        "Now you can proceed to your self evaluation",
      "notification#autoEvaluation_adminUnlock#object": "Self evaluation",
      "notification#autoEvaluation_collabAndBoss_active#description":
        "The Final Review has started. From this moment it is no longer possible to send feedback. Now you can proceed to your self evaluation",
      "notification#autoEvaluation_collabAndBoss_active#object":
        "Final review and self evaluation",
      "notification#autoEvaluation_onlyCollab_active#description":
        "The Final Review has started. From this moment it is no longer possible to send feedback. You can now proceed to your self evaluation",
      "notification#autoEvaluation_onlyCollab_active#object":
        "Final review and self evaluation",
      "notification#autoEvaluation_onlyCollab_deactivate#description":
        "The Final Review has started. From this moment it is no longer possible to send feedback",
      "notification#autoEvaluation_onlyCollab_deactivate#object":
        "Final review",
      "notification#confirmTouchpoint#description":
        "It has been confirmed that the touch point relating to the feedback having object: {{object}} has happened",
      "notification#confirmTouchpoint#object": "Occurred Touch point",
      "notification#finalEvaluation_active#description":
        "The Final Review has started. From this moment it is no longer possible to send feedback. Now you can proceed with the evaluation of your employees",
      "notification#finalEvaluation_active#object":
        "Final review and evaluation",
      "notification#finalEvaluation_adminUnlock#description":
        "One or more employees of your team have been admitted to the Final Review. From this moment you can proceed to their evaluation",
      "notification#finalEvaluation_adminUnlock#object": "Final review",
      "notification#finalEvaluation_deactivate#description":
        "The Final Review has started. From this moment it is no longer possible to send feedback",
      "notification#finalEvaluation_deactivate#object": "Final review",
      "notification#finalReview#description":
        "{{otherData}} users have not reached the minimum number of feedbacks to access the evaluation",
      "notification#finalReview#object": "Reminder",
      "notification#goalsApproved#description":
        "Your request for user {{otherData}} priority replacement has been approved",
      "notification#goalsApproved#object":
        "Priority substitution has been approved",
      "notification#goalsApprovedCollab#description":
        "Your priorities have been updated. View your profile to see the changes",
      "notification#goalsApprovedCollab#object": "Change priorities",
      "notification#goalsAssigned#description":
        "The expected priorities have been assigned to the user {{nameUserSender}}. Go to the Users page to confirm them",
      "notification#goalsAssigned#object": "Priority assignment",
      "notification#goalsEdited#description":
        "A priority replacement has been requested for user {{otherData}}. Go to the User section to manage it",
      "notification#goalsEdited#object": "Priority substitution",
      "notification#goalsRejected#description":
        "The priorities assigned to the user {{nameUserSender}} have not been approved. Go back to the user's page to edit and resend them",
      "notification#goalsRejected#object": "Rejected priorities",
      "notification#goalsRejectedEdit#description":
        "Your request for user {{otherData}} priority replacement has not been approved",
      "notification#goalsRejectedEdit#object":
        "Substitution of priority not approved",
      "notification#newUsers#description":
        "You have new employees in your Team! Proceed to the assignment of priorities",
      "notification#newUsers#object": "Priority assignment",
      "notification#notifyCollaboratorCardCompilation#description":
        "The collaborator {{collaborator}} completed its self-assessment",
      "notification#notifyCollaboratorCardCompilation#object":
        "Self-assessment completed",
      "notification#reminder#description":
        "Your employees are waiting for your feedback! Send one now",
      "notification#reminder#object": "Reminder",
      "notification#requestTouchpoint#description":
        "Requested a touch point on the feedback having object: {{object}}. Go to your feedback to see the details",
      "notification#requestTouchpoint#object": "Touch point request",
      "notification#startSettings#3#description":
        "Hi {{nameUserRecipient}}, now you can assign the priorities to your employees! Go to the Your Team page and proceed with the assignment",
      "notification#startSettings#3#object": "Priority assignment",
      "notification#stopFinalReview_activeCollab#object": "Closing review",
      "notification#stopFinalReview_activeCollab#description":
        "The review {{otherData}} has just ended. A new review will be launched shortly. You can now view your evaluation form",
      "notification#stopFinalReview_noActiveCollab#object": "Closing review",
      "notification#stopFinalReview_noActiveCollab#description":
        "The review {{otherData}} has just ended. A new review will be launched shortly",
      notifyEndCompilation: "Notification of completion",
      notifyEndCompilationError:
        "A problem occurred while sending the completion notification",
      notifyEndCompilationSuccesfully:
        "Notification of successful completion sent",
      numSendFeedbacks: "Trend sent feedback per person",
      object: "Subject",
      office365: "Sign in with Office 365",
      oldWorkflows: "Old Reviews",
      onGoing: "Ongoing",
      "onGoing#1": "Ongoing",
      onGoing2: "2° Ongoing",
      "onGoing2#1": "",
      onGoing2Phase: "Stage Ongoing 2",
      onGoingLockedEvaluation: "Insufficient feedback",
      onGoingNotCompiledEvaluation: "Minimum n° of feedbacks reached",
      onGoingPhase: "Stage of ongoing",
      onGoingSaveFailed:
        "A problem occurred while saving the Ongoing settings. Try later.",
      onGoingSaveSuccess: "Ongoing settings saved successfully",
      onGoingSettings: "Settings of the Ongoing stage",
      openQuestion: "Open-ended question",
      operationGenericErrorMessage:
        "Something went wrong. Please try again later",
      orCredentials: "or enter your credentials",
      orderBy: "Order by",
      pageFeedback: "Feedback",
      pageHome: "Ongoing",
      pageHomeSubMenu: "Ongoing",
      pageItems: "Evaluation items",
      pageProcess: "Process",
      pageStructures: "Team",
      pageTeam: "Your team",
      pageUsers: "Users",
      pageWorkflow: "Review",
      parentStructure: "Higher Team",
      partiallyCompiledCardsFinalReviewMessage:
        "Evaluation cards partially compiled",
      partiallyCompiled: "Partially completed",
      partiallyCompiledEvaluation: "Partially compiled",
      password: "Password",
      passwordModified: "Password changed",
      passwordModifiedDescription:
        "Your password has been successfully changed. Go back to the login page and log in",
      pending: "Requested",
      pendingGoalMessage: "Waiting for approval",
      pendingTouchpoints: "Unhappened Touch point",
      personalDataTitle: "Registry",
      phaseOf: "Stage of",
      placeholderDescriptionFeedback: "Write a description",
      placeholderQuestionText: "Question text",
      preferences: "Settings",
      problems: "Issues found:",
      problemsIsNotReady:
        'There are just few steps left to start the next stage "{{nameSubphase}}"',
      problemsIsReady: "Warning:",
      problemsIsNotReadySubtitle: "Missing steps:",
      process: "Process",
      processOnGoingFeedbacksHeader:
        "Minimum N° of feedbacks needed for the final evaluation",
      profession: "Cluster",
      profile: "Profile",
      project: "Project",
      projects: "Projects",
      quality: "Qualitative",
      quantity: "Quantitative",
      questionsValutationSettings: "Questions for the evaluation",
      rangeRatingSettings: "Range of variation of the employee's score",
      rating: "Rating",
      ratingScale: "Evaluation scale",
      RatingScaleLabel: "Default labels of the evaluation scale",
      readsFeedback: "Read feedbacks",
      received: "Received",
      receivedFeedback: "Received feedbacks",
      recipient: "Recipient",
      recoveryPassword: "Forgot your password?",
      rejectError: "Error: priorities could not be rejected",
      rejectGoals: "Reject priorities",
      rejectedGoals: "Priorities not approved",
      rejectSuccess: "Priorities rejected successfully",
      reminder: "Reminder",
      reminderNoAccessSettings: "No access to the platform",
      reminderNoFeedbackSettings: "No feedback sent",
      reportingFinalReview: "Final Review Dashboard",
      requestFeedback: "Request Feedback",
      requestFeedbackNotificationText: "Feedback request received",
      requestForFeedback: "Feedback requests",
      requestGoalFeedbackNotificationText: "Feedback request received",
      requestorNotes: "Notes of the applicant",
      requestTouchpoint: "Request touch point",
      requestTouchpointAlert:
        "Are you sure you want to request the touch point?",
      requestTouchpointGenericErrorMessage: "Could not request the touch point",
      requestTouchpointSuccessMessage:
        "Touch point request submitted successfully",
      requestsTouchpoint: "Touch point requests",
      requiredError: "Required field",
      requiredErrorRating: "You need to enter a rating for the feedback",
      requiredFeedback: "Feedback requests",
      replaceAGoal:
        "Do not consider for the final evaluation the feedback sent so far in the current review for this priority",
      resetPassword: "Edit password",
      resetPasswordDescription: "Enter a new password",
      reporting: "Dashboard of Ongoing",
      review: "Last Review",
      role: "Role",
      roleTenant: "Job title",
      save: "Save changes",
      score: "Rating",
      search: "Search",
      selectedAll: "Select All",
      selectATeam: "Select a team",
      selectBelow: "Select the underlyings",
      selectCdc: "Select facilities",
      selectCurrentWorkflow: "Select current Review",
      selectedTeam: "Selected teams ({{num}})",
      selectLang: "Select the language",
      selectMultipleAnswerQuestion: "Select the multiple choice question",
      selectTeamMember: "Select team members",
      selectTypeOfQuestion: "Select question type",
      selectReview: "Select Review",
      selectReviewTitle: "Select Review",
      selectUserToNotify: "Select users to notify",
      selectSkill: "Select competence",
      selectValor: "Select value",
      send: "Send",
      sendAgain: "Submit again",
      sender: "Sender",
      senderFeedbackNotes: "Notes of the sender",
      sendEmail: "send email",
      sendEmailsError: "Message failed to send, please try again later",
      sendEvalutationForms: "Submit all evaluations",
      sendEvalutationForm: "Submit evaluation",
      sendFeedback: "Send Feedback",
      sendFeedbackGenericErrorMessage: "Feedback not submitted",
      sendFeedbacks: "Sent feedback",
      sendFeedbackSuccessMessage: "Feedback submitted successfully",
      sendGoals: "Confirm priorities",
      sendNotification: "Send notification",
      sendRequestFeedbackGenericErrorMessage: "Feedback request not submitted",
      sendRequestFeedbackSuccessMessage:
        "Feedback request submitted successfully",
      sends: "Sent",
      sendSupportRequest: "Submit a support request",
      sendWelcomeEmail: "send now welcome email",
      sentSupportEmail:
        "Your support request has been submitted successfully. We have sent a copy to the address {{email}}",
      settings: "Setting",
      "settings#1": "Settings teams and users",
      "settings#2": "Creation of the evaluation cards",
      "settings#3": "Priority assignment",
      settingsMissing: "Failure to define process settings.",
      settingsPage: "Settings",
      "UserPoolGroupAdmin#settingsMissingPage": "Move to the Process page.",
      showOthers: "Show more",
      showWorkflows: "Archive Review",
      singleRatingLabel: "Rating title",
      singleStructure: "Team of reference",
      sixToTen: "from 6 to 10",
      sizeOver8mb: "The size of the attached files is bigger than 8 MB",
      skill: "Competencies",
      skills: "Competencies",
      skillFeedback: "Competencies feedback",
      skillsSubMenu: "Competencies",
      startDate: "Starting date",
      status: "Status",
      structure: "Team",
      structures: "Teams",
      structuresMissings: "Not uploaded teams.",
      "UserPoolGroupAdmin#structuresMissingsPage": "Go to the Teams page.",
      subCategory: "Subcategory",
      summary: "Overview",
      summaryEvalutation: "Summary Evaluation",
      support: "Support",
      surname: "Surname",
      system: "System",
      tableWorkflowAutovalutation: "Self evaluation",
      tableWorkflowKeepGoing: "N° Keep going",
      tableWorkflowMacroAreaRating: "Macroarea average evaluation",
      tableWorkflowRating: "Feedback average evaluation",
      tableWorkflowThinkAbout: "N° Think about",
      tableWorkflowTitle: "Title",
      team: "My Team",
      teamDetail: "Users details",
      teamMember: "Team members",
      teamStructureCaption: "(Manager: you)",
      totals: "Totals",
      theNextPhaseCanBeStarted: "You can now start the next stage",
      theNextPhaseCannotBeStarted:
        "It's still not possible to start the next stage",
      thinkabout: "Think About",
      title: "Title",
      titleEvalutationForm: "Evaluation card",
      titleModalFilter:
        "Add teams to the dashboard by selecting them from the corporate hierarchy",
      titleModalItem: "Feedback details",
      typeautoValutationTitle: "Type of self evaluation of the employees",
      typeGraph: "Feedback category",
      typeValutationTitle: "Type of final evaluation by managers",
      to: "To",
      toEvalutionCard: "Move to the evaluation card",
      toManyRequestError:
        "You have made too many attempts, please try again later",
      totalCards: "Total evaluation cards",
      touchpoint: "Touch Point",
      touchpointConfirmedNotificationText: "Confirmed Touch point",
      touchpointHappened: "Happened",
      touchpointIdle: "Not requested",
      touchpointNotificationText: "Requested Touch point",
      touchpointRequested: "Requested",
      touchpointNotRequested: "Not requested",
      touchpointSenderNotes: "Applicant's notes",
      trendOfFeedback: "Timeline of the received feedbacks",
      trendOfFeedbackAltText: "Timeline of sent feedbacks",
      unassignedGoals: "Priorities not assigned",
      unassignedSkills: "Unassigned competencies",
      unassignedValors: "Values not assigned",
      unconfirmedGoals: "Priorities waiting for approval",
      undefined: "Undefined",
      unlockedError: "Error unlocking the cards. Try later",
      unlockedErrorSingleUser: "Error unlocking the card. Try later",
      unlockedSuccessfully:
        "Cards unlocking started successfully, they will be unlocked soon",
      unlockedSuccessfullySingleUser:
        "Card unlocking started successfully, it will be unlocked soon",
      unlockFinalReview: "unlock locked cards",
      unlockFinalReviewSingleUser: "unlock the card",
      updateMacroArea: "Updating Macroarea",
      updateMacroAreaSuccess: "Macroarea successfully updated",
      updateMacroSkill: "Updating Macroarea",
      updateSettingsSuccess: "Settings successfully updated",
      updateSkill: "Competence Update",
      updateStructureGenericErrorMessage:
        "Something went wrong with the team edit. Try again later",
      updateStructureSuccessMessage: "The team has been successfully edited",
      updateUserStatusErrorMessage: "User status could not be updated",
      updateUserStatusSuccessMessage: "User status successfully updated",
      updateValor: "Updating Values",
      updateSkillSuccess: "The competence has been successfully updated",
      updateValorSuccess: "The value has been successfully updated",
      updateWorkflow: "Edit Review Title",
      user: "User",
      userAlreadyExists: "The user already exists",
      users: "Users",
      usersGoalConfirmation:
        "To {{num}} users were assigned the expected priorities.",
      "UserPoolGroupAdmin#usersGoalConfirmationPage":
        "Move to the Users page to confirm them.",
      usersGoalMissing:
        "To {{num}} users were not assigned the expected priorities.",
      "UserPoolGroupAdmin#usersGoalMissingPage": "Move to the Users page.",
      "UserPoolGroupBoss#usersGoalMissingPage": "Move to the Your Team page.",
      usersMissing: "Not uploaded users.",
      "UserPoolGroupAdmin#usersMissingPage": "Move to the Users page.",
      usersNotEnabled: "There are no enabled users.",
      "UserPoolGroupAdmin#usersNotEnabledPage": "Move to the Users page.",
      userOrEmail: "User or email",
      userDataUpdateError: "Error while updating data",
      userDataUpdateSuccessfully: "Data successfully updated",
      userGoalUpdatedSuccessfully:
        "Your request has been forwarded to the administrator",
      UserPoolGroupBoss: "Manager",
      UserPoolGroupCoBoss: "Co-Manager",
      UserPoolGroupCollaborator: "Employee",
      userUpdatedSuccessfully: "User updated correctly",
      usersSkillMissing: "{{num}} users were not assigned any competence.",
      usersValorMissing: "No value was assigned to {{num}} users.",
      "UserPoolGroupAdmin#usersSkillMissingPage": "Move to the Users page.",
      "UserPoolGroupAdmin#usersValorMissingPage": "Move to the Users page.",
      valor: "Value",
      valorRating: "Value",
      valors: "Values",
      valorsSubMenu: "Values",
      valorFeedback: "Value Feedbacks",
      valutations: "Assessments",
      valutationSaveFailed:
        "A problem occurred while saving the evaluation settings. Please try again later.",
      valutationSaveSuccess:
        "The evaluation settings have been successfully updated",
      valutationSettings: "Evaluation process settings",
      valutationWorkInProgress:
        "The card is being processed. In a few minutes you will be able to proceed with the evaluation of the employee",
      viewAnswers: "Show answers",
      welcome: "Welcome back!",
      welcomeMessage: "Welcome back! Here's your latest news:",
      workflow: "Workflow",
      workflowTitle: "Review Title",
      workflowWithoutName: "Untitled",
      writeFeedback: "Fill in the feedback",
      yourFeedbacks: "Your feedback",
      yourProfile: "Your Profile",
      zeroToFive: "from 0 to 5",
      welcomeEmail: "Welcome Email",
    },
  },
};
export default { resources };
