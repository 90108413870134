import React from "react";
import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as KeepGoing } from "assets/icon/keepgoing.svg";
import PropTypes from "prop-types";

const KeepGoingIcon = ({ isLarge, mr }) => (
  <Box mr={mr ?? 0} clone>
    <SvgIcon component={KeepGoing} fontSize={isLarge ? "large" : "medium"} />
  </Box>
);

KeepGoingIcon.propTypes = {
  isLarge: PropTypes.bool,
  mr: PropTypes.number,
};

export default KeepGoingIcon;
