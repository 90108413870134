import gql from "graphql-tag";

const GetUrls = gql`
  query getS3PutAndGetUrl($files: [FileInput]!) {
    getS3PutAndGetUrl(files: $files) {
      putUrls
      keys
    }
  }
`;

export default GetUrls;
