import { createTheme } from "@mui/material/styles";
import { variables } from "../globals/variables";
const defaultTheme = createTheme();

export const drawer = {
  MuiDrawer: {
    paperAnchorRight: {
      height: `calc(100% - ${variables.navbarHeight}px)`,
      marginTop: variables.navbarHeight,
      width: "100%",
      [defaultTheme.breakpoints.up("md")]: {
        width: "33.33%",
      },
    },
  },
};
