export const getUsersWarningNumber = (
  subPhase = "settings#1",
  settings,
  { usersValorMissing, usersGoalConfirmation, usersSkillMissing },
) => {
  const phasesMap = new Map([
    ["settings#1", 0],
    [
      "settings#2",
      (settings.valorActivated ? usersValorMissing : 0) +
        (settings.skillActivated ? usersSkillMissing : 0),
    ],
    ["settings#3", settings.goalActivated ? usersGoalConfirmation : 0],
  ]);
  const allWarnings =
    usersValorMissing + usersGoalConfirmation + usersSkillMissing;

  return ["settings#1", "settings#2", "settings#3"].includes(subPhase)
    ? phasesMap.get(subPhase ?? "settings#1")
    : allWarnings;
};
