import { useState, useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";
import { palette } from "theme/globals/palette";
import { getRgbaFromHex } from "helpers/GenericHelper";
import { convertTimestamp } from "helpers/DateHelper";

const onHoverFunctions = {
  onHover: function (event) {
    event.native.target.style.cursor = "pointer";
  },
  onLeave: function (event) {
    event.native.target.style.cursor = "default";
  },
};

export const useChartNature = ({
  numKeepGoing,
  numThinkAbout,
  isMediumDesktop,
}) => {
  const { t } = useTranslation();
  const natureFeedRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);

  useEffect(() => {
    if (!natureFeedRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const dataFeedback = [numThinkAbout, numKeepGoing];
    const data = {
      labels: [
        `${t("thinkabout")} (${numThinkAbout})`,
        `${t("keepgoing")} (${numKeepGoing})`,
      ],
      datasets: [
        {
          data: dataFeedback,
          backgroundColor: [palette.thinkAbout.main, palette.keepGoing.main],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: isMediumDesktop ? "bottom" : "right",
          align: isMediumDesktop ? "center" : "start",
          labels: {
            font: {
              size: 12,
            },
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: isMediumDesktop ? 1 : 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(natureFeedRef.current, config);
    setChartNatureFeedback(chart);
  }, [natureFeedRef, numKeepGoing, numThinkAbout, isMediumDesktop]);
  return { natureFeedRef };
};

export const useChartType = ({
  numValor,
  numGoal,
  numSkill,
  numGeneric,
  isMediumDesktop,
  settings,
}) => {
  const { t } = useTranslation();
  const typeFeedRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);
  const { showGoal, showGeneric, showValor, showSkill } = settings;
  useEffect(() => {
    if (!typeFeedRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const dataFeedback = [
      ...(showGoal ? [numGoal] : []),
      ...(showValor ? [numValor] : []),
      ...(showGeneric ? [numGeneric] : []),
      ...(showSkill ? [numSkill] : []),
    ];
    const data = {
      labels: [
        ...(showGoal ? [`${t("goalFeedback")} (${numGoal})`] : []),
        ...(showValor ? [`${t("valorFeedback")} (${numValor})`] : []),
        ...(showGeneric ? [`${t("genericFeedback")} (${numGeneric})`] : []),
        ...(showSkill ? [`${t("skillFeedback")} (${numSkill})`] : []),
      ],
      datasets: [
        {
          data: dataFeedback,
          backgroundColor: [
            ...(showGoal ? [palette.primary.dark] : []),
            ...(showValor ? [palette.primary.main] : []),
            ...(showGeneric ? [palette.grey[400]] : []),
            ...(showSkill ? ["#aa8def"] : []),
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: isMediumDesktop ? "bottom" : "right",
          align: isMediumDesktop ? "center" : "start",
          labels: {
            font: {
              size: 12,
            },
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: isMediumDesktop ? 1 : 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(typeFeedRef.current, config);
    setChartNatureFeedback(chart);
  }, [
    typeFeedRef,
    numValor,
    numGoal,
    numSkill,
    numGeneric,
    isMediumDesktop,
    showValor,
    showGeneric,
    showGoal,
    showSkill,
  ]);
  return { typeFeedRef };
};

export const useChartRead = ({ numRead, numNotRead, isMediumDesktop }) => {
  const { t } = useTranslation();
  const readFeedRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);

  useEffect(() => {
    if (!readFeedRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const dataFeedback = [numRead, numNotRead];
    const data = {
      labels: [
        `${t("readsFeedback")} (${numRead})`,
        `${t("notReadsFeedback")} (${numNotRead})`,
      ],
      datasets: [
        {
          label: "My First Dataset",
          data: dataFeedback,
          backgroundColor: [palette.primary.main, palette.grey[400]],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: isMediumDesktop ? "bottom" : "right",
          align: isMediumDesktop ? "center" : "start",
          labels: {
            font: {
              size: 12,
            },
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: isMediumDesktop ? 1 : 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(readFeedRef.current, config);
    setChartNatureFeedback(chart);
  }, [readFeedRef, numRead, numNotRead, isMediumDesktop]);
  return { readFeedRef };
};

export const useChartRequest = ({ numCompiled, numNotCompiled }) => {
  const { t } = useTranslation();
  const requestRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);

  useEffect(() => {
    if (!requestRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const dataFeedback = [numCompiled, numNotCompiled];
    const data = {
      labels: [
        `${t("compiledRequest")} (${numCompiled})`,
        `${t("notCompiledRequest")} (${numNotCompiled})`,
      ],
      datasets: [
        {
          data: dataFeedback,
          backgroundColor: [palette.secondary.main, palette.grey[400]],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: "right",
          align: "start",
          labels: {
            font: {
              size: 12,
            },
            padding: 16,
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(requestRef.current, config);
    setChartNatureFeedback(chart);
  }, [requestRef, numCompiled, numNotCompiled]);
  return { requestRef };
};

export const useChartTouchpoint = ({
  numTouchpointsEnded,
  numTouchpointsPending,
}) => {
  const { t } = useTranslation();
  const touchpointRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);
  useEffect(() => {
    if (!touchpointRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const dataFeedback = [numTouchpointsEnded, numTouchpointsPending];
    const data = {
      labels: [
        `${t("endedTouchpoints")} (${numTouchpointsEnded})`,
        `${t("pendingTouchpoints")} (${numTouchpointsPending})`,
      ],
      datasets: [
        {
          label: "My First Dataset",
          data: dataFeedback,
          backgroundColor: [palette.secondary.main, palette.grey[400]],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: "right",
          align: "start",
          labels: {
            font: {
              size: 12,
            },
            padding: 16,
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(touchpointRef.current, config);
    setChartNatureFeedback(chart);
  }, [touchpointRef, numTouchpointsEnded, numTouchpointsPending]);
  return { touchpointRef };
};

export const useChartFeedbackNature = ({
  thinkAboutOverTime,
  keepGoingOverTime,
  numKeepGoing,
  numThinkAbout,
  language,
  numFeedbacks,
  isMobile,
  isSmartphone,
  period,
  endDate,
}) => {
  const { t } = useTranslation();
  const feedbackNatureRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);
  useEffect(() => {
    if (!feedbackNatureRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const keepGoingData = keepGoingOverTime.map(({ count }) => count);
    const thinkAboutData = thinkAboutOverTime.map(({ count }) => count);
    let days = [];
    if (period === "30days") {
      days = keepGoingOverTime.map(({ date }, index) => [
        convertTimestamp(language, date, null, true),
        ...(keepGoingOverTime[`${index + 1}`]?.date
          ? [
              convertTimestamp(
                language,
                keepGoingOverTime[`${index + 1}`]?.date,
                null,
                true,
                false,
                true,
              ),
            ]
          : [convertTimestamp(language, endDate ?? Date.now(), null, true)]),
      ]);
    } else {
      days = keepGoingOverTime.map(({ date }) =>
        convertTimestamp(language, date, null, true, period === "review"),
      );
    }
    const totalsData = thinkAboutData.map(
      (value, index) => value + keepGoingData[`${index}`],
    );
    const totalsMax = Math.max(...totalsData);
    const data = {
      labels: days,
      datasets: [
        {
          label: `${t("keepgoing")} (${numKeepGoing})`,
          data: keepGoingData,
          borderColor: palette.keepGoing.main,
          backgroundColor: palette.grey[100],
        },
        {
          label: `${t("thinkabout")} (${numThinkAbout})`,
          data: thinkAboutData,
          borderColor: palette.thinkAbout.main,
          backgroundColor: palette.grey[100],
        },
        {
          label: `${t("totals")} (${numFeedbacks})`,
          data: totalsData,
          borderColor: getRgbaFromHex(palette.primary.light, 0.5),
          fill: "start",
          backgroundColor: getRgbaFromHex(palette.primary.light, 0.5),
        },
      ],
    };
    const config = {
      type: "line",
      data,
      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            beginAtZero: true,
            ...(totalsMax < 10 && {
              max: totalsMax && totalsMax % 2 === 0 ? totalsMax : totalsMax + 1,
            }),
            ticks: {
              precision: 0,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            ...onHoverFunctions,
            position: "bottom",
            align: "start",
          },
          title: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const labels = [t("keepgoing"), t("thinkabout"), t("totals")];
                return `${labels[`${context.datasetIndex}`]}: ${
                  context.formattedValue
                }`;
              },
            },
          },
        },
        aspectRatio: isMobile ? (isSmartphone ? 1 : 2) : 3,
      },
    };
    const chart = new Chart(feedbackNatureRef.current, config);
    setChartNatureFeedback(chart);
  }, [
    feedbackNatureRef,
    thinkAboutOverTime,
    keepGoingOverTime,
    isMobile,
    isSmartphone,
  ]);
  return { feedbackNatureRef };
};

export const useChartFeedbackType = ({
  genericOverTime,
  goalOverTime,
  valorOverTime,
  numGoal,
  numValor,
  numGeneric,
  numSkill,
  skillOverTime,
  language,
  numFeedbacks,
  isMobile,
  isSmartphone,
  settings: { showGoal, showValor, showGeneric, showSkill },
  period,
  endDate,
}) => {
  const { t } = useTranslation();
  const feedbackTypeRef = useRef(null);
  const [chartNatureFeedback, setChartNatureFeedback] = useState(null);
  useEffect(() => {
    if (!feedbackTypeRef.current) {
      return;
    }
    if (chartNatureFeedback) {
      chartNatureFeedback.destroy();
    }
    const genericData = genericOverTime.map(({ count }) => count);
    const goalData = goalOverTime.map(({ count }) => count);
    const valorData = valorOverTime.map(({ count }) => count);
    const skillData = skillOverTime.map(({ count }) => count);
    let days = [];
    if (period === "30days") {
      days = genericOverTime.map(({ date }, index) => [
        convertTimestamp(language, date, null, true),
        ...(genericOverTime[`${index + 1}`]?.date
          ? [
              convertTimestamp(
                language,
                genericOverTime[`${index + 1}`]?.date,
                null,
                true,
                false,
                true,
              ),
            ]
          : [convertTimestamp(language, endDate ?? Date.now(), null, true)]),
      ]);
    } else {
      days = genericOverTime.map(({ date }) =>
        convertTimestamp(language, date, null, true, period === "review"),
      );
    }
    const totalsData = genericData.map(
      (value, index) =>
        value +
        valorData[`${index}`] +
        goalData[`${index}`] +
        skillData[`${index}`],
    );
    const totalsMax = Math.max(...totalsData);
    const data = {
      labels: days,
      datasets: [
        ...(showGoal
          ? [
              {
                label: `${t("goals")} (${t(numGoal)})`,
                data: goalData,
                borderColor: palette.primary.dark,
                backgroundColor: palette.grey[100],
              },
            ]
          : []),
        ...(showValor
          ? [
              {
                label: `${t("valors")} (${t(numValor)})`,
                data: valorData,
                borderColor: palette.primary.main,
                backgroundColor: palette.grey[100],
              },
            ]
          : []),
        ...(showGeneric
          ? [
              {
                label: `${t("generics")} (${t(numGeneric)})`,
                data: genericData,
                borderColor: palette.grey[400],
                backgroundColor: palette.grey[100],
              },
            ]
          : []),
        ...(showSkill
          ? [
              {
                label: `${t("skills")} (${t(numSkill)})`,
                data: skillData,
                borderColor: "#aa8def",
                backgroundColor: palette.grey[100],
              },
            ]
          : []),
        {
          label: `${t("totals")} (${t(numFeedbacks)})`,
          data: totalsData,
          borderColor: getRgbaFromHex(palette.primary.light, 0.5),
          fill: "start",
          backgroundColor: getRgbaFromHex(palette.primary.light, 0.5),
        },
      ],
    };
    const config = {
      type: "line",
      data,
      options: {
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            beginAtZero: true,
            ...(totalsMax < 10 && {
              max: totalsMax && totalsMax % 2 === 0 ? totalsMax : totalsMax + 1,
            }),
            ticks: {
              precision: 0,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            ...onHoverFunctions,
            position: "bottom",
            align: "start",
          },
          title: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const labels = [
                  ...(showGoal ? [t("goals")] : []),
                  ...(showValor ? [t("valors")] : []),
                  ...(showGeneric ? [t("generics")] : []),
                  t("totals"),
                ];
                return `${labels[`${context.datasetIndex}`]}: ${
                  context.formattedValue
                }`;
              },
            },
          },
        },
        aspectRatio: isMobile ? (isSmartphone ? 1 : 2) : 3,
      },
    };
    const chart = new Chart(feedbackTypeRef.current, config);
    setChartNatureFeedback(chart);
  }, [
    feedbackTypeRef,
    genericOverTime,
    goalOverTime,
    valorOverTime,
    isMobile,
    isSmartphone,
    showGoal,
    showGeneric,
    showValor,
  ]);
  return { feedbackTypeRef };
};

export default {
  useChartNature,
  useChartType,
  useChartRead,
  useChartRequest,
  useChartTouchpoint,
  useChartFeedbackNature,
  useChartFeedbackType,
};
