import gql from "graphql-tag";

const SearchUserByKeyword = gql`
  query listUsersByKeyword($keyword: String!, $status: UserStatus) {
    listUsersByKeyword(keyword: $keyword, status: $status) {
      idUser
      name
      surname
      fullname: sort2
      cf
      email
      profession
    }
  }
`;

export default SearchUserByKeyword;
