import gql from "graphql-tag";

const updateNotificationStatus = gql`
  mutation updateNotificationStatus(
    $idNotification: String!
    $read: Boolean!
    $notificationRoles: [String]!
  ) {
    updateNotificationStatus(
      idNotification: $idNotification
      read: $read
      notificationRoles: $notificationRoles
    )
  }
`;

export default updateNotificationStatus;
