import gql from "graphql-tag";

const onUpdateCurrentPhase = gql`
  subscription onUpdateCurrentPhase($idTenant: String!) {
    onUpdateCurrentPhase(idTenant: $idTenant) {
      phase
      subPhase
      idTenant
    }
  }
`;

export default onUpdateCurrentPhase;
