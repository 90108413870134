import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { Avatar, Typography, Box, Button } from "@mui/material";
import { convertTimestamp } from "../../../helpers/DateHelper";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import { getUrl } from "./functions/helper";
import { AuthContext } from "components/auth/Authenticator";

const useStyles = makeStyles()((theme) => ({
  notificationItemRead: {
    backgroundColor: theme.palette.grey[200],
  },
  notificationItem: {
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  notificationAvatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  notificationLink: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  notificationType: {
    fontFamily: ["Comfortaa", "cursive"].join(","),
  },
  createdAtText: {
    color: theme.palette.grey[400],
  },
}));

const SystemNotification = ({ notification, handleClickNotice }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    createdAt,
    object,
    description,
    read,
    nameUserRecipient,
    nameUserSender,
    id,
    otherData,
  } = notification;
  const { language } = useContext(AuthContext);

  const [readNotice, setReadNotice] = useState(read);
  const [isDisabled, setDisabled] = useState(false);

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  });

  const handleClick = async (
    readNotice,
    getUrl,
    notification,
    setReadNotice,
  ) => {
    setDisabled(true);
    await handleClickNotice(
      readNotice,
      getUrl,
      notification,
      setReadNotice,
      id,
    );
    if (isMounted) {
      setDisabled(false);
    }
  };
  return (
    <Box
      display="flex"
      padding={4}
      position="relative"
      className={
        readNotice ? classes.notificationItemRead : classes.notificationItem
      }
    >
      <Avatar className={classes.notificationAvatar}>
        <SettingsIcon />
      </Avatar>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box fontWeight={600} clone>
            <Typography variant="subtitle1">{t("system")}</Typography>
          </Box>
          <Typography variant="caption" className={classes.createdAtText}>
            {convertTimestamp(language, createdAt)}
          </Typography>
        </Box>
        <Box color="secondary.dark" clone>
          <Typography className={classes.notificationType} variant="subtitle1">
            {t(object)}
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          {t(description, { nameUserRecipient, nameUserSender, otherData })}
        </Typography>
      </Box>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={isDisabled}
        onClick={() =>
          handleClick(readNotice, getUrl, notification, setReadNotice)
        }
        className={classes.notificationLink}
      ></Button>
    </Box>
  );
};

SystemNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  handleClickNotice: PropTypes.func.isRequired,
};

export default SystemNotification;
