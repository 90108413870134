import gql from "graphql-tag";

const DashboardFinalReviewAdmin = gql`
  query dashboardFinalReviewAdmin(
    $structures: [String]
    $users: [String]
    $cdc: [CdcDashboardFinalReviewInput]
    $language: String
  ) {
    dashboardFinalReviewAdmin(
      structures: $structures
      users: $users
      cdc: $cdc
      language: $language
    ) {
      valutationCounters {
        compiled
        partiallyCompiled
        notCompiled
        locked
      }
      autovalutationCounters {
        compiled
        partiallyCompiled
        notCompiled
        locked
      }
      scoresOverRating {
        numScores
        rating
      }
      scoresOverRatingAutovalutation {
        numScores
        rating
      }
      priorityRating
      macroareaRatings {
        id
        macroarea
        ratingAverage
      }
      macroskillRatings {
        id
        macroskill
        ratingAverage
      }
      questions {
        id
        question
        answers {
          answer
          percentage
        }
      }
      bossQuestions {
        id
        question
        answers {
          answer
          percentage
        }
      }
      isSingleUser
      valutationScore
      autovalutationScore
    }
  }
`;

export default DashboardFinalReviewAdmin;
