import * as Sentry from "@sentry/react";

const ignoreMessage = [
  "Network Error",
  "Temporary password has expired and must be reset by an administrator.",
  "Connection closed",
  "Failed to fetch",
  "Incorrect username or password.",
  "Password does not conform to policy: Password not long enough",
];

/**
 * funzione di helper per catturare gli errori con sentry.
 *
 * @param {String} key chiave scope
 * @param {String} value valore scope
 * @param {Array} errors lista di errori
 */
export const captureException = (key, value, error, extra) => {
  const scope = new Sentry.Scope();

  scope.setTag(key, value);
  scope.setExtra("data", extra);
  const listError = Array.isArray(error)
    ? error
    : error?.errors ?? error?.error?.errors;
  if (typeof error === "string") {
    if (ignoreMessage.includes(error)) {
      return;
    }
    Sentry.captureException(new Error(error), scope);
    return;
  }
  if (Array.isArray(listError)) {
    listError.forEach((error) => {
      if (error?.message) {
        if (ignoreMessage.includes(error.message)) {
          return;
        }
        Sentry.captureException(new Error(error.message), scope);
        return;
      }
      Sentry.captureException(
        new Error(`Error tracing issue: ${JSON.stringify(error)}`),
        scope,
      );
    });
    return;
  }
  if (error?.message) {
    if (ignoreMessage.includes(error.message)) {
      return;
    }
    Sentry.captureException(new Error(error.message), scope);
    return;
  }
  Sentry.captureException(
    new Error(
      `Error tracing issue, error message not found: ${JSON.stringify(error)}`,
    ),
    scope,
  );
};

export default { captureException };
