import React from "react";
import { Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import panelImage from "assets/img/dashboard_1.png";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: theme.spacing(5),
    height: theme.spacing(100),
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      height: theme.spacing(50),
    },
  },
  header: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  titleContainer: {
    color: theme.palette.primary.dark,
  },
  subtitle: {
    fontSize: "0.875rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
  containerImage: {
    width: "100%",
    height: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      width: "40%",
      height: "100%",
      justifyContent: "flex-end",
    },
  },
}));

const PlaceholderDashboard = ({ activeGroup, name, namePhase }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const helloTextAttribute =
    activeGroup === "UserPoolGroupAdmin" ? t("admin") : name;
  return (
    <Paper elevation={3} className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant="h4">
            {`${t("helloTextDashboard", { helloTextAttribute })},`}
          </Typography>
        </div>
        <div>
          <p className={classes.subtitle}>
            {t("HelloTextSubtitle", { namePhase })}
          </p>
        </div>
      </div>
      <div className={classes.containerImage}>
        <img src={panelImage} alt="welcomeImage" height="120%"></img>
      </div>
    </Paper>
  );
};

PlaceholderDashboard.propTypes = {
  activeGroup: PropTypes.string.isRequired,
  name: PropTypes.string,
  namePhase: PropTypes.string.isRequired,
};

export default PlaceholderDashboard;
