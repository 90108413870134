import gql from "graphql-tag";

const GetInitWorkflowData = gql`
  query getInitWorkflowData {
    getCurrentWorkflow {
      id
      name
      startDate
      startFinalReview
    }
    readCurrentPhase {
      phase
      subPhase
    }
    getRating {
      rating
      labels
      threshold
    }
  }
`;

export default GetInitWorkflowData;
