import React from "react";
import PropTypes from "prop-types";
import NotificationsNoneTwoTone from "@mui/icons-material/NotificationsNoneTwoTone";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";

const NotificationIcon = ({ action, openNotifications }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box
      color={
        openNotifications
          ? "secondary.main"
          : isDesktop
            ? "grey.100"
            : "primary.light"
      }
    >
      <IconButton color="inherit" onClick={action} size="large">
        <NotificationsNoneTwoTone />
      </IconButton>
    </Box>
  );
};

NotificationIcon.propTypes = {
  action: PropTypes.func.isRequired,
  openNotifications: PropTypes.bool,
};

export default React.memo(
  NotificationIcon,
  (prevProps, nextProps) =>
    prevProps.openNotifications === nextProps.openNotifications,
);
