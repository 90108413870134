import React, { useContext } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAverageValueByAverageIndex } from "components/workflow/EvalutationForm/helpers";
import PropTypes from "prop-types";
import { InfoContext } from "context/InfoContext";

const ElementAverageRating = ({
  rating,
  priorityRating,
  macroareaRatings,
  macroskillRatings,
}) => {
  const { t } = useTranslation();
  const {
    settings: { goalActivated, valorActivated, skillActivated },
  } = useContext(InfoContext);

  const { sumRatingsMacroareas, numRatedMacroareas } = (
    macroareaRatings ?? []
  ).reduce(
    (accumulator, { ratingAverage }) => {
      if (!isNaN(parseInt(ratingAverage, 10))) {
        accumulator.sumRatingsMacroareas += ratingAverage;
        accumulator.numRatedMacroareas += 1;
      }
      return accumulator;
    },
    {
      sumRatingsMacroareas: 0,
      numRatedMacroareas: 0,
    },
  );
  const averageAllMacroeareas = sumRatingsMacroareas / numRatedMacroareas;

  return (
    <Box mt={4}>
      <Paper elevation={3}>
        <Box p={4}>
          <Typography variant="h6">{t("elementAverageRating")}</Typography>
        </Box>
        {goalActivated && (
          <Box
            width="100%"
            bgcolor="primary.main"
            color="white"
            px={4}
            py={2}
            borderBottom="1px solid white"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              {t("averageGoalRating")}
            </Typography>
            <Typography variant="h5">
              {getAverageValueByAverageIndex(rating, priorityRating)}
            </Typography>
          </Box>
        )}
        {valorActivated && (
          <>
            <Box
              width="100%"
              bgcolor="primary.main"
              color="white"
              px={4}
              py={2}
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">
                {t("averageMacroareeRating")}
              </Typography>
              <Typography variant="h5">
                {getAverageValueByAverageIndex(rating, averageAllMacroeareas)}
              </Typography>
            </Box>
            {(macroareaRatings ?? []).map(
              ({ macroarea, ratingAverage }, index) => (
                <Box
                  key={`${macroarea}-${index}`}
                  width="100%"
                  borderBottom="1px solid #DFDADA"
                  px={4}
                  py={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">{macroarea}</Typography>
                  <Typography variant="h5">
                    {getAverageValueByAverageIndex(rating, ratingAverage)}
                  </Typography>
                </Box>
              ),
            )}
          </>
        )}
        {skillActivated && (
          <>
            <Box
              width="100%"
              bgcolor="primary.main"
              color="white"
              px={4}
              py={2}
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">
                {t("averageMacroskillRating")}
              </Typography>
              <Typography variant="h5">
                {getAverageValueByAverageIndex(rating, averageAllMacroeareas)}
              </Typography>
            </Box>
            {(macroskillRatings ?? []).map(
              ({ macroskill, ratingAverage }, index) => (
                <Box
                  key={`${macroskill}-${index}`}
                  width="100%"
                  borderBottom="1px solid #DFDADA"
                  px={4}
                  py={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">{macroskill}</Typography>
                  <Typography variant="h5">
                    {getAverageValueByAverageIndex(rating, ratingAverage)}
                  </Typography>
                </Box>
              ),
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};

ElementAverageRating.propTypes = {
  rating: PropTypes.array.isRequired,
  priorityRating: PropTypes.number,
  macroareaRatings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      macroarea: PropTypes.string.isRequired,
      ratingAverage: PropTypes.number,
    }),
  ),
  macroskillRatings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      macroskill: PropTypes.string.isRequired,
      ratingAverage: PropTypes.number,
    }),
  ),
};

export default ElementAverageRating;
