import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles()((theme) => ({
  subHeader: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(14),
    outline: "none",
    padding: theme.spacing(2, 4),
  },
}));

const ElementSelect = ({
  selectableValors,
  touched,
  error,
  formik,
  disabled,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const children = Object.entries(selectableValors).reduce(
    (accumulator, [macroElement, valors], index) => {
      accumulator.push(
        <ListSubheader value="" className={classes.subHeader} key={index}>
          {macroElement}
        </ListSubheader>,
      );
      valors.forEach((valor) => {
        accumulator.push(
          <MenuItem value={valor.selectIndex} key={valor.id}>
            {valor.title}
          </MenuItem>,
        );
      });
      return accumulator;
    },
    [<MenuItem value="" key="select-valor-key" label={t("selectValor")} />],
  );
  return (
    <Box mb={{ xs: 5, md: 9 }}>
      <TextField
        fullWidth
        select
        variant="filled"
        margin="dense"
        size="small"
        name="subCategory"
        label={t("subCategory")}
        required
        error={touched && error != null}
        helperText={error}
        disabled={disabled}
        {...formik}
      >
        {children}
      </TextField>
    </Box>
  );
};

export default ElementSelect;

ElementSelect.propTypes = {
  selectableValors: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ),
  ),
  touched: PropTypes.bool,
  error: PropTypes.string,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
