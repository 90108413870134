import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { WorkflowContext } from "context/WorkflowContext";

const useStyles = makeStyles()((theme) => ({
  mainButton: {
    position: "fixed",
    right: 0,
    marginRight: theme.spacing(6),
    paddingRight: theme.spacing(3),
    bottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      right: `33%`,
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      right: `calc(((100% - ${theme.drawerWidth}px)/3))`,
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      right: `calc(((100% - ${theme.drawerWidth}px)/4))`,
      marginRight: theme.spacing(3),
    },
  },
  noFilter: {
    [theme.breakpoints.up("sm")]: {
      right: 0,
    },
  },
}));

const ButtonsFeedback = ({ isBoss, noFilter, openModal, handleOpen }) => {
  const { classes } = useStyles();
  const { subPhase } = useContext(WorkflowContext);
  const { t } = useTranslation();
  return (
    <>
      {!openModal &&
        ![
          "settings#1",
          "settings#2",
          "settings#3",
          "finalReview#1",
          "finalReview#2",
        ].includes(subPhase) && (
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            aria-label="add"
            className={`${classes.mainButton} ${
              noFilter ? classes.noFilter : ""
            }`}
            onClick={() => handleOpen(isBoss ? "send" : "request")}
          >
            <AddIcon />
            {isBoss ? t("sendFeedback") : t("requestFeedback")}
          </Fab>
        )}
    </>
  );
};

ButtonsFeedback.propTypes = {
  isBoss: PropTypes.bool,
  noFilter: PropTypes.bool.isRequired,
  openModal: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
};

export default ButtonsFeedback;
