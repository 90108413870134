import gql from "graphql-tag";

const GetAllStructures = gql`
  query getAllStructures($language: String) {
    getAllStructures(language: $language) {
      structures
    }
  }
`;

export default GetAllStructures;
