import gql from "graphql-tag";

const GetStructureUsers = gql`
  query listStructureUsers(
    $activeStructure: String
    $activeGroup: String!
    $idStructure: String!
    $limit: Int
    $next: NextInput
    $roleFilter: [RoleFilter]
    $status: UserStatus
    $isArchive: Boolean
    $idWorkflow: String
    $orderedByName: Boolean
  ) {
    listStructureUsers(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idStructure: $idStructure
      limit: $limit
      next: $next
      roleFilter: $roleFilter
      status: $status
      isArchive: $isArchive
      idWorkflow: $idWorkflow
      orderedByName: $orderedByName
    ) {
      items {
        id
        name
        surname
        urlGet
        numKeepGoing
        numThinkAbout
        email
        fullname
        role
        goalsStatus
        numValors
        numSkills
        status
        evaluationStatus
      }
      next {
        key
        sort
        sort3
        sort2
      }
    }
  }
`;

export default GetStructureUsers;
