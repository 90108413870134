import React, { useContext, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DashboardFilter from "./components/DashboardFilter";
import { useStyles } from "helpers/cssCommon";
import { AuthContext } from "components/auth/Authenticator";
import { getFlatDataFromTree } from "@nosferatu500/react-sortable-tree";
import getAllStructures from "pages/admin/structures/functions/getAllStructures";
import PeriodFilter from "./components/PeriodFilter";
import StructureFilter from "./components/StructureFilter";
import { getDataHook } from "./helper/dashboardHelper";
import DataDashboard from "./components/DataDashboard";
import ExportDashboard from "components/base_components/ExportDashboard";
import ExportDashboardQuery from "queries/dashboard/ExportDashboardQuery";
import { API } from "@aws-amplify/api";
import { captureException } from "helpers/SentryHelper";
import AlertSnackbar from "components/AlertSnackbar";

const Dashboard = ({
  activeGroup,
  activeStructure,
  openFilters,
  setOpenFilters,
}) => {
  const { t } = useTranslation();
  const { language, slugTenant } = useContext(AuthContext);
  const { classes } = useStyles();
  const allPeriods = ["review", "30days", "lastweek"];

  const {
    currentTab,
    periodAggregate,
    periodDetail,
    structures,
    selectedStructure,
    singleStructure,
    filterUsers,
    users,
    treeData,
    requestAggregate,
    feedbackAggregate,
    touchpointAggregate,
    requestDetail,
    feedbackDetail,
    touchpointDetail,
    loadingData,
    dataAlert,
    setTreeData,
    setUsers,
    handleSubmitFilter,
    handleChange,
    handleSelectedPeriod,
    getStructures,
    handleSelectedTeam,
    getUsers,
    handleSubmitUserFilter,
    getDataDashboard,
    setLoadingData,
    setStructures,
    handleCloseAlert,
    handleAlert,
  } = getDataHook();

  const collabs = users.data.filter(
    (item) => item.role !== "UserPoolGroupBoss",
  );
  const handleOpenFilter = (value) => {
    setOpenFilters(value);
  };

  /**
   * trigger per effettuare il get delle strutture,
   * il get viene fatto solo quando si ha una lingua definita.
   */
  useEffect(() => {
    let isMounted = true;
    if (!language || activeGroup === "UserPoolGroupCollaborator") {
      return;
    }
    const isBoss = activeGroup === "UserPoolGroupBoss";
    (async () => {
      const structures = await getAllStructures({
        getFlatDataFromTree,
        language,
        isBoss,
        activeGroup,
        activeStructure,
        slugTenant,
      });
      if (isMounted) {
        setTreeData(structures);
      }
    })();
    if (isBoss) {
      (async () => {
        const newAllStructures = await getAllStructures({
          getFlatDataFromTree,
          language,
          isBoss: true,
          activeGroup,
          activeStructure,
          hierarchy: false,
          slugTenant,
        });
        if (isMounted) {
          setStructures(newAllStructures);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [language]);

  useEffect(() => {
    let isMounted = true;
    if (activeGroup === "UserPoolGroupAdmin") {
      (async () => {
        const newStructures = await getStructures();
        if (isMounted) {
          setStructures(newStructures);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * quando nel tab details team viene cambiata struttura vengono resettati gli utenti
   * e vengono presi gli utenti della nuova struttura
   */
  useEffect(() => {
    let isMounted = true;
    if (!singleStructure) {
      return () => {
        isMounted = false;
      };
    }
    setUsers({ loading: true, data: [] });
    (async () => {
      const newUsers = await getUsers(
        singleStructure,
        activeGroup,
        activeStructure,
      );
      if (isMounted) {
        setUsers(newUsers);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [singleStructure]);

  const getNextUsers = async () => {
    const newUsers = await getUsers(
      singleStructure,
      activeGroup,
      activeStructure,
      users.next,
    );

    setUsers({
      ...users,
      data: [...users.data, ...newUsers.data],
      next: newUsers.next,
    });
  };

  const timeframe = {
    review: "REVIEW",
    "30days": "MONTH",
    lastweek: "WEEK",
  };

  const bossDisabledAggregate =
    activeGroup === "UserPoolGroupBoss" && structures.data.length === 1;
  /**
   * questo useeffect serve per fare una query ogni volta che viene cambiato un parametro nei filtri,
   * viene cambiato periodo o ancora tab.
   */
  useEffect(() => {
    const isCollab = activeGroup === "UserPoolGroupCollaborator";
    let isMounted = true;
    if (structures.loading && !isCollab) {
      return () => {
        isMounted = false;
      };
    }
    setLoadingData(true);
    if (isCollab && currentTab === 1) {
      handleChange(null, 0);
    }
    if (bossDisabledAggregate) {
      handleChange(null, 1);
    }
    if ((currentTab === 0 || isCollab) && !bossDisabledAggregate) {
      const allSelected =
        selectedStructure.length === 0 ||
        Object.keys(treeData.nameStructures).length ===
          selectedStructure.length;
      const variables = {
        timeframe: timeframe[`${periodAggregate}`],
        ...(!allSelected &&
          activeGroup !== "UserPoolGroupCollaborator" && {
            structures: selectedStructure.map(({ id }) => id),
          }),
        ...(allSelected && { structures: structures.data.map(({ id }) => id) }),
      };
      (async () => {
        const listResponse = await Promise.all([
          getDataDashboard(variables, "FEEDBACK", false, activeGroup),
          getDataDashboard(variables, "REQUEST", false, activeGroup),
          getDataDashboard(variables, "TOUCHPOINT", false, activeGroup),
        ]);
        if (isMounted) {
          listResponse.forEach(({ data, setData }) => {
            if (data) {
              setData(data);
            }
          });
          setLoadingData(false);
        }
      })();
      return () => {
        isMounted = false;
      };
    }
    if (filterUsers.length === 0 && !singleStructure) {
      if (isMounted) {
        setLoadingData(false);
      }
      return () => {
        isMounted = false;
      };
    }
    const numUsers = users.data.filter(
      (item) => item.role !== "UserPoolGroupBoss",
    ).length;
    const sendUsers = filterUsers.length > 0 && filterUsers.length !== numUsers;
    const variables = {
      timeframe: timeframe[`${periodDetail}`],
      ...(sendUsers && {
        users: filterUsers,
      }),
      ...(!sendUsers && {
        structures: singleStructure,
      }),
    };

    (async () => {
      const listResponse = await Promise.all([
        getDataDashboard(variables, "FEEDBACK", true, activeGroup),
        getDataDashboard(variables, "REQUEST", true, activeGroup),
        getDataDashboard(variables, "TOUCHPOINT", true, activeGroup),
      ]);
      if (isMounted) {
        listResponse.forEach(({ data, setData }) => {
          if (data) {
            setData(data);
          }
        });
        setLoadingData(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    filterUsers,
    singleStructure,
    selectedStructure,
    periodDetail,
    periodAggregate,
    activeGroup,
    structures.loading,
  ]);

  const numUsers = users.data.filter(
    (item) => item.role !== "UserPoolGroupBoss",
  ).length;
  const filterUserActive =
    filterUsers.length > 0 && filterUsers.length !== numUsers;
  const allSelectedStructure =
    selectedStructure.length === 0 ||
    Object.keys(treeData.nameStructures).length === selectedStructure.length;

  const handleExport = async (all, isCollab, isXlsx) => {
    let structures = null;
    if (currentTab === 0) {
      if (!all && !allSelectedStructure && !isCollab) {
        structures = selectedStructure.map(({ id }) => id);
      }
    }
    if (currentTab === 1) {
      if (all || !filterUserActive) {
        structures = singleStructure;
      }
    }
    try {
      const response = await API.graphql({
        query: ExportDashboardQuery,
        variables: {
          isXlsx,
          activeGroup,
          activeStructure,
          timeframe:
            timeframe[`${currentTab === 0 ? periodAggregate : periodDetail}`],
          ...(structures && { structures }),
          language,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...(currentTab === 1 &&
            !structures && {
              users: filterUsers,
              idStructureUsers: singleStructure,
            }),
        },
      });
      if (response.data.exportDashboard) {
        handleAlert(t("exportFeedbackMessage"), "success");
        return;
      }
      handleAlert(t("exportDashboardError"), "error");
    } catch (err) {
      captureException("dashboard", "export", err);
      handleAlert(t("exportDashboardError"), "error");
    }
  };

  const getNumFilters = () => {
    if (currentTab === 0) {
      return selectedStructure?.length;
    }
    return filterUsers?.length;
  };

  return (
    <>
      <Backdrop
        open={loadingData}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>
      <Box
        className={`${
          activeGroup !== "UserPoolGroupCollaborator"
            ? classes.widthResponsive
            : ""
        }
            ${openFilters ? classes.boxWithOpenedFilters : ""}`}
      >
        <Paper elevation={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Typography variant="h4">{t("reporting")}</Typography>
            {(currentTab === 0 || singleStructure) && (
              <ExportDashboard
                exportDashboard={handleExport}
                isAggregate={currentTab === 0}
                disabledPartialExport={
                  (currentTab === 0 && allSelectedStructure) ||
                  (currentTab === 1 && !filterUserActive)
                }
                isCollab={activeGroup === "UserPoolGroupCollaborator"}
              />
            )}
          </Box>
          {activeGroup !== "UserPoolGroupCollaborator" &&
            !bossDisabledAggregate && (
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={currentTab}
                onChange={handleChange}
                aria-label="user-valutation-tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={t("aggregate")}
                  disableRipple={true}
                  disableFocusRipple={true}
                  value={0}
                />
                <Tab
                  label={t("teamDetail")}
                  disableRipple={true}
                  disableFocusRipple={true}
                  value={1}
                />
              </Tabs>
            )}
          {currentTab === 1 && (
            <StructureFilter
              structures={structures}
              singleStructure={singleStructure}
              handleSelectedTeam={handleSelectedTeam}
              activeStructure={activeStructure}
              isBoss={activeGroup === "UserPoolGroupBoss"}
              disabledSelect={bossDisabledAggregate}
            />
          )}
        </Paper>
        {currentTab === 0 && (
          <PeriodFilter
            allPeriods={allPeriods}
            handleSelectedPeriod={handleSelectedPeriod}
            period={periodAggregate}
          />
        )}
        {currentTab === 1 && (
          <PeriodFilter
            allPeriods={allPeriods}
            handleSelectedPeriod={handleSelectedPeriod}
            period={periodDetail}
          />
        )}
        {language && (currentTab === 0 || singleStructure) && (
          <DataDashboard
            openFilters={openFilters}
            language={language}
            isAdmin={activeGroup === "UserPoolGroupAdmin"}
            isCollab={activeGroup === "UserPoolGroupCollaborator"}
            period={currentTab === 0 ? periodAggregate : periodDetail}
            singleStructureId={
              currentTab === 1
                ? singleStructure
                : selectedStructure.length === 1
                  ? selectedStructure[0].id
                  : null
            }
            singleUserId={
              filterUsers.length === 1
                ? filterUsers[0]
                : collabs.length === 1
                  ? collabs[0].id
                  : null
            }
            numKeepGoing={
              currentTab === 0
                ? feedbackAggregate.numKeepGoing
                : feedbackDetail.numKeepGoing
            }
            numThinkAbout={
              currentTab === 0
                ? feedbackAggregate.numThinkAbout
                : feedbackDetail.numThinkAbout
            }
            numValor={
              currentTab === 0
                ? feedbackAggregate.numValor
                : feedbackDetail.numValor
            }
            numSkill={
              currentTab === 0
                ? feedbackAggregate.numSkill
                : feedbackDetail.numSkill
            }
            numGoal={
              currentTab === 0
                ? feedbackAggregate.numGoal
                : feedbackDetail.numGoal
            }
            numGeneric={
              currentTab === 0
                ? feedbackAggregate.numGeneric
                : feedbackDetail.numGeneric
            }
            numRead={
              currentTab === 0
                ? feedbackAggregate.numRead
                : feedbackDetail.numRead
            }
            numNotRead={
              currentTab === 0
                ? feedbackAggregate.numNotRead
                : feedbackDetail.numNotRead
            }
            numCompiled={
              currentTab === 0
                ? requestAggregate.numCompiled
                : requestDetail.numCompiled
            }
            numNotCompiled={
              currentTab === 0
                ? requestAggregate.numNotCompiled
                : requestDetail.numNotCompiled
            }
            numTouchpoints={
              currentTab === 0
                ? touchpointAggregate.numTouchpoints
                : touchpointDetail.numTouchpoints
            }
            numTouchpointsEnded={
              currentTab === 0
                ? touchpointAggregate.numTouchpointsEnded
                : touchpointDetail.numTouchpointsEnded
            }
            numTouchpointsPending={
              currentTab === 0
                ? touchpointAggregate.numTouchpointsPending
                : touchpointDetail.numTouchpointsPending
            }
            keepGoingOverTime={
              currentTab === 0
                ? feedbackAggregate.keepGoingOverTime
                : feedbackDetail.keepGoingOverTime
            }
            thinkAboutOverTime={
              currentTab === 0
                ? feedbackAggregate.thinkAboutOverTime
                : feedbackDetail.thinkAboutOverTime
            }
            genericOverTime={
              currentTab === 0
                ? feedbackAggregate.genericOverTime
                : feedbackDetail.genericOverTime
            }
            goalOverTime={
              currentTab === 0
                ? feedbackAggregate.goalOverTime
                : feedbackDetail.goalOverTime
            }
            valorOverTime={
              currentTab === 0
                ? feedbackAggregate.valorOverTime
                : feedbackDetail.valorOverTime
            }
            skillOverTime={
              currentTab === 0
                ? feedbackAggregate.skillOverTime
                : feedbackDetail.skillOverTime
            }
            numFeedbacks={
              currentTab === 0
                ? feedbackAggregate.numFeedbacks
                : feedbackDetail.numFeedbacks
            }
            numRequests={
              currentTab === 0
                ? requestAggregate.numRequests
                : requestDetail.numRequests
            }
          />
        )}
      </Box>
      {activeGroup !== "UserPoolGroupCollaborator" && (
        <DashboardFilter
          openFilters={openFilters}
          handleOpenFilter={handleOpenFilter}
          showButtonFilter={!openFilters}
          handleSubmitUserFilter={handleSubmitUserFilter}
          handleSubmitFilter={handleSubmitFilter}
          selectedStructure={selectedStructure}
          disabledButton={!treeData.data.length}
          numStructures={Object.keys(treeData.nameStructures).length}
          userFilter={currentTab === 1}
          users={users.data}
          usersLoading={users.loading}
          stopList={!users.next}
          filterUsers={filterUsers}
          treeData={treeData}
          setTreeData={setTreeData}
          numFilters={getNumFilters()}
          getNextUsers={getNextUsers}
        />
      )}
      <AlertSnackbar
        open={dataAlert.open}
        text={dataAlert.text}
        severity={dataAlert.severity}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

Dashboard.propTypes = {
  activeGroup: PropTypes.string.isRequired,
  activeStructure: PropTypes.string,
  name: PropTypes.string,
  openFilters: PropTypes.bool.isRequired,
  setOpenFilters: PropTypes.func.isRequired,
};

export default Dashboard;
