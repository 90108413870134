import React from "react";
import Box from "@mui/material/Box";
import SupportForm from "./SupportForm";

const Guidance = () => {
  return (
    <Box position="relative" display="flex" justifyContent="center">
      <Box width="100%">
        <SupportForm />
      </Box>
    </Box>
  );
};

export default Guidance;
