import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Paper,
  Button,
  Chip,
  LinearProgress,
} from "@mui/material";
import InputField from "components/base_components/InputField";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "tss-react/mui";
import AlertSnackbar from "components/AlertSnackbar";
import { AuthContext } from "components/auth/Authenticator";
import GetUrls from "queries/s3/GetUrls";
import { API } from "@aws-amplify/api";
import SendSupportEmail from "queries/email/sendSupportEmail";
import { useBlockHistory } from "helpers/RoutesHelper";
import { captureException } from "helpers/SentryHelper";

const useStyles = makeStyles()((theme) => ({
  buttonAttach: {
    paddingLeft: 0,
  },
  iconAttach: {
    marginRight: theme.spacing(2),
  },
}));

const SupportForm = () => {
  const { t } = useTranslation();
  const limit = 8388608; // 8mb.
  const { user } = useContext(AuthContext);
  const [attachment, setAttachment] = useState({
    files: [],
    size: 0,
    error: "",
  });
  const [dataAlert, setDataAlert] = useState({
    open: false,
    text: "",
    severity: null,
  });
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const validationSchema = Yup.object({
    object: Yup.string().required(t("requiredError")),
    description: Yup.string().required(t("requiredError")),
  });
  const formik = useFormik({
    initialValues: {
      object: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const result = await API.graphql({
          query: GetUrls,
          variables: {
            files: attachment.files.map(({ name, size, type }) => ({
              name,
              size,
              type,
            })),
          },
        });
        const promises = [];
        const fileKeys = [];
        result.data.getS3PutAndGetUrl.forEach(({ putUrls, keys }, index) => {
          const putParams = JSON.parse(putUrls);
          const form = new FormData();
          Object.keys(putParams.fields).forEach((key) => {
            if (key !== "Content-Type") {
              form.append(key, putParams.fields[`${key}`]);
            }
          });
          form.append("Content-Type", attachment.files[`${index}`].type);
          form.append("file", attachment.files[`${index}`].file);
          fileKeys.push(keys);
          promises.push(
            fetch(putParams.url, {
              method: "POST",
              body: form,
            }),
          );
        });
        await Promise.all(promises);
        const response = await API.graphql({
          query: SendSupportEmail,
          variables: {
            files: fileKeys,
            object: values.object,
            description: values.description,
          },
        });
        setDataAlert({
          open: true,
          text: response.data.sendSupportEmail
            ? t("sentSupportEmail", { email: user.attributes.email })
            : t("genericError"),
          severity: response.data.sendSupportEmail ? "success" : "error",
        });
      } catch (error) {
        setLoading(false);
        captureException("mutation", "send-support-email", error);
      }
      resetForm();
      setLoading(false);
      setAttachment({
        files: [],
        size: 0,
        error: "",
      });
    },
  });
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (!["image/png", "image/jpeg", "application/pdf"].includes(file.type)) {
      setDataAlert({
        open: true,
        text: t("invalidFormat"),
        severity: "error",
      });
      return;
    }
    const size = attachment.size + file.size;
    if (size > limit) {
      setAttachment({ ...attachment, error: t("sizeOver8mb") });
      return;
    }
    const filesName = attachment.files.map((file) => file.name);
    if (filesName.includes(file.name)) {
      setAttachment({ ...attachment, error: t("fileIsAlreadyExists") });
      return;
    }
    setAttachment({
      size,
      files: [
        ...attachment.files,
        {
          file,
          name: file.name,
          size: file.size,
          type: file.type,
        },
      ],
      error: "",
    });
  };
  const deleteAttachment = (index) => {
    const files = [...attachment.files];
    const newSize = attachment.size - attachment.files[`${index}`].size;
    files.splice(index, 1);
    setAttachment({ size: newSize, files, error: "" });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDataAlert({
      ...dataAlert,
      open: false,
    });
  };

  useBlockHistory(formik.dirty, "/guidance", t);

  return (
    <Box bgcolor="common.white">
      <Box bgcolor="common.white" width="100%" clone>
        <Paper elevation={3}>
          <Box px={4} py={5}>
            <Typography variant="h5" component="p">
              {t("sendSupportRequest")}
            </Typography>
          </Box>
        </Paper>
      </Box>
      {loading && <LinearProgress />}
      <Box p={4}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Typography variant="body1" component="p">
            {t("describeYourIssue")}
          </Typography>
          <InputField
            label={t("object")}
            name="object"
            touched={formik.touched.object}
            error={formik.errors.object}
            formik={formik.getFieldProps("object")}
          />
          <InputField
            label={t("description")}
            name="description"
            touched={formik.touched.description}
            error={formik.errors.description}
            formik={formik.getFieldProps("description")}
            other={{ rows: 4, multiline: true }}
          />
          <Box>
            <Box display="none" clone>
              <input
                accept="image/png, image/jpeg, application/pdf"
                id="contained-button-file"
                multiple
                type="file"
                value=""
                onChange={(e) => handleUpload(e)}
              />
            </Box>
            <label htmlFor="contained-button-file">
              <Button
                component="div"
                color="primary"
                className={classes.buttonAttach}
              >
                <AddIcon className={classes.iconAttach} />
                {t("attachFile")}
              </Button>
            </label>
          </Box>
          {attachment.files.length > 0 &&
            attachment.files.map((file, index) => (
              <Box key={`${file.name}-${index}`} mr={2} mb={2} clone>
                <Chip
                  label={file.name}
                  variant="outlined"
                  onDelete={() => deleteAttachment(index)}
                />
              </Box>
            ))}
          {attachment.error && (
            <Box mt={2}>
              <Typography component="p" variant="subtitle2" color="error">
                {attachment.error}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formik.dirty || formik.isSubmitting}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
      </Box>
      <AlertSnackbar
        open={dataAlert.open}
        text={dataAlert.text}
        severity={dataAlert.severity}
        handleClose={handleCloseAlert}
      />
    </Box>
  );
};

export default SupportForm;
