import gql from "graphql-tag";

const CurrentWorkflow = gql`
  query getCurrentWorkflow {
    getCurrentWorkflow {
      id
      name
      startDate
      startFinalReview
    }
  }
`;

export default CurrentWorkflow;
