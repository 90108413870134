import gql from "graphql-tag";

const CreateGoalFeedbackMutation = gql`
  mutation createGoalFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: createGoalFeedbackInput!
  ) {
    createGoalFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      key
      sort
      id
      createdAt
    }
  }
`;

export default CreateGoalFeedbackMutation;
