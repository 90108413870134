import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import { Badge, Box, Avatar } from "@mui/material";
import PopoverIcon from "../base_components/PopoverIcon";
import { userHasMessage, userHasWarning } from "helpers/";
import { WorkflowContext } from "context/WorkflowContext";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import { InfoContext } from "context/InfoContext";
import UserPlaceholder from "components/user/UserPlaceholder";

const UserAvatar = ({
  userImg,
  numValors,
  numSkills,
  goalsStatus,
  hiddenWarning,
  isBoss,
  isNotCollaborator,
  isListFeedback,
  placeHolder,
  initWarningMessage,
}) => {
  const [warningMessage, setWarningMessage] = useState({
    warnings: [],
    messages: [],
  });
  const [imageState, setImageState] = useState({
    loading: true,
    error: false,
  });
  const { t } = useTranslation();
  const { subPhase } = useContext(WorkflowContext);
  const { settings } = useContext(InfoContext);

  useEffect(() => {
    if (initWarningMessage) {
      setWarningMessage(initWarningMessage);
      return;
    }
    if (!hiddenWarning) {
      const warnings = userHasWarning(
        numValors,
        numSkills,
        goalsStatus,
        t,
        isBoss,
        subPhase,
        isNotCollaborator,
        settings.goalActivated,
        settings.valorActivated,
        settings.skillActivated,
      );
      const messages = settings.goalActivated
        ? userHasMessage(goalsStatus, t, isBoss, subPhase, isNotCollaborator)
        : [];
      setWarningMessage({ messages, warnings });
    }
  }, [goalsStatus, numValors, numSkills, settings, initWarningMessage]);

  useEffect(() => {
    setImageState({ loading: true, error: false });
  }, [userImg]);

  const badgeContent = (warningMessage.warnings.length ||
    warningMessage.messages.length) && (
    <PopoverIcon
      Icon={
        (isBoss && warningMessage.warnings.length === 0) ||
        (!isBoss &&
          warningMessage.warnings.length === 1 &&
          warningMessage.warnings[0] === t("unconfirmedGoals"))
          ? AccessTimeTwoToneIcon
          : WarningTwoToneIcon
      }
      popoverText={
        warningMessage.warnings.length > 0
          ? warningMessage.warnings
          : warningMessage.messages
      }
    />
  );
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={badgeContent}
    >
      <Box
        width={isListFeedback ? 40 : 50}
        height={isListFeedback ? 40 : 50}
        clone
        color={imageState.error && placeHolder ? "white" : "primary.main"}
      >
        {userImg && imageState.error ? (
          placeHolder ? (
            <Avatar>{placeHolder}</Avatar>
          ) : (
            <Box>
              <UserPlaceholder
                width={isListFeedback ? 40 : 50}
                height={isListFeedback ? 40 : 50}
              />
            </Box>
          )
        ) : (
          <Box
            width={isListFeedback ? 40 : 50}
            height={isListFeedback ? 40 : 50}
          >
            {imageState.loading && (
              <Skeleton variant="circular" height="inherit">
                <Avatar />
              </Skeleton>
            )}
            <Box display={imageState.loading ? "none" : ""}>
              <img
                src={userImg}
                alt="profile"
                width="100%"
                height="100%"
                onError={() => setImageState({ loading: false, error: true })}
                onLoad={() =>
                  setImageState({ loading: false, error: imageState.error })
                }
                style={{ objectFit: "cover", borderRadius: "50%" }}
              ></img>
            </Box>
          </Box>
        )}
      </Box>
    </Badge>
  );
};

UserAvatar.propTypes = {
  userImg: PropTypes.string,
  numValors: PropTypes.number,
  numSkills: PropTypes.number,
  goalsStatus: PropTypes.string,
  hiddenWarning: PropTypes.bool,
  isBoss: PropTypes.bool,
  isNotCollaborator: PropTypes.bool,
  isListFeedback: PropTypes.bool,
  placeHolder: PropTypes.string,
  initWarningMessage: PropTypes.object,
};

UserAvatar.defaultProps = {
  warningCodes: [],
};

export default UserAvatar;
