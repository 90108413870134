import { createTheme } from "@mui/material/styles";
const defaultTheme = createTheme();

export const container = {
  MuiContainer: {
    root: {
      paddingLeft: 16,
      paddingRight: 16,
      [defaultTheme.breakpoints.up("sm")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
};
