import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  icon: {
    cursor: "pointer",
    color: theme.palette.secondary.dark,
  },
  popover: {
    pointerEvents: "none",
    marginLeft: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  popoverText: {
    fontSize: theme.typography.pxToRem(12),
  },
}));
const PopoverIcon = ({ popoverText, Icon }) => {
  const iconRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Icon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        className={classes.icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ref={iconRef}
        color="secondary"
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        {Array.isArray(popoverText) ? (
          popoverText.map((text) => (
            <Typography key={text} className={classes.popoverText}>
              {text}
            </Typography>
          ))
        ) : (
          <Typography className={classes.popoverText}>{popoverText}</Typography>
        )}
      </Popover>
    </div>
  );
};

PopoverIcon.propTypes = {
  Icon: PropTypes.object.isRequired,
  popoverText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default PopoverIcon;
