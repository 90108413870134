import gql from "graphql-tag";

const DashboardAggregateCollaborator = gql`
  query dashboardAggregateCollaborator(
    $timeframe: DashboardTimeFrame!
    $type: DashboardType!
  ) {
    dashboardAggregateCollaborator(timeframe: $timeframe, type: $type) {
      numFeedbacks
      numRead
      numNotRead
      numThinkAbout
      thinkAboutOverTime {
        count
        date
      }
      numKeepGoing
      keepGoingOverTime {
        count
        date
      }
      numGeneric
      genericOverTime {
        count
        date
      }
      numGoal
      goalOverTime {
        count
        date
      }
      valorOverTime {
        count
        date
      }
      numRequests
      numCompiled
      numNotCompiled
      numTouchpoints
      numTouchpointsPending
      numTouchpointsEnded
      numValor
      numSkill
      skillOverTime {
        count
        date
      }
    }
  }
`;

export default DashboardAggregateCollaborator;
