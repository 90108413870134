import gql from "graphql-tag";

const ExportDashboardQuery = gql`
  mutation exportDashboard(
    $activeGroup: String
    $activeStructure: String
    $timeframe: DashboardTimeFrame!
    $structures: [String]
    $users: [String]
    $language: String
    $timezone: String
    $idStructureUsers: String
    $isXlsx: Boolean
  ) {
    exportDashboard(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      timeframe: $timeframe
      structures: $structures
      users: $users
      language: $language
      timezone: $timezone
      idStructureUsers: $idStructureUsers
      isXlsx: $isXlsx
    )
  }
`;

export default ExportDashboardQuery;
