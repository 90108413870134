import React from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PropTypes from "prop-types";

const useStyles = makeStyles()((theme) => ({
  keepGoing: {
    width: "40%",
    background: "#ddd",
    borderRadius: "0",
    marginRight: "0",
    marginLeft: "0",
  },
  thinkAbout: {
    width: "40%",
    background: "#eee",
    borderRadius: "0",
    marginRight: "0",
    marginLeft: "0",
  },
  block: {
    display: "block",
  },
}));

const NatureFeedback = ({ selectedValue, handleChange }) => {
  const { classes } = useStyles();
  return (
    <RadioGroup
      aria-label="nature"
      name="nature1"
      value={selectedValue}
      onChange={handleChange}
      className={classes.block}
    >
      <FormControlLabel
        value="keepgoing"
        control={<Radio color="default" />}
        label="Keep Going"
        labelPlacement="start"
        className={classes.keepGoing}
      />
      <FormControlLabel
        value="thinkabout"
        control={<Radio />}
        label="Think About"
        labelPlacement="start"
        className={classes.thinkAbout}
      />
    </RadioGroup>
  );
};

NatureFeedback.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default NatureFeedback;
