import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  ListItem,
  Box,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import GetIcon from "./components/GetIcon";
import { useTranslation } from "react-i18next";
import { variables } from "theme/globals/variables";

const useStyles = makeStyles()((theme, _, classes) => ({
  listItem: {
    color: theme.palette.grey["300"],
    marginBottom: theme.spacing(4),
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    [`&:hover, &.${classes.selectedItem}`]: {
      backgroundColor: "transparent",
      color: theme.palette.grey["100"],
    },
    [`&:hover .${classes.listIcon}, &.${classes.selectedItem} .${classes.listIcon}`]:
      {
        color: theme.palette.secondary.main,
      },
    "&:after": {
      content: '""',
      position: "absolute",
      height: 30,
      left: 0,
      width: 4,
      borderRadius: 2,
    },
    [`&:hover:after, &.${classes.selectedItem}:after`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  selectedItem: {},
  listIcon: {
    color: theme.palette.grey["300"],
    minWidth: "auto",
  },
}));

const ItemMenu = ({ item, info, activeGroup, activeStructure, isSelected }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const listItemStyles = makeStyles()((theme) => ({
    root: {
      flex: "0 0 auto",
      margin: 0,
    },
    primary: {
      fontFamily: variables.fontFamilyTitle,
    },
  }));
  const { classes: listItemClass } = listItemStyles();
  const IconSwitcher = useCallback((icon) => {
    return GetIcon(icon);
  });
  return (
    <ListItem
      button
      key={item.text}
      className={cx(classes.listItem, {
        [classes.selectedItem]: isSelected,
      })}
      onClick={() => {
        navigate(item.url);
      }}
    >
      <Box minWidth="auto" mr={2} clone={true} position="relative">
        <ListItemIcon className={classes.listIcon}>
          {IconSwitcher(item.code)}
        </ListItemIcon>
      </Box>

      <ListItemText
        classes={listItemClass}
        primaryTypographyProps={{ variant: "subtitle2" }}
        primary={t(item.text)}
      />

      <Box color="secondary.main" marginLeft={2}>
        <Typography variant="subtitle1">
          {info[
            `${activeGroup}#${item.code}${
              activeStructure ? "#" + activeStructure : ""
            }`
          ] > 0
            ? "(" +
              info[
                `${activeGroup}#${item.code}${
                  activeStructure ? "#" + activeStructure : ""
                }`
              ] +
              ")"
            : ""}
        </Typography>
      </Box>
    </ListItem>
  );
};

ItemMenu.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    authorizedRoles: PropTypes.array.isRequired,
  }),
  info: PropTypes.object.isRequired,
  activeGroup: PropTypes.string.isRequired,
  activeStructure: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
};

export default React.memo(ItemMenu);
