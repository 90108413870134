import React, { useContext } from "react";
import { AuthContext } from "components/auth/Authenticator";
const FeedbacksAdmin = React.lazy(() => import("../pages/admin/feedbacks"));
const FeedbacksBoss = React.lazy(() => import("../pages/boss/feedbacks"));
const FeedbacksCollaborator = React.lazy(
  () => import("../pages/collaborator/feedbacks"),
);

const getFeedbacksComponent = () => {
  const { activeGroup, activeStructure } = useContext(AuthContext);
  switch (activeGroup) {
    case "UserPoolGroupAdmin":
      return (
        <FeedbacksAdmin
          activeGroup={activeGroup}
          activeStructure={activeStructure}
        />
      );
    case "UserPoolGroupBoss":
      return (
        <FeedbacksBoss
          activeGroup={activeGroup}
          activeStructure={activeStructure}
        />
      );
    case "UserPoolGroupCollaborator":
      return (
        <FeedbacksCollaborator
          activeGroup={activeGroup}
          activeStructure={activeStructure}
        />
      );
  }
};

export default getFeedbacksComponent;
