const removeItemFromList = (items, value) => {
  const index = items.indexOf(value);
  if (index > -1) {
    items.splice(index, 1);
  }
  return items;
};

const getAvatarText = (name, lastname) => {
  return name.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
};

const getRoleFromGroup = (group, isCamelCase) => {
  switch (group) {
    case "UserPoolGroupAdmin":
      return isCamelCase ? "Admin" : "admin";
    case "UserPoolGroupBoss":
      return isCamelCase ? "Boss" : "boss";
    case "UserPoolGroupCollaborator":
      return isCamelCase ? "Collaborator" : "collaborator";
    default:
      return false;
  }
};

const getRgbaFromHex = (color, alpha) => {
  const red = parseInt(color.slice(1, 3), 16);
  const green = parseInt(color.slice(3, 5), 16);
  const blue = parseInt(color.slice(5, 7), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export { removeItemFromList, getAvatarText, getRoleFromGroup, getRgbaFromHex };
