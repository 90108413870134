import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources as resourcesIt } from "./translations/it";
import { resources as resourcesEn } from "./translations/en";
import { resources as resourcesEs } from "./translations/es";

const resources = {
  ...resourcesIt,
  ...resourcesEn,
  ...resourcesEs,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "it",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
