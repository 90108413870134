import React, { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import UserPlaceholder from "components/user/UserPlaceholder";

const SimpleUserAvatar = ({ urlImage, alt, height, width }) => {
  const [imageState, setImageState] = useState({
    loading: true,
    error: false,
  });
  return (
    <>
      {imageState.error ? (
        <UserPlaceholder width={width} height={height} />
      ) : (
        <>
          {imageState.loading && (
            <Skeleton variant="circular" height={height} width={width}>
              <Avatar />
            </Skeleton>
          )}
          <Box
            borderRadius="50%"
            display={imageState.loading ? "none" : ""}
            clone
          >
            <img
              src={urlImage}
              alt={alt}
              width={width}
              height={height}
              onError={() => setImageState({ loading: false, error: true })}
              onLoad={() =>
                setImageState({ loading: false, error: imageState.error })
              }
              style={{ objectFit: "cover" }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SimpleUserAvatar;

SimpleUserAvatar.defaultProps = {
  width: "100%",
  height: "100%",
};

SimpleUserAvatar.propTypes = {
  urlImage: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
