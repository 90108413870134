import gql from "graphql-tag";

const CreateFeedbackMutation = gql`
  mutation createFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: createFeedbackInput!
  ) {
    createFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      key
      sort
      id
      createdAt
    }
  }
`;

export default CreateFeedbackMutation;
