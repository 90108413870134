import getAlertsStepFunction from "queries/alert/getAlertsStepFunction";
import { API } from "@aws-amplify/api";
import ListGeneralSettings from "queries/settings/ListGeneralFeedback";
import { captureException } from "helpers/SentryHelper";
import { resetCache } from "helpers/cacheHelper";
import GetCdcLevels from "queries/cdc/getCdcLevels";
import { Cache } from "aws-amplify";

export const getSettings = async (
  activeGroup,
  activeStructure,
  slugTenant,
  idUser,
) => {
  try {
    const result = await API.graphql({
      query: ListGeneralSettings,
      variables: {
        activeGroup,
        ...(activeGroup !== "UserPoolGroupAdmin" && { activeStructure }),
      },
    });
    const settingsNew = {
      genericFeedback: result?.data?.listGeneralSettings?.genericFeedback,
      valorActivated: result?.data?.listGeneralSettings?.valorActivated,
      goalActivated: result?.data?.listGeneralSettings?.goalActivated,
      skillActivated: result?.data?.listGeneralSettings?.skillActivated,
      welcomeEmailCollaborators:
        result?.data.listGeneralSettings?.welcomeEmailCollaborators,
      loading: false,
      cacheVersion: result?.data.listGeneralSettings?.cacheVersion,
    };
    const oldCache = Cache.getItem(`${slugTenant}-cacheVersion`);
    if (!oldCache || oldCache !== settingsNew.cacheVersion) {
      resetCache(settingsNew.cacheVersion, slugTenant);
    }
    return settingsNew;
  } catch (error) {
    captureException("query", "general-settings", error, {
      activeGroup,
      activeStructure,
      slugTenant,
      idUser,
    });
    return {};
  }
};

export const getAlerts = async () => {
  const response = await API.graphql({
    query: getAlertsStepFunction,
  });
  const {
    alert,
    idMacroArea,
    idValor,
    idSkill,
    idMacroSkill,
    numAlert,
    initWorkflow,
  } = response?.data?.getAlertsStepFunction ?? {};
  return {
    stepFunctionInProgress: numAlert > 0,
    alert,
    idMacroArea,
    idValor,
    idSkill,
    idMacroSkill,
    initWorkflow,
  };
};

export const getCdcLevels = async () => {
  try {
    const response = await API.graphql({
      query: GetCdcLevels,
    });
    return response.data.getCdcLevels;
  } catch (error) {
    captureException("query", "cdcLevels", error);
    return [];
  }
};
