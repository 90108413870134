import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../auth/Authenticator";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { getAvatarText } from "helpers/GenericHelper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import UserAvatar from "components/UserAvatar";

const ProfileButton = ({
  handleClick,
  buttonRef,
  structures,
  activeGroup,
  activeStructure,
}) => {
  const { user, urlImageData } = useContext(AuthContext);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [currentInfo, setCurrentInfo] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    let currentInfo = "";
    if (activeGroup === "UserPoolGroupAdmin") {
      currentInfo = t("admin");
      setCurrentInfo(currentInfo);
      return;
    }
    const mapRole = {
      UserPoolGroupBoss: "Boss",
      UserPoolGroupCollaborator: "Collaborator",
    };
    const currentStructure =
      structures[`nameStructure${mapRole[`${activeGroup}`]}`];
    setCurrentInfo(currentStructure ?? "");
  }, [structures, activeStructure]);
  return (
    <Box display="flex">
      <Box
        display={isDesktop ? "flex" : "none"}
        flexDirection="column"
        justifyContent="center"
        textAlign="right"
      >
        <Typography variant="body2">{`${user?.attributes?.given_name ?? ""} ${
          user?.attributes.family_name ?? ""
        }`}</Typography>
        <Box color="grey.400">
          <Typography variant="caption">{currentInfo}</Typography>
        </Box>
      </Box>
      <Box marginLeft={2} position="relative">
        <UserAvatar
          userImg={
            sessionStorage.getItem("temporaryImageUser") ||
            urlImageData.urlImage
          }
          hiddenWarning={true}
          placeHolder={getAvatarText(
            user?.attributes.given_name ?? "",
            user?.attributes.family_name ?? "",
          )}
        />
        <Box
          display={isDesktop ? "none" : "block"}
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        ></Box>
        <Box ref={buttonRef}></Box>
      </Box>
      <Box display={isDesktop ? "flex" : "none"} alignItems="center" ml={2}>
        <IconButton
          aria-label="delete-dropdown"
          onClick={handleClick}
          disableRipple
          disableFocusRipple
          size="large"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

ProfileButton.propTypes = {
  handleClick: PropTypes.instanceOf(Function).isRequired,
  buttonRef: PropTypes.object.isRequired,
  structures: PropTypes.shape({
    idStructureBoss: PropTypes.string,
    nameStructureBoss: PropTypes.string,
    idStructureCollaborator: PropTypes.string,
    nameStructureCollaborator: PropTypes.string,
  }),
  activeGroup: PropTypes.oneOf([
    "UserPoolGroupAdmin",
    "UserPoolGroupBoss",
    "UserPoolGroupCoBoss",
    "UserPoolGroupCollaborator",
  ]).isRequired,
  activeStructure: PropTypes.string,
};

export default React.forwardRef((props, ref) => (
  <ProfileButton {...props} buttonRef={ref} />
));
