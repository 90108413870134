import React, { useEffect, useState } from "react";
import { Cache } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { initBossInfo, initAdminInfo } from "./InfoContextHelper";
import onUpdateGlobalWarnings from "queries/subscriptions/onUpdateGlobalWarnings";
import onUpdateStructuresWarnings from "queries/subscriptions/onUpdateStructuresWarning";
import onUpdateAlertStepFunction from "queries/subscriptions/onUpdateAlertsStepFunction";
import CurrentWorkflow from "queries/workflow/CurrentWorkflow";
import { API } from "@aws-amplify/api";
import { captureException } from "helpers/SentryHelper";
import { setCachedItems } from "./CacheHelper";
import { getAlerts, getCdcLevels, getSettings } from "./queries/infoQueries";
import { getUsersWarningNumber } from "./utils/infoUtils";

export const InfoContext = React.createContext({});

export const useInfoContext = ({
  activeGroup,
  activeStructure,
  subPhase,
  idUser,
  slugTenant,
}) => {
  const [info, setInfo] = useState({});
  const [infoStepFunction, setInfoStepFunction] = useState({});
  const levelsCached = Cache.getItem(`${slugTenant}-levels`);
  const [levels, setLevels] = useState(levelsCached ?? []);
  const [settings, setSettings] = useState({
    genericFeedback: false,
    valorActivated: true,
    goalActivated: true,
    skillActivated: false,
    loading: true,
    welcomeEmailCollaborators: true,
  });

  const alertsStepFunctionSubscription = (idUser) =>
    API.graphql({
      query: onUpdateAlertStepFunction,
      variables: { idUser },
    }).subscribe({
      next: async (response) => {
        const {
          alert,
          idMacroArea,
          idValor,
          idSkill,
          idMacroSkill,
          numAlert,
          initWorkflow,
        } = response?.value?.data?.onUpdateAlertStepFunction ?? {};
        setInfoStepFunction({
          alert,
          idMacroArea,
          idValor,
          idSkill,
          idMacroSkill,
          stepFunctionInProgress: numAlert > 0,
          initWorkflow,
        });
        if (initWorkflow === "success") {
          try {
            const result = await API.graphql({
              query: CurrentWorkflow,
            });

            Hub.dispatch("onWorkflowChanged", {
              currentWorkflow: result.data.getCurrentWorkflow,
              currentPhase: "settings",
              subPhase: "settings#1",
            });
          } catch (error) {
            captureException("query", "get-new-workflow", error);
          }
        }
      },
      error: (error) => {
        console.error(error);
      },
    });

  const globalWarningsSubscription = (idUser) =>
    API.graphql({
      query: onUpdateGlobalWarnings,
      variables: { idUser },
    }).subscribe({
      next: (response) => {
        const {
          usersGoalMissing,
          usersValorMissing,
          usersGoalConfirmation,
          usersSkillMissing,
        } = response.value.data.onUpdateGlobalWarnings;

        const usersPageWarning = getUsersWarningNumber(subPhase, settings, {
          usersValorMissing,
          usersGoalConfirmation,
          usersSkillMissing,
        });

        setCachedItems(
          {
            [`${activeGroup}#users#usersGoalMissing`]: usersGoalMissing,
            [`${activeGroup}#users#usersValorMissing`]: usersValorMissing,
            [`${activeGroup}#users#usersGoalConfirmation`]:
              usersGoalConfirmation,
            [`${activeGroup}#users#usersSkillMissing`]: usersSkillMissing,
          },
          slugTenant,
        );

        setInfo({
          ...info,
          [`${activeGroup}#users`]: usersPageWarning,
        });
      },
      error: (error) => {
        console.error(error);
      },
    });

  const structuresWarningsSubscription = (idUser, idStructure) =>
    API.graphql({
      query: onUpdateStructuresWarnings,
      variables: { idUser, idStructure },
    }).subscribe({
      next: (response) => {
        const { usersGoalMissing } =
          response.value.data.onUpdateStructuresWarnings;
        const numberWarning = ["settings#1", "settings#2"].includes(subPhase)
          ? 0
          : usersGoalMissing;
        Cache.setItem(`${slugTenant}-infoData`, {
          ...Cache.getItem(`${slugTenant}-infoData`),
          [`${activeGroup}#team#${activeStructure}#usersGoalMissing`]:
            numberWarning,
        });
        setInfo({
          ...info,
          updateView: true,
          [`${activeGroup}#team#${activeStructure}`]: numberWarning,
        });
      },
      error: (error) => {
        console.error(error);
      },
    });

  const initInfo = async (activeGroup, activeStructure) => {
    const settingsNew = await getSettings(
      activeGroup,
      activeStructure,
      slugTenant,
      idUser,
    );
    const allSettings = { ...settings, ...settingsNew };
    setSettings(allSettings);

    switch (activeGroup) {
      case "UserPoolGroupBoss": {
        const newInfo = await initBossInfo(
          activeGroup,
          activeStructure,
          subPhase,
          allSettings,
          slugTenant,
        );
        setInfo({ ...info, ...newInfo });
        break;
      }
      case "UserPoolGroupAdmin": {
        const [newInfo, alert, levels] = await Promise.all([
          initAdminInfo(subPhase, allSettings, slugTenant),
          getAlerts(),
          getCdcLevels(),
        ]);

        setLevels(levels);
        Cache.setItem(`${slugTenant}-levels`, levels);
        setInfo({ ...info, ...newInfo });
        setInfoStepFunction({ ...alert });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      !activeGroup ||
      (activeGroup !== "UserPoolGroupAdmin" && !activeStructure) ||
      !subPhase ||
      !idUser
    ) {
      return;
    }

    let subscription;
    let subscriptionAlert;
    if (activeGroup === "UserPoolGroupAdmin") {
      subscription = globalWarningsSubscription(idUser);
      subscriptionAlert = alertsStepFunctionSubscription(idUser);
    }
    if (activeGroup === "UserPoolGroupBoss") {
      subscription = structuresWarningsSubscription(idUser, activeStructure);
    }

    Cache.removeItem(`${slugTenant}-infoData`);
    initInfo(activeGroup, activeStructure);

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (subscriptionAlert) {
        subscriptionAlert.unsubscribe();
      }
    };
  }, [idUser, activeGroup, activeStructure, subPhase]);

  useEffect(() => {
    const onAuthEvent = ({ _, payload }) => {
      if (payload.event === "signOut") {
        setInfo({});
      }
    };

    const updateInfo = ({ channel, payload }) => {
      if (channel === "updateInfo") {
        Cache.setItem(`${slugTenant}-infoData`, {
          ...Cache.getItem(`${slugTenant}-infoData`),
          [`${payload.key}#${payload.tag}`]: payload.value,
        });
        setInfo({
          ...info,
          [payload.key]: payload.value,
        });
      }
    };

    Hub.listen("auth", onAuthEvent);
    Hub.listen("updateInfo", updateInfo);
  }, []);

  return {
    info,
    setInfoStepFunction,
    infoStepFunction,
    setInfo,
    settings,
    setSettings,
    levels,
  };
};
