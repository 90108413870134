import React, { useRef } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../helpers/AuthHelper";
import Divider from "@mui/material/Divider";
import StructuresMenu from "./StructuresMenu";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

const popoverStyles = makeStyles()((theme) => ({
  root: {
    zIndex: `${theme.zIndex.snackbar + 1} !important`,
  },
}));
const ProfileDropdown = ({
  structures,
  handleClose,
  buttonRef,
  open,
  changeStructure,
}) => {
  const { classes: popoverClasses } = popoverStyles();
  const { t } = useTranslation();
  const dropdownDom = useRef(null);
  const navigate = useNavigate();
  const handleClick = (to) => {
    handleClose();
    navigate(to);
  };
  return (
    <Menu
      id="profile-menu"
      anchorEl={buttonRef}
      keepMounted
      open={open}
      onClose={handleClose}
      PopoverClasses={popoverClasses}
    >
      <StructuresMenu
        structures={structures}
        handleClose={handleClose}
        changeStructure={changeStructure}
        ref={dropdownDom}
      />
      <Divider />
      <MenuItem onClick={() => handleClick("/profile")}>
        {t("yourProfile")}
      </MenuItem>
      <MenuItem onClick={logout}>{t("logout")}</MenuItem>
    </Menu>
  );
};

ProfileDropdown.propTypes = {
  structures: PropTypes.shape({
    idStructureBoss: PropTypes.string,
    nameStructureBoss: PropTypes.string,
    idStructureCollaborator: PropTypes.string,
    nameStructureCollaborator: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
  buttonRef: PropTypes.object,
  open: PropTypes.bool.isRequired,
  changeStructure: PropTypes.func.isRequired,
};

export default ProfileDropdown;
