import React from "react";
import { API } from "@aws-amplify/api";
import SaveEvaluationCard from "queries/EvaluationCard/SaveEvaluationCard";
import ListCategoriesQuery from "queries/categories/ListCategoriesSelectedUser";
import ListCategoriesQueryForBoss from "queries/categories/ListCategoriesSelectedUserForBoss";
import { captureException } from "helpers/SentryHelper";
import GetEvaluationCardManager from "queries/EvaluationCard/GetEvaluationCardManager";
import GetEvaluationCardAdmin from "queries/EvaluationCard/GetEvaluationCardAdmin";
import { makeStyles } from "tss-react/mui";
import StartExportEvaluationCards from "queries/export/StartExportEvaluationCard";
import { Trans } from "react-i18next";

export const filterValorsForMacroArea = (valorItems, listMacroarea = []) => {
  // valorItems è un array di oggetti valore
  const valorsByMacroArea = valorItems.reduce((accumulator, valor) => {
    if (!accumulator[valor.idMacroArea]) {
      const { finalScore } =
        listMacroarea.find(({ id }) => id === valor.idMacroArea) ?? {};
      accumulator[valor.idMacroArea] = [];
      accumulator[valor.idMacroArea].push({
        isMacroArea: true,
        title: valor.nameMacroArea,
        id: valor.idMacroArea,
        numValors: 0,
        finalScore,
      });
    }
    accumulator[valor.idMacroArea].push(valor);

    accumulator[valor.idMacroArea][0].numValors += 1;
    return accumulator;
  }, {});

  // cicla le macroaree
  const processedValors = Object.values(valorsByMacroArea).reduce(
    (accumulator, valorsForMacroArea) => {
      accumulator.valors = [...accumulator.valors, ...valorsForMacroArea];
      const macroarea = valorsForMacroArea[0];
      accumulator.macroarea.push({
        id: macroarea.id,
        finalScore: macroarea.finalScore,
      });
      return accumulator;
    },
    { valors: [], macroarea: [] },
  );
  /* Il risultato finale è un array ordinato di macroarea e valori, ogni macroarea è susseguita
    da tutti i valori ad essa associati
      */
  return processedValors;
};

export const filterSkillsForMacroSkill = (skills, listMacroskill) => {
  const skillsByMacroArea = skills.reduce((accumulator, skill) => {
    if (!accumulator[skill.idMacroSkill]) {
      const { finalScore } =
        listMacroskill.find(({ id }) => id === skill.idMacroSkill) ?? {};
      accumulator[skill.idMacroSkill] = [];
      accumulator[skill.idMacroSkill].push({
        isMacroArea: true,
        title: skill.nameMacroSkill,
        id: skill.idMacroSkill,
        numSkills: 0,
        finalScore,
      });
    }
    accumulator[skill.idMacroSkill].push(skill);

    accumulator[skill.idMacroSkill][0].numSkills =
      accumulator[skill.idMacroSkill][0].numSkills + 1;
    return accumulator;
  }, {});

  // cicla le macrocompetenze
  const processedSkills = Object.values(skillsByMacroArea).reduce(
    (accumulator, skillsForMacroSkill, index) => {
      accumulator.skills = [...accumulator.skills, ...skillsForMacroSkill];
      const macroskill = skillsForMacroSkill[0];
      accumulator.macroskill.push({
        id: macroskill.id,
        finalScore: macroskill.finalScore,
      });
      return accumulator;
    },
    { skills: [], macroskill: [] },
  );

  return processedSkills;
};

export const getRatingSum = (rows, category) =>
  rows.reduce((accumulator, item) => {
    const idMacroElement =
      category === "valor" ? item.idMacroArea : item.idMacroSkill;
    if (!item.isMacroArea) {
      if (!item.numKeepGoing && !item.numThinkAbout) {
        return accumulator;
      }
      if (accumulator[`${idMacroElement}`]) {
        accumulator[`${idMacroElement}`].sum += item.ratingAverage;
        accumulator[`${idMacroElement}`].counter++;
        return accumulator;
      }
      accumulator[`${idMacroElement}`] = {
        sum: item.ratingAverage,
        counter: 1,
      };
    }
    return accumulator;
  }, {});

/**
 * Formatta i dati ed esegue una chiamata graphql per salvare la scheda di valutazione
 *
 * @param {object} evalutationDetails oggetto con i valori della scheda di valutazione
 * @param {*} idWorkflow
 * @param {*} activeGroup
 * @param {*} activeStructure
 * @param {*} idUserRecipient
 * @param {*} name nome utente
 * @param {*} surname cognome utente
 */
export const handleSubmitCard = async (
  evalutationDetails,
  idWorkflow,
  activeGroup,
  activeStructure,
  idUserRecipient,
) => {
  // formatta i goal
  const goals = evalutationDetails.goals.map(
    ({
      id,
      finalScore,
      description,
      numKeepGoing,
      numThinkAbout,
      ratingAverage,
      labels,
      title,
    }) => ({
      id,
      finalScore,
      description,
      numKeepGoing: numKeepGoing ?? 0,
      numThinkAbout: numThinkAbout ?? 0,
      ratingAverage,
      labels,
      sort2: title,
    }),
  );
  // formatta i valori filtrando prima le macroaree
  const valors = evalutationDetails.valors
    .filter(({ isMacroArea }) => !isMacroArea)
    .map(
      ({
        id,
        idMacroArea,
        nameMacroArea,
        description,
        numKeepGoing,
        numThinkAbout,
        ratingAverage,
        labels,
        title,
        finalScore,
      }) => ({
        id,
        idMacroArea,
        nameMacroArea,
        description,
        numKeepGoing: numKeepGoing ?? 0,
        numThinkAbout: numThinkAbout ?? 0,
        ratingAverage,
        labels,
        sort2: title,
        finalScore,
      }),
    );
  // formatta le competenze filtrando prima le macroaree
  const skills = evalutationDetails.skills
    .filter(({ isMacroArea }) => !isMacroArea)
    .map(
      ({
        id,
        idMacroSkill,
        nameMacroSkill,
        description,
        numKeepGoing,
        numThinkAbout,
        ratingAverage,
        labels,
        title,
        finalScore,
      }) => ({
        id,
        idMacroSkill,
        nameMacroSkill,
        description,
        numKeepGoing: numKeepGoing ?? 0,
        numThinkAbout: numThinkAbout ?? 0,
        ratingAverage,
        labels,
        sort2: title,
        finalScore,
      }),
    );
  // formatta i dati da passare alla chiamata graphql
  const variables = {
    idWorkflow,
    activeGroup,
    activeStructure,
    idUserRecipient,
    input: {
      idUserRecipient,
      synthesis: {
        finalScore: evalutationDetails.synthesis.finalScore,
        managerNote: evalutationDetails.synthesis.managerNote,
        bossQuestions: evalutationDetails.synthesis.bossQuestions,
      },
      goals,
      valors,
      skills,
      macroarea: evalutationDetails.macroarea,
      macroskill: evalutationDetails.macroskill,
    },
  };
  const response = await API.graphql({
    query: SaveEvaluationCard,
    variables,
  });
  return response;
};

const getQueryParams = ({
  idUser,
  isBoss,
  activeGroup,
  activeStructure,
  idWorkflow,
  subPhase,
  evaluationStatus,
  isArchive,
  selectedWorkflow,
}) => {
  // in base al caso setta il tipo di query e le variabili in input
  let query = ListCategoriesQuery;
  let variables = {
    selectedIdUser: idUser,
    idUserRecipient: idUser,
    idWorkflow,
    statusFilter: "approved",
  };
  let typeQuery = {
    valor: "listValuesSelectedUser",
    goal: "listObjectivesSelectedUser",
    skill: "listSkillsSelectedUser",
  };
  let nameAttributeValors = "Items";
  let nameAttributeGoals = "Items";
  let nameAttributeSkills = "Items";
  if (
    (subPhase.includes("finalReview") &&
      ["compiled", "partiallyCompiled"].includes(evaluationStatus)) ||
    isArchive
  ) {
    query = GetEvaluationCardAdmin;
    variables = {
      idUserRecipient: idUser,
      idWorkflow: isArchive ? selectedWorkflow : idWorkflow,
      isArchive,
    };
    typeQuery = {
      valor: "getEvaluationCardAdmin",
      goal: "getEvaluationCardAdmin",
      skill: "getEvaluationCardAdmin",
    };
    nameAttributeValors = "valors";
    nameAttributeGoals = "goals";
    nameAttributeSkills = "skills";
  }
  if (isBoss) {
    query = ListCategoriesQueryForBoss;
    typeQuery = {
      valor: "listValuesSelectedUserForBoss",
      goal: "listObjectivesSelectedUserForBoss",
      skill: "listSkillsSelectedUserForBoss",
    };
    const bossVariables = {
      activeGroup,
      activeStructure,
      idUserRecipient: idUser,
      idWorkflow,
    };
    variables = {
      ...variables,
      ...bossVariables,
    };
    if (
      (subPhase.includes("finalReview") &&
        ["compiled", "partiallyCompiled"].includes(evaluationStatus)) ||
      isArchive
    ) {
      query = GetEvaluationCardManager;
      variables = {
        ...bossVariables,
        isArchive,
        idWorkflow: isArchive ? selectedWorkflow : idWorkflow,
      };
      typeQuery = {
        valor: "getEvaluationCardManager",
        goal: "getEvaluationCardManager",
        skill: "getEvaluationCardManager",
      };
      nameAttributeValors = "valors";
      nameAttributeGoals = "goals";
      nameAttributeSkills = "skills";
    }
  }
  return {
    query,
    variables,
    typeQuery,
    nameAttributeGoals,
    nameAttributeValors,
    nameAttributeSkills,
  };
};

// questo è usato da boss e admin sul componente useritem
export const getEvalutationDetails = async (
  idUser,
  isBoss,
  activeGroup,
  activeStructure,
  evaluationStatus,
  idWorkflow,
  subPhase,
  idStructure,
  isArchive,
  selectedWorkflow,
) => {
  try {
    const {
      query,
      variables,
      typeQuery,
      nameAttributeGoals,
      nameAttributeValors,
      nameAttributeSkills,
    } = getQueryParams({
      idUser,
      isBoss,
      activeGroup,
      activeStructure,
      idWorkflow,
      subPhase,
      evaluationStatus,
      idStructure,
      isArchive,
      selectedWorkflow,
    });
    // fa la chiamata
    const result = await API.graphql({
      query,
      variables,
    });
    const { valors, macroarea } = filterValorsForMacroArea(
      result.data[`${typeQuery.valor}`][`${nameAttributeValors}`] ?? [],
      result.data[
        `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
      ]?.macroarea ?? [],
    );
    const { skills, macroskill } = filterSkillsForMacroSkill(
      result.data[`${typeQuery.skill}`][`${nameAttributeSkills}`] ?? [],
      result.data[
        `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
      ]?.macroskill ?? [],
    );
    return {
      valors,
      skills,
      goals: (
        result.data[`${typeQuery.goal}`][`${nameAttributeGoals}`] ?? []
      ).map((item) => ({
        ...item,
        finalScore: item.finalScore ?? null,
      })),
      ...((subPhase.includes("finalReview") || isArchive) && {
        synthesis:
          result.data[
            `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
          ]?.synthesis,
        noCard:
          result.data[
            `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
          ]?.noCard,
        version:
          result.data[
            `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
          ]?.version ?? 1,
      }),
      macroarea:
        result.data[
          `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
        ]?.macroarea ?? macroarea,
      macroskill:
        result.data[
          `${isBoss ? "getEvaluationCardManager" : "getEvaluationCardAdmin"}`
        ]?.macroskill ?? macroskill,
    };
  } catch (error) {
    captureException("mutation", "send-feedback", error);
    return {
      valors: [],
      goals: [],
      skills: [],
      synthesis: {
        finalScore: null,
        managerNote: "",
      },
      macroarea: [],
    };
  }
};

// funzione per il calcolo della media rating
export const getRatingAverage = (singleRatingSum, getIndex, rating) => {
  if (isNaN(parseInt(singleRatingSum?.sum, 10))) {
    return null;
  }
  const indexRating = singleRatingSum.sum / singleRatingSum.counter;
  if (getIndex) {
    return indexRating;
  }
  return getAverageValueByAverageIndex(rating, indexRating);
};

export const disabledCheck = (index, averageFeedback, valutationSettings) => {
  if (!averageFeedback && averageFeedback !== 0) {
    return false;
  }
  const average = Math.round(averageFeedback);
  return (
    !isNaN(parseInt(average)) &&
    ((valutationSettings.highestDeviation &&
      index - average > valutationSettings.highestDeviation) ||
      (valutationSettings.lowestDeviation &&
        average - index > valutationSettings.lowestDeviation))
  );
};

// tramite id recupera la final score della macroarea
export const getFinalScore = (rowId, macroarea) =>
  macroarea.find(({ id }) => id === rowId)?.finalScore;

export const getAverageValueByAverageIndex = (rating, indexAverage) => {
  if (!indexAverage && indexAverage !== 0) {
    return "-";
  }
  // dati rating
  const isNum = !isNaN(rating[0]);
  const init = rating[0];
  const incr = rating[1] - rating[0];

  return isNum
    ? parseInt(init) + (Math.round(indexAverage * 10) * incr) / 10
    : rating[Math.round(indexAverage)];
};

export const useStylesInput = makeStyles()((theme) => ({
  inputManager: {
    backgroundColor: theme.palette.primary.lighter,
  },
  inputCollab: {
    backgroundColor: theme.palette.secondary.lighter,
  },
}));

/**
 * funzione per l'export delle schede di valutazione
 * @param {object} input input per la query dell'export
 * @param {function} setLoading stato loading export
 * @param {function} setDataAlert stato alert\
 */
export const handleExport = async ({
  input,
  setLoading,
  setDataAlert,
  t,
  isXlsx,
}) => {
  setLoading(true);
  const successfullyMessage = "exportEvalutationCardSuccessfully";
  const errorMessage = "exportEvalutationCardError";
  try {
    const result = await API.graphql({
      query: StartExportEvaluationCards,
      variables: {
        input,
        isXlsx,
      },
    });
    setLoading(false);
    setDataAlert({
      open: true,
      text: result.data.startExportEvaluationCards
        ? t(successfullyMessage)
        : t(errorMessage),
      severity: result.data.startExportEvaluationCards ? "success" : "error",
    });
  } catch (err) {
    setLoading(false);
    captureException("query", "export-evaluation-card", err);
    setDataAlert({
      open: true,
      text: t(errorMessage),
      severity: "error",
    });
  }
};

const getSingularOrPlural = (num) => {
  return num === 1 ? "singular" : "plural";
};
export const getMessageUnlockFinalReview = ({
  hideMessage,
  locked,
  unlocked,
  t,
}) => {
  if (hideMessage) {
    return "";
  }
  if (unlocked > 0 && locked === 0) {
    return (
      <Trans>
        {t(`actionUnlockedAllUsers_${getSingularOrPlural(unlocked)}`, {
          unlocked,
        })}
      </Trans>
    );
  }
  if (unlocked === 0 && locked > 0) {
    return (
      <Trans>
        {t(`actionUnlockedNotWork_${getSingularOrPlural(locked)}`, {
          locked,
        })}
      </Trans>
    );
  }
  return (
    <Trans>
      {t(
        `actionUnlockedSomeUsers_${getSingularOrPlural(
          unlocked,
        )}_${getSingularOrPlural(locked)}`,
        { unlocked, locked },
      )}
    </Trans>
  );
};

export default {
  filterValorsForMacroArea,
  getRatingSum,
  handleSubmitCard,
  getEvalutationDetails,
  disabledCheck,
  getRatingAverage,
  getFinalScore,
  getAverageValueByAverageIndex,
  useStylesInput,
  handleExport,
  getMessageUnlockFinalReview,
};
