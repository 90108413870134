import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ListItem, Box, ListItemText, ListItemIcon } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import GetIcon from "./components/GetIcon";
import { useTranslation } from "react-i18next";
import { variables } from "theme/globals/variables";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles()((theme, _, classes) => ({
  buttonDiv: {
    cursor: "pointer",
  },
  listItem: {
    color: theme.palette.grey["300"],
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    [`&:hover, &.${classes.selectedItem}`]: {
      backgroundColor: "transparent",
      color: theme.palette.grey["100"],
    },
    [`&:hover .${classes.listIcon}, &.${classes.selectedItem} .${classes.listIcon}`]:
      {
        color: theme.palette.secondary.main,
      },
    "&:after": {
      content: '""',
      position: "absolute",
      height: 30,
      left: 0,
      width: 4,
      borderRadius: 2,
    },
    [`&:hover:after, &.${classes.selectedItem}:after`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listSubItem: {
    color: theme.palette.grey["300"],
    backgroundColor: "#024266",
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    "& div": {
      marginLeft: theme.spacing(8),
    },
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    [`&:hover, &.${classes.selectedSubItem}`]: {
      color: theme.palette.secondary.main,
      backgroundColor: "#024266",
    },
  },
  selectedItem: {},
  selectedSubItem: {},
  listIcon: {
    color: theme.palette.grey["300"],
    minWidth: "auto",
  },
}));

const ItemMenu = ({ items, selected, activeGroup, title, code }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [showItems, setShowItems] = useState(false);
  const listItemStyles = makeStyles()((theme) => ({
    root: {
      flex: "0 0 auto",
      margin: 0,
    },
    primary: {
      fontFamily: variables.fontFamilyTitle,
    },
  }));
  const { classes: listItemClass } = listItemStyles();
  const IconSwitcher = useCallback((icon) => {
    return GetIcon(icon);
  });

  const itemTitles = items.map(({ text }) => text);
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setShowItems(!showItems)}
        className={classes.buttonDiv}
      >
        <Box
          display="flex"
          alignItems="center"
          mb={showItems ? 0 : 4}
          className={cx(classes.listItem, {
            [classes.selectedItem]: itemTitles.includes(selected),
          })}
        >
          <Box mr={2}>
            <ListItemIcon className={classes.listIcon}>
              {IconSwitcher(code)}
            </ListItemIcon>
          </Box>
          <ListItemText
            classes={listItemClass}
            primaryTypographyProps={{ variant: "subtitle2" }}
            primary={t(title)}
          />
        </Box>
        <Box color="grey.300" mb={showItems ? 0 : 4}>
          {showItems ? (
            <ExpandLessIcon fontSize="large" />
          ) : (
            <ExpandMoreIcon fontSize="large" />
          )}
        </Box>
      </Box>
      {showItems &&
        items.map((item) => {
          return item.authorizedRoles.includes(activeGroup) ? (
            <Link to={item.url} key={item.text}>
              <ListItem
                button
                key={item.text}
                className={cx(classes.listSubItem, {
                  [classes.selectedSubItem]: selected === item.text,
                })}
              >
                <ListItemText
                  classes={listItemClass}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  primary={t(`${item.text}SubMenu`)}
                />
              </ListItem>
            </Link>
          ) : null;
        })}
    </>
  );
};

ItemMenu.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  activeGroup: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default React.memo(ItemMenu);
