import gql from "graphql-tag";

const SendSupportEmail = gql`
  mutation sendSupportEmail(
    $files: [String!]
    $object: String!
    $description: String!
  ) {
    sendSupportEmail(files: $files, object: $object, description: $description)
  }
`;

export default SendSupportEmail;
