export const palette = {
  primary: {
    lighter: "#B9DEEE",
    light: "#79B4DB",
    main: "#4EA8DE",
    dark: "#055480",
  },
  secondary: {
    lighter: "#FFF7E5",
    light: "#F1DCA7",
    main: "#F9C74F",
    dark: "#F6AA1C",
  },
  error: {
    lighter: "#FFF7E5",
    light: "#F1DCA7",
    main: "#FF7261",
    dark: "#F6AA1C",
  },
  grey: {
    600: "#333333",
    500: "#7C7575",
    400: "#00000061",
    300: "#DFDADA",
    200: "#F5F5F5",
    100: "#FFFFFF",
    main: "#7C7575",
  },
  keepGoing: {
    dark: "#2D6A4F",
    main: "#52B788",
    light: "#B7E4C7",
  },
  thinkAbout: {
    dark: "#FF7261",
    main: "#FF9464",
    light: "#FFC8B0",
  },
  text: {
    primary: "#333333",
    secondary: "#7C7575",
  },
};
