export const memoRatingForm = (prevProps, nextProps) => {
  const { keyGoal, label } = prevProps;
  return (
    prevProps.formik.values[`rating-${keyGoal}-${label}`] ===
      nextProps.formik.values[`rating-${keyGoal}-${label}`] &&
    prevProps.formik.errors[`rating-${keyGoal}-${label}`] ===
      nextProps.formik.errors[`rating-${keyGoal}-${label}`] &&
    prevProps.formik.touched[`rating-${keyGoal}-${label}`] ===
      nextProps.formik.touched[`rating-${keyGoal}-${label}`]
  );
};

export const memoTabGoalForm = (prevProps, nextProps) => {
  const { keyGoal, rating } = prevProps;
  const longCondition =
    prevProps.keyGoal === nextProps.keyGoal &&
    prevProps.rating === nextProps.rating &&
    prevProps.formikSendGoals.touched[`title-${keyGoal}`] ===
      nextProps.formikSendGoals.touched[`title-${keyGoal}`] &&
    prevProps.formikSendGoals.touched[`description-${keyGoal}`] ===
      nextProps.formikSendGoals.touched[`description-${keyGoal}`] &&
    prevProps.formikSendGoals.values[`title-${keyGoal}`] ===
      nextProps.formikSendGoals.values[`title-${keyGoal}`] &&
    prevProps.formikSendGoals.values[`description-${keyGoal}`] ===
      nextProps.formikSendGoals.values[`description-${keyGoal}`] &&
    prevProps.formikSendGoals.errors[`title-${keyGoal}`] ===
      nextProps.formikSendGoals.errors[`title-${keyGoal}`] &&
    prevProps.formikSendGoals.errors[`description-${keyGoal}`] ===
      nextProps.formikSendGoals.errors[`description-${keyGoal}`];
  return rating.reduce((accumulator, item) => {
    return (
      accumulator &&
      prevProps.formikSendGoals.touched[`rating-${keyGoal}-${item}`] ===
        nextProps.formikSendGoals.touched[`rating-${keyGoal}-${item}`] &&
      prevProps.formikSendGoals.values[`rating-${keyGoal}-${item}`] ===
        nextProps.formikSendGoals.values[`rating-${keyGoal}-${item}`] &&
      prevProps.formikSendGoals.errors[`rating-${keyGoal}-${item}`] ===
        nextProps.formikSendGoals.errors[`rating-${keyGoal}-${item}`]
    );
  }, longCondition);
};

export const memoStructuresComponent = (prevProps, nextProps) => {
  return (
    prevProps.structures === nextProps.structures &&
    prevProps.referenceStructure === nextProps.referenceStructure
  );
};

export const memoPersonalData = (prevProps, nextProps) => {
  return (
    prevProps.formik.values === nextProps.formik.values &&
    prevProps.formik.errors === nextProps.formik.errors &&
    prevProps.formik.touched === nextProps.formik.touched
  );
};

export const memoInputField = (prevProps, nextProps) => {
  return (
    prevProps.error === nextProps.error &&
    prevProps.touched === nextProps.touched &&
    prevProps.formik.value === nextProps.formik.value
  );
};

export default {
  memoRatingForm,
  memoTabGoalForm,
  memoStructuresComponent,
  memoPersonalData,
  memoInputField,
};
