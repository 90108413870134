import React from "react";
import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as ThinkAbout } from "assets/icon/thinkabout.svg";
import PropTypes from "prop-types";

const ThinkAboutIcon = ({ isLarge, mr }) => (
  <Box mr={mr ?? 0} clone>
    <SvgIcon component={ThinkAbout} fontSize={isLarge ? "large" : "medium"} />
  </Box>
);

ThinkAboutIcon.propTypes = {
  isLarge: PropTypes.bool,
  mr: PropTypes.number,
};

export default ThinkAboutIcon;
