import { Cache } from "aws-amplify";
export const createQueryString = (params) => {
  const tenant = Cache.getItem("tenant");
  const page = location.pathname.slice(1);

  // inserisce la pagina
  const pageQueryString = page && page !== "login" ? `page=${page}` : "";

  // inserisce il tenant
  const tenantQueryString = tenant && !params.tenant ? `tenant=${tenant}` : "";

  /**
   * la query string sarà composta
   * - solo da tenantquery
   * - da tenant query e pagequery
   * - solo da page query
   * - o vuota
   */
  let queryString = tenantQueryString
    ? `?${tenantQueryString}${pageQueryString ? `&${pageQueryString}` : ""}`
    : pageQueryString
      ? `?${pageQueryString}`
      : "";

  // reinserisce tutti i possibili parametri che c'erano prima
  Object.keys(params).forEach((key) => {
    if (!queryString) {
      queryString += `?${key}=${params[`${key}`]}`;
      return;
    }
    queryString += `&${key}=${params[`${key}`]}`;
  });
  return queryString;
};
