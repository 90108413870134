import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useReducer,
} from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import PropTypes from "prop-types";
import NotificationsSidebar from "./notifications/NotificationsSidebar";
import listNotifications from "../../queries/notifications/listNotifications";
import onCreateNotification from "../../queries/subscriptions/onCreateNotification";
import { API, graphqlOperation } from "@aws-amplify/api";
import { AuthContext } from "../auth/Authenticator";
import { getRoleFromGroup } from "helpers/GenericHelper";
import { getNotificationRoles } from "./notifications/functions/helper";
import { captureException } from "helpers/SentryHelper";

const notificationsReducer = (notifications, action) => {
  switch (action.type) {
    case "getList": {
      return {
        ...action.data,
      };
    }
    case "subscription": {
      const { loading, newNotification, counter, activeGroup } = action.data;
      const notificationRoles = getNotificationRoles(newNotification);
      let data = notifications.data;
      const idsNotification = data.map((notice) => notice.id);
      if (
        notificationRoles.includes(activeGroup) &&
        !idsNotification.includes(newNotification.id)
      ) {
        data.unshift(newNotification);
        data = [...new Set(data)];
      }
      return { ...notifications, data, loading, counter };
    }
    case "pagination": {
      let data = notifications.data;
      const { loading, newNotifications, counter, nextToken } = action.data;
      data.push(...newNotifications);
      data = [...new Set(data)];
      return {
        data,
        loading,
        counter,
        nextToken,
      };
    }
    case "setLoading":
      return {
        ...notifications,
        loading: true,
      };
    case "setRead": {
      const { id } = action.data;
      const { data } = notifications;
      const index = data.findIndex((item) => item.id === id);
      data[`${index}`].read = true;
      return {
        ...notifications,
        data,
      };
    }
  }
};

const Navigation = ({ title }) => {
  // limit item notifiche singola paginazione
  const limit = 20;

  const [open, setOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, dispatchNotifications] = useReducer(
    notificationsReducer,
    {
      data: [],
      loading: true,
      counter: 0,
      nextToken: {},
    },
  );
  const { user, activeGroup, activeStructure } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenNotifications(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNotificationsWindow = () => {
    setOpenNotifications(!openNotifications);
  };

  const getCounter = (activeGroup, data) => {
    const role = getRoleFromGroup(activeGroup, true);
    const counterType = role === "Admin" ? "counter" : `counter${role}`;
    const counter = data[`${counterType}`] || 0;
    return counter;
  };

  const getNotifications = async (activeGroup, activeStructure) => {
    try {
      const result = await API.graphql({
        query: listNotifications,
        variables: { activeGroup, activeStructure, limit },
      });
      const retryQuery =
        result.data.listNotifications.notifications.length < 3 &&
        result.data.listNotifications.nextToken;
      if (retryQuery) {
        retryQueryNotifications(
          result.data.listNotifications.notifications,
          result.data.listNotifications.nextToken,
          1,
          true,
        );
        return;
      }
      const counter = getCounter(activeGroup, result.data.listNotifications);
      dispatchNotifications({
        type: "getList",
        data: {
          loading: false,
          data: result.data.listNotifications.notifications,
          counter,
          nextToken: result.data.listNotifications.nextToken,
        },
      });
    } catch (error) {
      captureException("query", "notifications", error, {
        activeGroup,
        activeStructure,
        idUser: user?.attributes?.sub,
      });
    }
  };
  const notificationSubscription = useCallback(() => {
    return API.graphql({
      ...graphqlOperation(onCreateNotification),
      variables: { idUser: user.attributes.sub },
    }).subscribe({
      next: (response) => {
        const counter = getCounter(
          activeGroup,
          response.value.data.onCreateNotification,
        );
        dispatchNotifications({
          type: "subscription",
          data: {
            loading: false,
            newNotification:
              response.value.data.onCreateNotification.newNotification,
            counter,
            activeGroup,
          },
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }, [activeGroup, user]);

  useEffect(() => {
    if (!user || !activeGroup) {
      return;
    }
    getNotifications(activeGroup, activeStructure);
    const subscription = notificationSubscription();
    return () => {
      subscription.unsubscribe();
    };
  }, [activeGroup, user, activeStructure]);

  const retryQueryNotifications = async (
    notificationsToConcat = [],
    nextToken,
    numAttempts,
    fromInit,
  ) => {
    const result = await API.graphql({
      query: listNotifications,
      variables: { activeGroup, activeStructure, nextToken, limit },
    });
    // Rifaccio la query solamente se il numero dei risultati non è ancora quello che mi aspetto, non ho ancora fatto tre tentativi e ho un nextToken
    const retryQuery =
      notificationsToConcat.length +
        result.data.listNotifications.notifications.length <
        3 &&
      numAttempts < 3 &&
      result.data.listNotifications.nextToken;
    const notificationsToShow = [
      ...new Set([
        ...notificationsToConcat,
        ...result.data.listNotifications.notifications,
      ]),
    ];

    if (retryQuery) {
      retryQueryNotifications(
        notificationsToShow,
        result.data.listNotifications.nextToken,
        numAttempts + 1,
        fromInit,
      );
      return;
    }
    if (fromInit) {
      const counter = getCounter(activeGroup, result.data.listNotifications);
      dispatchNotifications({
        type: "getList",
        data: {
          loading: false,
          data: notificationsToShow,
          counter,
          nextToken: result.data.listNotifications.nextToken,
        },
      });
      return;
    }
    const counter = getCounter(activeGroup, result.data.listNotifications);
    dispatchNotifications({
      type: "pagination",
      data: {
        loading: false,
        newNotifications: notificationsToShow,
        counter,
        nextToken: result.data.listNotifications.nextToken,
      },
    });
  };

  const loadMoreNotification = async () => {
    if (!notifications.nextToken) {
      return;
    }
    dispatchNotifications({
      type: "setLoading",
    });
    const result = await API.graphql({
      query: listNotifications,
      variables: {
        activeGroup,
        activeStructure,
        nextToken: notifications.nextToken,
        limit,
      },
    });

    const retryQuery =
      result.data.listNotifications.notifications.length < 3 &&
      result.data.listNotifications.nextToken;

    if (retryQuery) {
      retryQueryNotifications(
        result.data.listNotifications.notifications,
        result.data.listNotifications.nextToken,
        1,
      );
      return;
    }

    const counter = getCounter(activeGroup, result.data.listNotifications);
    dispatchNotifications({
      type: "pagination",
      data: {
        loading: false,
        newNotifications: result.data.listNotifications.notifications,
        counter,
        nextToken: result.data.listNotifications.nextToken,
      },
    });
  };

  return (
    <>
      <Navbar
        title={title}
        handleNotificationsWindow={handleNotificationsWindow}
        counter={notifications.counter}
        openNotifications={openNotifications}
      />
      <Sidebar
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open}
        selected={title}
      />
      <NotificationsSidebar
        openNotifications={openNotifications}
        notifications={notifications}
        dispatchNotifications={dispatchNotifications}
        loadMoreNotification={loadMoreNotification}
        handleNotificationsWindow={handleNotificationsWindow}
        limit={limit}
      />
    </>
  );
};

Navigation.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(
  Navigation,
  (prevProps, nextProps) => prevProps.title === nextProps.title,
);
