import { palette } from "../globals/palette";

export const avatar = {
  MuiAvatar: {
    root: {
      height: 50,
      width: 50,
    },
    colorDefault: {
      backgroundColor: palette.primary.main,
    },
  },
};
