import gql from "graphql-tag";

const onCreateNotification = gql`
  subscription onCreateNotification($idUser: String!) {
    onCreateNotification(idUser: $idUser) {
      counter
      counterBoss
      counterCollaborator
      idUser
      newNotification {
        idFeedback
        createdAt
        nameUserSender
        category
        subCategory
        type
        id
        read
        object
        description
        idUserAssignedGoals
        typeRequest
        idStructureSender
        idWorkflow
        feedbackObject
        nameUserRecipient
        otherData
      }
    }
  }
`;

export default onCreateNotification;
