import { createTheme } from "@mui/material/styles";
import { palette } from "../globals/palette";
import { variables } from "../globals/variables";
const defaultTheme = createTheme();

export const navbar = {
  MuiAppBar: {
    root: {
      height: variables.navbarHeight,
      marginLeft: variables.drawerButtonWidth,
      transition: `width ${defaultTheme.transitions.duration.shorter}ms ${defaultTheme.transitions.easing.sharp} 0ms,margin ${defaultTheme.transitions.duration.shorter}ms ${defaultTheme.transitions.easing.sharp} 0ms`,
      width: `calc(100% - ${variables.drawerButtonWidth}px)`,
      zIndex: defaultTheme.zIndex.snackbar,
      [defaultTheme.breakpoints.up("md")]: {
        marginLeft: variables.drawerWidth,
        width: `calc(100% - ${variables.drawerWidth}px)`,
      },
    },
    colorPrimary: {
      backgroundColor: palette.grey[100],
    },
  },
  MuiToolbar: {
    root: {
      height: "100%",
    },
    gutters: {
      paddingLeft: 16,
      paddingRight: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        paddingLeft: 16,
        paddingRight: 0,
      },
      [defaultTheme.breakpoints.up("md")]: {
        paddingLeft: 24,
        paddingRight: 0,
      },
    },
  },
};
