import { createTheme } from "@mui/material/styles";
import { palette } from "../globals/palette";
const defaultTheme = createTheme();

export const tabs = {
  MuiTab: {
    root: {
      fontWeight: 600,
      lineHeight: "16px",
      margin: "0 12px",
      "&:first-of-type": {
        marginLeft: 0,
      },
      minHeight: 30,
      padding: 0,
      [defaultTheme.breakpoints.up("xs")]: {
        minWidth: "auto",
      },
      textTransform: "none",
      paddingTop: 12,
    },
  },
  MuiTabs: {
    root: {
      minHeight: "auto",
      padding: 16,
    },
    indicator: {
      border: `3px solid ${palette.primary.main}`,
      borderRadius: 3,
      bottom: "auto",
      top: 0,
      height: 3,
      width: "40px !important",
    },
  },
  MuiTabScrollButton: {
    root: {
      marginTop: 12,
    },
  },
};
