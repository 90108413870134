import gql from "graphql-tag";

const GetEvaluationCardManager = gql`
  query getEvaluationCardManager(
    $activeGroup: String!
    $activeStructure: String!
    $idUserRecipient: String!
    $idWorkflow: String!
    $isArchive: Boolean
  ) {
    getEvaluationCardManager(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      idUserRecipient: $idUserRecipient
      idWorkflow: $idWorkflow
      isArchive: $isArchive
    ) {
      key
      sort
      idWorkflow
      idStructure
      idUserRecipient
      valors {
        description
        id
        idMacroArea
        nameMacroArea
        numKeepGoing
        numThinkAbout
        ratingAverage
        finalScore
        title: sort2
        labels {
          value
          description
        }
      }
      skills {
        description
        id
        idMacroSkill
        nameMacroSkill
        numKeepGoing
        numThinkAbout
        ratingAverage
        title: sort2
        finalScore
        labels {
          value
          description
        }
      }
      goals {
        description
        id
        ratingAverage
        numKeepGoing
        numThinkAbout
        finalScore
        title: sort2
        labels {
          value
          description
        }
      }
      macroarea {
        id
        finalScore
      }
      macroskill {
        id
        finalScore
      }
      synthesis {
        mediaRatingFeedback
        autovalutationScore
        finalScore
        managerNote
        collaboratorNote
        bossQuestions {
          question
          answer
        }
        questions {
          question
          answer
        }
      }
      noCard
      autovalutationStatus
      collaboratorNotification
      version
    }
  }
`;

export default GetEvaluationCardManager;
