import gql from "graphql-tag";

const listWarnings = gql`
  query listWarnings(
    $activeGroup: String!
    $activeStructure: String
    $input: WarningInput!
  ) {
    listWarnings(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      input: $input
    ) {
      Items {
        tag
        page
        role
        substitute
      }
    }
  }
`;

export default listWarnings;
