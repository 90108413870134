import React, { useState } from "react";
import ManageWorkflowModal from "./ManageWorkflowModal";
import CreateWorkflow from "queries/workflow/CreateWorkflow";
import { API } from "@aws-amplify/api";
import { Hub } from "@aws-amplify/core";
import { captureException } from "helpers/SentryHelper";

const createWorkflow = async (title) => {
  const response = await API.graphql({
    query: CreateWorkflow,
    variables: {
      nameWorkFlow: title,
    },
  });
  return response.data.createWorkflow;
};

const PaginaCreateWorkflow = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const saveWorkflow = (title) => {
    setIsLoaded(false);
    createWorkflow(title)
      .then((data) => {
        if (data) {
          Hub.dispatch("onWorkflowChanged", {
            currentWorkflow: data.currentWorkflow,
            currentPhase: data.currentPhase,
            subPhase: data.subPhase,
          });
        }
      })
      .catch((err) => {
        captureException("mutation", "create-workflow", err);
      });
  };

  return (
    <ManageWorkflowModal
      open={true}
      save={saveWorkflow}
      isLoaded={isLoaded}
      type="edit"
    />
  );
};

export default PaginaCreateWorkflow;
