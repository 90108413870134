import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  IconButton,
  Chip,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const FilterBox = ({
  type,
  disabledButton,
  handleOpenModal,
  level,
  showChips,
  selected,
  otherNumbers,
  copyAllSelected,
  handleDeleteFilter,
  usersLoading,
}) => {
  const { t } = useTranslation();
  const title = {
    users: t("teamMember"),
    structures: t("structure"),
    cdc: `${t("cdc")} ${level}`,
  };
  const cloneSelected = JSON.parse(JSON.stringify(selected));
  return (
    <Box px={5} py={4} mx={5} mb={5} border="1px solid #DFDADA">
      <Box
        display="flex"
        justifyContent="space-between"
        mb={3}
        alignItems="center"
      >
        <Box width="70%">
          <Typography variant="body2">{title[`${type}`]}</Typography>
        </Box>
        {type === "users" && usersLoading ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton
            disabled={disabledButton}
            onClick={() => handleOpenModal(true, type, level)}
            color="inherit"
            size="large"
          >
            <FilterListIcon />
          </IconButton>
        )}
        {type === "cdc" && (
          <IconButton
            onClick={() => handleDeleteFilter(level)}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {showChips ? (
        cloneSelected.splice(0, 3).map(({ name, id }, index) => (
          <Box key={id} p={1} clone>
            <Chip
              m={2}
              label={index === 2 ? `+ ${t("others")} ${otherNumbers}` : name}
              variant="outlined"
              style={{ maxWidth: 180 }}
            />
          </Box>
        ))
      ) : (
        <Box p={2} clone>
          <Chip label={t(copyAllSelected)} variant="outlined" />
        </Box>
      )}
    </Box>
  );
};

FilterBox.propTypes = {
  type: PropTypes.string.isRequired,
  disabledButton: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleDeleteFilter: PropTypes.func.isRequired,
  level: PropTypes.string,
  showChips: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  otherNumbers: PropTypes.number,
  copyAllSelected: PropTypes.string.isRequired,
  usersLoading: PropTypes.bool.isRequired,
};

export default FilterBox;
