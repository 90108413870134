import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Pagination } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { palette } from "theme/globals/palette";

const AnswersAutovalutation = ({
  questions,
  hidePercentage,
  isBossQuestions,
}) => {
  const { t } = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState({
    ...questions[0],
    index: 1,
  });

  useEffect(() => {
    setSelectedQuestion({
      ...questions[0],
      index: 1,
    });
  }, [questions]);

  const [primaryColorName, secondaryColor] = isBossQuestions
    ? ["primary.main", palette.primary.light]
    : ["secondary.dark", palette.secondary.light];

  return (
    <Box mt={4}>
      <Paper elevation={3}>
        <Box p={4} display="flex" justifyContent="space-between">
          <Typography variant="h6">
            {t(isBossQuestions ? "answersValutation" : "answersAutovalutation")}
          </Typography>
          {questions.length > 1 && (
            <Pagination
              count={questions.length}
              onChange={(e, value) =>
                setSelectedQuestion({
                  ...questions[value - 1],
                  index: value,
                })
              }
              page={selectedQuestion?.index ?? 0}
            />
          )}
        </Box>
        <Box
          width="100%"
          bgcolor={primaryColorName}
          color="white"
          px={4}
          py={2}
        >
          <Typography variant="subtitle1">
            {selectedQuestion?.question ?? ""}
          </Typography>
        </Box>
        {(selectedQuestion?.answers ?? []).map(
          ({ answer, percentage }, index) => (
            <Box
              key={`${answer}-${index}`}
              width="100%"
              borderBottom="1px solid #DFDADA"
              px={4}
              py={2}
              display="flex"
              justifyContent="space-between"
              style={{
                background: `linear-gradient(to right, ${secondaryColor}99 0, ${secondaryColor}99 ${
                  percentage?.toFixed(2) ?? 0
                }%, #fff ${percentage?.toFixed(2) ?? 0}%, #fff 100%)`,
              }}
            >
              <Typography variant="subtitle1">{answer}</Typography>
              {!hidePercentage && (
                <Box color={primaryColorName}>
                  <Typography variant="h5">{`${
                    percentage?.toFixed(2) ?? 0
                  }%`}</Typography>
                </Box>
              )}
            </Box>
          ),
        )}
      </Paper>
    </Box>
  );
};

AnswersAutovalutation.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string.isRequired,
          percentage: PropTypes.number,
        }),
      ),
    }),
  ),
  hidePercentage: PropTypes.bool.isRequired,
  isBossQuestions: PropTypes.bool.isRequired,
};

export default AnswersAutovalutation;
