import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { WorkflowContext } from "context/WorkflowContext";
import { useTranslation } from "react-i18next";

const WorkflowBox = () => {
  const { t } = useTranslation();
  const { currentWorkflow, currentPhase } = useContext(WorkflowContext);
  const theme = useTheme();
  return (
    <Box
      bgcolor="primary.dark"
      mt="auto"
      borderTop={1}
      borderColor={theme.palette.primary.main}
    >
      <Box px={5} pt={2} pb={4}>
        <Box mb={2} color="grey.100">
          <Typography variant="h6" component="p">
            {currentWorkflow ? currentWorkflow.name : t("workflowWithoutName")}
          </Typography>
        </Box>
        <Typography variant="caption" color="primary">
          {t(currentPhase)}
        </Typography>
      </Box>
    </Box>
  );
};

export default WorkflowBox;
