import React, { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { Cache } from "aws-amplify";
import AppConfig from "AppConfig";
import GetUserData from "queries/User/GetUserData";
import { API } from "@aws-amplify/api";
import { captureException } from "helpers/SentryHelper";
import { useTranslation } from "react-i18next";
import UpdateLanguage from "queries/preferences/UpdateLanguage";
import queryString from "query-string";
import config from "aws_export";

export const AuthContext = React.createContext({
  user: null,
  isLoaded: false,
  setUser: () => {},
  email: "",
});

export const useAuth = () => {
  const [user, setUser] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [cognitoConfig, setCognitoConfig] = useState({
    aws_user_pools_id: "",
    aws_user_pools_web_client_id: "",
  });
  const [slugTenant, setSlugTenant] = useState("");
  const [nameTenant, setNameTenant] = useState("");
  const [errorTenant, setErrorTenant] = useState(false);
  const [mustModifyPasswordUser, setMustModifyPasswordUser] = useState("");
  const [activeStructure, setActiveStructure] = useState("");
  const [activeGroup, setActiveGroup] = useState("");
  const [structures, setStructures] = useState({
    nameStructureBoss: "",
    nameStructureCollaborator: "",
    idStructureCollaborator: "",
    idStructureBoss: "",
  });
  const [evaluationStatus, setEvaluationStatus] = useState("");
  const [urlImageData, setUrlImageData] = useState({
    urlImage: "",
    imageBaseUrl: "",
  });
  const [signOut, setSignOut] = useState(false);
  const [language, setLanguage] = useState("");
  const handleChangeStructure = (structure, role) => {
    setActiveStructure(structure);
    setActiveGroup(role);
    if (slugTenant && (structure || role)) {
      localStorage.setItem(
        `${slugTenant}-structureData`,
        JSON.stringify({
          structure,
          role,
        }),
      );
    }
  };
  const getLanguageBrowser = () => {
    const langClient = (navigator.language || navigator.userLanguage).substring(
      0,
      2,
    );
    const langToSend = ["it", "en", "es"].includes(langClient)
      ? langClient
      : "it";
    setLanguage(langToSend);
    return langToSend;
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const getInfoUser = async (activeGroup) => {
    let result = { data: { readUser: {} } };
    try {
      result = await API.graphql({
        query: GetUserData,
        variables: {
          activeGroup,
          activeStructure,
        },
      });
    } catch (error) {
      captureException("query", "user-data", error);
    }
    const {
      nameStructureCollaborator,
      idStructureCollaborator,
      nameStructureBoss,
      idStructureBoss,
      urlGet,
      imageBaseUrl,
      language,
      evaluationStatus,
    } = result.data.readUser;
    setStructures({
      nameStructureCollaborator: nameStructureCollaborator ?? "",
      idStructureCollaborator: idStructureCollaborator ?? "",
      nameStructureBoss: nameStructureBoss ?? "",
      idStructureBoss: idStructureBoss ?? "",
    });
    setEvaluationStatus(evaluationStatus);
    setUrlImageData({
      urlImage: urlGet,
      imageBaseUrl,
    });
    if (language) {
      setLanguage(language);
      return;
    }
    const langToSend = getLanguageBrowser();
    await API.graphql({
      query: UpdateLanguage,
      variables: {
        language: langToSend,
      },
    });
  };

  useEffect(() => {
    if (user && activeGroup) {
      (async () => getInfoUser(activeGroup))();
      return;
    }
    if (!activeGroup) {
      getLanguageBrowser();
    }
  }, [user, activeGroup]);

  const updateCurrentUser = async () => {
    try {
      if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return;
      }
      const authUser = await Auth.currentAuthenticatedUser();
      if (authUser && !authUser.attributes) {
        authUser.attributes = authUser.signInUserSession.getIdToken().payload;
      }
      setUser(authUser);
      const structureData = JSON.parse(
        localStorage.getItem(`${slugTenant}-structureData`),
      ) || {
        structure: authUser.attributes["custom:structure"],
        role: authUser.attributes["custom:role"],
      };
      handleChangeStructure(structureData.structure, structureData.role);
      return authUser;
    } catch (err) {
      if (typeof err === "object") {
        if (err.message.includes("Already found an entry for username")) {
          captureException("user", "authentication", err);
          const OAuthUrl = Cache.getItem(`${slugTenant}-endpointOAuth`);
          if (OAuthUrl) {
            window.location.href = OAuthUrl;
            return;
          }
        }
      }
      setUser(false);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!cognitoConfig.aws_user_pools_id && !slugTenant) {
      return;
    }
    const onAuthEvent = async ({ channel, payload }) => {
      if (channel === "auth") {
        await updateCurrentUser();
        Cache.removeItem(`${slugTenant}-infoData`);
      }
      if (payload.event === "signOut") {
        setSignOut(true);
        const nameTenant = Cache.getItem(`${slugTenant}-nameTenant`);
        Cache.clear();
        localStorage.removeItem(`${slugTenant}-structureData`);
        setActiveGroup(null);
        setActiveStructure(null);
        setUrlImageData({
          urlImage: "",
          imageBaseUrl: "",
        });
        // 1 day
        const expires = Date.now() + 1000 * 60 * 60 * 24;
        if (slugTenant) {
          Cache.setItem("tenant", slugTenant, { expires });
          Cache.setItem(`${slugTenant}-nameTenant`, nameTenant, { expires });
        }
      }
    };
    const initComponent = async () => {
      try {
        await AppConfig.initConfig(cognitoConfig);
        const authUser = await updateCurrentUser();
        const session = await Auth.currentSession();
        const refreshToken = session.getRefreshToken();
        authUser.refreshSession(refreshToken, async (error) => {
          if (error && error.code === "ResourceNotFoundException") {
            await Auth.signOut();
          }
        });
        Cache.removeItem(`${slugTenant}-infoData`);
      } catch (error) {
        if (error !== "No current user") {
          captureException("user", "auth", error);
        }
      }
    };
    initComponent();
    Hub.listen("auth", onAuthEvent);
  }, [cognitoConfig, slugTenant]);

  const hasOauth = (loginTypes, saml) => {
    // Se è presente almeno un login type diverso da normal, allora abbiamo un'autenticazione oauth
    const loginTypesCodes = loginTypes.map((loginType) => loginType.name);

    return (
      loginTypesCodes.length > 0 &&
      loginTypesCodes.some((code) => code !== "normal") &&
      saml
    );
  };

  const getTenantInfo = async (slugTenantLocalParam) => {
    const response = await fetch(`${config.endpointGetTenant}/getTenant`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          tenant: slugTenantLocalParam,
        },
      }),
    });
    if (response.status !== 200) {
      return;
    }
    const body = await response.json();
    return body;
  };

  const createCognitoConfig = (tenantCognitoConfig) => {
    const localCognitoConfig = {
      aws_user_pools_id: tenantCognitoConfig?.aws_user_pools_id,
      aws_user_pools_web_client_id:
        tenantCognitoConfig?.aws_user_pools_web_client_id,
      loginTypes: tenantCognitoConfig?.loginTypes,
      domain: tenantCognitoConfig?.domain,
    };

    if (hasOauth(tenantCognitoConfig.loginTypes, tenantCognitoConfig.saml)) {
      localCognitoConfig.oauth = {
        domain: tenantCognitoConfig.domain,
        scope: tenantCognitoConfig.saml.scopes,
        redirectSignIn: tenantCognitoConfig.saml.redirectSignIn,
        redirectSignOut: tenantCognitoConfig.saml.redirectSignOut,
        responseType: tenantCognitoConfig.saml.responseType,
      };
      localCognitoConfig.hasOAuth = true;
    }
    return localCognitoConfig;
  };

  const handleStructureAndRole = (slugTenantLocalParam) => {
    if (activeStructure || activeGroup) {
      localStorage.setItem(
        `${slugTenantLocalParam}-structureData`,
        JSON.stringify({
          structure: activeStructure,
          role: activeGroup,
        }),
      );
    }
  };

  const handleConfig = async (params, clearCache) => {
    const slugTenantLocalParam = params?.tenant ?? "";
    try {
      const tenantInfo = await getTenantInfo(slugTenantLocalParam);
      if (!tenantInfo) {
        setErrorTenant(true);
        return;
      }

      const tenantCognitoConfig = tenantInfo.data;

      if (!tenantCognitoConfig?.aws_user_pools_id) {
        setErrorTenant(true);
        return;
      }

      if (clearCache) {
        Cache.clear();
        localStorage.removeItem(`${slugTenantLocalParam}-structureData`);
      }

      // setta il nome del tenant
      Cache.setItem(
        `${slugTenantLocalParam}-nameTenant`,
        tenantInfo.nameTenant,
      );

      const localCognitoConfig = createCognitoConfig(tenantCognitoConfig);
      setCognitoConfig(localCognitoConfig);

      // 1 day
      const expires = Date.now() + 1000 * 60 * 60 * 24;
      Cache.setItem(`${slugTenantLocalParam}-cognitoConfig`, cognitoConfig, {
        expires,
      });
      Cache.setItem("tenant", slugTenantLocalParam, { expires });

      setSlugTenant(slugTenantLocalParam);
      setNameTenant(tenantInfo.nameTenant);
      handleStructureAndRole(slugTenantLocalParam);
    } catch (error) {
      if (
        (!error.message || error.message !== "Failed to fetch") &&
        error !== "Failed to fetch"
      ) {
        captureException("tenant", "get-tenant", error);
      }
      setErrorTenant(true);
    }
  };

  const handleNameTenant = async (slugTenantLocalParam) => {
    const tenantInfo = await getTenantInfo(slugTenantLocalParam);
    if (!tenantInfo) {
      return;
    }
    Cache.setItem(`${slugTenantLocalParam}-nameTenant`, tenantInfo.nameTenant);
    setNameTenant(tenantInfo.nameTenant);
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    const tenant = Cache.getItem("tenant");
    const cognito = Cache.getItem(`${tenant}-cognitoConfig`);
    const nameTenant = Cache.getItem(`${tenant}-nameTenant`);
    const isDifferentTenant = !!params.tenant && tenant !== params.tenant;
    if (!cognito?.aws_user_pools_id || isDifferentTenant) {
      handleConfig(
        params.tenant || !tenant ? params : { tenant },
        isDifferentTenant,
      );
      return;
    }
    if (!nameTenant) {
      handleNameTenant(params.tenant || !tenant ? params : { tenant }, true);
    } else {
      setNameTenant(nameTenant);
    }
    setSlugTenant(tenant);
    setCognitoConfig(cognito);
  }, []);

  return {
    user,
    setUser,
    isLoaded,
    email,
    setEmail,
    mustModifyPasswordUser,
    setMustModifyPasswordUser,
    handleChangeStructure,
    activeGroup,
    activeStructure,
    cognitoConfig,
    structures,
    urlImageData,
    signOut,
    setSignOut,
    language,
    setLanguage,
    evaluationStatus,
    slugTenant,
    nameTenant,
    errorTenant,
  };
};
