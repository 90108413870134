export const resources = {
  it: {
    translation: {
      "30days": "Ultimo mese",
      "3CharsError": "Inserire almeno 3 caratteri",
      actionErrorPageText:
        "Prova a <1><0>ricaricare la pagina</0></1> oppure torna alla <1><0>home page</0></1>",
      actionUnlockedAllUsers_plural:
        "Azione eseguita. Sono state sbloccate <strong>{{unlocked}} schede di valutazione.</strong>",
      actionUnlockedAllUsers_singular:
        "Azione eseguita. È stata sbloccata <strong>{{unlocked}} scheda di valutazione.</strong>",
      actionUnlockedNotWork_plural:
        "Non è stato possibile sbloccare <strong>{{locked}} schede di valutazione</strong> perchè queste non contengono alcun elemento di valutazione.",
      actionUnlockedNotWork_singular:
        "Non è stato possibile sbloccare <strong>{{locked}} scheda di valutazione</strong> perchè queste non contengono alcun elemento di valutazione.",
      actionUnlockedSomeUsers_plural_plural:
        "Sono state sbloccate <strong>{{unlocked}} schede di valutazione</strong>. Non è stato possibile, invece, sbloccare <strong>{{locked}} schede di valutazione</strong> perchè queste non contengono alcun elemento di valutazione.",
      actionUnlockedSomeUsers_plural_singular:
        "Sono state sbloccate <strong>{{unlocked}} schede di valutazione</strong>. Non è stato possibile, invece, sbloccare <strong>{{locked}} scheda di valutazione</strong> perchè questa non contiene alcun elemento di valutazione.",
      actionUnlockedSomeUsers_singular_plural:
        "È stata sbloccata <strong>{{unlocked}} scheda di valutazione</strong>. Non è stato possibile, invece, sbloccare <strong>{{locked}} schede di valutazione</strong> perchè queste non contengono alcun elemento di valutazione.",
      actionUnlockedSomeUsers_singular_singular:
        "È stata sbloccata {{unlocked}} scheda di valutazione. Non è stato possibile, invece, sbloccare <strong>{{locked}} scheda di valutazione</strong> perchè questa non contiene alcun elemento di valutazione.",
      activeAutovalutation: "Abilita autovalutazione dei collaboratori",
      admin: "Amministratore",
      adminApprovalOnEdit:
        "La modifica delle priorità che prevede la cancellazione dei feedback precedenti deve essere approvata dall'amministratore",
      adminApprovalWarning:
        "Selezionando questa opzione, le tue modifiche saranno sottoposte ad approvazione da parte dell'amministratore. In attesa dell'approvazione il tuo collaboratore non potrà ricevere feedback sulle priorità.",
      addFilter: "Aggiungi filtro",
      addQuestion: "Aggiungi Domanda",
      adoptionPage: "Adoption",
      adoptionPageSubMenu: "Adoption",
      afterNDays: "Dopo {{num}} giorni",
      aggregate: "Aggregata",
      alertUnsavedChanges:
        "Vuoi uscire dalla pagina? Le modifiche apportate potrebbero non essere salvate.",
      alertWorkInProgress:
        "I contenuti di questa pagina sono in fase di aggiornamento",
      alertWorkInProgressSkill: "Competenza in aggiornamento",
      alertWorkInProgressValor: "Valore in aggiornamento",
      alertWorkInProgressMacroarea: "Macroarea in aggiornamento",
      alertWorkInProgressMacroskill: "Macrocompetenza in aggiornamento",
      all: "Tutti",
      allCdc: "Tutte le strutture",
      allStructure: "Altri Team",
      allTeamFeedbacks: "Feedback di tutti i team",
      allTeams: "Tutti i team",
      allTeamsDescription:
        "I dati presentati nella Dashboard sono relativi ai seguenti team selezionati:",
      allUsers: "Tutti",
      and: "e",
      answer: "Risposta",
      answersValutation: "Risposte alle domande di valutazione",
      answersAutovalutation: "Risposte alle domande di autovalutazione",
      applyFilter: "Applica filtri",
      approveAllGoals: "Approva tutte le priorità",
      approvedAllGoalsErrorMessage:
        "Si è verificato un errore durante l'approvazione delle priorità, riprova più tardi",
      approvedAllGoalsSuccessMessage:
        "Tutte le priorità stanno per essere approvate, attendi qualche minuto",
      approveError: "Errore: priorità non approvate",
      approveGoals: "Approva priorità",
      approveSuccess: "Priorità approvate",
      asc: "Crescente",
      assigned: "Assegnato",
      assignTo: "Assegna a",
      attachFile: "allega file",
      autovalutations: "Autovalutazioni",
      autovalutation: "Autovalutazione",
      autovalutationSaveError:
        "Errore con il salvataggio dell' autovalutazione, riprova più tardi",
      autovalutationSaveSuccesfully: "Autovalutazione salvata correttamente",
      autovalutationWorkInProgress:
        "La scheda è in fase di elaborazione. Tra pochi minuti potrai procedere alla tua autovalutazione",
      averageGoalRating: "Punteggio medio sulle Priorità",
      averageMacroareeRating: "Punteggio medio sulle Macroaree di Valori",
      averageMacroskillRating: "Punteggio medio sulle Macroaree di Competenze",
      averageRatingFeedback: "Media punteggi da feedback",
      averageRatingManager: "Media punteggi dal manager",
      back: "Annulla",
      backEdit: "Annulla Modifiche",
      backToLogin: "Torna al login",
      backToLogin2: "per tornare alla pagina di login",
      backToLogin3: "Torna alla pagina di login",
      backToStructures: "Torna all'elenco dei team",
      backToStructure: "Torna al team",
      backToStructureName: "Torna a {{nameTeam}}",
      boss: "Manager",
      bossAnswers: "Risposte di valutazione",
      bossQuestionsValutationSettings: "Domande per il manager",
      category: "Categoria",
      cdc: "Strutture di livello",
      cf: "Codice Fiscale",
      click: "Clicca",
      clickHere: "clicca qui",
      closeProcess: "Chiusura",
      "closeProcess#1": "",
      closeReview: "chiudi review",
      coboss: "Co-Manager",
      cobosses: "Co-Managers",
      collaborator: "Collaboratore",
      collaboratorAnswers: "Risposte di autovalutazione",
      collaboratorAutovalutation: "Autovalutazione del collaboratore",
      collaboratorAutovalutations: "Autovalutazioni dei collaboratori",
      collaboratorNotes: "Note del collaboratore",
      collaboratorQuestionsValutationSettings: "Domande per il collaboratore",
      collaborators: "Collaboratori",
      competence: "Competenza",
      competences: "Competenze",
      compiledCardsFinalReviewMessage: "Schede di valutazione compilate",
      compiled: "Compilate",
      compiledEvaluation: "Compilata",
      compiledFeedbackNotificationText: "Feedback ricevuto",
      compiledGoalFeedbackNotificationText: "Feedback ricevuto",
      compiledRequest: "Feedback ricevuti",
      confirm: "Conferma",
      confirmChanges: "Conferma modifiche",
      confirmEmailMessage: "Ti abbiamo inviato un'email di conferma.",
      "confirmfinalReview#1": "Sei sicuro di voler avviare la Final Review?",
      "confirmfinalReview#2":
        "Sei sicuro di voler chiudere la review corrente?",
      "confirmfinalReview#1Subtext":
        '{{substitute}} Utenti non hanno ancora raggiunto il numero minimo di feedback ricevuti per accedere alla valutazione finale. Potrai sempre ammetterli manualmente una volta avviata la Final Review.<br/>Inoltre non sarà più possibile modificare le impostazioni di "Tipologia Final Review" in pagina <1><0>Processo</0></1>.',
      "confirmfinalReview#2Subtext":
        "Una volta chiusa la review,  le schede di valutazione non potranno più essere modificate e i collaboratori potranno visualizzare la valutazione ricevuta dai rispettivi manager",
      confirmGoalAlert: "Sei sicuro di voler confermare le priorità?",
      confirmGoalAlertSubtext:
        "Se confermi le priorità verranno inviate all'amministratore per l'accettazione",
      confirmManualSendEmailSubtitle:
        "Sei sicuro di voler inviare le email di benvenuto agli utenti selezionati?",
      confirmManualSendEmailTitle: "Invio manuale delle email di benvenuto",
      confirmNextPhase: "Sei sicuro di voler avviare la fase successiva?",
      confirmNextPhase2: "Vuoi procedere comunque?",
      confirmNotifyEndCompilation:
        "Sei sicuro di voler inviare la notifica al tuo manager?",
      confirmNotifyEndCompilationDescription:
        "Puoi inviare questa notifica solo una volta, ma potrai comunque continuare a modificare la tua autovalutazione anche successivamente fino all'interruzione del processo di review da parte dell'amministratore",
      confirmPassword: "Conferma password",
      confirmResetPasswordDescription:
        "Controlla la tua casella di posta. Ti abbiamo inviato le istruzioni per reimpostare la password",
      confirmSettings2Subtext:
        "Avviando la fase successiva non sarà più possibile modificare i setting presenti nelle pagine",
      confirmTouchpoint: "Conferma touch point",
      confirmTouchpointAlert: "Sei sicuro di voler confermare il touch point?",
      confirmTouchpointGenericErrorMessage:
        "Non è stato possibile confermare il touch point",
      confirmTouchpointSuccessMessage: "Il touch point è stato confermato",
      contractAll: "Contrai tutto",
      createdAt: "Creato il",
      createMacroAreaSuccess:
        "La creazione della macroarea è avvenuta con successo",
      createSkillSuccess:
        "La creazione della competenza è avvenuta con successo",
      createStructureGenericErrorMessage:
        "Qualcosa è andato storto nella creazione del team. Riprova più tardi",
      createStructureSuccessMessage:
        "La creazione del team è avvenuta con successo",
      createValorSuccess: "La creazione del valore è avvenuta con successo",
      createWorkflow: "Conferma e avvia",
      creationDate: "Data di creazione",
      ctaEvalutationForm: "Invia Autovalutazione",
      currentPassword: "Password corrente",
      currentStage: "Fase attuale:",
      currentWorkflow: "Workflow corrente",
      customAuth: "Accedi tramite credenziali di posta",
      dashboard: "Dashboard",
      dateRange: "Intervallo di date",
      delete: "Elimina",
      deletedUsers: "Utenti eliminati",
      deleteFeedbackAlert: "Sei sicuro di voler eliminare il feedback?",
      deleteFeedbackGenericErrorMessage:
        "Non è stato possibile eliminare il feedback",
      deleteFeedbackSuccessMessage: "Feedback eliminato con successo",
      deleteFeedbackTimeErrorMessage:
        "Il tempo limite per eliminare il feedback è scaduto",
      deleteMacroArea: "Elimina Macroarea",
      deleteMacroSkill: "Elimina Macroarea",
      deleteRequestFeedbackAlert:
        "Sei sicuro di voler eliminare la richiesta di feedback?",
      deleteRequestFeedbackGenericErrorMessage:
        "Non è stato possibile eliminare la richiesta di feedback",
      deleteRequestFeedbackSuccessMessage:
        "Richiesta di feedback eliminata con successo",
      deleteRequestFeedbackTimeErrorMessage:
        "Il tempo limite per eliminare la richiesta di feedback è scaduto",
      deleteSkill: "Elimina Competenza",
      deleteStructureAlert: "Sei sicuro di voler eliminare il team",
      deleteStructureErrorMessage: "Team non eliminato",
      deleteStructureSuccessMessage: "Team eliminato con successo",
      deleteUser: "Elimina utente",
      deleteValor: "Elimina Valore",
      desc: "Decrescente",
      deselectBelow: "Deseleziona sottostanti",
      describeYourIssue: "Descrivi il tuo problema",
      description: "Descrizione",
      detail: "Dettaglio",
      didNotReceivedEmail: "Non hai ricevuto la mail?",
      disabled: "Disabilitato",
      disabledUsers: "Utenti disabilitati",
      disableUser: "Disabilita utente",
      done: "Avvenuto",
      edit: "Modifica",
      editImage: "Modifica immagine",
      editMacroArea: "Modifica Macroarea",
      editMacroSkill: "Modifica Macroarea",
      editPassword: "Modifica Password",
      editSelection: "Modifica Selezione",
      editSkill: "Modifica Competenza",
      editUser: "Modifica utente",
      editValor: "Modifica Valore",
      editWorkflow: "Modifica titolo",
      elementAverageRating: "Punteggi medi per elementi di valutazione",
      endedTouchpoints: "Touchpoint avvenuti",
      email: "Email",
      emailUserCollabs: "Email per utenti collaboratori",
      emailCollabsLabel:
        "Abilita email di benvenuto automatica per utenti collaboratori",
      emailsSentSuccessfully: "Email inviate con successo",
      en: "Inglese",
      enableAll: "Abilita tutti",
      enableAllUser: "Abilitati tutti gli utenti",
      enabled: "Abilitato",
      enableGenericFeedback: "Abilita feedback generici",
      enabledUsers: "Utenti abilitati",
      enableUser: "Abilita utente",
      endDate: "Data fine",
      errorCheckPassword: "Le due password devono coincidere",
      errorExport: "Errore: export non avviato correttamente",
      errorEmail: "Email non valida",
      errorFormatImage: "Formato immagine non valido",
      errorImport:
        "Si è verificato un errore durante l'import deli team e degli utenti.",
      errorNameAlreadyExist:
        "Questo nome è già in uso dal team di {{manager}}. Prova a inserire un nome diverso",
      errorOnUserUpdate:
        "Al momento non è possibile eseguire questa operazione. Riprova tra qualche minuto",
      errorPageText: "Oooops! C'è stato un errore",
      errorPassword: "Password non valida",
      errorPasswordFormat: "Formato password non corretto",
      errorPasswordFormat2:
        "Formato password non corretto, la password deve avere almeno 8 caratteri alfanumerici",
      errorPasswordNotLongEnough:
        "La password deve contenere almeno N caratteri",
      errorPasswordNumericCharacters:
        "La password deve contenere caratteri numerici",
      errorSessionIsExpired: "Errore: la sessione è scaduta",
      es: "Spagnolo",
      evaluationCardSaveSuccessfully:
        "Scheda di valutazione salvata correttamente",
      evaluationCardErrorSave:
        "Errore nel salvataggio della scheda di valutazione, riprova più tardi",
      evalutationCard: "Scheda di valutazione",
      evalutationElements: "Elementi di valutazione",
      evalutationForms: "Schede di valutazione",
      evalutationIndices: "Indici di valutazione",
      evalutationItems: "Elementi di valutazione",
      expandAll: "Espandi tutto",
      export: "Export in CSV",
      exportXlsx: "Export in Excel",
      exportAllCsv: "Esporta tutti in CSV",
      exportAllMembers: "Esporta tutti i membri in CSV",
      exportAllTeams: "Esporta tutti i team in CSV",
      exportAllMembersXlsx: "Esporta tutti i membri in Excel",
      exportAllTeamsXlsx: "Esporta tutti i team in Excel",
      exportCsv: "Esporta in CSV",
      exportDashboardError:
        "Si è verificato un errore durante l'export della dashboard, riprova più tardi",
      exportEvalutationCardError:
        "Si è verificato un errore durante l'export delle schede di valutazione, riprova più tardi",
      exportEvalutationCardSuccessfully:
        "Export in corso a breve riceverai una mail",
      exportFeedbackMessage: "Export in corso a breve riceverai una mail",
      exportPdf: "Export in PDF",
      exportSelected: "Esporta selezionati in CSV",
      exportSelectedXlsx: "Esporta selezionati in Excel",
      exportStructuresErrorMessage:
        "Si è verificato un errore durante l'export dei team, riprova più tardi",
      exportStructuresSuccessMessage:
        "Export dei team in corso, a breve riceverai un email con il file csv",
      exportUsersErrorMessage:
        "Si è verificato un errore durante l'export degli utenti, riprova più tardi",
      exportUsersSuccessMessage:
        "Export degli utenti in corso, a breve riceverai un email con il file csv",
      feedback: "Feedback",
      feedbackForMacroareaSettings: "N° feedback per Macroarea di valori",
      feedbackForMacroSkillSettings: "N° feedback per Macroarea di competenze",
      feedbackForPrioritySettings: "N° feedback per Priorità",
      feedbackNature: "Tipologia del feedback",
      feedbackNotificationText: "Feedback ricevuto",
      feedbacksPerPerson: "Feedback inviati per persona",
      fileIsAlreadyExists: "Hai già caricato questo file",
      filterBy: "Filtra per",
      filterDate: "Data del processo",
      filterDateOrProcess: "Data o fase del processo",
      finalNotes: "Note finali",
      finalReview: "Final Review",
      "finalReview#1": "Compilazione schede di valutazione",
      "finalReview#2": "Chiusura schede di valutazione",
      finalReviewPage: "Final Review",
      finalReviewPageSubMenu: "Final review",
      finalReviewSettings: "Tipologia Final Review",
      finalReviewSettingsMissing:
        "Inserire le impostazioni di final review per andare avanti",
      finalReviewLockedEvaluation: "Bloccata",
      finalReviewNotCompiledEvaluation: "Non compilata",
      finalReviewSaveSuccess:
        "Impostazioni di final review salvate correttamente",
      finalScoreManager: "Valutazione finale dal manager",
      finalScore: "Punteggio finale",
      finalScores: "Punteggi finali",
      forgotPassword: "Recupera password",
      forgotPasswordDescription:
        "Inserisci la tua email. Ti invieremo le istruzioni per reimpostare la password",
      from: "Dal",
      fromReviewStart: "da inizio Review",
      general: "Generale",
      generals: "Generali",
      generalFeedback: "Feedback generali",
      generalSettings: "Impostazioni generali",
      generic: "Generico",
      genericError: "Si è verificato un errore, per favore riprova più tardi",
      genericFeedback: "Feedback generici",
      generics: "Generici",
      goal: "Priorità",
      goalFeedback: "Feedback priorità",
      goalFeedbackNotificationText: "Feedback ricevuto",
      goals: "Priorità",
      google: "Accedi tramite Google",
      helloTextDashboard: "Ciao {{helloTextAttribute}}",
      HelloTextSubtitle:
        "I grafici delle attività verranno mostrati all'avvio della fase di {{namePhase}}",
      here: "qui",
      hideAnswers: "Nascondi risposte",
      highestDeviation: "Scostamento massimo superiore",
      import: "Import",
      infoSaveAutovaluationCard:
        "E' sempre possibile modificare i campi della tua scheda di valutazione finchè l'amministratore non interrompe l'attuale processo di review. Una volta compilata la scheda potrai anche inviare una notifica di fine compilazione al tuo manager",
      infoSaveAutovaluationCardWithoutNotification:
        "E' sempre possibile modificare i campi della tua scheda di valutazione finchè l'amministratore non interrompe l'attuale processo di review. Hai già inviato la notifica di fine compilazione al tuo manager",
      infoSaveEvaluationCard:
        "E' sempre possibile modificare i campi delle schede dei tuoi collaboratori finchè l'amministratore non interrompe l'attuale processo di review. Ad interruzione avvenuta, le valutazioni saranno rese visibili ai tuoi collaboratori.",
      insertDescription: "Inserisci descrizione",
      insertNameOrEmail: "Inserisci nome utente o email",
      insertQuestionText: "Inserisci il testo della domanda",
      invalidUser: "Utente non trovato",
      invalidFormat: "Formato file non valido",
      it: "Italiano",
      keepgoing: "Keep Going",
      labelDescriptionFeedback: "Descrivi il tuo percorso",
      locked: "Schede bloccate",
      lockedCardsOnGoingMessage: "Schede con feedback insufficienti",
      lockedCardsFinalReviewMessage: "Schede di valutazione bloccate",
      last30days: "negli ultimi 30 giorni",
      lastFeedback: "Ultimo feedback inviato",
      lastFeedbacksSent: "Feedback inviati per persona negli ultimi 30 giorni",
      lastLogin: "Ultimo accesso",
      lastLoginMessage: "Data e ora ultimo accesso",
      lastweek: "Ultima settimana",
      lang: "Lingua",
      login: "Accedi",
      loginDescription: "Inserisci le tue credenziali e accedi alla dashboard",
      loginError: "Email o password non valida",
      logout: "Logout",
      lowestDeviation: "Scostamento massimo inferiore",
      macroarea: "Macro Area",
      macroskill: "Macro Area",
      manageStructures: "Gestione teams",
      manager: "Manager",
      managerNotDefined: "Manager non definito",
      managerNotes: "Note del Manager",
      managerRating: "Punteggio del manager",
      managerValutation: "Valutazione del manager",
      managerValutations: "Valutazioni dei manager",
      manualSend: "Invio manuale",
      manualSendSubtitle:
        "L'invio manuale della mail di benvenuto consente di notificare gli utenti selezionati che non hanno mai effettuato l'accesso alla piattaforma",
      midYearReview: "Mid Year Review",
      "midYearReview#1": "Autovalutazione",
      "midYearReview#2": "Valutazione da parte del capo",
      "midYearReview#3": "Approvazione da parte di admin",
      "midYearReview#4": "Chiusura scheda di valutazione",
      moreOne: "più di 1",
      moreTen: "più di 10",
      multipleChoiceQuestion: "Domanda a risposta multipla",
      name: "Nome",
      nameMacroAreaIsAlreasyExists:
        "Errore: il nome della macroarea esiste già",
      nPrioritiess: "N° di priorità",
      nameStructure: "Nome team",
      nameSurname: "Nome Cognome",
      nameWorkflowIsRequired: "Inserisci il nome della Review",
      nature: "Natura",
      natureGraph: "Keep going/Think about",
      natureFeedback: "Natura del feedback",
      nPrioritySettings: "N° priorità da assegnare a ciascun collaboratore",
      nCoBoss: "N° Co-managers",
      nCollabs: "N° Collaboratori",
      nEvalutationFormsToBeFilled: "{{num}} Schede di valutazione da compilare",
      new: "Nuovo",
      newFeedback: "Nuovo feedback",
      newMacroArea: "Nuova Macro Area",
      newMacroSkill: "Nuova Macro Area",
      newPassword: "Nuova password",
      newQuestion: "Nuova domanda",
      newSkill: "Nuova Competenza",
      newStructure: "Nuovo Team",
      newValor: "Nuovo Valore",
      newWorkflow: "Avvia una nuova Review",
      newWorkflowCanBeExecuted: "È possibile avviare una nuova review",
      newWorkflowIsInProcess: "La creazione della nuova review è in esecuzione",
      newWorkflowSubtitle:
        "Per iniziare, inserisci il titolo della nuova Review. Potrai modificarlo anche più avanti accedendo alla pagina Review",
      nextStage: "Avvia fase successiva",
      noEnabledUsers: "Non ci sono collaboratori abilitati dentro questo team",
      noEvalutationForm: "Scheda di valutazione non disponibile",
      noEvalutationForms: "Schede di valutazione non disponibili",
      noEvalutationFormsMessage:
        "I criteri di ricerca selezionati non corrispondono a nessuna scheda di valutazione",
      noFeedbackSent: "Nessun feedback inviato",
      noLogin: "Nessun accesso",
      none: "Nessun profilo",
      noStructureCollaboratoreWarning: "Team di riferimento non definito",
      notAssigned: "Non Assegnato",
      notCompiled: "Non compilate",
      notCompiledCardsOnGoingMessage:
        "Schede con numero minimo di feedback raggiunto",
      notCompiledCardsFinalReviewMessage: "Schede di valutazione non compilate",
      notCompiledRequest: "Feedback non ricevuti",
      note: "Note",
      notFoundPage: "Pagina non trovata",
      notReadsFeedback: "Feedback non letti",
      notice: "Notifiche",
      "notification#autoEvaluation_adminUnlock#description":
        "Adesso puoi procedere alla tua autovalutazione",
      "notification#autoEvaluation_adminUnlock#object": "Autovalutazione",
      "notification#autoEvaluation_collabAndBoss_active#description":
        "È iniziata la Final Review. Da questo momento non è più possibile inviare feedback. Adesso puoi procedere alla tua autovalutazione",
      "notification#autoEvaluation_collabAndBoss_active#object":
        "Final review e autovalutazione",
      "notification#autoEvaluation_onlyCollab_active#description":
        "È iniziata la Final Review. Da questo momento non è più possibile inviare feedback. Adesso puoi procedere alla tua autovalutazione",
      "notification#autoEvaluation_onlyCollab_active#object":
        "Final review e autovalutazione",
      "notification#autoEvaluation_onlyCollab_deactivate#description":
        "È iniziata la Final Review. Da questo momento non è più possibile inviare feedback",
      "notification#autoEvaluation_onlyCollab_deactivate#object":
        "Final review",
      "notification#confirmTouchpoint#description":
        "È stato confermato che il touch point relativo al feedback avente oggetto: {{object}} sia avvenuto",
      "notification#confirmTouchpoint#object": "Touch point avvenuto",
      "notification#finalEvaluation_active#description":
        "È iniziata la Final Review. Da questo momento non è più possibile inviare feedback. Adesso puoi procedere alla valutazione dei tuoi collaboratori",
      "notification#finalEvaluation_active#object":
        "Final review e valutazione",
      "notification#finalEvaluation_adminUnlock#description":
        "Uno o più collaboratori nel tuo team sono stati ammessi alla Final Review. Da questo momento puoi procedere alla loro valutazione",
      "notification#finalEvaluation_adminUnlock#object": "Final review",
      "notification#finalEvaluation_deactivate#description":
        "È iniziata la Final Review. Da questo momento non è più possibile inviare feedback",
      "notification#finalEvaluation_deactivate#object": "Final review",
      "notification#finalReview#description":
        "{{otherData}} utenti non hanno raggiunto il numero minimo di feedback per accedere alla valutazione",
      "notification#finalReview#object": "Reminder",
      "notification#goalsApproved#description":
        "La tua richiesta di sostituzione delle priorità per l'utente {{otherData}} è stata approvata",
      "notification#goalsApproved#object": "Sostituzione di priorità approvata",
      "notification#goalsApprovedCollab#description":
        "Le tue priorità sono state aggiornate. Visualizza il tuo profilo per visualizzare le modifiche",
      "notification#goalsApprovedCollab#object": "Modifica delle priorità",
      "notification#goalsAssigned#description":
        "All'utente {{nameUserSender}} sono state assegnate le priorità previste. Vai alla sezione Utenti per confermarle",
      "notification#goalsAssigned#object": "Assegnazione priorità",
      "notification#goalsEdited#description":
        "Per l'utente {{otherData}} è stata richiesta una sostituzione di priorità. Vai alla sezione Utenti per gestirla",
      "notification#goalsEdited#object": "Sostituzione di priorità",
      "notification#goalsRejected#description":
        "Le priorità assegnate all'utente {{nameUserRecipient}} non sono state approvate. Torna alla pagina dell'utente per modificarle e inviarle nuovamente",
      "notification#goalsRejected#object": "Priorità rigettate",
      "notification#goalsRejectedEdit#description":
        "La tua richiesta di sostituzione delle priorità per l'utente {{otherData}} non è stata approvata",
      "notification#goalsRejectedEdit#object":
        "Sostituzione di priorità non approvata",
      "notification#newUsers#description":
        "Hai dei nuovi collaboratori nel tuo Team! Procedi all'assegnazione delle priorità",
      "notification#newUsers#object": "Assegnazione priorità",
      "notification#notifyCollaboratorCardCompilation#description":
        "Il collaboratore {{collaborator}} ha completato la sua autovalutazione",
      "notification#notifyCollaboratorCardCompilation#object":
        "Autovalutazione completata",
      "notification#reminder#description":
        "I tuoi collaboratori aspettano i tuoi feedback! Inviane uno adesso",
      "notification#reminder#object": "Reminder",
      "notification#requestTouchpoint#description":
        "Richiesto un touch point in merito al feedback avente oggetto: {{object}}. Vai ai tuoi feedback per vedere i dettagli",
      "notification#requestTouchpoint#object": "Richiesta di touch point",
      "notification#startSettings#3#description":
        "Ciao {{nameUserRecipient}}, adesso puoi assegnare le priorità ai tuoi collaboratori! Vai alla pagina Il tuo Team e procedi all'assegnazione",
      "notification#startSettings#3#object": "Assegnazione priorità",
      "notification#stopFinalReview_activeCollab#object": "Chiusura review",
      "notification#stopFinalReview_activeCollab#description":
        "La review {{otherData}} si è appena conclusa. A breve verrà avviata una nuova review. Adesso puoi visualizzare la tua scheda di valutazione",
      "notification#stopFinalReview_noActiveCollab#object": "Chiusura review",
      "notification#stopFinalReview_noActiveCollab#description":
        "La review {{otherData}} si è appena conclusa. A breve verrà avviata una nuova review",
      notifyEndCompilation: "Notifica fine compilazione",
      notifyEndCompilationError:
        "É accorso un problema durante l'invio della notifica di completamento",
      notifyEndCompilationSuccesfully:
        "Notifica di completamento inviata con successo",
      numSendFeedbacks: "Trend Feedback inviati per persona",
      object: "Oggetto",
      office365: "Accedi tramite Office 365",
      oldWorkflows: "Workflow conclusi",
      onGoing: "Ongoing",
      "onGoing#1": "Ongoing",
      onGoing2: "2° Ongoing",
      "onGoing2#1": "",
      onGoing2Phase: "Fase Ongoing 2",
      onGoingLockedEvaluation: "Feedback insufficienti",
      onGoingNotCompiledEvaluation: "N° minimo di feedback raggiunto",
      onGoingPhase: "Fase di ongoing",
      onGoingSaveFailed:
        "É accorso un problema durante il salvataggio delle impostazioni di Ongoing. Riprova più tardi.",
      onGoingSaveSuccess: "Impostazioni di Ongoing salvate con successo",
      onGoingSettings: "Impostazioni fase di Ongoing",
      openQuestion: "Domanda a risposta aperta",
      operationGenericErrorMessage:
        "Qualcosa è andato storto. Riprova più tardi",
      orCredentials: "oppure inserisci le tue credenziali",
      orderBy: "Ordina per",
      pageFeedback: "Feedback",
      pageHome: "Ongoing",
      pageHomeSubMenu: "Ongoing",
      pageItems: "Elementi di valutazione",
      pageProcess: "Processo",
      pageStructures: "Team",
      pageTeam: "Il tuo team",
      pageUsers: "Utenti",
      pageWorkflow: "Review",
      parentStructure: "Team superiore",
      partiallyCompiledCardsFinalReviewMessage:
        "Schede di valutazione parzialmente compilate",
      partiallyCompiled: "Parzialmente compilate",
      partiallyCompiledEvaluation: "Parzialmente compilata",
      password: "Password",
      passwordModified: "Password modificata",
      passwordModifiedDescription:
        "La tua password è stata modificata con successo. Torna alla pagina di login e effettua l'accesso",
      pending: "Richiesto",
      pendingGoalMessage: "In attesa di approvazione",
      pendingTouchpoints: "Touchpoint non avvenuti",
      personalDataTitle: "Anagrafica",
      phaseOf: "Fase di",
      placeholderDescriptionFeedback: "Inserisci una descrizione",
      placeholderQuestionText: "Testo della domanda",
      preferences: "Preferenze",
      problems: "Problemi riscontrati:",
      problemsIsNotReady:
        'Mancano ancora pochi passaggi per avviare la fase successiva "{{nameSubphase}}"',
      problemsIsReady: "Attenzione:",
      problemsIsNotReadySubtitle: "Passaggi mancanti:",
      process: "Processo",
      processOnGoingFeedbacksHeader:
        "N° minimo di feedback necessari per la valutazione finale",
      profession: "Cluster",
      profile: "Profilo",
      project: "Progetto",
      projects: "Progetti",
      quality: "Qualitativa",
      quantity: "Quantitativa",
      questionsValutationSettings: "Domande per valutazione di sintesi",
      rangeRatingSettings:
        "Range di modifica del punteggio ottenuto dal collaboratore",
      rating: "Rating",
      ratingScale: "Scala di valutazione",
      RatingScaleLabel: "Etichette di default della scala di rating",
      readsFeedback: "Feedback letti",
      received: "Ricevuti",
      receivedFeedback: "Feedback ricevuti",
      recipient: "Destinatario",
      recoveryPassword: "Hai dimenticato la password?",
      rejectError: "Errore: non è stato possibile rigettare le priorità",
      rejectGoals: "Rigetta le priorità",
      rejectedGoals: "Priorità non approvate",
      rejectSuccess: "Priorità rigettate con successo",
      reminder: "Reminder",
      reminderNoAccessSettings: "Nessun accesso alla piattaforma",
      reminderNoFeedbackSettings: "Nessun feedback inviato",
      reportingFinalReview: "Dashboard di Final Review",
      requestFeedback: "Richiedi Feedback",
      requestFeedbackNotificationText: "Richiesta di feedback ricevuta",
      requestForFeedback: "Richieste di feedback",
      requestGoalFeedbackNotificationText: "Richiesta di feedback ricevuta",
      requestorNotes: "Note del richiedente",
      requestTouchpoint: "Richiedi touch point",
      requestTouchpointAlert: "Sei sicuro di voler richiedere il touch point?",
      requestTouchpointGenericErrorMessage:
        "Non è stato possibile richiedere il touch point",
      requestTouchpointSuccessMessage:
        "Richiesta di touch point inviata con successo",
      requestsTouchpoint: "Richieste di Touch Point",
      requiredError: "Campo richiesto",
      requiredErrorRating:
        "È necessario inserire una valutazione per il feedback",
      requiredFeedback: "Richieste di feedback",
      replaceAGoal:
        "Non considerare per la valutazione finale i feedback inviati sino ad ora nella review corrente per questa priorità",
      resetPassword: "Modifica password",
      resetPasswordDescription: "Inserisci una nuova password",
      reporting: "Dashboard di Ongoing",
      review: "Ultima Review",
      role: "Ruolo",
      roleTenant: "Ruolo aziendale",
      save: "Salva modifiche",
      score: "Punteggio",
      search: "Cerca",
      selectedAll: "Seleziona tutto",
      selectATeam: "Seleziona un team",
      selectBelow: "Seleziona sottostanti",
      selectCdc: "Seleziona strutture",
      selectCurrentWorkflow: "Seleziona workflow corrente",
      selectedTeam: "Team selezionati ({{num}})",
      selectLang: "Seleziona la lingua",
      selectMultipleAnswerQuestion: "Seleziona la domanda a risposta multipla",
      selectTeamMember: "Seleziona i membri del team",
      selectTypeOfQuestion: "Seleziona la tipologia di domanda",
      selectReview: "Seleziona review",
      selectReviewTitle: "Seleziona review",
      selectUserToNotify: "Seleziona utenti da notificare",
      selectSkill: "Seleziona competenza",
      selectValor: "Seleziona valore",
      send: "Invia",
      sendAgain: "Invia di nuovo",
      sender: "Mittente",
      senderFeedbackNotes: "Note del mittente",
      sendEmail: "invia email",
      sendEmailsError: "Errore nell'invio della email, riprova più tardi",
      sendEvalutationForms: "Invia tutte le valutazioni",
      sendEvalutationForm: "Invia valutazione",
      sendFeedback: "Invia Feedback",
      sendFeedbackGenericErrorMessage: "Feedback non inviato",
      sendFeedbacks: "Feedback inviati",
      sendFeedbackSuccessMessage: "Feedback inviato con successo",
      sendGoals: "Conferma le priorità",
      sendNotification: "Invia notifica",
      sendRequestFeedbackGenericErrorMessage:
        "Richiesta di feedback non inviata",
      sendRequestFeedbackSuccessMessage:
        "Richiesta di feedback inviata con successo",
      sends: "Inviati",
      sendSupportRequest: "Invia una richiesta di supporto",
      sendWelcomeEmail: "invia adesso email di benvenuto",
      sentSupportEmail:
        "La tua richiesta di supporto è stata inviata con successo. Abbiamo inviato una copia all'indirizzo {{email}}",
      settings: "Setting",
      "settings#1": "Settings teams e utenti",
      "settings#2": "Generazione schede di valutazione",
      "settings#3": "Assegnazione priorità",
      settingsMissing: "Mancata definizione delle impostazioni di processo.",
      settingsPage: "Impostazioni",
      "UserPoolGroupAdmin#settingsMissingPage": "Vai alla sezione Processo.",
      showOthers: "Mostra altri",
      showWorkflows: "Archivio Review",
      singleRatingLabel: "Titolo rating",
      singleStructure: "Team di riferimento",
      sixToTen: "da 6 a 10",
      sizeOver8mb: "Le dimensioni dei file allegati sono superiori a 8 MB",
      skill: "Competenze",
      skills: "Competenze",
      skillFeedback: "Feedback competenze",
      skillsSubMenu: "Competenze",
      startDate: "Data inizio",
      status: "Stato",
      structure: "Team",
      structures: "Teams",
      structuresMissings: "Teams non caricati.",
      "UserPoolGroupAdmin#structuresMissingsPage": "Vai alla sezione Teams.",
      subCategory: "Sotto categoria",
      summary: "Riepilogo",
      summaryEvalutation: "Valutazione di sintesi",
      support: "Supporto",
      surname: "Cognome",
      system: "Sistema",
      tableWorkflowAutovalutation: "Autovalutazione",
      tableWorkflowKeepGoing: "N° Keep going",
      tableWorkflowMacroAreaRating: "Media per Macroarea",
      tableWorkflowRating: "Media feedback",
      tableWorkflowThinkAbout: "N° Think about",
      tableWorkflowTitle: "Titolo",
      team: "Il tuo team",
      teamDetail: "Dettaglio persone",
      teamMember: "Membri del team",
      teamStructureCaption: "(Manager: tu)",
      totals: "Totali",
      theNextPhaseCanBeStarted: "È possibile avviare la fase successiva",
      theNextPhaseCannotBeStarted:
        "Non è ancora possibile avviare la fase successiva",
      thinkabout: "Think About",
      title: "Titolo",
      titleEvalutationForm: "Scheda di valutazione",
      titleModalFilter:
        "Aggiungi i team alla dashboard selezionandoli dalla gerarchia aziendale",
      titleModalItem: "Dettaglio dei feedback",
      typeautoValutationTitle: "Tipologia di autovalutazione dei collaboratori",
      typeGraph: "Categoria feedback",
      typeValutationTitle:
        "Tipologia di valutazione finale da parte dei manager",
      to: "Al",
      toEvalutionCard: "Vai alla scheda di valutazione",
      toManyRequestError:
        "Hai effettuato troppi tentativi, per favore riprova più tardi",
      totalCards: "Totale schede di valutazione",
      touchpoint: "Touch point",
      touchpointConfirmedNotificationText: "Touch point confermato",
      touchpointHappened: "Avvenuto",
      touchpointIdle: "Non richiesto",
      touchpointNotificationText: "Touch point richiesto",
      touchpointRequested: "Richiesto",
      touchpointNotRequested: "Non richiesto",
      touchpointSenderNotes: "Note del richiedente",
      trendOfFeedback: "Timeline dei feedback ricevuti",
      trendOfFeedbackAltText: "Timeline dei feedback inviati",
      unassignedGoals: "Priorità non assegnate",
      unassignedSkills: "Competenze non assegnate",
      unassignedValors: "Valori non assegnati",
      unconfirmedGoals: "Priorità in attesa di conferma",
      undefined: "Non definito",
      unlockedError: "Errore nello sblocco della schede. Riprova più tardi",
      unlockedErrorSingleUser:
        "Errore nello sblocco della scheda. Riprova più tardi",
      unlockedSuccessfully:
        "Sblocco della schede avviato con successo, a breve verranno sbloccate",
      unlockedSuccessfullySingleUser:
        "Sblocco della scheda avviato con successo, a breve la scheda verrà sbloccata",
      unlockFinalReview: "sblocca le schede bloccate",
      unlockFinalReviewSingleUser: "sblocca la scheda",
      updateMacroArea: "Aggiornamento Macro Area",
      updateMacroAreaSuccess: "Macroarea aggiornata con successo",
      updateMacroSkill: "Aggiornamento Macro Area",
      updateSettingsSuccess: "Impostazioni aggiornate con successo",
      updateSkill: "Aggiornamento Competenza",
      updateStructureGenericErrorMessage:
        "Qualcosa è andato storto nella modifica del team. Riprova più tardi",
      updateStructureSuccessMessage:
        "La modifica del team è avvenuta con successo",
      updateUserStatusErrorMessage:
        "Non è stato possibile aggiornare lo stato dell'utente",
      updateUserStatusSuccessMessage:
        "Stato dell'utente aggiornato con successo",
      updateValor: "Aggiornamento Valori",
      updateSkillSuccess: "Aggiornamento competenza avvenuto con successo",
      updateValorSuccess: "Aggiornamento valore avvenuto con successo",
      updateWorkflow: "Modifica il titolo della Review",
      user: "Utente",
      userAlreadyExists: "L'utente è già esistente",
      users: "Utenti",
      usersGoalConfirmation:
        "A {{num}} utenti sono state assegnate le priorità previste.",
      "UserPoolGroupAdmin#usersGoalConfirmationPage":
        "Vai alla sezione Utenti per confermarle.",
      usersGoalMissing:
        "A {{num}} utenti non sono state assegnate le priorità previste.",
      "UserPoolGroupAdmin#usersGoalMissingPage": "Vai alla sezione Utenti.",
      "UserPoolGroupBoss#usersGoalMissingPage": "Vai alla sezione Il tuo team.",
      usersMissing: "Utenti non caricati.",
      "UserPoolGroupAdmin#usersMissingPage": "Vai alla sezione Utenti.",
      usersNotEnabled: "Non ci sono utenti abilitati.",
      "UserPoolGroupAdmin#usersNotEnabledPage": "Vai alla sezione Utenti.",
      userOrEmail: "Utente o email",
      userDataUpdateError: "Errore durante l'aggiornamento dei dati",
      userDataUpdateSuccessfully: "Dati aggiornati correttamente",
      userGoalUpdatedSuccessfully:
        "La tua richiesta è stata inoltrata all'amministratore",
      UserPoolGroupBoss: "Manager",
      UserPoolGroupCoBoss: "Co-Manager",
      UserPoolGroupCollaborator: "Collaboratore",
      userUpdatedSuccessfully: "Utente aggiornato correttamente",
      usersSkillMissing:
        "A {{num}} utenti non è stato assegnato alcuna competenza.",
      usersValorMissing: "A {{num}} utenti non è stato assegnato alcun valore.",
      "UserPoolGroupAdmin#usersSkillMissingPage": "Vai alla pagina Utenti.",
      "UserPoolGroupAdmin#usersValorMissingPage": "Vai alla pagina Utenti.",
      valor: "Valore",
      valorRating: "Valore",
      valors: "Valori",
      valorsSubMenu: "Valori",
      valorFeedback: "Feedback valori",
      valutations: "Valutazioni",
      valutationSaveFailed:
        "É accorso un problema durante il salvataggio delle impostazioni di valutazione. Riprova più tardi.",
      valutationSaveSuccess: "Impostazioni di valutazione salvate con successo",
      valutationSettings: "Impostazioni processo di valutazione",
      valutationWorkInProgress:
        "La scheda è in fase di elaborazione. Tra pochi minuti potrai procedere alla valutazione del collaboratore",
      viewAnswers: "Vedi risposte",
      welcome: "Bentornato!",
      welcomeMessage: "Bentornato! Ecco le ultime novità:",
      workflow: "Workflow",
      workflowTitle: "Titolo della Review",
      workflowWithoutName: "Senza Nome",
      writeFeedback: "Compila Feedback",
      yourFeedbacks: "I tuoi feedback",
      yourProfile: "Il tuo profilo",
      zeroToFive: "da 0 a 5",
      welcomeEmail: "Email di benvenuto",
    },
  },
};

export default { resources };
