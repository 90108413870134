import gql from "graphql-tag";

const ExportDashboardFinalReviewQuery = gql`
  mutation exportDashboardFinalReview(
    $activeGroup: String!
    $activeStructure: String
    $structures: [String]
    $users: [String]
    $language: String
    $cdc: [CdcDashboardFinalReviewInput]
    $isXlsx: Boolean
  ) {
    exportDashboardFinalReview(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      structures: $structures
      users: $users
      language: $language
      cdc: $cdc
      isXlsx: $isXlsx
    )
  }
`;

export default ExportDashboardFinalReviewQuery;
