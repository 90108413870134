import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import FormSendFeedback from "components/feedback/FormSendFeedback";
import FormRequestFeedback from "components/feedback/FormRequestFeedback";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useBlockHistory } from "helpers/RoutesHelper";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  boxCustomCss: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "&:focus": {
      outline: "none",
    },
    height: "90%",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      transform: "none",
    },
  },
  headerModal: {
    "&::after": {
      content: "''",
      display: "block",
      border: "1px solid #E0E0E0",
      borderBottom: "none",
    },
  },
  titleModal: {
    padding: theme.spacing(6, 4, 5, 4),
  },
  modalFeedback: {
    height: `calc(100vh - ${theme.navbarHeight}px)`,
    top: "72px !important",
    right: "0 !important",
    left: `${theme.drawerWidth}px !important`,
    bottom: "0 !important",
    width: `calc(100% - ${theme.drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100% - ${theme.navbarHeight}px)`,
      position: "fixed !important",
      left: `0 !important`,
      width: "100%",
    },
  },
}));

const ModalFeedback = ({
  open,
  setDataModal,
  type,
  handleClickAlert,
  feedbackData,
  updateDataFeedbacks,
  isPrecompiledFeedback,
  isUpdate,
  activeStructure,
  activeGroup,
  isBoss,
  isGoalFeedback,
  setModalOpen,
  idSelectedUser,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const handleClose = (isSubmit) => {
    if (dirty && !isSubmit) {
      const leavePage = confirm(t("alertUnsavedChanges"));
      if (!leavePage) return;
    }
    setDataModal({
      open: false,
      type,
    });
    if (setModalOpen) {
      setModalOpen(false);
    }
  };
  const location = useLocation();
  useBlockHistory(dirty, location.pathname, t);

  useEffect(() => {
    if (!open) {
      setDirty(false);
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open ?? false}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalFeedback}
        disablePortal
      >
        <Box
          bgcolor="white"
          position="absolute"
          height={type === "send" ? "60%" : "45%"}
          className={classes.boxCustomCss}
        >
          <Box height={72} className={classes.headerModal}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" className={classes.titleModal}>
                {type === "send" ? t("sendFeedback") : t("requestFeedback")}
              </Typography>
              <Box mr={2} display={{ xs: "block", md: "none" }} clone>
                <IconButton onClick={() => handleClose()} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box padding={4} pt={0} height="calc(100% - 105px)">
            {type === "send" ? (
              <FormSendFeedback
                setDataModal={setDataModal}
                handleClickAlert={handleClickAlert}
                feedbackData={feedbackData}
                updateDataFeedbacks={updateDataFeedbacks}
                isPrecompiledFeedback={isPrecompiledFeedback}
                isUpdate={isUpdate}
                activeStructure={activeStructure}
                activeGroup={activeGroup}
                isBoss={isBoss}
                isGoalFeedback={isGoalFeedback}
                handleClose={handleClose}
                idSelectedUser={idSelectedUser}
                setDirty={setDirty}
                dirty={dirty}
              />
            ) : (
              <FormRequestFeedback
                setDataModal={setDataModal}
                handleClickAlert={handleClickAlert}
                feedbackData={feedbackData}
                isPrecompiledFeedback={isPrecompiledFeedback}
                updateDataFeedbacks={updateDataFeedbacks}
                isUpdate={isUpdate}
                activeStructure={activeStructure}
                activeGroup={activeGroup}
                setModalOpen={setModalOpen}
                setDirty={setDirty}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ModalFeedback.propTypes = {
  open: PropTypes.bool,
  setDataModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["send", "request", ""]),
  handleClickAlert: PropTypes.func.isRequired,
  feedbackData: PropTypes.object,
  updateDataFeedbacks: PropTypes.func,
  isPrecompiledFeedback: PropTypes.bool,
  isUpdate: PropTypes.bool,
  activeStructure: PropTypes.string,
  activeGroup: PropTypes.string.isRequired,
  isBoss: PropTypes.bool,
  isGoalFeedback: PropTypes.bool,
  setModalOpen: PropTypes.func,
  idSelectedUser: PropTypes.string,
};

export default ModalFeedback;
