import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const useStyles = makeStyles()((theme) => ({
  filterButton: {
    borderRadius: 0,
  },
}));

const FilterFooter = ({ buttonText, action, disabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box mt="auto" position="absolute" bottom={0} left={0} width="100%">
      <Button
        onClick={action}
        variant="contained"
        color="primary"
        fullWidth={true}
        className={classes.filterButton}
        disabled={disabled}
      >
        {buttonText || t("applyFilter")}
      </Button>
    </Box>
  );
};

FilterFooter.propTypes = {
  buttonText: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FilterFooter;
