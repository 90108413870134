import React from "react";
import { TextField, MenuItem, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InputField = ({
  label,
  name,
  touched,
  error,
  formik,
  options,
  disabled,
  isFeedback,
  valueIsItem,
}) => {
  const { t } = useTranslation();
  return (
    <Box mb={{ xs: isFeedback ? 5 : 4, md: isFeedback ? 9 : 4 }}>
      <TextField
        fullWidth
        select
        variant="filled"
        margin="dense"
        size="small"
        name={name}
        label={label}
        required
        error={touched && error != null}
        helperText={error}
        disabled={disabled}
        {...formik}
      >
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
        {options.map((item, index) => (
          <MenuItem value={valueIsItem ? item : index} key={index}>
            {item.title || t(item)}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
  formik: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  disabled: PropTypes.bool,
  isFeedback: PropTypes.bool,
  valueIsItem: PropTypes.bool,
};

export default InputField;
