import React, { useContext } from "react";
import { AuthContext } from "components/auth/Authenticator";
const ProfileAdmin = React.lazy(() => import("../pages/admin/profile"));
const ProfileCollaborator = React.lazy(
  () => import("../pages/collaborator/profile"),
);
const ProfileBoss = React.lazy(() => import("../pages/boss/profile"));

const getProfileComponent = () => {
  const userInfo = useContext(AuthContext);
  switch (userInfo.activeGroup) {
    case "UserPoolGroupAdmin":
      return <ProfileAdmin {...userInfo} />;
    case "UserPoolGroupCollaborator":
      return <ProfileCollaborator {...userInfo} />;
    case "UserPoolGroupBoss":
      return <ProfileBoss {...userInfo} />;
  }
};

export default getProfileComponent;
