import React from "react";
import Box from "@mui/material/Box";
import userPlaceholder from "assets/img/placeholderUser.png";
import PropTypes from "prop-types";

const UserPlaceholder = ({ height, width }) => (
  <Box
    color="primary.main"
    border="2px solid"
    borderRadius="80px"
    width={width}
    height={height}
  >
    <img
      src={userPlaceholder}
      alt="profile"
      width="100%"
      height="100%"
      style={{ objectFit: "cover" }}
    ></img>
  </Box>
);

export default UserPlaceholder;

UserPlaceholder.defaultProps = {
  width: 50,
  height: 50,
};

UserPlaceholder.propTypes = {
  urlImage: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
