import gql from "graphql-tag";

const onUpdateGlobalWarnings = gql`
  subscription onUpdateGlobalWarnings($idUser: String!) {
    onUpdateGlobalWarnings(idUser: $idUser) {
      usersGoalConfirmation
      usersGoalMissing
      usersValorMissing
      usersSkillMissing
      idUser
    }
  }
`;

export default onUpdateGlobalWarnings;
