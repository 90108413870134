import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const FooterModalFilter = ({
  handleOpenModal,
  handleSubmit,
  buttonDisabled,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end" pt={2}>
      <Button color="primary" onClick={() => handleOpenModal(false, type)}>
        {t("back")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={buttonDisabled}
      >
        {t("confirm")}
      </Button>
    </Box>
  );
};

FooterModalFilter.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default FooterModalFilter;
