import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { useStyles } from "helpers/cssCommon";
import { useTranslation } from "react-i18next";
import DashboardFilter from "./components/DashboardFilter";
import { AuthContext } from "components/auth/Authenticator";
import getAllStructures from "pages/admin/structures/functions/getAllStructures";
import { getFlatDataFromTree } from "@nosferatu500/react-sortable-tree";
import { getDataHook } from "./helper/dashboardHelper";
import StructureFilter from "./components/StructureFilter";
import DataDashboardFinalReview from "./components/DataDashboardFinalReview";
import { API } from "@aws-amplify/api";
import ListFinalReviewSettings from "queries/settings/ListFinalReviewSettings";
import { captureException } from "helpers/SentryHelper";
import ExportDashboard from "components/base_components/ExportDashboard";
import ExportDashboardFinalReviewQuery from "queries/dashboardFinalReview/ExportDashboardFInalReviewQuery";
import AlertSnackbar from "components/AlertSnackbar";

const DashboardFinalReview = ({
  activeGroup,
  activeStructure,
  openFilters,
  setOpenFilters,
}) => {
  const { classes } = useStyles();
  const { language, slugTenant } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleOpenFilter = (value) => {
    setOpenFilters(value);
  };
  const [finalReviewSettings, setFinalReviewSettings] = useState({
    typeAutovalutation: "",
    typeValutation: "",
    loading: true,
  });

  const {
    currentTab,
    selectedStructure,
    filterUsers,
    users,
    treeData,
    structures,
    singleStructure,
    filterCdc,
    loadingData,
    countersDetail,
    countersAggregate,
    scoresOverRatingAggregate,
    scoresOverRatingDetail,
    averageRatingsElementsAggregate,
    averageRatingsElementsDetail,
    questionsAggregate,
    questionsDetail,
    bossQuestionsAggregate,
    bossQuestionsDetail,
    scoresSingleUser,
    dataAlert,
    setTreeData,
    handleSubmitFilter,
    handleChange,
    setStructures,
    getStructures,
    handleSelectedTeam,
    setUsers,
    getUsers,
    handleSubmitUserFilter,
    setLoadingData,
    getFinalReviewDashboardData,
    handleAlert,
    handleCloseAlert,
    handleSetDataDashboard,
  } = getDataHook();

  /**
   * trigger per effettuare il get delle strutture,
   * il get viene fatto solo quando si ha una lingua definita.
   * Recupera le strutture in modo gerarchico e se l'utente è un manager anche senza gerarchia
   */
  useEffect(() => {
    let isMounted = true;
    if (!language) {
      return;
    }
    const isBoss = activeGroup === "UserPoolGroupBoss";
    (async () => {
      const promises = [
        getAllStructures({
          getFlatDataFromTree,
          language,
          isBoss,
          activeGroup,
          activeStructure,
          slugTenant,
        }),
        getSettings(),
      ];
      promises.push(
        isBoss
          ? getAllStructures({
              getFlatDataFromTree,
              language,
              isBoss: true,
              activeGroup,
              activeStructure,
              hierarchy: false,
              slugTenant,
            })
          : getStructures(),
      );
      const [structureTreeData, newSettingsFinalReview, flatStructures] =
        await Promise.all(promises);
      if (isMounted) {
        setTreeData(structureTreeData);
        setFinalReviewSettings(newSettingsFinalReview);
        setStructures(flatStructures);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [language]);

  const getSettings = async () => {
    try {
      const resultFinalReview = await API.graphql({
        query: ListFinalReviewSettings,
        variables: {
          activeStructure,
          activeGroup,
        },
      });
      return {
        ...resultFinalReview.data.listFinalReviewSettings,
        loading: false,
      };
    } catch (error) {
      captureException("user", "dashboard-finalreview", error);
      return {
        typeAutovalutation: "",
        typeValutation: "",
        loading: false,
      };
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (!singleStructure) {
      return () => {
        isMounted = false;
      };
    }
    setUsers({ loading: true, data: [] });
    (async () => {
      const newUsers = await getUsers(
        singleStructure,
        activeGroup,
        activeStructure,
      );
      if (isMounted) {
        setUsers(newUsers);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [singleStructure]);

  const bossDisabledAggregate =
    activeGroup === "UserPoolGroupBoss" && structures.data.length === 1;

  const allSelectedStructure =
    selectedStructure.length === 0 ||
    Object.keys(treeData.nameStructures).length === selectedStructure.length;

  const numUsers = users.data.filter(
    (item) => item.role !== "UserPoolGroupBoss",
  ).length;
  const sendUsers = filterUsers.length > 0 && filterUsers.length !== numUsers;

  const getVariables = (isAggregate, sendUsers) => {
    const allStructures =
      activeGroup === "UserPoolGroupAdmin" ? null : structures.data;

    const variables = { language };
    if (filterCdc) {
      variables.cdc = filterCdc;
    }
    if (isAggregate) {
      variables.structures = (
        allSelectedStructure ? allStructures : selectedStructure
      )?.map(({ id }) => id);
      return variables;
    }
    if (sendUsers) {
      variables.users = filterUsers;
      return variables;
    }
    variables.structures = singleStructure;
    return variables;
  };

  /**
   * questo useeffect serve per fare una query ogni volta che viene cambiato un parametro nei filtri,
   * viene cambiato periodo o ancora tab.
   */
  useEffect(() => {
    let isMounted = true;
    if (structures.loading) {
      return () => {
        isMounted = false;
      };
    }
    setLoadingData(true);
    if (bossDisabledAggregate) {
      handleChange(null, 1);
    }
    const isAggregate = currentTab === 0 && !bossDisabledAggregate;

    if (!isAggregate && filterUsers.length === 0 && !singleStructure) {
      if (isMounted) {
        setLoadingData(false);
      }
      return () => {
        isMounted = false;
      };
    }

    const variables = getVariables(isAggregate, sendUsers);
    (async () => {
      const response = await getFinalReviewDashboardData(
        variables,
        !isAggregate,
        activeGroup,
      );
      if (isMounted && response) {
        handleSetDataDashboard(response);
        setLoadingData(false);
      }
    })();

    return () => {
      isMounted = true;
    };
  }, [
    filterUsers,
    singleStructure,
    selectedStructure,
    activeGroup,
    structures.loading,
    filterCdc,
  ]);

  const collabs = users.data.filter(
    (item) => item.role !== "UserPoolGroupBoss",
  );
  const singleUserIdTab = [
    selectedStructure.length === 1 ? selectedStructure[0].id : null,
    singleStructure,
  ];
  const singleUserId =
    filterUsers.length === 1 && currentTab === 1
      ? filterUsers[0]
      : collabs.length === 1 && currentTab === 1
        ? collabs[0].id
        : null;
  const numEvalutationForms = Object.values(
    currentTab === 0
      ? countersAggregate.valutationCounters
      : countersDetail.valutationCounters,
  ).reduce((accumulator, item) => (accumulator += item), 0);
  const getNumFilters = () => {
    if (currentTab === 0) {
      return selectedStructure?.length + filterCdc?.length;
    }
    return filterUsers?.length + filterCdc?.length;
  };

  const handleExport = async (all, _, isXlsx) => {
    let structures = null;
    if (currentTab === 0) {
      if (!all && !allSelectedStructure) {
        structures = selectedStructure.map(({ id }) => id);
      }
    }
    if (currentTab === 1) {
      if (all || !sendUsers) {
        structures = singleStructure;
      }
    }
    try {
      const response = await API.graphql({
        query: ExportDashboardFinalReviewQuery,
        variables: {
          activeGroup,
          activeStructure,
          language,
          ...(structures && { structures }),
          ...(currentTab === 1 &&
            !structures && {
              users: filterUsers,
              idStructureUsers: singleStructure,
            }),
          ...(!all && filterCdc.length > 0 && { cdc: filterCdc }),
          isXlsx,
        },
      });
      if (response.data.exportDashboardFinalReview) {
        handleAlert(t("exportFeedbackMessage"), "success");
        return;
      }
      handleAlert(t("exportDashboardError"), "error");
    } catch (err) {
      captureException("dashboard", "export", err);
      handleAlert(t("exportDashboardError"), "error");
    }
  };

  const getNextUsers = async () => {
    const newUsers = await getUsers(
      singleStructure,
      activeGroup,
      activeStructure,
      users.next,
    );

    setUsers({
      ...users,
      data: [...users.data, ...newUsers.data],
      next: newUsers.next,
    });
  };

  return (
    <>
      <Backdrop
        open={loadingData}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>
      <Box
        className={`${classes.widthResponsive}
            ${openFilters ? classes.boxWithOpenedFilters : ""}`}
      >
        <Paper elevation={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Typography variant="h4">{t("reportingFinalReview")}</Typography>
            {(currentTab === 0 || singleStructure) &&
              numEvalutationForms > 0 && (
                <ExportDashboard
                  exportDashboard={handleExport}
                  isAggregate={currentTab === 0}
                  disabledPartialExport={
                    filterCdc.length === 0 &&
                    ((currentTab === 0 && allSelectedStructure) ||
                      (currentTab === 1 && !sendUsers))
                  }
                  isCollab={false}
                />
              )}
          </Box>
          {!bossDisabledAggregate && (
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={currentTab}
              onChange={handleChange}
              aria-label="user-valutation-tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={t("aggregate")}
                disableRipple={true}
                disableFocusRipple={true}
                value={0}
              />
              <Tab
                label={t("teamDetail")}
                disableRipple={true}
                disableFocusRipple={true}
                value={1}
              />
            </Tabs>
          )}
          {currentTab === 1 && (
            <StructureFilter
              structures={structures}
              singleStructure={singleStructure}
              handleSelectedTeam={handleSelectedTeam}
              activeStructure={activeStructure}
              isBoss={activeGroup === "UserPoolGroupBoss"}
              disabledSelect={bossDisabledAggregate}
            />
          )}
        </Paper>
        {numEvalutationForms === 0 &&
          language &&
          (currentTab === 0 || singleStructure) &&
          !finalReviewSettings.loading &&
          !loadingData && (
            <Box mt={4}>
              <Paper elevation={3}>
                <Box display="flex">
                  <Box p={4} pr={2} color="primary.main">
                    <Typography variant="h5">0</Typography>
                  </Box>
                  <Box py={4}>
                    <Typography variant="h6">
                      {t("evalutationForms")}
                    </Typography>
                  </Box>
                </Box>
                <Box pl={4} pb={8}>
                  <Typography variant="subtitle1">
                    {t("noEvalutationFormsMessage")}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          )}
        {numEvalutationForms > 0 &&
          language &&
          (currentTab === 0 || singleStructure) &&
          !finalReviewSettings.loading && (
            <DataDashboardFinalReview
              openFilters={openFilters}
              language={language}
              activeGroup={activeGroup}
              counters={currentTab === 0 ? countersAggregate : countersDetail}
              scoresOverRating={
                currentTab === 0
                  ? scoresOverRatingAggregate
                  : scoresOverRatingDetail
              }
              averageRatingsElements={
                currentTab === 0
                  ? averageRatingsElementsAggregate
                  : averageRatingsElementsDetail
              }
              finalReviewSettings={finalReviewSettings}
              singleStructureId={singleUserIdTab[`${currentTab}`]}
              singleUserId={singleUserId}
              questions={
                currentTab === 0 ? questionsAggregate : questionsDetail
              }
              bossQuestions={
                currentTab === 0 ? bossQuestionsAggregate : bossQuestionsDetail
              }
              scoresSingleUser={scoresSingleUser}
              numEvalutationForms={numEvalutationForms}
            />
          )}
      </Box>
      <DashboardFilter
        openFilters={openFilters}
        handleOpenFilter={handleOpenFilter}
        showButtonFilter={!openFilters}
        handleSubmitFilter={handleSubmitFilter}
        handleSubmitUserFilter={handleSubmitUserFilter}
        selectedStructure={selectedStructure}
        disabledButton={!treeData.data.length}
        numStructures={Object.keys(treeData.nameStructures).length}
        userFilter={currentTab === 1}
        users={users.data}
        usersLoading={users.loading}
        stopList={!users.next}
        filterUsers={filterUsers}
        treeData={treeData}
        setTreeData={setTreeData}
        cdcFilterOn={activeGroup === "UserPoolGroupAdmin"}
        numFilters={getNumFilters()}
        getNextUsers={getNextUsers}
      />
      <AlertSnackbar
        open={dataAlert.open}
        text={dataAlert.text}
        severity={dataAlert.severity}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

DashboardFinalReview.propTypes = {
  activeGroup: PropTypes.string.isRequired,
  activeStructure: PropTypes.string,
  openFilters: PropTypes.bool,
  setOpenFilters: PropTypes.func,
};

export default DashboardFinalReview;
