import gql from "graphql-tag";

const ListGeneralSettings = gql`
  query listGeneralSettings(
    $activeStructure: String
    $activeGroup: String
    $isArchive: Boolean
    $idWorkflow: String
  ) {
    listGeneralSettings(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      isArchive: $isArchive
      idWorkflow: $idWorkflow
    ) {
      genericFeedback
      valorActivated
      goalActivated
      skillActivated
      welcomeEmailCollaborators
      cacheVersion
    }
  }
`;

export default ListGeneralSettings;
