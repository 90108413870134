import gql from "graphql-tag";

const ListCategoriesSelectedUserForUser = gql`
  query listCategoriesSelectedUserForUser(
    $activeGroup: String!
    $activeStructure: String!
    $selectedIdUser: String!
    $statusFilter: [StatusFilter]
    $idUserRecipient: String!
    $idWorkflow: String!
  ) {
    listValuesSelectedUserForBoss(
      selectedIdUser: $selectedIdUser
      activeGroup: $activeGroup
      activeStructure: $activeStructure
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        nameMacroArea
        idMacroArea
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    listSkillsSelectedUserForBoss(
      selectedIdUser: $selectedIdUser
      activeGroup: $activeGroup
      activeStructure: $activeStructure
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        nameMacroSkill
        idMacroSkill
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    listObjectivesSelectedUserForBoss(
      selectedIdUser: $selectedIdUser
      statusFilter: $statusFilter
      activeGroup: $activeGroup
      activeStructure: $activeStructure
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        status
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    getEvaluationCardManager(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      idUserRecipient: $idUserRecipient
      idWorkflow: $idWorkflow
    ) {
      key
      sort
      idWorkflow
      idStructure
      idUserRecipient
      valors {
        description
        id
        idMacroArea
        nameMacroArea
        numKeepGoing
        numThinkAbout
        ratingAverage
      }
      goals {
        description
        id
        ratingAverage
        numKeepGoing
        numThinkAbout
        finalScore
        ratingAverage
      }
      skills {
        description
        id
        idMacroSkill
        nameMacroSkill
        numKeepGoing
        numThinkAbout
        ratingAverage
      }
      macroarea {
        id
        finalScore
      }
      macroskill {
        id
        finalScore
      }
      synthesis {
        mediaRatingFeedback
        autovalutationScore
        finalScore
        managerNote
        collaboratorNote
        questions {
          question
          answer
        }
        bossQuestions {
          question
          answer
        }
      }
      noCard
      version
    }
  }
`;

export default ListCategoriesSelectedUserForUser;
