const reducerSubcategories = (state, action) => {
  switch (action.type) {
    case "updateSubcategories":
      return {
        ...state,
        subCategories: action.payload.subCategories,
        valorsForSelect: action.payload.valorsForSelect,
        skillsForSelect: action.payload.skillsForSelect,
      };
    case "updateHasSubcategories":
      return {
        ...state,
        hasSubcategories: action.payload.hasSubcategories,
      };
    case "resetSubcategories":
    default:
      return {
        subCategories: [],
        hasSubcategories: false,
        valorsForSelect: {},
        skillsForSelect: {},
      };
  }
};

export default reducerSubcategories;
