import React, { useState } from "react";
import { useStyles } from "../../helper/modalFilterHelper";
import { Modal, Box, Typography, Checkbox, TextField } from "@mui/material";
import PropTypes from "prop-types";
import HeaderModal from "../HeaderModal";
import { useTranslation } from "react-i18next";
import FooterModalFilter from "../FooterModalFilter";
import { Waypoint } from "react-waypoint";

const ModalFilter = ({
  openModal,
  handleOpenModal,
  items,
  selected,
  handleSelected,
  level,
  type,
  stopList,
  getNextUsers,
}) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const { classes } = useStyles();
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleClose = () => {
    setSearch("");
    handleOpenModal(false, type);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleOpenModal(false, type)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      className={classes.modalFilter}
    >
      <Box
        bgcolor="white"
        position="absolute"
        height={"60%"}
        className={classes.boxCustomCssOther}
      >
        <Box height={100} className={classes.headerModal}>
          <HeaderModal
            classes={classes}
            handleOpenModal={handleOpenModal}
            title={type === "cdc" ? "selectCdc" : "selectTeamMember"}
            type={type}
          />
        </Box>
        <Box px={4} pt={4} height="calc(100% - 130px)">
          <Box height="calc(100% - 54px)" className={classes.widthResponsive}>
            <Box display="flex" justifyContent="space-between">
              <Typography>{t(type)}</Typography>
            </Box>
            <TextField
              fullWidth
              value={search}
              variant="filled"
              margin="dense"
              size="small"
              name="search"
              label={t("search")}
              required
              type={"text"}
              onChange={handleSearch}
            />
            <Box className={classes.checkboxContainer}>
              {items
                .filter(({ fullname }) =>
                  fullname.toLowerCase().includes(search.toLowerCase()),
                )
                .map(({ fullname, id }) => (
                  <Box display="flex" alignItems="center" key={id}>
                    <Checkbox
                      color="primary"
                      checked={selected.includes(id)}
                      onChange={(event) =>
                        handleSelected(
                          selected,
                          event.target.checked,
                          id,
                          level,
                        )
                      }
                      name="value"
                    />
                    <Typography variant="subtitle1">{fullname}</Typography>
                  </Box>
                ))}
              {!stopList && (
                <Box height={4}>
                  <Waypoint onEnter={getNextUsers} />
                </Box>
              )}
            </Box>
          </Box>
          <FooterModalFilter
            handleOpenModal={handleOpenModal}
            handleSubmit={handleClose}
            type={type}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ModalFilter.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  handleSelected: PropTypes.func.isRequired,
  level: PropTypes.string,
  type: PropTypes.string.isRequired,
  stopList: PropTypes.bool.isRequired,
  getNextUsers: PropTypes.func.isRequired,
};

export default ModalFilter;
