export const getUrl = (notification) => {
  switch (notification.typeRequest) {
    case "startSettings#3":
    case "newUsers":
      return "/team";
    case "goalsAssigned":
      return `/users?idUser=${notification.idUserAssignedGoals}&nameUser=${notification.nameUserSender}`;
    case "goalsEdited":
      return `/users?idUser=${notification.idUserAssignedGoals}&nameUser=${notification.otherData}`;
    case "goalsRejectedEdit":
    case "goalsApproved":
      return `/team?idUser=${notification.idUserAssignedGoals}&nameUser=${notification.otherData}`;
    case "goalsRejected":
      return `/team?idUser=${notification.idUserAssignedGoals}&nameUser=${notification.nameUserRecipient}`;
    case "autoEvaluation_onlyCollab_active":
    case "autoEvaluation_onlyCollab_deactivate":
    case "autoEvaluation_collabAndBoss_active":
    case "autoEvaluation_adminUnlock":
    case "finalEvaluation_active":
    case "finalEvaluation_deactivate":
    case "finalEvaluation_adminUnlock":
    case "stopFinalReview_noActiveCollab":
      return "/workflow";
    case "stopFinalReview_activeCollab":
      return "/workflow?role=UserPoolGroupCollaborator";
    case "notifyCollaboratorCardCompilation":
      return `/workflow?role=UserPoolGroupBoss&idUser=${
        notification.idUserSender || notification.otherData
      }`;
    case "goalsApprovedCollab":
      return "/profile";
    default:
      return "";
  }
};

export const getFeedbackUrl = (notification) => {
  let typeFeedback = notification.type;
  const isRequestFeedback = ["requestFeedback", "requestGoalFeedback"].includes(
    notification.type,
  );

  const sent = ["touchpoint", "touchpointConfirmed"].includes(
    notification.type,
  );
  if (!isRequestFeedback) {
    typeFeedback =
      notification.category === "goal" ? "goalFeedback" : "feedback";
  }
  let url = `/feedbacks?idFeedback=${notification.idFeedback}&idWorkflow=${
    notification.idWorkflow
  }${
    notification.idStructureSender
      ? "&idStructure=" + notification.idStructureSender
      : ""
  }&data=${typeFeedback}`;

  if (sent) {
    url = `${url}&sent=true`;
  }
  return url;
};

export const getNotificationRoles = (notification) => {
  if (notification.type === "system") {
    switch (notification.typeRequest) {
      case "autoEvaluation_onlyCollab_active":
      case "autoEvaluation_onlyCollab_deactivate":
      case "autoEvaluation_collabAndBoss_active":
      case "autoEvaluation_adminUnlock":
      case "goalsApprovedCollab":
        return ["UserPoolGroupCollaborator"];
      case "startSettings#3":
      case "goalsRejected":
      case "newUsers":
      case "reminder":
      case "finalEvaluation_active":
      case "finalEvaluation_deactivate":
      case "finalEvaluation_adminUnlock":
      case "goalsApproved":
      case "goalsRejectedEdit":
        return ["UserPoolGroupBoss"];
      case "finalReview":
      case "goalsAssigned":
      case "goalsEdited":
        return ["UserPoolGroupAdmin"];
      case "stopFinalReview_activeCollab":
      case "stopFinalReview_noActiveCollab":
        return ["UserPoolGroupBoss", "UserPoolGroupCollaborator"];
      default:
        throw new Error("Tipo notifica non definita");
    }
  }
  switch (notification.type) {
    case "touchpoint":
    case "touchpointConfirmed":
    case "requestFeedback":
    case "requestGoalFeedback":
    case "notifyCollaboratorCardCompilation":
      return ["UserPoolGroupBoss"];
    case "feedback":
    case "compiledFeedback":
      return ["UserPoolGroupBoss", "UserPoolGroupCollaborator"];
    case "compiledGoalFeedback":
    case "goalFeedback":
      return ["UserPoolGroupCollaborator"];
    default:
      throw new Error("Tipo notifica non definita");
  }
};

export default { getUrl, getFeedbackUrl, getNotificationRoles };
