import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  titleRowContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.grey["200"],
      width: "33.33%",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.grey["200"],
      width: "25%",
    },
  },
  feedbackContainer: {
    display: "flex",
    padding: theme.spacing(0, 4),
    paddingBottom: theme.spacing(4),
    "& span": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& span:not(:first-of-type)": {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  widthResponsive: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      width: "75%",
    },
    width: "100%",
  },
  widthResponsiveNoMargin: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    width: "100%",
  },
  boxWithOpenedFilters: {
    [theme.breakpoints.down("lg")]: {
      width: "66.66%",
    },
  },
  numFeeds: {
    fontWeight: 400,
    margin: theme.spacing(0, 2),
  },
  noMarginTop: {
    marginTop: 0,
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  flexOverXs: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  trendBoxExpanded: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      justifyContent: "",
    },
  },
  trendBox: {
    width: "auto",
  },
}));

export default { useStyles };
