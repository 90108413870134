import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, LinearProgress } from "@mui/material";
import { toggleExpandedForAll } from "@nosferatu500/react-sortable-tree";
import { useTranslation } from "react-i18next";
import {
  useStyles,
  checkAllSelect,
  checkNoAllSelect,
} from "../../helper/modalFilterHelper";
import StructureTree from "../StructureTree";
import HeaderModal from "../HeaderModal";
import ActionModalFilter from "../ActionModalFilter";
import FooterModalFilter from "../FooterModalFilter";

const ModalFilterTeam = ({
  openModal,
  handleOpenModal,
  treeData,
  setTreeData,
  handleOpenFilter,
  structures,
  setStructures,
  type,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const handleExpand = (expanded) => {
    const treeDataExpanded = toggleExpandedForAll({
      treeData: treeData.data,
      expanded,
    });
    setTreeData({ ...treeData, data: treeDataExpanded });
  };

  const handleChangeSingle = (checked, { title, id }) => {
    if (checked) {
      setStructures([...structures, { name: title, id }]);
      return;
    }
    const index = structures.findIndex((item) => item.id === id);
    const newArray = [...structures];
    newArray.splice(index, 1);
    setStructures(newArray);
  };

  const handleSelectAll = (data, prevData = [], isSelect) => {
    if (
      (!structures.some(({ id }) => id === data.id) || !isSelect) &&
      !prevData.length
    ) {
      prevData.push({ name: data.title, id: data.id });
      if (!isSelect) {
        checkNoAllSelect(treeData, data);
      } else {
        checkAllSelect(treeData, data, structures);
      }
    }
    data.noAllSelected = !isSelect;
    if (data?.children?.length) {
      data.children.forEach((item) => {
        if (!structures.some(({ id }) => id === item.id) || !isSelect) {
          prevData.push({ name: item.title, id: item.id });
        }
        item.noAllSelected = !isSelect;
        handleSelectAll(item, prevData, isSelect);
      });
      return;
    }
    if (!isSelect) {
      prevData.forEach((item) => {
        const index = structures.findIndex(({ id }) => id === item.id);
        if (index >= 0) {
          structures.splice(index, 1);
        }
      });
      prevData = [];
    }
    setStructures([...structures, ...prevData]);
  };
  useEffect(() => {
    if (!openModal) {
      return;
    }
    if (structures.length === 0) {
      handleSelectAll(treeData.data[0]);
    }
  }, [openModal]);

  const handleSubmit = () => {
    handleOpenModal(false, type);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleOpenModal(false, type)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      className={classes.modalFilter}
    >
      <Box
        bgcolor="white"
        position="absolute"
        height={"60%"}
        className={classes.boxCustomCss}
      >
        <Box height={100} className={classes.headerModal}>
          <HeaderModal
            classes={classes}
            handleOpenModal={handleOpenModal}
            title="titleModalFilter"
            type={type}
          />
        </Box>
        <Box px={4} pt={4} height="calc(100% - 130px)">
          <Box height="calc(100% - 54px)" className={classes.widthResponsive}>
            <Box display="flex" justifyContent="space-between">
              <Typography>{t("structure")}</Typography>
              <ActionModalFilter
                handleExpand={handleExpand}
                handleSelectAll={handleSelectAll}
                treeData={treeData}
              />
            </Box>
            {treeData.loading ? (
              <LinearProgress />
            ) : (
              <StructureTree
                classes={classes}
                treeData={treeData}
                setTreeData={setTreeData}
                structures={structures}
                handleChangeSingle={handleChangeSingle}
                handleSelectAll={handleSelectAll}
              />
            )}
          </Box>
          <FooterModalFilter
            handleOpenModal={handleOpenModal}
            handleSubmit={handleSubmit}
            type={type}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ModalFilterTeam.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  treeData: PropTypes.object.isRequired,
  setTreeData: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  structures: PropTypes.array.isRequired,
  setStructures: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ModalFilterTeam;
