import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography, Fab } from "@mui/material";
import UserEvaluationStatus from "components/workflow/UserEvaluationStatus";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useStyles } from "../../helper/dashboardHelper";

const DetailsSingleUser = ({
  handleNavigate,
  numEvalutationForms,
  counters,
  rating,
  scoresSingleUser,
  typeAutovalutation,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const getSingleStatus = (counters) => {
    return Object.keys(counters).find(
      (counter) => counters[`${counter}`] === 1,
    );
  };

  return (
    <Box mt={4}>
      <Paper elevation={3}>
        <Box
          p={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t("evalutationCard")}</Typography>
          {numEvalutationForms > 0 && (
            <Box>
              <Fab
                size="small"
                color="primary"
                className={classes.buttonLink}
                onClick={() => handleNavigate("workflow")}
              >
                <ArrowForwardIcon fontSize="small" />
              </Fab>
            </Box>
          )}
        </Box>
        <Box
          width="100%"
          bgcolor="primary.main"
          color="white"
          display="flex"
          justifyContent="space-between"
        >
          <Box
            bgcolor="primary.main"
            px={4}
            py={2}
            width={typeAutovalutation ? "50%" : "100%"}
            borderRight={typeAutovalutation ? "1px solid white" : ""}
          >
            <Typography variant="subtitle1">
              {t("managerValutation")}
            </Typography>
          </Box>
          {typeAutovalutation && (
            <Box bgcolor="secondary.dark" px={4} py={2} width="50%">
              <Typography variant="subtitle1">
                {t("collaboratorAutovalutation")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #DFDADA"
        >
          <Box
            px={4}
            py={2}
            width={typeAutovalutation ? "50%" : "100%"}
            borderRight={typeAutovalutation ? "1px solid #DFDADA" : ""}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">{t("status")}</Typography>
            {counters.valutationCounters && (
              <UserEvaluationStatus
                evaluationStatus={getSingleStatus(counters.valutationCounters)}
                currentPhase="finalReview"
                loading={false}
              />
            )}
          </Box>
          {typeAutovalutation && (
            <Box
              px={4}
              py={2}
              width="50%"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">{t("status")}</Typography>
              {counters.autovalutationCounters && (
                <UserEvaluationStatus
                  evaluationStatus={getSingleStatus(
                    counters.autovalutationCounters,
                  )}
                  currentPhase="finalReview"
                  loading={false}
                />
              )}
            </Box>
          )}
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box
            px={4}
            py={2}
            width={typeAutovalutation ? "50%" : "100%"}
            borderRight={typeAutovalutation ? "1px solid #DFDADA" : ""}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">{t("finalScore")}</Typography>
            <Box color="primary.main">
              <Typography variant="h6">
                {rating[`${scoresSingleUser?.valutationScore}`] ?? "-"}
              </Typography>
            </Box>
          </Box>
          {typeAutovalutation === "quantity" && (
            <Box
              px={4}
              py={2}
              width="50%"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">{t("finalScore")}</Typography>
              <Box color="secondary.dark">
                <Typography variant="h6">
                  {rating[`${scoresSingleUser.autovalutationScore}`] ?? "-"}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

DetailsSingleUser.propTypes = {
  handleNavigate: PropTypes.func.isRequired,
  numEvalutationForms: PropTypes.number,
  counters: PropTypes.shape({
    valutationCounters: PropTypes.shape({
      locked: PropTypes.number.isRequired,
      notCompiled: PropTypes.number.isRequired,
      compiled: PropTypes.number.isRequired,
      partiallyCompiled: PropTypes.number.isRequired,
    }),
    autovalutationCounters: PropTypes.shape({
      locked: PropTypes.number.isRequired,
      notCompiled: PropTypes.number.isRequired,
      compiled: PropTypes.number.isRequired,
      partiallyCompiled: PropTypes.number.isRequired,
    }),
  }),
  rating: PropTypes.array.isRequired,
  scoresSingleUser: PropTypes.shape({
    autovalutationScore: PropTypes.number,
    valutationScore: PropTypes.number,
  }),
  typeAutovalutation: PropTypes.string,
};

export default DetailsSingleUser;
