import gql from "graphql-tag";

const ListWorkflows = gql`
  query listWorkflows($activeGroup: String!, $filterByDate: Boolean) {
    listWorkflows(activeGroup: $activeGroup, filterByDate: $filterByDate) {
      id
      name
      startDate
      endDate
    }
  }
`;

export default ListWorkflows;
