import { useEffect, useContext } from "react";
import { useBeforeunload } from "react-beforeunload";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

const useBlockHistory = (isBlock, path, t) => {
  const { navigator } = useContext(NavigationContext);
  useBeforeunload((event) => {
    if (isBlock) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    if (!isBlock) {
      return;
    }
    const unblock = navigator.push((...args) => {
      if (location.pathname !== path && isBlock) {
        const confirmLeave = confirm(t("alertUnsavedChanges"));
        if (confirmLeave) {
          navigator.push(...args);
        }
      }
    });
    return unblock;
  }, [isBlock, navigator]);
};

export const matrixPhasePages = {
  UserPoolGroupAdmin: {
    settings: "adoption",
    onGoing: "",
    finalReview: "dashboard-final-review",
  },
  UserPoolGroupBoss: {
    settings: "",
    onGoing: "",
    finalReview: "dashboard-final-review",
  },
  UserPoolGroupCollaborator: {
    settings: "",
    onGoing: "",
    finalReview: "",
  },
};
export { useBlockHistory };
