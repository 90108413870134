import React from "react";
import { Box, Paper, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles()((theme) => ({
  button: {
    [theme.breakpoints.down("sm")]: {
      minWidth: theme.spacing(44),
      maxHeight: theme.spacing(10),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
}));

const PeriodFilter = ({ allPeriods, handleSelectedPeriod, period }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box mt={4} clone>
      <Paper elevation={3}>
        <Box p={4}>
          <Box display="flex" className={classes.buttonContainer}>
            {allPeriods.map((item, index) => (
              <Box key={`${item}-${index}`} ml={index !== 0 ? 2 : 0}>
                <Button
                  onClick={() => handleSelectedPeriod(item)}
                  variant={period === item ? "contained" : "outlined"}
                  color={period ? "primary" : "default"}
                  className={classes.button}
                >
                  {t(item)}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PeriodFilter.propTypes = {
  allPeriods: PropTypes.array.isRequired,
  handleSelectedPeriod: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
};

export default PeriodFilter;
