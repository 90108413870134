import { createTheme, adaptV4Theme } from "@mui/material/styles";
const theme = createTheme(adaptV4Theme({}));

const shadows = [...theme.shadows];
shadows[2] = "3px 3px 16px #9E9E9E40";
shadows[3] = "3px 3px 16px #00000029";
shadows[4] = "3px 10px 16px #9E9E9E40";
shadows[5] = "-3px 3px 6px #9E9E9E29";

export { shadows };
