import gql from "graphql-tag";

const ListSkillsQuery = gql`
  query listSkills(
    $activeStructure: String
    $activeGroup: String!
    $idUserRecipient: String
    $forRecipient: Boolean
  ) {
    listSkills(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idUserRecipient: $idUserRecipient
      forRecipient: $forRecipient
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        labels {
          value
          description
        }
        idMacroSkill
        nameMacroSkill
      }
    }
  }
`;

export default ListSkillsQuery;
