import gql from "graphql-tag";

const ListCategoriesSelectedUser = gql`
  query listCategoriesSelectedUser(
    $selectedIdUser: String!
    $statusFilter: [StatusFilter]
    $idUserRecipient: String!
    $idWorkflow: String!
  ) {
    listValuesSelectedUser(selectedIdUser: $selectedIdUser) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        nameMacroArea
        idMacroArea
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    listSkillsSelectedUser(selectedIdUser: $selectedIdUser) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        nameMacroSkill
        idMacroSkill
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    listObjectivesSelectedUser(
      selectedIdUser: $selectedIdUser
      statusFilter: $statusFilter
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        status
        ratingAverage
        labels {
          description
          value
        }
      }
    }
    getEvaluationCardAdmin(
      idUserRecipient: $idUserRecipient
      idWorkflow: $idWorkflow
    ) {
      idWorkflow
      idStructure
      idUserRecipient
      valors {
        description
        id
        idMacroArea
        nameMacroArea
        numKeepGoing
        numThinkAbout
        finalScore
        ratingAverage
      }
      skills {
        description
        id
        idMacroSkill
        nameMacroSkill
        numKeepGoing
        numThinkAbout
        finalScore
        ratingAverage
      }
      goals {
        description
        id
        numKeepGoing
        numThinkAbout
        finalScore
        ratingAverage
      }
      macroarea {
        id
        finalScore
      }
      macroskill {
        id
        finalScore
      }
      synthesis {
        mediaRatingFeedback
        autovalutationScore
        finalScore
        managerNote
        collaboratorNote
        bossQuestions {
          question
          answer
        }
        questions {
          question
          answer
        }
      }
      version
    }
  }
`;

export default ListCategoriesSelectedUser;
