import gql from "graphql-tag";

const GetAlertsStepFunction = gql`
  query getAlertsStepFunction {
    getAlertsStepFunction {
      numAlert
      alert
      idValor
      idMacroArea
      idSkill
      idMacroSkill
      initWorkflow
    }
  }
`;

export default GetAlertsStepFunction;
