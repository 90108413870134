import { Cache } from "aws-amplify";

export const resetCache = (cacheVersion, slugTenant) => {
  const expires = Date.now() + 1000 * 60 * 60 * 24;
  const nameTenant = Cache.getItem(`${slugTenant}-nameTenant`);
  const cognitoConfig = Cache.getItem(`${slugTenant}-cognitoConfig`);

  Cache.clear();
  Cache.setItem("tenant", slugTenant, { expires });
  Cache.setItem(`${slugTenant}-nameTenant`, nameTenant, { expires });
  Cache.setItem(`${slugTenant}-cognitoConfig`, cognitoConfig, { expires });
  if (cacheVersion) {
    Cache.setItem(`${slugTenant}-cacheVersion`, cacheVersion, { expires });
  }
};

export default { resetCache };
