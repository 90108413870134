import gql from "graphql-tag";

const CreateRequestGoalFeedbackMutation = gql`
  mutation createRequestGoalFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: createRequestGoalFeedbackInput!
  ) {
    createRequestGoalFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      key
      sort
      createdAt
      id
    }
  }
`;

export default CreateRequestGoalFeedbackMutation;
