import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { palette, typography, shadows, variables } from "./globals/";
import { overrides } from "./components";

const theme = createTheme(
  adaptV4Theme({
    ...variables,
    palette,
    typography,
    overrides,
    shadows,
  }),
);

export default theme;
