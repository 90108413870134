import ListValuesQuery from "../../queries/categories/values/ListValues";
import ListObjectivesQuery from "../../queries/categories/objectives/ListObjectives";
import { API } from "@aws-amplify/api";
import { captureException } from "helpers/SentryHelper";
import ListSkillsQuery from "queries/categories/skills/ListSkills";

const listValues = async ({
  idUserRecipient,
  forRecipient,
  activeStructure,
  activeGroup,
}) => {
  try {
    const result = await API.graphql({
      query: ListValuesQuery,
      variables: {
        idUserRecipient,
        forRecipient,
        activeStructure,
        activeGroup,
      },
    });
    return result.data.listValues.Items;
  } catch (error) {
    captureException("query", "valors", error);
    return [];
  }
};
const listObjectives = async ({
  idUserRecipient,
  forRecipient,
  activeStructure,
  activeGroup,
}) => {
  try {
    const result = await API.graphql({
      query: ListObjectivesQuery,
      variables: {
        idUserRecipient,
        forRecipient,
        activeStructure,
        activeGroup,
        statusFilter: ["approved"],
      },
    });
    return result.data.listObjectives.Items;
  } catch (error) {
    captureException("query", "goals", error);
    return [];
  }
};

const listSkills = async ({
  idUserRecipient,
  forRecipient,
  activeStructure,
  activeGroup,
}) => {
  try {
    const result = await API.graphql({
      query: ListSkillsQuery,
      variables: {
        idUserRecipient,
        forRecipient,
        activeStructure,
        activeGroup,
      },
    });
    return result.data.listSkills.Items;
  } catch (error) {
    captureException("query", "skills", error);
    return [];
  }
};

export { listValues, listSkills, listObjectives };
