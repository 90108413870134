import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import NotAuthorized from "../pageErrors/401";
import CreateWorkflowPage from "pages/admin/createWorkflow";
import CircularProgress from "components/CircularProgress";
import queryString from "query-string";
import { InfoContext } from "context/InfoContext";
import { AuthContext } from "./Authenticator";

const isAuthorized = (auth, authorizedRoles) =>
  authorizedRoles.includes(auth.activeGroup) &&
  auth.user.signInUserSession.accessToken.payload["cognito:groups"].includes(
    auth.activeGroup,
  );

const ProtectedRoute = ({
  Component,
  auth,
  authorizedRoles,
  workflow,
  valorPage,
  skillPage,
  ...props
}) => {
  const location = useLocation();
  const navigation = useNavigate();
  const params = queryString.parse(location.search);
  const { settings } = useContext(InfoContext);
  const { handleChangeStructure, activeGroup, structures } =
    useContext(AuthContext);
  const rolesAllowed = ["UserPoolGroupBoss", "UserPoolGroupCollaborator"];
  if (
    rolesAllowed.includes(activeGroup) &&
    params.role &&
    rolesAllowed.includes(params.role) &&
    ((params.role === "UserPoolGroupBoss" && structures.idStructureBoss) ||
      (params.role === "UserPoolGroupCollaborator" &&
        structures.idStructureCollaborator))
  ) {
    const selectedIdStructure =
      params.role === "UserPoolGroupBoss"
        ? structures.idStructureBoss
        : structures.idStructureCollaborator;
    if (selectedIdStructure) {
      handleChangeStructure(selectedIdStructure, params.role);
    }
    navigation(location.pathname);
  }
  if (
    workflow.currentWorkflow === false &&
    isAuthorized(auth, "UserPoolGroupAdmin")
  ) {
    return <CreateWorkflowPage />;
  }
  if (workflow.currentWorkflow) {
    return isAuthorized(auth, authorizedRoles) &&
      (!valorPage || settings.valorActivated) &&
      (!skillPage || settings.skillActivated) ? (
      <Component
        {...props}
        activeStructure={auth.activeStructure}
        activeGroup={auth.activeGroup}
        name={auth?.user?.attributes.given_name}
      />
    ) : (
      <NotAuthorized />
    );
  }
  return <CircularProgress />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
    .isRequired,
  auth: PropTypes.object,
  workflow: PropTypes.object,
  authorizedRoles: PropTypes.arrayOf(PropTypes.string),
  valorPage: PropTypes.bool,
  skillPage: PropTypes.bool,
};
