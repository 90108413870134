import React, { useContext, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import DoneIcon from "@mui/icons-material/Done";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../auth/Authenticator";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const StructuresMenu = ({ structures, changeStructure, menuRef }) => {
  const { t } = useTranslation();
  const { activeStructure } = useContext(AuthContext);
  const navigation = useNavigate();
  const handleChangeStructure = useCallback(
    (id, role) => {
      changeStructure({
        structure: id,
        role,
      });
      navigation("/");
    },
    [changeStructure],
  );

  const arrayStructures = [];
  if (structures.idStructureBoss) {
    arrayStructures.push({
      role: "UserPoolGroupBoss",
      idStructure: structures.idStructureBoss,
      nameStructure: structures.nameStructureBoss,
    });
  }
  if (structures.idStructureCollaborator) {
    arrayStructures.push({
      role: "UserPoolGroupCollaborator",
      idStructure: structures.idStructureCollaborator,
      nameStructure: structures.nameStructureCollaborator,
    });
  }
  return (
    <div ref={menuRef}>
      {arrayStructures.map((item) => (
        <MenuItem
          onClick={() => handleChangeStructure(item.idStructure, item.role)}
          key={item.idStructure}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            minWidth={150}
          >
            <Box>
              <Box>
                <Typography variant="caption">{item.nameStructure}</Typography>
              </Box>
              <Typography variant="caption" color="textSecondary">
                {t(item.role)}
              </Typography>
            </Box>
            {item.idStructure === activeStructure ? (
              <Box marginLeft={4} color="primary.main" clone={true}>
                <DoneIcon />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </MenuItem>
      ))}
    </div>
  );
};

StructuresMenu.propTypes = {
  changeStructure: PropTypes.func.isRequired,
  structures: PropTypes.shape({
    idStructureBoss: PropTypes.string,
    nameStructureBoss: PropTypes.string,
    idStructureCollaborator: PropTypes.string,
    nameStructureCollaborator: PropTypes.string,
  }),
  menuRef: PropTypes.object.isRequired,
};

export default React.forwardRef((props, ref) => (
  <StructuresMenu {...props} menuRef={ref} />
));
