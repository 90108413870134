import React from "react";
import PropTypes from "prop-types";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Box, Typography, alpha, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  partiallyCompiled: {
    color: theme.palette.error.dark,
    background: `conic-gradient(${alpha(
      theme.palette.error.dark,
      0.4,
    )} 270deg, transparent 270deg 360deg)`,
    borderRadius: "50%",
  },
  marginBackground: {
    margin: "-2px",
  },
}));
const UserEvaluationStatus = ({ evaluationStatus, currentPhase, loading }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  if (loading) {
    return (
      <Box display="flex" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }
  switch (evaluationStatus) {
    case "locked":
      return (
        <Box display="flex" alignItems="center">
          <Box
            color="text.secondary"
            mr={2}
            display={{ xs: "none", md: "block" }}
          >
            <Typography variant="subtitle2">
              {t(`${currentPhase}LockedEvaluation`)}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            color={currentPhase === "onGoing" ? "primary.main" : "error.main"}
          >
            {currentPhase === "onGoing" ? (
              <RadioButtonUncheckedTwoToneIcon
                className={classes.marginBackground}
              />
            ) : (
              <NotInterestedIcon className={classes.marginBackground} />
            )}
          </Box>
        </Box>
      );
    case "notCompiled":
      return (
        <Box display="flex" alignItems="center">
          <Box
            color="text.secondary"
            mr={2}
            display={{ xs: "none", md: "block" }}
          >
            <Typography variant="subtitle2">
              {t(`${currentPhase}NotCompiledEvaluation`)}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            color={currentPhase === "onGoing" ? "primary.dark" : "grey.500"}
          >
            {currentPhase === "onGoing" ? (
              <CheckCircleTwoToneIcon className={classes.marginBackground} />
            ) : (
              <RadioButtonUncheckedTwoToneIcon
                className={classes.marginBackground}
              />
            )}
          </Box>
        </Box>
      );
    case "partiallyCompiled":
      return (
        <Box display="flex" alignItems="center">
          <Box
            color="text.secondary"
            mr={2}
            display={{ xs: "none", md: "block" }}
          >
            <Typography variant="subtitle2">
              {t(`partiallyCompiledEvaluation`)}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.partiallyCompiled}
          >
            <RadioButtonUncheckedTwoToneIcon
              className={classes.marginBackground}
            />
          </Box>
        </Box>
      );
    case "compiled":
      return (
        <Box display="flex" alignItems="center">
          <Box
            color="text.secondary"
            mr={2}
            display={{ xs: "none", md: "block" }}
          >
            <Typography variant="subtitle2">
              {t(`compiledEvaluation`)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" color="keepGoing.main">
            <CheckCircleTwoToneIcon className={classes.marginBackground} />
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

UserEvaluationStatus.propTypes = {
  evaluationStatus: PropTypes.string.isRequired,
  currentPhase: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserEvaluationStatus;
