export const resources = {
  es: {
    translation: {
      "30days": "Último mes",
      "3CharsError": "Introducir al menos 3 caracteres",
      actionErrorPageText:
        "Intente <0> <1> volver a cargar la página </1> </0> o vuelva a la <1> <0> página de inicio </0> </1>",
      actionUnlockedAllUsers_plural:
        "Acción realizada. <strong>{{unlocked}} se han desbloqueado las hojas de evaluación.</strong>",
      actionUnlockedAllUsers_singular:
        "Acción realizada. Desbloqueado <strong> {{unlocked}} placa de evaluación.</strong>",
      actionUnlockedNotWork_plural:
        "No ha sido posible desbloquear <strong>{{locked}} hojas de evaluación</strong> porque no contienen ningún elemento de evaluación.",
      actionUnlockedNotWork_singular:
        "No ha sido posible desbloquear <strong>{{locked}} las tarjetas de evaluación</strong>porque no contienen ningún elemento de evaluación.",
      actionUnlockedSomeUsers_plural_plural:
        "Desbloqueados <strong>{{unlocked}} los marcadores</strong>. Sin embargo, no ha sido posible desbloquear <strong>{{locked}} las tarjetas de evaluación </strong> porque no contienen ningún elemento de evaluación.",
      actionUnlockedSomeUsers_plural_singular:
        "Desbloqueados <strong>{{unlocked}} los marcadores</strong>. Sin embargo, no ha sido posible desbloquear <strong>{{locked}} las tarjetas de evaluación </strong> porque no contienen ningún elemento de evaluación.",
      actionUnlockedSomeUsers_singular_plural:
        "Desbloqueada <strong>{{unlocked}} tarjeta de puntuación</strong>. Sin embargo, no fue posible desbloquear <strong>{{locked}} las tarjetas de evaluación </strong> porque no contienen ningún elemento de evaluación.",
      actionUnlockedSomeUsers_singular_singular:
        "Se ha desbloqueado{{unlocked}} hoja de evaluación. Sin embargo, no fue posible desbloquear<strong>{{locked}} formulario de evaluación</strong> porque no contiene ningún elemento de evaluación.",
      activeAutovalutation: "Habilitar la autoevaluación de los colaboradores",
      admin: "Administrador",
      adminApprovalOnEdit:
        "Un cambio de prioridad que implique la eliminación de feedback anteriores debe ser aprobado por el administrador",
      adminApprovalWarning:
        "Al seleccionar esta opción, sus cambios se enviarán al administrador para su aprobación. A la espera de su aprobación, su colaborador no podrá recibir comentarios sobre las prioridades.",
      addFilter: "Añadir filtro",
      addQuestion: "Añade una pregunta",
      adoptionPage: "Adopción",
      adoptionPageSubMenu: "Adopción",
      afterNDays: "Después de {{num}} días",
      aggregate: "Agregada",
      alertUnsavedChanges:
        "¿Estás seguro de que quieres salir de esta página? Los cambios realizados se podrían perder.",
      alertWorkInProgress: "El contenido de esta página se está actualizando",
      alertWorkInProgressSkill: "Competencia en actualización",
      alertWorkInProgressValor: "Valor actualizándose",
      alertWorkInProgressMacroarea: "Macroárea actualizándose",
      alertWorkInProgressMacroskill: "Macrocompetencia en actualización",
      all: "Todos",
      allCdc: "Todas las instalaciones",
      allStructure: "Otros Equipos",
      allTeamFeedbacks: "Feedback de todos los equipos",
      allTeams: "Todos los equipos",
      allTeamsDescription:
        "Los datos presentados en el panel son relativos a los siguientes equipos seleccionados:",
      allUsers: "Todos",
      and: "y",
      answer: "Respuesta",
      answersValutation: "Respuestas a las preguntas de evaluación",
      answersAutovalutation: "Respuestas a las preguntas de autoevaluación",
      applyFilter: "Aplicar filtros",
      approveAllGoals: "Aprobar todas las prioridades",
      approvedAllGoalsErrorMessage:
        "Se ha producido un error al aprobar las prioridades, prueba más tarde",
      approvedAllGoalsSuccessMessage:
        "Todas las prioridades están a punto de aprobarse, espera algunos minutos",
      approveError: "Error: priodidades no aprobadas",
      approveGoals: "Aprobar prioridades",
      approveSuccess: "Prioridades aprobadas",
      asc: "Creciente",
      assigned: "Asignado",
      assignTo: "Asignar a",
      attachFile: "adjuntar archivo",
      autovalutations: "Autoevaluaciones",
      autovalutation: "Autoevaluación",
      autovalutationSaveError:
        "Se ha producido un error al guardar la autoevaluación, prueba más tarde",
      autovalutationSaveSuccesfully: "Autoevaluación guardada correctamente",
      autovalutationWorkInProgress:
        "La ficha está en elaboración. En pocos minutos podrás acceder a tu autoevaluación",
      averageGoalRating: "Puntuación media en Prioridades",
      averageMacroareeRating: "Puntuación media en las macroáreas de valores",
      averageMacroskillRating:
        "Puntuación media en las MacroÁreas de Competencias",
      averageRatingFeedback: "Nota media de los feedbacks",
      averageRatingManager: "Nota media del responsable",
      back: "Cancelar",
      backEdit: "Cancelar cambios",
      backToLogin: "Volver al inicio de sesión",
      backToLogin2: "para volver al inicio de sesión",
      backToLogin3: "Volver al inicio de sesión",
      backToStructures: "Volver a la lista de los equipos",
      backToStructure: "Volver al equipo",
      backToStructureName: "Volver a {{nameTeam}}",
      boss: "Responsable",
      bossAnswers: "Respuestas de evaluación",
      bossQuestionsValutationSettings: "Preguntas para el responsable",
      category: "Categoría",
      cdc: "Estructuras de nivel",
      cf: "DNI",
      click: "Haz clic",
      clickHere: "haz clic aquí",
      closeProcess: "Cerrar",
      "closeProcess#1": "",
      closeReview: "cerrar revisión",
      coboss: "Co-Responsable",
      cobosses: "Co-Responsables",
      collaborator: "Colaborador",
      collaboratorAnswers: "Respuestas de autoevaluación",
      collaboratorAutovalutation: "Autoevaluación del colaborador",
      collaboratorAutovalutations: "Autoevaluación del personal",
      collaboratorNotes: "Notas del colaborador",
      collaboratorQuestionsValutationSettings: "Preguntas para el colaborador",
      collaborators: "Colaboradores",
      competence: "Competencia",
      competences: "Competencias",
      compiledCardsFinalReviewMessage:
        "Formularios de evaluación cumplimentados",
      compiled: "Rellenar",
      compiledEvaluation: "Completada",
      compiledFeedbackNotificationText: "Feedback recibido",
      compiledGoalFeedbackNotificationText: "Feedback recibido",
      compiledRequest: "Feedbacks recibidos",
      confirm: "Confirmar",
      confirmChanges: "Aplicar cambios",
      confirmEmailMessage: "Te hemos enviado un correo de confirmación.",
      "confirmfinalReview#1":
        "¿Estás seguro que deseas iniciar la Revisión Final?",
      "confirmfinalReview#2":
        "¿Estás seguro que deseas cerrar la revisión actual?",
      "confirmfinalReview#1Subtext":
        '{{substitute}} Los usuarios aún no han alcanzado el número mínimo de opiniones recibidas para poder optar a la Revisión Final. Siempre puede admitirlos manualmente una vez iniciada la Revisión Final.<br/>Además, ya no será posible cambiar la configuración del "Tipo de revisión final" en la página <1><0>Proceso</0></1>.',
      "confirmfinalReview#2Subtext":
        "Una vez cerrada la revisión, las fichas de evaluación no se podrán modificar y los colaboradores podrán ver la evaluación recibida de los respectivos responsables",
      confirmGoalAlert:
        "¿Estás seguro de que quieres confirmar las prioridades?",
      confirmGoalAlertSubtext:
        "Si confirmas las prioridades, éstas se enviarán al administrador para su aprobación",
      confirmManualSendEmailSubtitle:
        "¿Estás seguro de querer enviar el email de bienvenida a los usuarios seleccionados?",
      confirmManualSendEmailTitle: "Envío manual del email de bienvenida",
      confirmNextPhase:
        "¿Estás seguro de que quieres pasar a la siguiente fase?",
      confirmNextPhase2: "¿Quieres proceder de todos modos?",
      confirmNotifyEndCompilation:
        "¿Está seguro de que quiere enviar la notificación a su gerente?",
      confirmNotifyEndCompilationDescription:
        "Sólo puede enviar esta notificación una vez, pero puede seguir editando su autoevaluación después hasta que el administrador detenga el proceso de revisión",
      confirmPassword: "Confirmar tu contraseña",
      confirmResetPasswordDescription:
        "Por favor, comprueba tu bandeja de entrada. Te hemos enviado las instrucciones para cambiar la contraseña",
      confirmSettings2Subtext:
        "Si pasas a la siguiente fase no podrás volver a cambiar los ajustes en las páginas",
      confirmTouchpoint: "Confirmar touch point",
      confirmTouchpointAlert:
        "¿Estás seguro de que quieres confirmar el touch point?",
      confirmTouchpointGenericErrorMessage:
        "No ha sido posible confirmar el touch point",
      confirmTouchpointSuccessMessage:
        "El touch point se ha cofirmado correctamente",
      contractAll: "Contraer todo",
      createdAt: "Se ha creado el",
      createMacroAreaSuccess: "Se ha creado correctamente la macroáera",
      createSkillSuccess: "Se ha creado correctamente la competencia",
      createStructureGenericErrorMessage:
        "Algo ha salido mal en la creación del equipo. Inténtalo más tarde",
      createStructureSuccessMessage: "Se ha creado correctamente el equipo",
      createValorSuccess: "Se ha creado correctamente el valor",
      createWorkflow: "Confirmar y enviar",
      creationDate: "Fecha de creación",
      ctaEvalutationForm: "Enviar Autoevaluación",
      currentPassword: "Contraseña actual",
      currentStage: "Fase actual:",
      currentWorkflow: "Revisión actual",
      dashboard: "Dashboard",
      dateRange: "Intervalo de fechas",
      delete: "Eliminar",
      deletedUsers: "Usuarios eliminados",
      deleteFeedbackAlert: "¿Estás seguro de que quieres eliminar el feedback?",
      deleteFeedbackGenericErrorMessage:
        "No ha sido posible eliminar el feedback",
      deleteFeedbackSuccessMessage: "Feedback eliminado correctamente",
      deleteFeedbackTimeErrorMessage:
        "El límite de tiempo para eliminar el feedback ha expirado",
      deleteMacroArea: "Eliminar Macroárea",
      deleteMacroSkill: "Eliminar Macroárea",
      deleteRequestFeedbackAlert:
        "¿Estás seguro de querer eliminar la solicitud de feedback?",
      deleteRequestFeedbackGenericErrorMessage:
        "No ha sido posible eliminar la solicitud de feedback",
      deleteRequestFeedbackSuccessMessage:
        "Solicitud de feedback eliminada correctamente",
      deleteRequestFeedbackTimeErrorMessage:
        "El tiempo límite para eliminar la solicitud de feecback ha expirado",
      deleteSkill: "Eliminar Competencia",
      deleteStructureAlert: "¿Estás seguro de querer eliminar el equipo?",
      deleteStructureErrorMessage: "Equipo no eliminado",
      deleteStructureSuccessMessage:
        "El equipo ha sido eliminado correctamente",
      deleteUser: "Eliminar usuario",
      deleteValor: "Eliminar valor",
      desc: "Decreciente",
      deselectBelow: "Deseleccionar subyacente",
      describeYourIssue: "Describe el problema",
      description: "Descripción",
      detail: "Detalle",
      didNotReceivedEmail: "¿No has recibido el correo electrónico?",
      disabled: "Desactivado",
      disabledUsers: "Usuarios desactivados",
      disableUser: "Desactivar usuario",
      done: "Hecho",
      edit: "Editar",
      editImage: "Modificar imagen",
      editMacroArea: "Editar Macroárea",
      editMacroSkill: "Editar Macroárea",
      editPassword: "Cambiar Contraseña",
      editSelection: "Modificar selección",
      editSkill: "Editar Competencia",
      editUser: "Editar usuario",
      editValor: "Editar valor",
      editWorkflow: "Editar título",
      elementAverageRating: "Puntuación media de los elementos de evaluación",
      endedTouchpoints: "Los touch points han tenido lugar",
      email: "Email",
      emailUserCollabs: "Email para los usuarios colaboradores",
      emailCollabsLabel:
        "Activar el email de bienvenida para los usuarios colaboradores",
      emailsSentSuccessfully: "Emails enviados con éxito",
      en: "Inglés",
      enableAll: "Activar todos",
      enableAllUser: "Disponible para todos los usuarios",
      enabled: "Activado",
      enableGenericFeedback: "Activar feedbacks genéricos",
      enabledUsers: "Usuarios habilitados",
      enableUser: "Habilitar usuario",
      endDate: "Fecha final",
      errorCheckPassword: "Las dos contraseñas deben coincidir",
      errorExport: "Error: exportación incorrecta",
      errorEmail: "Correo electrónico no válido",
      errorFormatImage: "El formato de la imagen no es válido",
      errorImport:
        "Se ha producido un error al importar los equipos y los usuarios.",
      errorNameAlreadyExist:
        "Este nombre está ya en uso por el equipo de {{manager}}. Prueba a introducir un nombre diferente",
      errorOnUserUpdate:
        "Por el momento no es posible efectuar esta operación. Prueba más tarde",
      errorPageText: "Hubo un error",
      errorPassword: "La contraseña no es válida",
      errorPasswordFormat: "Formato de contraseña incorrecto",
      errorPasswordFormat2:
        "Formato de contraseña incorrecto, la contraseña tiene que tener al menos 8 caracteres alfanuméricos",
      errorPasswordNotLongEnough:
        "La contraseña debe tener al menos N caracteres",
      errorPasswordNumericCharacters:
        "La contraseña debe contener caracteres numéricos",
      errorSessionIsExpired: "Error: la sesión ha expirado",
      es: "Español",
      evaluationCardSaveSuccessfully:
        "Ficha de evaluación guardada correctamente",
      evaluationCardErrorSave:
        "Se ha producido un error al guardar la ficha de evaluación, prueba más tarde",
      evalutationCard: "Ficha de evaluación",
      evalutationElements: "Criterios de evaluación",
      evalutationForms: "Ficha de evaluación",
      evalutationIndices: "Índices de evaluación",
      evalutationItems: "Criterios de evaluación",
      expandAll: "Expandir todo",
      export: "Exportar datos como CSV",
      exportXlsx: "Exportar datos como Excel",
      exportAllCsv: "Exportar datos como CSV",
      exportAllMembers: "Exportar todos los miembros como CSV",
      exportAllTeams: "Exportar todos los equipos como CSV",
      exportAllMembersXlsx: "Exportar todos los miembros como Excel",
      exportAllTeamsXlsx: "Exportar todos los equipos como Excel",
      exportCsv: "Exporta como CSV",
      exportDashboardError:
        "Se ha producido un error al exportar el panel, prueba más tarde",
      exportEvalutationCardError:
        "Se ha producido un error durante la exportación de las hojas de evaluación, inténtelo de nuevo más tarde",
      exportEvalutationCardSuccessfully:
        "Exportación en curso, recibirá un correo electrónico en breve",
      exportFeedbackMessage:
        "Exportación en curso, en breve recibirás un correo electrónico",
      exportPdf: "Exportar como PDF",
      exportSelected: "Exportar seleccionado como CSV",
      exportSelectedXlsx: "Exportar seleccionado como Excel",
      exportStructuresErrorMessage:
        "Se ha producido un error al exportar los equipos. Vuelve a intentarlo más tarde",
      exportStructuresSuccessMessage:
        "Exportación del equipo en curso, en breve recibirás un correo con el archivo CSV",
      exportUsersErrorMessage:
        "Se ha producido un error al exportar los usuarios, vuelve a intentarlo más tarde",
      exportUsersSuccessMessage:
        "Exportación de los usuarios en curso, en breve recibirás un correo con el archivo CSV",
      feedback: "Feedback",
      feedbackForMacroareaSettings: "N° feedbacks por Macroárea",
      feedbackForMacroSkillSettings:
        "Nº de feedback por Macroárea de competencia",
      feedbackForPrioritySettings: "N° feedbacks por Prioridad",
      feedbackNature: "Tipo de feedback",
      feedbackNotificationText: "Feedback recibido",
      feedbacksPerPerson: "Feedbacks enviados por persona",
      fileIsAlreadyExists: "Ya has cargado este archivo",
      filterBy: "Filtra por",
      filterDate: "Filtrar por fecha",
      filterDateOrProcess: "Fecha o fase del proceso",
      finalNotes: "Notas Finales",
      finalReview: "Revisión final",
      "finalReview#1": "Rellenar ficha de evaluación",
      "finalReview#2": "Cerrar ficha de evaluación",
      finalReviewPage: "Revisión final",
      finalReviewPageSubMenu: "Revisión final",
      finalReviewSettings: "Tipo de Revisión Final",
      finalReviewSettingsMissing: "Configurar la revisión final para continuar",
      finalReviewLockedEvaluation: "Bloqueada",
      finalReviewNotCompiledEvaluation: "No completada",
      finalReviewSaveSuccess:
        "Se ha guardado completamente la configuración de la revisión final",
      finalScoreManager: "Evaluación final del responsable",
      finalScore: "Puntuación final",
      finalScores: "Resultados finales",
      forgotPassword: "Recuperar la contraseña",
      forgotPasswordDescription:
        "Introduce tu dirección de correo electrónico. Te enviaremos instrucciones para reestablecer la contraseña",
      from: "Desde el",
      fromReviewStart: "desde el inicio de la revisión",
      general: "General",
      generals: "Generales",
      generalFeedback: "Feedbacks generales",
      generalSettings: "Ajustes generales",
      generic: "Genérico",
      genericError: "Se ha producido un error, por favor inténtalo más tarde",
      genericFeedback: "Feedbacks genéricos",
      generics: "Genéricos",
      goal: "Prioridad",
      goalFeedback: "Feedback prioridad",
      goalFeedbackNotificationText: "Feedback recibido",
      goals: "Prioridades",
      google: "Inicia sesión con Google",
      helloTextDashboard: "Hola {{helloTextAttribute}}",
      HelloTextSubtitle:
        "Los gráficos de actividad se mostrarán al inicio de la {{namePhase}}",
      here: "aquí",
      hideAnswers: "Ocultar respuestas",
      highestDeviation: "Desviación máxima superior",
      import: "Importar",
      infoSaveAutovaluationCard:
        "Siempre es posible editar los campos de su cuadro de mando hasta que el administrador detenga el proceso de revisión en curso. Una vez que haya rellenado el formulario, también puede enviar una notificación de finalización a su gestor",
      infoSaveAutovaluationCardWithoutNotification:
        "Siempre es posible editar los campos de su cuadro de mando hasta que el administrador detenga el proceso de revisión en curso. Una vez que haya rellenado el formulario, también puede enviar una notificación de finalización a su gestor",
      infoSaveEvaluationCard:
        "Siempre es posible editar los campos de los registros de sus colaboradores hasta que el administrador detenga el proceso de revisión en curso. Una vez interrumpida la revisión, las evaluaciones se harán visibles para sus compañeros de trabajo.",
      insertDescription: "Introducir descripción",
      insertNameOrEmail: "Introduce tu nombre de usuario o dirección de correo",
      insertQuestionText: "Introduce texto de la pregunta",
      invalidUser: "No ha sido posible encontrar al usuario",
      invalidFormat: "Formato de archivo no válido",
      it: "Italiano",
      keepgoing: "Keep Going",
      labelDescriptionFeedback: "Describe tu trayectoria",
      locked: "Tarjetas bloqueadas",
      lockedCardsOnGoingMessage: "Ficha con feedbacks insuficientes",
      lockedCardsFinalReviewMessage: "Tarjetas de puntuación bloqueadas",
      last30days: "en los últimos 90 días",
      lastFeedback: "Último feedback enviado",
      lastFeedbacksSent:
        "Feedbacks enviados por persona en los últimos 30 días",
      lastLogin: "Último acceso",
      lastLoginMessage: "Fecha y hora desde el último acceso",
      lastweek: "Última semana",
      lang: "Idioma",
      login: "Iniciar Sesión",
      loginDescription: "Introduce tus credenciales para acceder al panel",
      loginError: "Correo electrónico o contraseña no válida",
      logout: "Cerrar sesión",
      lowestDeviation: "Desviación máxima inferior",
      macroarea: "Macroárea",
      macroskill: "Macroárea",
      manageStructures: "Administración del equipo",
      manager: "Responsable",
      managerNotDefined: "Responsable no definido",
      managerNotes: "Notas del responsable",
      managerRating: "Puntuación del responsable",
      managerValutation: "Evaluación del gestor",
      managerValutations: "Evaluaciones de los gestores",
      manualSend: "Envío manual",
      manualSendSubtitle:
        "El envío manual del email de bienvenida permite notificar a los usuarios seleccionados que aún no han iniciado sesión en la plataforma",
      midYearReview: "Revisión de mitad de año",
      "midYearReview#1": "Autoevaluación",
      "midYearReview#2": "Evaluación del responsable",
      "midYearReview#3": "Aprobación del administrador",
      "midYearReview#4": "Cerrar la ficha de evaluación",
      moreOne: "más de 1",
      moreTen: "más de 10",
      multipleChoiceQuestion: "Pregunta de opción múltiple",
      name: "Nombre",
      nameMacroAreaIsAlreasyExists:
        "Error: el nombre de la macroárea ya existe",
      nPrioritiess: "Nº de prioridades",
      nameStructure: "Nombre del equipo",
      nameSurname: "Nombre Apellidos",
      nameWorkflowIsRequired: "Introduce el nombre de la Revisión",
      nature: "Naturaleza",
      natureGraph: "Keep going/Think about",
      natureFeedback: "Naturaleza del feedback",
      nPrioritySettings:
        "Nº de prioridades asignada a cada uno de los colaboradores",
      nCoBoss: "Nº de Co-responsables",
      nCollabs: "Nº de colaboradores",
      nEvalutationFormsToBeFilled:
        "{{num}} de fichas de evaluación por completar",
      new: "Nuevo",
      newFeedback: "Nuevo feedback",
      newMacroArea: "Nueva Macroárea",
      newMacroSkill: "Nuova Macro Area",
      newPassword: "Nueva contraseña",
      newQuestion: "Nueva pregunta",
      newSkill: "Nueva competencia",
      newStructure: "Nuevo equipo",
      newValor: "Nuevo valor",
      newWorkflow: "Crea una nueva Revisión",
      newWorkflowCanBeExecuted: "Puedes comenzar una nueva Revisión",
      newWorkflowIsInProcess: "La creación de la nueva Revisión está en curso",
      newWorkflowSubtitle:
        "Para empezar, introduce el título de la nueva Review. Podrás modificarlo más adelante accediendo a la página Review",
      nextStage: "Activa la siguiente fase",
      noEnabledUsers: "No hay colaboradores habilitados en este equipo",
      noEvalutationForm: "La ficha de evaluación no está disponible",
      noEvalutationForms: "Fichas de evaluación no disponibles",
      noEvalutationFormsMessage:
        "Los criterios de búsqueda seleccionados no coinciden con ningún formulario de evaluación",
      noFeedbackSent: "Ningún feedback enviado",
      noLogin: "Sin acceso",
      none: "No hay perfil",
      noStructureCollaboratoreWarning: "Equipo de referencia no definido",
      notAssigned: "Sin asignar",
      notCompiled: "No rellenado",
      notCompiledCardsOnGoingMessage:
        "Fichas con número mínimo de feedback alcanzado",
      notCompiledCardsFinalReviewMessage:
        "Formularios de evaluación no completados",
      notCompiledRequest: "Feedbacks no recibidos",
      note: "Notas",
      notFoundPage: "Página no encontrada",
      notReadsFeedback: "Feedbacks no leídos",
      notice: "Notificaciones",
      "notification#autoEvaluation_adminUnlock#description":
        "Ahora podrás acceder a tu autoevaluación",
      "notification#autoEvaluation_adminUnlock#object": "Autoevaluación",
      "notification#autoEvaluation_collabAndBoss_active#description":
        "Se ha iniciado la Revisión Final. Desde este momento no será posible enviar feedbacks. Ahora podrás acceder a tu autoevaluación",
      "notification#autoEvaluation_collabAndBoss_active#object":
        "Revisión final y autoevaluación",
      "notification#autoEvaluation_onlyCollab_active#description":
        "Se ha iniciado la Revisión Final. Desde este momento no será posible enviar feedbacks. Ahora podrás acceder a tu autoevaluación",
      "notification#autoEvaluation_onlyCollab_active#object":
        "Revisión final y autoevaluación",
      "notification#autoEvaluation_onlyCollab_deactivate#description":
        "Se ha iniciado la Revisión Final. Desde este momento no será posible enviar feedbacks",
      "notification#autoEvaluation_onlyCollab_deactivate#object":
        "Revisión final",
      "notification#confirmTouchpoint#description":
        "Se ha confirmado el touch point relativo al feedback del siguiente asunto: {{object}}, ha tenido lugar",
      "notification#confirmTouchpoint#object": "El touch point ha tenido lugar",
      "notification#finalEvaluation_active#description":
        "Se ha iniciado la Revisión Final. Desde este momento no será posible enviar feedbacks. Ahora podrás acceder a tu evaluación de tus colaboradores",
      "notification#finalEvaluation_active#object":
        "Revisión final y evaluación",
      "notification#finalEvaluation_adminUnlock#description":
        "Uno o más colaboradores en tu equipo han sido admitidos a la Revisión Final. Ahora podrás acceder a su evaluación",
      "notification#finalEvaluation_adminUnlock#object": "Revisión final",
      "notification#finalEvaluation_deactivate#description":
        "Se ha iniciado la Revisión Final. Desde este momento no será posible enviar feedback",
      "notification#finalEvaluation_deactivate#object": "Revisión final",
      "notification#finalReview#description":
        "{{otherData}} usuarios no han conseguido el número mínimo de feedbacks para acceder a la evaluación",
      "notification#finalReview#object": "Recordatorio",
      "notification#goalsApproved#description":
        "Su solicitud de cambio de prioridades para el usuario {{otherData}} ha sido aprobada",
      "notification#goalsApproved#object":
        "Sustitución de prioridades aprobada",
      "notification#goalsApprovedCollab#description":
        "Tus prioridades han sido actualizadas. Consulta tu perfil para ver los cambios",
      "notification#goalsApprovedCollab#object": "Editar prioridades",
      "notification#goalsAssigned#description":
        "Al usuario {{nameUserSender}} se le han las priodidades previstas. Ve a la sección Usuarios para confirmarlas",
      "notification#goalsAssigned#object": "Asignación prioridades",
      "notification#goalsEdited#description":
        "Se ha solicitado una sustitución de prioridad para el usuario {{otherData}}. Vaya a la sección Usuarios para gestionarlo",
      "notification#goalsEdited#object": "Sustituir prioridad",
      "notification#goalsRejected#description":
        "Las prioridades asignadas al usuario {{nameUserRecipient}} no han sido aprobadas. Vuelve a la pagina del usuario para modificarlas y enviarlas nuevamente",
      "notification#goalsRejected#object": "Prioridades rechazadas",
      "notification#goalsRejectedEdit#description":
        "Su solicitud de cambio de prioridades para el usuario {{otherData}} no ha sido aprobada",
      "notification#goalsRejectedEdit#object":
        "Sustitución de prioridades no aprobada",
      "notification#newUsers#description":
        "¡Tienes nuevos colaboradores en tu equipo! Procede a la asignación de prioridades",
      "notification#newUsers#object": "Asignación de prioridades",
      "notification#notifyCollaboratorCardCompilation#description":
        "El colaborador{{collaborator}} completó su autoevaluación",
      "notification#notifyCollaboratorCardCompilation#object":
        "Autoevaluación realizada",
      "notification#reminder#description":
        "¡Tus colaboradores esperan tu feedback! Envía uno ahora",
      "notification#reminder#object": "Recordatorio",
      "notification#requestTouchpoint#description":
        "Se ha solicitado un touch point al feedback del siguiente asunto: {{object}}. Vuelve a tus feedbacks para ver los detalles",
      "notification#requestTouchpoint#object": "Solicitud de touch point",
      "notification#startSettings#3#description":
        "¡Hola {{nameUserRecipient}}, ahora puedes asignar las prioridades a tus colaboradores! Ve a la página Tu Equipo y procede a la asignación",
      "notification#startSettings#3#object": "Asignación prioridades",
      "notification#stopFinalReview_activeCollab#object": "Revisión final",
      "notification#stopFinalReview_activeCollab#description":
        "La revisión {{otherData}} acaba de terminar. En breve se lanzará una nueva revisión. Ahora puede ver su formulario de evaluación",
      "notification#stopFinalReview_noActiveCollab#object": "Revisión final",
      "notification#stopFinalReview_noActiveCollab#description":
        "La revisión {{otherData}} acaba de terminar. En breve se lanzará una nueva revisión",
      notifyEndCompilation: "Notificación de finalización",
      notifyEndCompilationError:
        "Se ha producido un problema al enviar la notificación de finalización",
      notifyEndCompilationSuccesfully:
        "Envío de la notificación de finalización con éxito",
      numSendFeedbacks: "Media feedback enviados por persona",
      object: "Objeto",
      office365: "Inicia sesión con Office 365",
      oldWorkflows: "Viejas Revisión",
      onGoing: "Ongoing",
      "onGoing#1": "Ongoing",
      onGoing2: "2º Ongoing",
      "onGoing2#1": "",
      onGoing2Phase: "Fase Ongoing 2",
      onGoingLockedEvaluation: "Feedbacks insuficientes",
      onGoingNotCompiledEvaluation: "Nº mínimo de feedbacks conseguido",
      onGoingPhase: "Fase de Ongoing",
      onGoingSaveFailed:
        "Ha ocurrido un problema al guardar la configuración de la fase de Ongoing. Inténtalo más tarde.",
      onGoingSaveSuccess:
        "Configuración fase de Ongoing guardados correctamente",
      onGoingSettings: "Ajustes fase de Ongoing",
      openQuestion: "Pregunta con respuesta abierta",
      operationGenericErrorMessage:
        "Algo salió mal, por favor vuelve a intentarlo más tarde",
      orCredentials: "o introduce tus credenciales",
      orderBy: "Ordena por",
      pageFeedback: "Feedback",
      pageHome: "En curso",
      pageHomeSubMenu: "En curso",
      pageItems: "Criterios de evaluación",
      pageProcess: "Proceso",
      pageStructures: "Equipo",
      pageTeam: "Tu equipo",
      pageUsers: "Usuarios",
      pageWorkflow: "Revisar",
      parentStructure: "Equipo superior",
      partiallyCompiledCardsFinalReviewMessage:
        "Formularios de evaluación parcialmente cumplimentados",
      partiallyCompiled: "Parcialmente completado",
      partiallyCompiledEvaluation: "Parcialmente completada",
      password: "Contraseña",
      passwordModified: "Contraseña modificada",
      passwordModifiedDescription:
        "Tu contraseña se ha modificado con éxito. Vuelve a la página de inicio de sesión y efectúa el acceso",
      pending: "Solicitado",
      pendingGoalMessage: "Aprobación pendiente",
      pendingTouchpoints: "Los touch points no han tenido lugar aún",
      personalDataTitle: "Anagráfica",
      phaseOf: "Fase de",
      placeholderDescriptionFeedback: "Introduce una descripción",
      placeholderQuestionText: "Título de la pregunta",
      preferences: "Preferencias",
      problems: "Problemas encontrados:",
      problemsIsNotReady:
        'Quedan pocos pasos para activar la siguiente fase "{{nameSubphase}}"',
      problemsIsReady: "Atención:",
      problemsIsNotReadySubtitle: "Pasos que faltan:",
      process: "Proceso",
      processOnGoingFeedbacksHeader:
        "N° minimo de feedbacks necesarios para la evaluación final",
      profession: "Cluster",
      profile: "Perfil",
      project: "Proyecto",
      projects: "Proyectos",
      quality: "Cualitativa",
      quantity: "Cuantitativa",
      questionsValutationSettings: "Preguntas para la evaluación resumida",
      rangeRatingSettings:
        "Rango de modificación de la puntuación obtenida por el colaborador",
      rating: "Clasificación",
      ratingScale: "Escala de evaluación",
      RatingScaleLabel: "Etiqueta por defecto de la escala de evaluación",
      readsFeedback: "Feedbacks leídos",
      received: "Recibidos",
      receivedFeedback: "Feedbacks recibidos",
      recipient: "Destinatario",
      recoveryPassword: "¿No recuerdas la contraseña?",
      rejectError: "Error: no se pudieron rechazar las prioridades",
      rejectGoals: "Rechaza las prioridades",
      rejectedGoals: "Prioridades no aprobadas",
      rejectSuccess: "Prioridades rechazadas correctamente",
      reminder: "Recordatorio",
      reminderNoAccessSettings: "Ningún acceso a la plataforma",
      reminderNoFeedbackSettings: "Ningún feedback enviado",
      reportingFinalReview: "Panel de control de la revisión final",
      requestFeedback: "Solicitar feedback",
      requestFeedbackNotificationText: "Solicitud de feedback recibida",
      requestForFeedback: "Solicitud de feedback",
      requestGoalFeedbackNotificationText: "Solicitud de feedback recibida",
      requestorNotes: "Notas del solicitante",
      requestTouchpoint: "Solicita touch point",
      requestTouchpointAlert:
        "¿Estás seguro de que quieres solicitar el touchpoint?",
      requestTouchpointGenericErrorMessage:
        "No ha sido posible solicitar el touchpoint",
      requestTouchpointSuccessMessage:
        "La solicitud de touchpoint se ha enviado correctamente",
      requestsTouchpoint: "Solicitudes de touchpoint",
      requiredError: "Campo requerido",
      requiredErrorRating:
        "Es necesario introducir una valoración del feedback",
      requiredFeedback: "Solicitudes de feedback",
      replaceAGoal:
        "No considerar para la evaluación final los comentarios enviados hasta ahora en la revisión actual para esta prioridad",
      resetPassword: "Cambiar contraseña",
      resetPasswordDescription: "Introducir nueva contraseña",
      reporting: "Tablero de mando de las actividades en curso",
      review: "Ultima revisión",
      role: "Puesto",
      roleTenant: "Posición en la empresa",
      save: "Guardar cambios",
      score: "Puntuación",
      search: "Busque en",
      selectedAll: "Seleccionar todo",
      selectATeam: "Seleccione un equipo",
      selectBelow: "Seleccionar todos",
      selectCdc: "Seleccione las instalaciones",
      selectCurrentWorkflow: "Seleccionar revisión actual",
      selectedTeam: "Equipos seleccionados ({{num}})",
      selectLang: "Seleccionar idioma",
      selectMultipleAnswerQuestion: "Escoge la pregunta de opción múltiple",
      selectTeamMember: "Seleccionar a los miembros del equipo",
      selectTypeOfQuestion: "Selecciona el tipo de pregunta",
      selectReview: "Selecciona Revisión",
      selectReviewTitle: "Selecciona Revisión",
      selectUserToNotify: "Selecciona los usuarios a notificar",
      selectSkill: "Seleccionar competencia",
      selectValor: "Selecciona valor",
      send: "Enviar",
      sendAgain: "Envia de nuevo",
      sender: "Remitente",
      senderFeedbackNotes: "Notas del remitente",
      sendEmail: "enviar email",
      sendEmailsError:
        "No se pudo enviar el email, inténtalo de nuevo más tarde",
      sendEvalutationForms: "Enviar todas las evaluaciones",
      sendEvalutationForm: "Enviar evaluación",
      sendFeedback: "Enviar feedback",
      sendFeedbackGenericErrorMessage: "Feedback no enviado",
      sendFeedbacks: "Feedbacks enviados",
      sendFeedbackSuccessMessage: "Feedback enviado correctamente",
      sendGoals: "Confirmar las prioridades",
      sendNotification: "Enviar notificación",
      sendRequestFeedbackGenericErrorMessage:
        "Solicitud de feedback no enviada",
      sendRequestFeedbackSuccessMessage:
        "Solicitud de feedback enviada correctamente",
      sends: "Enviados",
      sendSupportRequest: "Enviar una solicitud de soporte",
      sendWelcomeEmail: "enviar email de bienvenida",
      sentSupportEmail:
        "Tu solicitud de soporte se ha enviado correctamente. Te hemos enviado una copia a la dirección de correo {{email}}",
      settings: "Ajustes",
      "settings#1": "Ajustes de equipos y usuarios",
      "settings#2": "Generación de la ficha de evaluación",
      "settings#3": "Asignación de prioridades",
      settingsMissing: "Falta la definición de los ajustes de proceso.",
      settingsPage: "Ajustes",
      "UserPoolGroupAdmin#settingsMissingPage": "Ir a la sección Proceso.",
      showOthers: "Mostrar más",
      showWorkflows: "Archivo de las revisiones",
      singleRatingLabel: "Calificación del título",
      singleStructure: "Equipo de referencia",
      sixToTen: "de 6 a 10",
      sizeOver8mb: "El tamaño de los archivos adjuntos es superior a 8 MB",
      skill: "Competencias",
      skills: "Competencias",
      skillFeedback: "Feedback sobre las competencias",
      skillsSubMenu: "Competencias",
      startDate: "Fecha Inicio",
      status: "Estado",
      structure: "Equipo",
      structures: "Equipos",
      structuresMissings: "Equipos no cargados.",
      "UserPoolGroupAdmin#structuresMissingsPage": "Ir a la sección Equipos.",
      subCategory: "Subcategoría",
      summary: "Resumen",
      summaryEvalutation: "Resumen de la evaluación",
      support: "Soporte",
      surname: "Apellidos",
      system: "Sistema",
      tableWorkflowAutovalutation: "Autoevaluación",
      tableWorkflowKeepGoing: 'Nº "Keep going"',
      tableWorkflowMacroAreaRating: "Media por macroárea",
      tableWorkflowRating: "Media feedback",
      tableWorkflowThinkAbout: 'Nº "Think about"',
      tableWorkflowTitle: "Título",
      team: "Tu equipo",
      teamDetail: "Datos usuarios",
      teamMember: "Miembros de Equipo",
      teamStructureCaption: "(Responsable: tú)",
      totals: "Totales",
      theNextPhaseCanBeStarted: "Puedes iniciar la siguiente fase",
      theNextPhaseCannotBeStarted: "No se puede iniciar aún la siguiente fase",
      thinkabout: "Think about",
      title: "Título",
      titleEvalutationForm: "Ficha de evaluación",
      titleModalFilter:
        "Añadir los equipos al panel de control seleccionándolos a partir del organigrama",
      titleModalItem: "Detalles de los feedbacks",
      typeautoValutationTitle: "Tipo de autoevaluación de los colaboradores",
      typeGraph: "Categoría feedback",
      typeValutationTitle: "Tipo de evaluación final de parte del responsable",
      to: "Para",
      toEvalutionCard: "Ir a la ficha de evaluación",
      toManyRequestError: "Demasiados intentos, por favor prueba más tarde",
      totalCards: "Total de fichas de evaluación",
      touchpoint: "Punto de contacto",
      touchpointConfirmedNotificationText: "Touch point confirmado",
      touchpointHappened: "Ocurrido",
      touchpointIdle: "No requerido",
      touchpointNotificationText: "Touchpoint solicitado",
      touchpointRequested: "Recuperado",
      touchpointNotRequested: "No es necesario",
      touchpointSenderNotes: "Notas del solicitante",
      trendOfFeedback: "Timeline de los feedbacks recibidos",
      trendOfFeedbackAltText: "Timeline de los feedback enviados",
      unassignedGoals: "Prioridades no asignadas",
      unassignedSkills: "Competencias no asignadas",
      unassignedValors: "Valores no asignados",
      unconfirmedGoals: "Prioridades pendientes de confirmación",
      undefined: "No definido",
      unlockedError:
        "Se ha producido un error al desbloquear las fichas. Prueba más tarde",
      unlockedErrorSingleUser:
        "Se ha producido un error al desbloquear la ficha. Prueba más tarde",
      unlockedSuccessfully:
        "El desbloqueo de las fichas se ha iniciado con éxito, en breves momentos estarán desbloqueadas",
      unlockedSuccessfullySingleUser:
        "El desbloqueo de la ficha se ha iniciado con éxito, en breves momentos estará desbloqueada",
      unlockFinalReview: "desbloquear las fichas",
      unlockFinalReviewSingleUser: "desbloquear la ficha",
      updateMacroArea: "Actualización de la macroárea",
      updateMacroAreaSuccess: "Macroárea actualizada con éxito",
      updateMacroSkill: "Aggiornamento Macro Area",
      updateSettingsSuccess: "Ajustes guardados correctamente",
      updateSkill: "Actualización de la Competencia",
      updateStructureGenericErrorMessage:
        "Algo ha salido mal en la modificación del equipo. Inténtalo más tarde",
      updateStructureSuccessMessage: "Se ha modificado el equipo correctamente",
      updateUserStatusErrorMessage:
        "No ha sido posible actualizar el estado del usuario",
      updateUserStatusSuccessMessage:
        "Estado del usuario actualizado correctamente",
      updateValor: "Actualización de los valores",
      updateSkillSuccess: "Se ha actualizado correctamente la competencia",
      updateValorSuccess: "Se ha actualizado correctamente el valor",
      updateWorkflow: "Editar el título de la revisión",
      user: "Usuario",
      userAlreadyExists: "El usuario ya existe",
      users: "Usuarios",
      usersGoalConfirmation:
        "A {{num}} usuarios les han sido asignados las prioridades previstas.",
      "UserPoolGroupAdmin#usersGoalConfirmationPage":
        "Ir a la sección Usuarios para confirmarlas.",
      usersGoalMissing:
        "A {{num}} usuarios non le han sido asignadas las prioridades previstas.",
      "UserPoolGroupAdmin#usersGoalMissingPage": "Ir a la sección Usuarios.",
      "UserPoolGroupBoss#usersGoalMissingPage": "Ir a la sección Tu equipo.",
      usersMissing: "Usuarios no cargados.",
      "UserPoolGroupAdmin#usersMissingPage": "Ir a la sección Usuarios.",
      usersNotEnabled: "No hay usuarios habilitados.",
      "UserPoolGroupAdmin#usersNotEnabledPage": "Ir a la sección Usuarios.",
      userOrEmail: "Usuario o email",
      userDataUpdateError: "Error mientras se actualizaban los datos",
      userDataUpdateSuccessfully:
        "La información se ha actualizado correctamente",
      userGoalUpdatedSuccessfully:
        "Su solicitud ha sido enviada al administrador",
      UserPoolGroupBoss: "Responsable",
      UserPoolGroupCoBoss: "Co-Responsable",
      UserPoolGroupCollaborator: "Colaborador",
      userUpdatedSuccessfully: "El usuario ha sido actualizado correctamente",
      usersSkillMissing:
        "A {{num}} usuarios no se les asignó ninguna competencia.",
      usersValorMissing:
        "A {{num}} usuarios no se les ha asignado ningún valor.",
      "UserPoolGroupAdmin#usersSkillMissingPage": "Ir a la sección Usuarios.",
      "UserPoolGroupAdmin#usersValorMissingPage": "Ir a la sección Usuarios.",
      valor: "Valor",
      valorRating: "Valor",
      valors: "Valores",
      valorsSubMenu: "Valores",
      valorFeedback: "Feedback de los valores",
      valutations: "Evaluaciones",
      valutationSaveFailed:
        "Ha ocurrido un problema al guardar la configuración de evaluación. Intentalo más tarde.",
      valutationSaveSuccess:
        "Los ajustes de evaluación han sido guardados correctamente",
      valutationSettings: "Ajustes del proceso de evaluación",
      valutationWorkInProgress:
        "La ficha está en elaboración. En pocos minutos podrás acceder a la evaluación del colaborador",
      viewAnswers: "Ver respuestas",
      welcome: "¡Bienvenido!",
      welcomeMessage: "¡Bienvenido! Echa un vistazo a las novedades:",
      workflow: "Workflow",
      workflowTitle: "Título de la revisión",
      workflowWithoutName: "Sin nombre",
      writeFeedback: "Rellenar el feedback",
      yourFeedbacks: "Tus feedbacks",
      yourProfile: "Tu perfil",
      zeroToFive: "de 0 a 5",
      welcomeEmail: "Email de bienvenida",
    },
  },
};
export default { resources };
