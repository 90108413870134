import React from "react";
import ButtonsFeedback from "./ButtonsFeedback";
import ModalFeedback from "./ModalFeedback";
import AlertSnackbar from "components/AlertSnackbar";
import PropTypes from "prop-types";

const ModalWrapper = ({
  activeStructure,
  activeGroup,
  isBoss,
  openModal,
  type,
  openAlert,
  textAlert,
  handleCloseAlert,
  handleClickAlert,
  setDataModal,
  noFilter,
  severityAlert,
  updateDataFeedbacks,
  handleOpen,
  idSelectedUser,
}) => {
  return (
    <>
      {!openModal && (
        <ButtonsFeedback
          activeStructure={activeStructure}
          activeGroup={activeGroup}
          isBoss={isBoss}
          noFilter={noFilter || false}
          handleOpen={handleOpen}
        />
      )}
      <ModalFeedback
        open={openModal}
        setDataModal={setDataModal}
        type={type}
        handleClickAlert={handleClickAlert}
        activeStructure={activeStructure}
        activeGroup={activeGroup}
        isBoss={isBoss}
        updateDataFeedbacks={updateDataFeedbacks}
        idSelectedUser={idSelectedUser}
      />
      <AlertSnackbar
        open={openAlert}
        text={textAlert}
        severity={severityAlert}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

ModalWrapper.propTypes = {
  activeStructure: PropTypes.string.isRequired,
  activeGroup: PropTypes.string.isRequired,
  isBoss: PropTypes.bool,
  openModal: PropTypes.bool,
  type: PropTypes.string,
  openAlert: PropTypes.bool.isRequired,
  textAlert: PropTypes.string,
  handleCloseAlert: PropTypes.func.isRequired,
  handleClickAlert: PropTypes.func.isRequired,
  setDataModal: PropTypes.func.isRequired,
  severityAlert: PropTypes.string,
  noFilter: PropTypes.bool,
  updateDataFeedbacks: PropTypes.func,
  handleOpen: PropTypes.func.isRequired,
  idSelectedUser: PropTypes.string,
};

export default ModalWrapper;
