import gql from "graphql-tag";

const StartExportEvaluationCards = gql`
  query startExportEvaluationCards(
    $input: ExportEvaluationCardsInput!
    $isXlsx: Boolean
  ) {
    startExportEvaluationCards(input: $input, isXlsx: $isXlsx)
  }
`;

export default StartExportEvaluationCards;
