import dragIcon from "assets/icon/drag_indicator.svg";
import { shadows, palette } from "../globals";
import { variables } from "../globals/variables";

export const structureTree = {
  ".rst__row.hierarchy-filter .rst__rowContents": {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "& .rst__rowLabel": {
      paddingLeft: 0,
      "& .rst__rowTitle": {
        margin: 0,
        height: "auto",
      },
    },
  },
  ".rst__row.hierarchy-filter .rst__node .rst__lineHalfHorizontalRight, .rst__lineFullVertical, .rst__nodeContent, .rst__lineHalfVerticalTop":
    {
      "&:after": {
        display: "none",
      },
      "& .rst__lineChildren:after": {
        display: "none",
      },
    },
  ".rst__tree": {
    "& .rst__lineBlock": {
      height: "80px",
    },
    "& .rst__rowSearchMatch": {
      outline: `solid 3px ${palette.secondary.main}`,
    },
    "& .rst__collapseButton, .rst__expandButton": {
      top: "65%",
      background: "#fff",
      "&:before": {
        color: palette.primary.light,
        fontSize: "16px",
      },
    },
    "& .rst__collapseButton": {
      boxShadow: `0 0 0 1px ${palette.grey[400]}`,
      "&:before": {
        content: '"-"',
        lineHeight: "1px",
      },
    },
    "& .rst__expandButton": {
      boxShadow: `0 0 0 1px ${palette.primary.light}`,
      "&:before": {
        content: '"+"',
      },
    },
  },
  ".rst__row": {
    "& .rst__rowContents": {
      borderRadius: "4px",
      paddingLeft: 0,
      boxShadow: shadows[5],
      border: `1px solid ${palette.grey[300]}`,
      "&:before": {
        content: '""',
        height: "100%",
        width: "32px",
        background: `${palette.primary.main} url(${dragIcon}) no-repeat center`,
        borderRadius: "4px 0 0 4px",
      },
    },
    "& .rst__rowLabel": {
      paddingLeft: "8px",
    },
    "& .rst__rowTitleWithSubtitle": {
      fontSize: "14px",
      fontWeight: "500",
      marginBottom: "4px",
    },
    "& .rst__rowSubtitle": {
      color: palette.grey["400"],
      fontSize: "10px",
      fontWeight: "100",
    },
  },
  ".rst__node": {
    "& .rst__lineHalfHorizontalRight, .rst__lineFullVertical, .rst__lineChildren":
      {
        "&:before": {
          backgroundColor: palette.primary.light,
        },
        "&:after": {
          backgroundColor: palette.primary.light,
        },
      },
    "& .rst__rowWrapper": {
      padding: "16px 0",
      height: "80px",
    },
  },
  ".rst__rowLabel": {
    "& .rst__rowTitle": {
      fontFamily: variables.fontFamilyTitle,
    },
    "& .rst__rowSubtitle": {
      fontFamily: variables.fontFamilyBody,
    },
  },
};
