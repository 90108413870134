import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ActionModalFilter = ({ handleExpand, handleSelectAll, treeData }) => {
  const { t } = useTranslation();
  return (
    <Box height={56} display="flex">
      <Button color="primary" onClick={() => handleExpand(true)}>
        {t("expandAll")}
      </Button>
      <Button
        color="primary"
        onClick={() => handleSelectAll(treeData.data[0], undefined, true)}
      >
        {t("selectedAll")}
      </Button>
    </Box>
  );
};

ActionModalFilter.propTypes = {
  handleExpand: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  treeData: PropTypes.object.isRequired,
};

export default ActionModalFilter;
