import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { Typography, Box, Button } from "@mui/material";
import { convertTimestamp } from "../../../helpers/DateHelper";
import { useTranslation } from "react-i18next";
import { getFeedbackUrl, getUrl } from "./functions/helper";
import SimpleUserAvatar from "components/base_components/SimpleUserAvatar";
import { AuthContext } from "components/auth/Authenticator";

const useStyles = makeStyles()((theme) => ({
  notificationItemRead: {
    backgroundColor: theme.palette.grey[200],
  },
  notificationItem: {
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  notificationLink: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  notificationType: {
    fontFamily: ["Comfortaa", "cursive"].join(","),
  },
  createdAtText: {
    color: theme.palette.grey[400],
  },
}));

/**
 * Ritorna il primo sottotitolo in base al tipo di notifica
 * @param {string} typeNotification tipp di notifica
 * @param {object} notification oggetto notifica
 * @param {function} t funzione per le traduzioni
 * @returns {string} primo sottotitolo
 */
const getSubtitle1 = (typeNotification, notification, t) => {
  return typeNotification === "feedback"
    ? t(`${notification.type}NotificationText`)
    : t(notification.object);
};

/**
 * Ritorna il primo sottotitolo in base al tipo di notifica
 * @param {string} typeNotification tipp di notifica
 * @param {object} notification oggetto notifica
 * @param {function} t funzione per le traduzioni
 * @returns {string} secondo sottotitolo
 */
const getSubtitle2 = (typeNotification, notification, t) => {
  const { category, description, feedbackObject, nameUserSender, subCategory } =
    notification;
  switch (typeNotification) {
    case "feedback": {
      const categoryText =
        category && t(category) === t("generic")
          ? t(category)
          : `${t(category)}:`;
      return categoryText ? subCategory : "";
    }
    case "touchpoint":
      return t(description, { object: feedbackObject });
    case "notifyEndCompilation":
      return t(description, {
        collaborator: nameUserSender,
      });
    default:
      return null;
  }
};

/**
 * Ritorna la funzione per recuperare l'url in base al tipo di notifica
 * @param {string} typeNotification tipo di notifica
 * @returns {function} function per recuperare l'url
 */
const getUrlFunction = (typeNotification) => {
  return ["feedback", "touchpoint"].includes(typeNotification)
    ? getFeedbackUrl
    : getUrl;
};

const GeneralNotification = ({
  notification,
  handleClickNotice,
  imageBaseUrl,
  typeNotification,
}) => {
  const { classes } = useStyles();
  const { language } = useContext(AuthContext);
  const { t } = useTranslation();
  const { createdAt, read, nameUserSender, idUserSender, id } = notification;
  const [readNotice, setReadNotice] = useState(read);
  const [isDisabled, setDisabled] = useState(false);

  const handleClick = async (
    readNotice,
    getUrl,
    notification,
    setReadNotice,
  ) => {
    setDisabled(true);
    await handleClickNotice(
      readNotice,
      getUrl,
      notification,
      setReadNotice,
      id,
    );
  };
  return (
    <Box
      display="flex"
      padding={4}
      position="relative"
      className={
        readNotice ? classes.notificationItemRead : classes.notificationItem
      }
    >
      <Box mr={2}>
        <SimpleUserAvatar
          urlImage={`${imageBaseUrl}/${idUserSender}`}
          alt={nameUserSender}
          width={"40px"}
          height={"40px"}
        />
      </Box>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box fontWeight={600} clone>
            <Typography variant="subtitle1">{nameUserSender}</Typography>
          </Box>
          <Typography variant="caption" className={classes.createdAtText}>
            {convertTimestamp(language, createdAt)}
          </Typography>
        </Box>
        <Box color="primary.main" clone>
          <Typography
            className={classes.notificationType}
            variant="subtitle1"
            component="p"
          >
            {getSubtitle1(typeNotification, notification, t)}
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          {getSubtitle2(typeNotification, notification, t)}
        </Typography>
      </Box>
      <Button
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={isDisabled}
        onClick={() =>
          handleClick(
            readNotice,
            getUrlFunction(typeNotification),
            notification,
            setReadNotice,
          )
        }
        className={classes.notificationLink}
      ></Button>
    </Box>
  );
};

GeneralNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  handleClickNotice: PropTypes.func.isRequired,
  imageBaseUrl: PropTypes.string,
  typeNotification: PropTypes.string,
};

export default GeneralNotification;
