import gql from "graphql-tag";

const CreateRequestFeedbackMutation = gql`
  mutation createRequestFeedback(
    $activeStructure: String
    $activeGroup: String!
    $input: createRequestFeedbackInput!
  ) {
    createRequestFeedback(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      input: $input
    ) {
      key
      sort
      createdAt
      id
    }
  }
`;

export default CreateRequestFeedbackMutation;
