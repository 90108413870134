import { palette } from "../globals/palette";

export const input = {
  MuiInputLabel: {
    root: {
      fontSize: "0.75rem",
    },
  },
  MuiInputBase: {
    root: {
      "&$disabled": {
        color: palette.grey["400"],
      },
    },
    disabled: {},
  },
  MuiFilledInput: {
    root: {
      backgroundColor: palette.grey["200"],
      "&$disabled": {
        backgroundColor: palette.grey["200"],
      },
    },
    disabled: {},
  },
};
