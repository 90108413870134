const convertTimestamp = (
  lang,
  timestamp,
  isDMY,
  isChart,
  isOnlyMonth = false,
  lessOneDay = false,
) => {
  // giorno in millisecondi (60 secondi * 60 minuti * 24 * 1000)
  const dayInMilliseconds = 60 * 60 * 24 * 1000;
  const reCalc = lessOneDay ? dayInMilliseconds : 0;
  const date = new Date(parseInt(timestamp - reCalc, 10));
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  let month = new Intl.DateTimeFormat(lang, { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat(lang, { day: "numeric" }).format(date);
  const hour = new Intl.DateTimeFormat(lang, options).format(date);
  const year = new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
  if (isChart) {
    month = new Intl.DateTimeFormat(lang, { month: "numeric" }).format(date);
    return `${!isOnlyMonth ? `${day}/` : ""}${month}${
      isOnlyMonth ? `/${year.toString().slice(2)}` : ""
    }`;
  }
  if (isDMY) {
    return `${day} ${month.replace(/^\w/, (c) => c.toUpperCase())} ${year}`;
  }
  return `${day} ${month.replace(/^\w/, (c) => c.toUpperCase())} ${hour}`;
};

export { convertTimestamp };
