import React, { useContext } from "react";
import { Paper, Grid, Box, Typography, Fab } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useChartCountersValutation,
  useChartCountersAutovalutation,
  useChartScoresOverRating,
} from "../../helper/dataDashboardFinalReviewHelper";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { WorkflowContext } from "context/WorkflowContext";
import ElementAverageRating from "../ElementAverageRating";
import AnswersAutovalutation from "../AnswersAutovalutation";
import { useStyles, getDataHook } from "../../helper/dashboardHelper";
import DetailsSingleUser from "../DetailsSingleUser";

const DataDashboardFinalReview = ({
  openFilters,
  activeGroup,
  counters,
  finalReviewSettings,
  singleStructureId,
  singleUserId,
  scoresOverRating,
  averageRatingsElements: {
    macroareaRatings,
    priorityRating,
    macroskillRatings,
  },
  questions,
  bossQuestions,
  scoresSingleUser,
  numEvalutationForms,
}) => {
  const { t } = useTranslation();
  const { rating } = useContext(WorkflowContext);
  const { isMediumDesktop, isMobile, isSmartphone } = getDataHook();

  const navigation = useNavigate();
  const { classes } = useStyles();

  const { countersValutationRef } = useChartCountersValutation({
    counters: counters.valutationCounters,
    isMediumDesktop,
    isSmartphone,
  });
  const { countersAutovalutationRef } = useChartCountersAutovalutation({
    counters: counters.autovalutationCounters,
    isMediumDesktop,
    isSmartphone,
  });
  const { scoresOverRatingRef } = useChartScoresOverRating({
    scoresOverRatingData: scoresOverRating,
    isMediumDesktop,
    rating,
    activeAutovalutation: finalReviewSettings.typeAutovalutation,
    activeAutovalutationQuantity:
      finalReviewSettings.typeAutovalutation === "quantity",
    isMobile,
    isSmartphone,
  });

  const handleNavigate = (page) => {
    const isBoss = activeGroup === "UserPoolGroupBoss";
    let queryString = "";
    if (!isBoss && (singleUserId || singleStructureId)) {
      queryString = singleUserId
        ? `?idStructure=${singleStructureId}&idUser=${singleUserId}`
        : `?idStructure=${singleStructureId}`;
    }
    if (isBoss && singleUserId) {
      queryString = `?idUser=${singleUserId}`;
    }
    navigation(`/${page}${queryString}`);
  };

  const getWidthBox = (incr = 0, isSecondary) => {
    if (!isMediumDesktop) {
      if (isSmartphone) {
        return "100%";
      }
      if (isMobile) {
        return "100%";
      }
      if (isSecondary) {
        return "74%";
      }
      return "100%";
    }
    if (openFilters) {
      return `${86 + incr * 2}%`;
    }
    if (isSecondary) {
      return "58%";
    }
    return `${70 + incr}%`;
  };

  return (
    <>
      {singleUserId && (
        <DetailsSingleUser
          handleNavigate={handleNavigate}
          numEvalutationForms={numEvalutationForms}
          counters={counters}
          rating={rating}
          scoresSingleUser={scoresSingleUser}
          typeAutovalutation={finalReviewSettings.typeAutovalutation}
        />
      )}
      {!singleUserId && (
        <Box
          display={
            finalReviewSettings.typeAutovalutation
              ? "block"
              : { sm: "block", md: "flex" }
          }
          justifyContent="space-between"
        >
          <Box
            mt={4}
            width={
              finalReviewSettings.typeAutovalutation
                ? "100%"
                : { sm: "100%", md: "48%" }
            }
            clone
          >
            <Paper elevation={3}>
              <Box display="flex" px={4} py={6} justifyContent="space-between">
                <Box display="flex">
                  <Box color="primary.main" mr={2}>
                    <Typography variant="h5" style={{ fontSize: "1.25rem" }}>
                      {numEvalutationForms}
                    </Typography>
                  </Box>
                  <Typography variant="h6">{t("evalutationForms")}</Typography>
                </Box>
                {numEvalutationForms > 0 && (
                  <Box>
                    <Fab
                      size="small"
                      color="primary"
                      className={classes.buttonLink}
                      onClick={() => handleNavigate("workflow")}
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </Fab>
                  </Box>
                )}
              </Box>
              {numEvalutationForms > 0 && (
                <Box p={{ xs: "16px 0", sm: 4 }}>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      md={finalReviewSettings.typeAutovalutation ? 6 : 12}
                      xs={12}
                    >
                      <Box display="flex">
                        <Box
                          width={getWidthBox()}
                          margin={{
                            xs: "0 0 20px 0",
                            sm: "0 0 20px 0",
                            md: "0 auto",
                          }}
                          padding={{ xs: 4, sm: 0 }}
                        >
                          <Box mb={2}>
                            <Typography variant="subtitle2" component="p">
                              {t("managerValutations")}
                            </Typography>
                          </Box>
                          <canvas ref={countersValutationRef}></canvas>
                        </Box>
                      </Box>
                    </Grid>
                    {finalReviewSettings.typeAutovalutation && (
                      <Grid item md={6} xs={12}>
                        <Box display="flex">
                          <Box
                            width={getWidthBox()}
                            margin={{
                              xs: "0 0 20px 0",
                              sm: "0 0 20px 0",
                              md: "0 auto",
                            }}
                            padding={{ xs: 4, sm: 0 }}
                          >
                            <Box mb={2}>
                              <Typography variant="subtitle2" component="p">
                                {t("collaboratorAutovalutations")}
                              </Typography>
                            </Box>
                            <canvas ref={countersAutovalutationRef}></canvas>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Paper>
          </Box>
          <Box
            mt={4}
            width={
              finalReviewSettings.typeAutovalutation
                ? "100%"
                : { sm: "100%", md: "48%" }
            }
          >
            <Paper elevation={3}>
              <Box
                display={{ xs: "block", md: "flex" }}
                justifyContent="space-between"
                alignItems="center"
                p={4}
              >
                <Typography variant="h6">{t("finalScores")}</Typography>
              </Box>
              <Box p={4}>
                <canvas height="100%" ref={scoresOverRatingRef}></canvas>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
      {finalReviewSettings.typeValutation === "quantity" && (
        <ElementAverageRating
          rating={rating}
          priorityRating={priorityRating}
          macroareaRatings={macroareaRatings}
          macroskillRatings={macroskillRatings}
        />
      )}
      {bossQuestions[0] && (
        <AnswersAutovalutation
          questions={bossQuestions}
          hidePercentage={!!singleUserId}
          isBossQuestions={true}
        />
      )}
      {questions[0] && finalReviewSettings.typeAutovalutation && (
        <AnswersAutovalutation
          questions={questions}
          hidePercentage={!!singleUserId}
          isBossQuestions={false}
        />
      )}
    </>
  );
};

DataDashboardFinalReview.propTypes = {
  openFilters: PropTypes.bool,
  activeGroup: PropTypes.string.isRequired,
  counters: PropTypes.shape({
    valutationCounters: PropTypes.shape({
      locked: PropTypes.number.isRequired,
      notCompiled: PropTypes.number.isRequired,
      compiled: PropTypes.number.isRequired,
      partiallyCompiled: PropTypes.number.isRequired,
    }),
    autovalutationCounters: PropTypes.shape({
      locked: PropTypes.number.isRequired,
      notCompiled: PropTypes.number.isRequired,
      compiled: PropTypes.number.isRequired,
      partiallyCompiled: PropTypes.number.isRequired,
    }),
  }),
  finalReviewSettings: PropTypes.shape({
    typeAutovalutation: PropTypes.string,
    typeValutation: PropTypes.string.isRequired,
  }),
  singleStructureId: PropTypes.string,
  singleUserId: PropTypes.string,
  scoresOverRating: PropTypes.shape({
    scoresOverRating: PropTypes.arrayOf(
      PropTypes.shape({
        numScores: PropTypes.number.isRequired,
        rating: PropTypes.number.isRequired,
      }),
    ),
    scoresOverRatingAutovalutation: PropTypes.arrayOf(
      PropTypes.shape({
        numScores: PropTypes.number.isRequired,
        rating: PropTypes.number.isRequired,
      }),
    ),
  }),
  averageRatingsElements: PropTypes.shape({
    priorityRating: PropTypes.number,
    macroareaRatings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        macroarea: PropTypes.string,
        ratingAverage: PropTypes.number,
      }),
    ),
    macroskillRatings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        macroskill: PropTypes.string,
        ratingAverage: PropTypes.number,
      }),
    ),
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string.isRequired,
          percentage: PropTypes.number,
        }),
      ),
    }),
  ),
  bossQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string.isRequired,
          percentage: PropTypes.number,
        }),
      ),
    }),
  ),
  scoresSingleUser: PropTypes.shape({
    autovalutationScore: PropTypes.number,
    valutationScore: PropTypes.number,
  }),
  numEvalutationForms: PropTypes.number.isRequired,
};

export default DataDashboardFinalReview;
