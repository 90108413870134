import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const HeaderModal = ({ classes, handleOpenModal, title, type }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="space-between" bgcolor="grey.200">
      <Typography variant="h5" className={classes.titleModal}>
        {t(title)}
      </Typography>
      <Box m={4}>
        <IconButton onClick={() => handleOpenModal(false, type)} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

HeaderModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default HeaderModal;
