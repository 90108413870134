import gql from "graphql-tag";

const CreateWorkflow = gql`
  mutation CreateWorkflow($nameWorkFlow: String) {
    createWorkflow(nameWorkFlow: $nameWorkFlow) {
      currentWorkflow {
        id
        name
        startDate
      }
      currentPhase
      subPhase
    }
  }
`;

export default CreateWorkflow;
