const adminWarning = (
  numValors,
  numSkills,
  goalsStatus,
  subPhase,
  isNotCollaborator,
  goalActivated,
  valorActivated,
  skillActivated,
) => {
  const warningCodes = [];
  if (
    valorActivated &&
    (!numValors || numValors === 0) &&
    subPhase !== "settings#1"
  ) {
    warningCodes.push("unassignedValors");
  }
  if (
    skillActivated &&
    (!numSkills || numSkills === 0) &&
    subPhase !== "settings#1"
  ) {
    warningCodes.push("unassignedSkills");
  }
  if (isNotCollaborator) {
    return warningCodes;
  }
  if (
    goalActivated &&
    (!goalsStatus || goalsStatus !== "approved") &&
    !["settings#1", "settings#2"].includes(subPhase)
  ) {
    switch (goalsStatus) {
      case "pending":
        warningCodes.push("unconfirmedGoals");
        break;
      default:
        warningCodes.push("unassignedGoals");
    }
  }
  return warningCodes;
};

const bossWarning = (goalsStatus, subPhase) => {
  const warningCodes = [];
  if (
    (!goalsStatus || goalsStatus === "draft") &&
    !["settings#1", "settings#2"].includes(subPhase)
  ) {
    warningCodes.push("unassignedGoals");
    return warningCodes;
  }

  if (
    goalsStatus === "rejected" &&
    !["settings#1", "settings#2"].includes(subPhase)
  ) {
    warningCodes.push("rejectedGoals");
  }
  return warningCodes;
};

export const userHasWarning = (
  numValors,
  numSkills,
  goalsStatus,
  translator,
  isBoss = false,
  subPhase,
  isNotCollaborator = false,
  goalActivated = true,
  valorActivated = true,
  skillActivated = true,
) => {
  const warningCodes = isBoss
    ? goalActivated
      ? bossWarning(goalsStatus, subPhase)
      : []
    : adminWarning(
        numValors,
        numSkills,
        goalsStatus,
        subPhase,
        isNotCollaborator,
        goalActivated,
        valorActivated,
        skillActivated,
      );
  const messages = warningCodes.map((warningCode) => {
    return translator(warningCode) || "";
  });
  return messages;
};

export const userHasMessage = (
  goalsStatus,
  translator,
  isBoss = false,
  subPhase,
) => {
  const messageCodes = [];
  if (!isBoss) {
    return [];
  }
  if (
    goalsStatus === "pending" &&
    !["settings#1", "settings#2"].includes(subPhase)
  ) {
    messageCodes.push("unconfirmedGoals");
  }
  const messages = messageCodes.map((messageCode) => {
    return translator(messageCode) || "";
  });
  return messages;
};
