import { createTheme } from "@mui/material/styles";
import { palette } from "../globals/palette";
import { shadows } from "../globals/shadows";
const defaultTheme = createTheme();

export const fab = {
  MuiFab: {
    root: {
      boxShadow: shadows[3],
    },
    secondary: {
      color: palette.grey["100"],
      fontWeight: 600,
      zIndex: defaultTheme.zIndex.tooltip,
    },
    primary: {
      color: palette.grey["100"],
    },
  },
};
