import gql from "graphql-tag";

const GetAllStructuresManager = gql`
  query getAllStructuresManager(
    $activeStructure: String
    $activeGroup: String!
    $hierarchy: Boolean
  ) {
    getAllStructuresManager(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      hierarchy: $hierarchy
    ) {
      structures
    }
  }
`;

export default GetAllStructuresManager;
