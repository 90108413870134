import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import { convertTimestamp } from "helpers/DateHelper";
import { AuthContext } from "components/auth/Authenticator";
import PropTypes from "prop-types";

const CustomButton = ({ workflows, handleWorkflow, selectedId, id }) => {
  const { t } = useTranslation();

  const { language } = useContext(AuthContext);
  const isSelected = id === selectedId;
  const getDateWorkflow = (item, type) => {
    if (!language || !workflows[`${item}`]?.[`${type}`]) {
      return "";
    }
    let dateToReturn = t("from");
    if (type === "endDate") {
      dateToReturn = ` ${t("to")}`;
    }
    return `${dateToReturn} ${convertTimestamp(
      language,
      workflows[`${item}`]?.[`${type}`],
      true,
    )}`;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button
        style={{ justifyContent: "flex-start" }}
        color={isSelected ? "primary" : "inherit"}
        onClick={() => handleWorkflow(id)}
      >
        <Box display="flex" alignItems="center">
          <Box height="30px" width="40px" mr={2}>
            {isSelected && <CheckIcon />}
          </Box>
          <Box>
            <Typography variant="body1">{workflows[`${id}`]?.name}</Typography>
          </Box>
        </Box>
      </Button>
      <Box ml={2}>
        <Typography variant="caption">
          {`(${getDateWorkflow(id, "startDate")}${getDateWorkflow(
            id,
            "endDate",
          )})`}
        </Typography>
      </Box>
    </Box>
  );
};

CustomButton.propTypes = {
  workflows: PropTypes.object.isRequired,
  handleWorkflow: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default CustomButton;
