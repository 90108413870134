import gql from "graphql-tag";

const ListFinalReviewSettings = gql`
  query listFinalReviewSettings(
    $activeStructure: String
    $activeGroup: String
    $isArchive: Boolean
    $idWorkflow: String
  ) {
    listFinalReviewSettings(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      isArchive: $isArchive
      idWorkflow: $idWorkflow
    ) {
      typeValutation
      typeAutovalutation
    }
  }
`;

export default ListFinalReviewSettings;
