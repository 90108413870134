import { useContext } from "react";
import { AuthContext } from "components/auth/Authenticator";
import { useFormik } from "formik";
import CreateFeedbackMutation from "queries/feedbacks/CreateFeedback";
import UpdateFeedbackMutation from "queries/feedbacks/UpdateFeedback";
import CreateGoalFeedbackMutation from "queries/feedbacks/CreateGoalFeedback";
import CompileFeedbackMutation from "queries/feedbacks/CompileFeedback";
import * as Yup from "yup";
import { API } from "@aws-amplify/api";
import { useTranslation } from "react-i18next";
import CreateRequestFeedbackMutation from "queries/feedbacks/CreateRequestFeedback";
import CreateRequestGoalFeedbackMutation from "queries/feedbacks/CreateRequestGoalFeedback";
import { WorkflowContext } from "context/WorkflowContext";
import { captureException } from "helpers/SentryHelper";
import { Cache } from "aws-amplify";

export const categories = ["goal", "valor", "skill", "generic"];

const executeMutation = async (
  mutation,
  data,
  activeStructure,
  activeGroup,
) => {
  const newFeedback = await API.graphql({
    query: mutation,
    variables: {
      input: data,
      activeStructure,
      activeGroup,
    },
  });
  return newFeedback;
};

const createFeedback = (
  data,
  isGeneralFeedback,
  isUpdate,
  isPrecompiledFeedback,
  activeStructure,
  activeGroup,
) => {
  try {
    if (isUpdate) {
      if (isPrecompiledFeedback) {
        data.data = isGeneralFeedback
          ? "compiledFeedback"
          : "compiledGoalFeedback";
      }
      return executeMutation(
        UpdateFeedbackMutation,
        data,
        activeStructure,
        activeGroup,
      );
    }
    if (isPrecompiledFeedback) {
      data.data = isGeneralFeedback
        ? "compiledFeedback"
        : "compiledGoalFeedback";
      return executeMutation(
        CompileFeedbackMutation,
        data,
        activeStructure,
        activeGroup,
      );
    }
    if (isGeneralFeedback) {
      return executeMutation(
        CreateFeedbackMutation,
        data,
        activeStructure,
        activeGroup,
      );
    }
    return executeMutation(
      CreateGoalFeedbackMutation,
      data,
      activeStructure,
      activeGroup,
    );
  } catch (error) {
    captureException("mutation", "feedback", error);
    return false;
  }
};

const createRequestFeedback = async (
  data,
  isGeneralFeedback,
  isUpdate,
  activeStructure,
  activeGroup,
) => {
  try {
    let query = CreateRequestGoalFeedbackMutation;
    const input = { ...data };
    if (isGeneralFeedback) {
      query = CreateRequestFeedbackMutation;
    }
    if (isUpdate) {
      query = UpdateFeedbackMutation;
    }
    if (!isUpdate) {
      delete input.data;
    }
    const requestFeedback = await API.graphql({
      query,
      variables: {
        input,
        activeStructure,
        activeGroup,
      },
    });
    return requestFeedback;
  } catch (error) {
    captureException("mutation", "request-feedback", error);
    return false;
  }
};

export const useGetFormikSendFeedback = ({
  isBoss,
  selectedValue,
  initialValues,
  rating,
  stateSubcategories,
  thresholdRating,
  isUpdate,
  feedbackData,
  subPhase,
  isPrecompiledFeedback,
  updateDataFeedbacks,
  handleClickAlert,
  handleClose,
  allowedCategories,
  slugTenant,
}) => {
  const { t } = useTranslation();
  const { currentWorkflow } = useContext(WorkflowContext);
  const { structures, activeGroup, activeStructure } = useContext(AuthContext);
  const validationSchema = Yup.object({
    idRecipient: Yup.string().required(t("requiredError")),
    nameRecipient: Yup.string().required(t("requiredError")),
    emailRecipient: Yup.string().required(t("requiredError")),
    professionalFamilyRecipient: Yup.string(),
    object: Yup.string().required(t("requiredError")),
    category: Yup.string().required(t("requiredError")),
    subCategory: Yup.string().when("category", {
      is: (category) => allowedCategories[`${category}`] === "generic",
      then: () => Yup.string(),
      otherwise: () => Yup.string().required(t("requiredError")),
    }),
    description: Yup.string().required(t("requiredError")),
    ratingFeedback: Yup.string().required(t("requiredErrorRating")),
    requestDescription: Yup.string(),
  });
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const category = allowedCategories[values.category];
      const isGeneralFeedback = category !== "goal";
      const { subCategories } = stateSubcategories;
      const ratingToSend = rating.findIndex(
        (item) => item === values.ratingFeedback,
      );
      const data = {
        category,
        subCategory: subCategories[values.subCategory]
          ? subCategories[values.subCategory].title
          : "",
        idSubcategory: subCategories[values.subCategory]
          ? subCategories[values.subCategory].id
          : "",
        nature: isBoss
          ? ratingToSend > thresholdRating
          : selectedValue === "keepgoing",
        idUserRecipient: values.idRecipient,
        nameUserRecipient: values.nameRecipient,
        emailRecipient: values.emailRecipient,
        professionalFamilyRecipient: values.professionalFamilyRecipient,
        touchpoint: "idle",
        description: values.description,
        object: values.object,
        rating: ratingToSend,
        nameSubphase: subPhase,
        ...(category === "valor" && {
          idMacroArea: subCategories[values.subCategory].idMacroArea,
          nameMacroArea: subCategories[values.subCategory].nameMacroArea,
        }),
        ...(category === "skill" && {
          idMacroSkill: subCategories[values.subCategory].idMacroSkill,
          nameMacroSkill: subCategories[values.subCategory].nameMacroSkill,
        }),
        idStructureRecipient: structures.idStructureBoss,
        idStructureSender: structures.idStructureBoss,
        nameStructureRecipient: isBoss
          ? structures.nameStructureBoss
          : structures.nameStructureCollaborator,
        nameStructureSender: isBoss
          ? structures.nameStructureBoss
          : structures.nameStructureCollaborator,
        idWorkflow: currentWorkflow.id,
        requestDescription: values.requestDescription,
      };
      if (isUpdate) {
        data.oldIdFeedback = feedbackData.id;
        data.data = isGeneralFeedback ? "feedback" : "goalFeedback";
      }
      if (isPrecompiledFeedback) {
        data.idRequestFeedback = isUpdate
          ? feedbackData.idRequestFeedback
          : feedbackData.id;
        data.data = isGeneralFeedback ? "feedback" : "goalFeedback";
      }
      createFeedback(
        data,
        isGeneralFeedback,
        isUpdate,
        isPrecompiledFeedback,
        activeStructure,
        activeGroup,
      )
        .then((result) => {
          if (result) {
            handleClickAlert(t("sendFeedbackSuccessMessage"), "success");
            if (isUpdate && updateDataFeedbacks) {
              if (isPrecompiledFeedback) {
                data.data =
                  data.data === "compiledFeedback"
                    ? "feedback"
                    : "goalFeedback";
              }
              updateDataFeedbacks(
                {
                  ...data,
                  id: result.data.updateFeedback.id,
                  createdAt: result.data.updateFeedback.createdAt,
                },
                "updateFeedback",
              );
              return;
            }
            if (isPrecompiledFeedback && updateDataFeedbacks) {
              updateDataFeedbacks(
                {
                  newFeedback: { ...result.data.compileFeedback, ...data },
                  idRequestFeedback: data.idRequestFeedback,
                },
                "compileFeedback",
              );
              return;
            }
            if (updateDataFeedbacks) {
              updateDataFeedbacks(
                {
                  ...data,
                  data: isGeneralFeedback ? "feedback" : "goalFeedback",
                  id:
                    result.data?.createFeedback?.id ||
                    result.data?.createGoalFeedback?.id,
                  createdAt:
                    result.data?.createFeedback?.createdAt ||
                    result.data?.createGoalFeedback?.createdAt,
                },
                "addFeedback",
              );
              return;
            }
            Cache.removeItem(`${slugTenant}-feedbacks`);
            return;
          }
          handleClickAlert(t("sendFeedbackGenericErrorMessage"), "error");
        })
        .catch((error) => {
          handleClickAlert(t("sendFeedbackGenericErrorMessage"), "error");
          captureException("mutation", "feedback", error);
        })
        .finally(() => {
          handleClose(true);
        });
    },
  });
};

export const useGetFormikSendRequestFeedback = ({
  initialValues,
  stateSubcategories,
  feedbackData,
  isUpdate,
  handleClickAlert,
  updateDataFeedbacks,
  handleClose,
  allowedCategories,
}) => {
  const { t } = useTranslation();
  const { currentWorkflow, subPhase } = useContext(WorkflowContext);
  const { structures, activeGroup, activeStructure } = useContext(AuthContext);
  const validationSchema = Yup.object({
    idRecipient: Yup.string().required(t("requiredError")),
    nameRecipient: Yup.string().required(t("requiredError")),
    emailRecipient: Yup.string().required(t("requiredError")),
    professionalFamilyRecipient: Yup.string(),
    object: Yup.string().required(t("requiredError")),
    category: Yup.string().required(t("requiredError")),
    subCategory: Yup.string().when("category", {
      is: (category) => allowedCategories[`${category}`] === "generic",
      then: () => Yup.string(),
      otherwise: () => Yup.string().required(t("requiredError")),
    }),
    requestDescription: Yup.string(),
  });
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const category = allowedCategories[values.category];
      const isGeneralFeedback = category !== "goal";
      const { subCategories } = stateSubcategories;
      const data = {
        category,
        subCategory: subCategories[values.subCategory]
          ? subCategories[values.subCategory].title
          : "",
        idSubcategory: subCategories[values.subCategory]
          ? subCategories[values.subCategory].id
          : "",
        idUserRecipient: values.idRecipient,
        nameUserRecipient: values.nameRecipient,
        emailRecipient: values.emailRecipient,
        professionalFamilyRecipient: values.professionalFamilyRecipient,
        object: values.object,
        nameStructureRecipient: structures.nameStructureCollaborator,
        nameStructureSender: structures.nameStructureCollaborator,
        ...(category === "valor" && {
          idMacroArea: subCategories[values.subCategory].idMacroArea,
          nameMacroArea: subCategories[values.subCategory].nameMacroArea,
        }),
        ...(category === "skill" && {
          idMacroSkill: subCategories[values.subCategory].idMacroSkill,
          nameMacroSkill: subCategories[values.subCategory].nameMacroSkill,
        }),
        idWorkflow: currentWorkflow.id,
        nameSubphase: subPhase,
        requestDescription: values.requestDescription,
      };
      if (isUpdate) {
        data.oldIdFeedback = feedbackData.id;
      }
      if (!isGeneralFeedback) {
        data.idStructureRecipient = structures.idStructureCollaborator;
        data.idStructureSender = structures.idStructureCollaborator;
      }
      data.data = isGeneralFeedback ? "requestFeedback" : "requestGoalFeedback";
      createRequestFeedback(
        data,
        isGeneralFeedback,
        isUpdate,
        activeStructure,
        activeGroup,
      )
        .then((result) => {
          if (result) {
            handleClickAlert(t("sendRequestFeedbackSuccessMessage"), "success");
            if (isUpdate && updateDataFeedbacks) {
              updateDataFeedbacks(
                {
                  ...data,
                  id: result.data.updateFeedback.id,
                  createdAt: result.data.updateFeedback.createdAt,
                },
                "updateFeedback",
              );
              return;
            }
            if (updateDataFeedbacks) {
              updateDataFeedbacks(
                {
                  ...data,
                  id:
                    result.data?.createRequestGoalFeedback?.id ||
                    result.data?.createRequestFeedback?.id,
                  createdAt:
                    result.data?.createRequestGoalFeedback?.createdAt ||
                    result.data?.createRequestFeedback?.createdAt,
                },
                "addFeedback",
              );
            }
            return;
          }
          handleClickAlert(
            t("sendRequestFeedbackGenericErrorMessage"),
            "error",
          );
        })
        .catch((error) => {
          handleClickAlert(
            t("sendRequestFeedbackGenericErrorMessage"),
            "error",
          );
          captureException("mutation", "send-feedback", error);
        })
        .finally(() => {
          handleClose(true);
        });
    },
  });
};

export default {
  useGetFormikSendFeedback,
  categories,
  useGetFormikSendRequestFeedback,
};
