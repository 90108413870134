import {
  getCachedItem,
  getCachedItems,
  setCachedItem,
  setCachedItems,
} from "./CacheHelper";
import { API } from "@aws-amplify/api";
import ListAdminWarnings from "queries/warnings/ListAdminWarnings";
import ListWarnings from "queries/warnings/ListWarnings";
import { captureException } from "helpers/SentryHelper";

const listWarnings = async (
  activeGroup,
  subPhase = "settings#1",
  page = "workflowPage",
  tag,
  idStructure,
  pageTag,
  slugTenant,
) => {
  try {
    const cachedItem = getCachedItem(
      `${activeGroup}#${pageTag}${idStructure ? "#" + idStructure : ""}#${tag}`,
      slugTenant,
    );
    if (cachedItem || cachedItem === 0) {
      return cachedItem;
    }
    const result = await API.graphql({
      query: ListWarnings,
      variables: {
        activeGroup,
        activeStructure: idStructure,
        input: {
          nameSubphase: subPhase,
          page,
          tag,
          idStructure,
        },
      },
    });
    const resultItem = result.data.listWarnings.Items[0]
      ? result.data.listWarnings.Items[0].substitute
      : 0;

    setCachedItem(
      `${activeGroup}#${pageTag}${idStructure ? "#" + idStructure : ""}#${tag}`,
      resultItem,
      slugTenant,
    );
    return resultItem;
  } catch (err) {
    captureException("query", "warnings", err);
  }
};

const getBossWarningsInfo = async (
  activeGroup,
  activeStructure,
  subPhase,
  settings,
  slugTenant,
) => {
  let numUsersWithoutAssignedGoals;
  if (
    settings.goalActivated &&
    ["settings#3", "onGoing#1"].includes(subPhase)
  ) {
    const goalWarning = await listWarnings(
      activeGroup,
      subPhase,
      "workflowPage",
      "usersGoalMissing",
      activeStructure,
      "team",
      slugTenant,
    );
    numUsersWithoutAssignedGoals = goalWarning;
  }
  return {
    numUsersWithoutAssignedGoals,
  };
};

export const initBossInfo = async (
  activeGroup,
  activeStructure,
  subPhase,
  settings,
  slugTenant,
) => {
  try {
    const newInfo = {};
    const { numUsersWithoutAssignedGoals } = await getBossWarningsInfo(
      activeGroup,
      activeStructure,
      subPhase,
      settings,
      slugTenant,
    );
    newInfo[
      `UserPoolGroupBoss#team${activeStructure ? "#" + activeStructure : ""}`
    ] = numUsersWithoutAssignedGoals;
    return newInfo;
  } catch (err) {
    captureException("query", "boss-warning", err);
  }
};

const listAdminWarnings = async (
  subPhase = "settings#1",
  { page = "workflowPage", tag },
) => {
  try {
    const result = await API.graphql({
      query: ListAdminWarnings,
      variables: {
        input: {
          nameSubphase: subPhase,
          page,
          tag,
        },
      },
    });
    const resultItem = result.data.listAdminWarnings.Items[0]
      ? result.data.listAdminWarnings.Items[0].substitute
      : 0;
    return resultItem;
  } catch (err) {
    captureException("query", "admin-warnings", err);
  }
};

const getAdminWarningsInfo = async (subPhase, settings) => {
  const adminInfo = {
    numUsersWithoutApprovedGoals: 0,
    numUsersWithoutValors: 0,
    numUsersWithoutSkills: 0,
  };

  const promises = [];
  const results = [];

  if (["settings#2", "onGoing#1"].includes(subPhase)) {
    if (settings.valorActivated) {
      promises.push(
        listAdminWarnings(subPhase, {
          page: "workflowPage",
          tag: "usersValorMissing",
        }),
      );
      results.push("numUsersWithoutValors");
    }

    if (settings.skillActivated) {
      promises.push(
        listAdminWarnings(subPhase, {
          page: "workflowPage",
          tag: "usersSkillMissing",
        }),
      );
      results.push("numUsersWithoutSkills");
    }
  }

  if (
    settings.goalActivated &&
    ["settings#3", "onGoing#1"].includes(subPhase)
  ) {
    promises.push(
      listAdminWarnings(subPhase, {
        page: "workflowPage",
        tag: "usersGoalConfirmation",
      }),
    );
    results.push("numUsersWithoutSkills");
  }

  const response = await Promise.all(promises);

  results.forEach((item, index) => {
    adminInfo[`${item}`] = response[`${index}`];
  });

  return adminInfo;
};

const isInvalidCache = (items) => items.some((item) => !item && item !== 0);

export const initAdminInfo = async (subPhase, settings, slugTenant) => {
  try {
    const newInfo = {};

    const keyAdminWarnings = [
      `UserPoolGroupAdmin#users#usersValorMissing`,
      `UserPoolGroupAdmin#users#usersSkillMissing`,
      `UserPoolGroupAdmin#users#usersGoalConfirmation`,
    ];

    let adminWarnings = getCachedItems(keyAdminWarnings, slugTenant);
    let [
      numUsersWithoutValors,
      numUsersWithoutSkills,
      numUsersWithoutApprovedGoals,
    ] = adminWarnings;

    if (isInvalidCache(adminWarnings)) {
      ({
        numUsersWithoutValors,
        numUsersWithoutApprovedGoals,
        numUsersWithoutSkills,
      } = await getAdminWarningsInfo(subPhase, settings));
      adminWarnings = [
        numUsersWithoutValors,
        numUsersWithoutSkills,
        numUsersWithoutApprovedGoals,
      ];

      const warningsObject = keyAdminWarnings.reduce(
        (accumulator, key, index) => {
          accumulator[`${key}`] = adminWarnings[`${index}`];
          return accumulator;
        },
        {},
      );
      setCachedItems(warningsObject, slugTenant);
    }

    newInfo[`UserPoolGroupAdmin#users`] =
      numUsersWithoutValors +
      numUsersWithoutApprovedGoals +
      numUsersWithoutSkills;
    return newInfo;
  } catch (err) {
    captureException("mutation", "send-feedback", err);
  }
};

export default { initBossInfo, initAdminInfo };
