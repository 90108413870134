import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const FilterHeader = ({ title, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Box px={4} py={5} display="flex" justifyContent="space-between">
      <Typography variant="h5" color="primary" component="p">
        {title || t("filterBy")}
      </Typography>
      <Box display={{ lg: "none" }}>
        <IconButton
          onClick={handleClose}
          disableFocusRipple
          disableRipple
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

FilterHeader.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default FilterHeader;
