import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Navigation from "../../sidebar/Navigation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.lighter,
    display: "flex",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
    marginTop: theme.navbarHeight,
    [theme.breakpoints.up("md")]: {
      maxWidth: `calc( 100% - ${theme.drawerWidth}px)`,
      padding: theme.spacing(6),
    },
    maxWidth: "100%",
    position: "relative",
  },
  fullWidth: {
    height: "100%",
    width: "100%",
  },
}));

const mapTitlePage = {
  "/": "pageHome",
  "/adoption": "adoptionPage",
  "/feedbacks": "pageFeedback",
  "/structures": "pageStructures",
  "/users": "pageUsers",
  "/valors": "valors",
  "/skills": "skills",
  "/process": "pageProcess",
  "/workflow": "pageWorkflow",
  "/team": "pageTeam",
  "/settings": "settingsPage",
  "/dashboard-final-review": "finalReviewPage",
  "/guidance": "",
  "/profile": "",
  "/wait": "",
};

const PageWrapper = ({ children, auth }) => {
  const { classes } = useStyles();
  const location = useLocation();
  return (
    <div className={classes.root}>
      {auth.user && location?.pathname && (
        <Navigation
          title={mapTitlePage[`${location.pathname}`] ?? "notFoundPage"}
        />
      )}
      <main className={auth.user ? classes.content : classes.fullWidth}>
        {children}
      </main>
    </div>
  );
};

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.element,
  auth: PropTypes.object.isRequired,
};
