import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { captureException } from "helpers/SentryHelper";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error({ error, info });
    if (/Loading chunk [\d]+ failed/.test(error?.message)) {
      window.location.reload();
      return;
    }
    captureException("error-boundary", "generic", error);
  }

  render() {
    const t = this.props.t;
    if (this.state.hasError) {
      return (
        <>
          <Typography variant="h3">{t("errorPageText")}</Typography>
          <Typography variant="subtitle1">
            <Trans i18nKey="actionErrorPageText">
              text
              <strong>
                <a href={location.href}>link</a>
              </strong>
              text
              <strong>
                <a href="/">link</a>
              </strong>
            </Trans>
          </Typography>
        </>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default ErrorBoundary;
