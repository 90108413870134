import gql from "graphql-tag";

const listNotifications = gql`
  query listNotifications(
    $activeGroup: String!
    $activeStructure: String
    $limit: Int
    $nextToken: NextNotificationInput
  ) {
    listNotifications(
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken {
        key
        sort
        createdAt
      }
      notifications {
        category
        createdAt
        id
        idFeedback
        nameUserSender
        read
        subCategory
        type
        object
        description
        idUserAssignedGoals
        typeRequest
        idWorkflow
        idStructureSender
        idUser
        idUserSender
        feedbackObject
        nameUserRecipient
        otherData
      }
      counter
      counterBoss
      counterCollaborator
    }
  }
`;

export default listNotifications;
