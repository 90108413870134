import React, { useContext, useEffect } from "react";
import FilterWrapper from "components/filter/FilterWrapper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, TextField, MenuItem, CircularProgress } from "@mui/material";
import "@nosferatu500/react-sortable-tree/style.css";
import ModalFilterTeam from "../ModalFilterTeam";
import ModalFilter from "../ModalFilter";
import FilterBox from "../FilterBox";
import { getDataHook } from "../../helper/dataDashboardFilter";
import { AuthContext } from "components/auth/Authenticator";

const DashboardFilter = ({
  openFilters,
  handleOpenFilter,
  showButtonFilter,
  disabledButton,
  selectedStructure,
  numStructures,
  userFilter,
  users,
  usersLoading,
  handleSubmitUserFilter,
  filterUsers,
  handleSubmitFilter,
  treeData,
  setTreeData,
  cdcFilterOn,
  numFilters,
  getNextUsers,
  stopList,
}) => {
  const { t } = useTranslation();
  const { slugTenant } = useContext(AuthContext);

  const {
    selectedUsers,
    openModalTeam,
    structures,
    openModal,
    loadingCdc,
    cdc,
    selectedCdc,
    dataModalFilter,
    levels,
    disabledUserFilterSubmit,
    setSelectedUsers,
    setStructures,
    handleOpenModal,
    getDataFilterBox,
    selectAllUser,
    handleSubmit,
    handleCdcFilter,
    handleDeleteFilter,
    refreshUsers,
  } = getDataHook({
    userFilter,
    users,
    usersLoading,
    handleSubmitUserFilter,
    handleOpenFilter,
    handleSubmitFilter,
    disabledButton,
    numStructures,
  });

  useEffect(() => {
    if (!users.length) {
      setSelectedUsers([]);
      return;
    }
    refreshUsers();
  }, [users]);

  useEffect(() => {
    if (!users.length) {
      return;
    }
    if (filterUsers.length === 0) {
      selectAllUser();
      return;
    }
    setSelectedUsers(filterUsers);
  }, [userFilter]);

  const dataFilterBox = getDataFilterBox();

  return (
    <>
      {treeData.data.length > 0 && (
        <ModalFilterTeam
          openModal={openModalTeam}
          handleOpenModal={handleOpenModal}
          treeData={treeData}
          setTreeData={setTreeData}
          selectedStructure={selectedStructure}
          handleSubmitFilter={handleSubmitFilter}
          numStructures={numStructures}
          handleOpenFilter={handleOpenFilter}
          structures={structures}
          setStructures={setStructures}
          type="structures"
        />
      )}
      {dataModalFilter.items.length > 0 && (
        <ModalFilter
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          items={dataModalFilter.items}
          handleSelected={dataModalFilter.handleSelected}
          selected={
            dataModalFilter.type === "cdc"
              ? selectedCdc[`${dataModalFilter.level}`] ?? []
              : selectedUsers
          }
          level={dataModalFilter.level}
          type={dataModalFilter.type}
          stopList={stopList}
          getNextUsers={getNextUsers}
        />
      )}
      <FilterWrapper
        handleClose={() => handleOpenFilter(false)}
        action={() => handleSubmit()}
        openFilters={openFilters}
        handleOpenFilter={handleOpenFilter}
        showButtonFilter={showButtonFilter}
        numberFilters={numFilters}
        buttonText={t("applyFilter")}
        title={t("filterBy")}
        disabled={userFilter ? disabledUserFilterSubmit : disabledButton}
      >
        <>
          {dataFilterBox.map(
            (
              {
                type,
                disabled,
                level,
                copyAllSelected,
                showChips,
                selected,
                otherNumbers,
              },
              index,
            ) => (
              <FilterBox
                key={index}
                type={type}
                disabledButton={disabled}
                level={level}
                handleOpenModal={handleOpenModal}
                showChips={showChips}
                selected={selected}
                otherNumbers={otherNumbers}
                copyAllSelected={copyAllSelected}
                handleDeleteFilter={handleDeleteFilter}
                usersLoading={usersLoading}
              />
            ),
          )}
          {loadingCdc && (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          )}
          {levels.length > 0 && cdcFilterOn && (
            <Box px={5} py={4}>
              <TextField
                fullWidth
                select
                value=""
                variant="filled"
                margin="dense"
                size="small"
                name="addFilter"
                label={t("addFilter")}
                onChange={(e) => handleCdcFilter(e, slugTenant)}
              >
                {levels.map((item) => (
                  <MenuItem
                    value={item}
                    key={`option-levels${item}`}
                    label={item}
                    disabled={!!cdc[`${item}`]}
                  >
                    {`${t("cdc")} ${item}`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
        </>
      </FilterWrapper>
    </>
  );
};

DashboardFilter.propTypes = {
  openFilters: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  showButtonFilter: PropTypes.bool.isRequired,
  disabledButton: PropTypes.bool.isRequired,
  selectedStructure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  numStructures: PropTypes.number.isRequired,
  userFilter: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  usersLoading: PropTypes.bool.isRequired,
  handleSubmitUserFilter: PropTypes.func.isRequired,
  filterUsers: PropTypes.array.isRequired,
  handleSubmitFilter: PropTypes.func.isRequired,
  treeData: PropTypes.object.isRequired,
  setTreeData: PropTypes.func.isRequired,
  cdcFilterOn: PropTypes.bool,
  numFilters: PropTypes.number.isRequired,
  getNextUsers: PropTypes.func.isRequired,
  stopList: PropTypes.bool.isRequired,
};

export default DashboardFilter;
