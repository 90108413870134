import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

import FilterHeader from "./components/FilterHeader";
import FilterFooter from "./components/FilterFooter";
import Box from "@mui/material/Box";
import ButtonFilter from "components/ButtonFilter";

const useStyles = makeStyles()((theme) => ({
  filterContainer: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  isBlock: {
    display: "block",
  },
  responsiveWidth: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "33.33%",
    },
    [theme.breakpoints.up("md")]: {
      width: `calc((100% - ${theme.drawerWidth}px)/3)`,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc((100% - ${theme.drawerWidth}px)/4)`,
    },
  },
  filterBody: {
    overflowY: "auto",
    maxHeight: "calc(100% - 102px);",
  },
}));

const FilterWrapper = ({
  title,
  handleClose,
  action,
  buttonText,
  openFilters,
  children,
  showButtonFilter,
  numberFilters,
  handleOpenFilter,
  disabled,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <>
      <div
        className={`${classes.filterContainer} ${
          openFilters && classes.isBlock
        }`}
      >
        <Box
          bgcolor="grey.100"
          position="fixed"
          right={0}
          top={theme.navbarHeight}
          height={`calc(100% - ${theme.navbarHeight}px)`}
          zIndex={{
            xs: theme.zIndex.drawer,
            md: 1,
          }}
          className={classes.responsiveWidth}
        >
          <FilterHeader title={title} handleClose={handleClose} />
          <Box className={classes.filterBody}>{children}</Box>
          <FilterFooter
            buttonText={buttonText}
            action={action}
            disabled={disabled}
          />
        </Box>
      </div>
      {showButtonFilter && (
        <ButtonFilter
          numFilter={numberFilters}
          handleClick={() => handleOpenFilter(true)}
        />
      )}
    </>
  );
};

FilterWrapper.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  openFilters: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  showButtonFilter: PropTypes.bool.isRequired,
  numberFilters: PropTypes.number,
  handleOpenFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FilterWrapper;
