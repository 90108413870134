import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  modalFilter: {
    height: `calc(100vh - ${theme.navbarHeight}px)`,
    top: "72px !important",
    right: "0 !important",
    left: `${theme.drawerWidth}px !important`,
    bottom: "0 !important",
    width: `calc(100% - ${theme.drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      height: `calc(100% - ${theme.navbarHeight}px)`,
      position: "fixed !important",
      left: `0 !important`,
      width: "100%",
    },
  },
  boxCustomCss: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "&:focus": {
      outline: "none",
    },
    height: "90vh",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      transform: "none",
    },
  },
  boxCustomCssOther: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "&:focus": {
      outline: "none",
    },
    height: "90vh",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      transform: "none",
    },
  },
  headerModal: {
    "&::after": {
      content: "''",
      display: "block",
      border: "1px solid #E0E0E0",
      borderBottom: "none",
    },
  },
  titleModal: {
    padding: theme.spacing(6, 4, 5, 4),
  },
  widthResponsive: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      width: "100%",
    },
    width: "100%",
  },
  boxWithOpenedFilters: {
    [theme.breakpoints.down("lg")]: {
      width: "66.66%",
    },
  },
  structuresContainer: {
    overflowX: "auto",
  },
  checkBox: {
    paddingLeft: 0,
  },
  buttonNoUpperCase: {
    textTransform: "none",
  },
  checkboxContainer: {
    height: "calc(100% - 100px)",
    overflowY: "auto",
  },
}));

/**
 * Recupera parent dalla path, scorre da sopra verso sotto l'albero seguento il path
 * @param {object} parent parent root
 * @param {array} arrayPath lista di id di strutture sopra gerarchicamente
 * @returns {object} parent diretto
 */
const getParent = (parent, arrayPath) => {
  let tempParent = parent;

  // se è un boss di un team di livello 3 o minore la gerarchia parte dal suo team
  // in questo modo elimina tutti gli id dei team parent
  const indexFirstParent = arrayPath.findIndex((item) => item === parent.id);
  if (indexFirstParent >= 0) {
    arrayPath.splice(0, indexFirstParent + 1);
  }

  arrayPath.forEach((item, i) => {
    const index = tempParent.children.findIndex(({ id }) => id === item);
    tempParent = tempParent.children[`${index}`];
  });
  return tempParent;
};

/**
 * Verifica che tutti i figli sono selezionati, scorre l'albero dal basso verso l'alto
 * parte dal padre diretto e verifica che abbia tutti i figli selezionati,
 * risale l'albero grazie al path e fa la stessa verifica ad ogni parent fino al root,
 * Se un parent non rispetta la regola esce e non controlla i rami superiori.
 *
 * @param {object} treeData tutto l'abero delle strutture
 * @param {object} node nodo da dove arriva l'azione
 * @param {array} structures strutture correntemente selezionate
 * @returns {null}
 */
export const checkAllSelect = (treeData, node, structures) => {
  let arrayPath = node.path.split("#");
  arrayPath.shift();
  const idsStructure = structures.map(({ id }) => id);
  while (arrayPath.length > 0) {
    // deve eliminare l'ultimo valore solo se non è uguale al root id
    if (arrayPath.at(-1) !== treeData.data[0].id) {
      arrayPath.pop();
    }
    const parent = getParent(treeData.data[0], arrayPath);
    if (
      parent.children.some(
        ({ id }) => !idsStructure.includes(id) && id !== node.id,
      )
    ) {
      arrayPath = [];
      return;
    }
    parent.noAllSelected = false;
  }
};

/**
 * Quando un nodo viene deselezionato setta noAllSelected a true al root e seguendo il path
 * lo setta per tutti i parent che trova fino al parent diretto del nodo
 * questa volta quindi si scorre l'albero dall'alto verso il basso
 *
 * @param {object} treeData tutto l'abero delle strutture
 * @param {object} node nodo da dove arriva l'azione
 */
export const checkNoAllSelect = (treeData, node) => {
  const arrayPath = node.path.split("#");
  arrayPath.shift();
  const historyIndex = [];
  arrayPath.forEach((item, index) => {
    const currentNode =
      index === 0 ? treeData.data[0] : getParent(treeData.data[0], arrayPath);
    historyIndex.push(item);
    currentNode.noAllSelected = true;
  });
};

export default {
  useStyles,
  checkAllSelect,
  checkNoAllSelect,
};
