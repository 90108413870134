import React, { useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import ProfileButton from "./ProfileButton";
import ProfileDropdown from "./ProfileDropdown";
import { AuthContext } from "../../auth/Authenticator";

const ProfileMenuArea = () => {
  const { activeStructure, activeGroup, handleChangeStructure, structures } =
    useContext(AuthContext);
  const buttonDom = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const changeStructure = ({ structure, role }) => {
    setIsOpen(false);
    handleChangeStructure(structure, role);
  };
  return (
    <>
      {activeGroup && (
        <ProfileButton
          handleClick={handleClick}
          ref={buttonDom}
          structures={structures}
          activeGroup={activeGroup}
          activeStructure={activeStructure}
        />
      )}
      <ProfileDropdown
        handleClose={handleClick}
        changeStructure={changeStructure}
        buttonRef={buttonDom.current}
        open={isOpen}
        structures={structures}
      />
    </>
  );
};

ProfileButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default React.memo(ProfileMenuArea);
