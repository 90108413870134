import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { memoInputField } from "helpers/MemoHelper";

const useStyles = makeStyles()((theme) => ({
  colorText: {
    " & input": {
      color: theme.palette.text.primary,
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
}));

const InputField = ({
  label,
  name,
  touched,
  error,
  formik,
  disabled,
  other,
  showLabel,
  isFeedback,
}) => {
  const { classes } = useStyles();
  return (
    <Box width="100%" mb={{ xs: isFeedback ? 5 : 4, md: isFeedback ? 9 : 4 }}>
      {showLabel && (
        <Typography variant="h6" component="p" className={classes.marginBottom}>
          {label}
        </Typography>
      )}
      <TextField
        className={classes.colorText}
        fullWidth
        variant="filled"
        margin="dense"
        size="small"
        name={name}
        label={label}
        autoComplete={name}
        required
        type="text"
        error={touched && error != null}
        helperText={error}
        disabled={disabled}
        {...formik}
        {...other}
      />
    </Box>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
  formik: PropTypes.object.isRequired,
  other: PropTypes.object,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  isFeedback: PropTypes.bool,
};

export default React.memo(InputField, memoInputField);
