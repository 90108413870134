import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const ExportDashboard = ({
  exportDashboard,
  isAggregate,
  disabledPartialExport,
  isCollab,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const openDropdown = () => {
    setIsOpen(true);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };
  const buttonDom = useRef(null);

  const handleClick = (all = false, isCollab, isXlsx) => {
    setIsOpen(false);
    exportDashboard(all, isCollab, isXlsx);
  };
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box minWidth="auto" clone>
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={openDropdown}
          ref={buttonDom}
          style={{ minWidth: "auto", padding: 0, color: "#333333" }}
        >
          <MoreVertIcon />
        </Button>
      </Box>
      {!isCollab ? (
        <Menu
          id="export-menu"
          anchorEl={buttonDom.current}
          open={isOpen}
          onClose={closeDropdown}
        >
          <MenuItem
            disabled={disabledPartialExport}
            onClick={() => handleClick(false)}
          >
            {t("exportSelected")}
          </MenuItem>
          <MenuItem
            disabled={disabledPartialExport}
            onClick={() => handleClick(false, false, true)}
          >
            {t("exportSelectedXlsx")}
          </MenuItem>
          <MenuItem onClick={() => handleClick(true)}>
            {isAggregate ? t("exportAllTeams") : t("exportAllMembers")}
          </MenuItem>
          <MenuItem onClick={() => handleClick(true, false, true)}>
            {isAggregate ? t("exportAllTeamsXlsx") : t("exportAllMembersXlsx")}
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="export-menu"
          anchorEl={buttonDom.current}
          open={isOpen}
          onClose={closeDropdown}
        >
          <MenuItem onClick={() => handleClick(true, true)}>
            {t("exportCsv")}
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

ExportDashboard.propTypes = {
  exportDashboard: PropTypes.func.isRequired,
  isAggregate: PropTypes.bool.isRequired,
  disabledPartialExport: PropTypes.bool.isRequired,
  isCollab: PropTypes.bool.isRequired,
};

export default ExportDashboard;
