import gql from "graphql-tag";

const ListAdminWarnings = gql`
  query listAdminWarnings($input: WarningInput!) {
    listAdminWarnings(input: $input) {
      Items {
        tag
        page
        role
        substitute
      }
    }
  }
`;

export default ListAdminWarnings;
