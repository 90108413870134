import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import GeneralNotification from "./GeneralNotification";
import SystemNotification from "./SystemNotification";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  LinearProgress,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Waypoint } from "react-waypoint";
import { API } from "@aws-amplify/api";
import updateNotificationStatus from "queries/notifications/updateNotificationStatus";
import { useNavigate } from "react-router-dom";
import { getNotificationRoles } from "./functions/helper";
import { AuthContext } from "components/auth/Authenticator";
import DrawerWrapper from "components/DrawerWrapper";
import { captureException } from "helpers/SentryHelper";

const useStyles = makeStyles()((theme) => ({
  beforeNotice: {
    lineHeight: "3",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      width: theme.spacing(10),
      border: `3px solid ${theme.palette.primary.main}`,
      height: "3px",
      borderRadius: "3px",
    },
  },
}));
const NotificationsSidebar = ({
  openNotifications,
  notifications,
  dispatchNotifications,
  loadMoreNotification,
  handleNotificationsWindow,
  limit,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { urlImageData } = useContext(AuthContext);
  const [loadingNotice, setLoadingNotice] = useState(false);
  const [notificationItems, setNotificationItems] = useState([]);
  const navigation = useNavigate();

  const updateCounter = () => {
    dispatchNotifications({
      type: "getList",
      data: {
        ...notifications,
        counter: notifications.counter - 1,
      },
    });
  };

  const handleClick = async (
    readNotice,
    getUrl,
    notification,
    setReadNotice,
    id,
  ) => {
    setLoadingNotice(true);
    if (readNotice) {
      const url = getUrl(notification);
      navigation(url);
      setLoadingNotice(false);
      handleNotificationsWindow();
      return;
    }
    let url;
    const notificationRoles = getNotificationRoles(notification);
    try {
      await API.graphql({
        query: updateNotificationStatus,
        variables: {
          idNotification: notification.id,
          read: true,
          notificationRoles,
        },
      });
      updateCounter();
      url = getUrl(notification);
      setReadNotice(true);
      dispatchNotifications({
        type: "setRead",
        data: { id },
      });
      setLoadingNotice(false);
      handleNotificationsWindow();
      navigation(url);
    } catch (err) {
      setLoadingNotice(false);
      captureException("mutation", "read-notification", err);
    }
  };

  useEffect(() => {
    if (!openNotifications || !notifications.data?.length) {
      return;
    }
    setNotificationItems([
      ...notificationItems,
      ...notifications.data.slice(
        notificationItems.length,
        notificationItems.length + limit,
      ),
    ]);
  }, [notifications.data, openNotifications]);
  useEffect(() => {
    if (!openNotifications) {
      setNotificationItems([]);
    }
  }, [openNotifications]);

  const loadMoreNotificationItem = () => {
    if (!openNotifications) {
      return;
    }
    if (notifications.data.length > notificationItems.length + limit) {
      setNotificationItems([
        ...notificationItems,
        ...notifications.data.slice(
          notificationItems.length,
          notificationItems.length + limit,
        ),
      ]);
      return;
    }
    if (notifications.data.length > notificationItems.length) {
      setNotificationItems([
        ...notificationItems,
        ...notifications.data.slice(
          notificationItems.length,
          notifications.data.length,
        ),
      ]);
      return;
    }
    loadMoreNotification();
  };

  return (
    <>
      <Backdrop
        open={loadingNotice}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} />
      </Backdrop>
      <DrawerWrapper
        closeDrawer={handleNotificationsWindow}
        open={openNotifications}
        noOverlay={true}
      >
        <Box height="100%">
          <Box p={4}>
            <Typography variant="subtitle1">{t("welcomeMessage")}</Typography>
            <Box position="relative" mt={2}>
              <Typography variant="h5" className={classes.beforeNotice}>
                {t("notice")}
              </Typography>
            </Box>
          </Box>
          <Box overflow="auto" height="calc(100% - 124px)">
            <Box>
              {notifications.loading && <LinearProgress />}
              {notificationItems.length > 0 &&
                notificationItems.map((notification) => {
                  switch (notification.type) {
                    case "system":
                      return (
                        <SystemNotification
                          notification={notification}
                          key={`system-${notification.createdAt}`}
                          handleClickNotice={handleClick}
                        />
                      );
                    case "touchpoint":
                    case "touchpointConfirmed":
                      return (
                        <GeneralNotification
                          notification={notification}
                          key={notification.id}
                          handleClickNotice={handleClick}
                          imageBaseUrl={urlImageData.imageBaseUrl}
                          typeNotification="touchpoint"
                        />
                      );
                    case "feedback":
                    case "compiledFeedback":
                    case "compiledGoalFeedback":
                    case "goalFeedback":
                    case "requestFeedback":
                    case "requestGoalFeedback":
                      return (
                        <GeneralNotification
                          notification={notification}
                          key={notification.id}
                          handleClickNotice={handleClick}
                          imageBaseUrl={urlImageData.imageBaseUrl}
                          typeNotification="feedback"
                        />
                      );
                    case "notifyCollaboratorCardCompilation":
                      return (
                        <GeneralNotification
                          notification={notification}
                          key={notification.id}
                          handleClickNotice={handleClick}
                          imageBaseUrl={urlImageData.imageBaseUrl}
                          typeNotification="notifyEndCompilation"
                        />
                      );
                    default:
                      return "";
                  }
                })}
              {(notifications.nextToken ||
                notifications.data.length > notificationItems.length) && (
                <Box display="flex" justifyContent="center" padding={3}>
                  <CircularProgress size={48} />
                </Box>
              )}
              {!notifications.loading && (
                <Waypoint onEnter={loadMoreNotificationItem} />
              )}
            </Box>
          </Box>
        </Box>
      </DrawerWrapper>
    </>
  );
};

NotificationsSidebar.propTypes = {
  openNotifications: PropTypes.bool.isRequired,
  notifications: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    counter: PropTypes.number,
    nextToken: PropTypes.object,
  }),
  dispatchNotifications: PropTypes.func.isRequired,
  loadMoreNotification: PropTypes.func.isRequired,
  handleNotificationsWindow: PropTypes.func,
  limit: PropTypes.number.isRequired,
};

export default React.memo(
  NotificationsSidebar,
  (prevProps, nextProps) =>
    prevProps.openNotifications === nextProps.openNotifications &&
    prevProps.notifications === nextProps.notifications,
);
