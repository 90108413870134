import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Dashboard from "components/dashboard/Dashboard";
import { WorkflowContext } from "context/WorkflowContext";
import PlaceholderDashboard from "components/dashboard/PlaceholderDashboard";
import { useMediaQuery } from "@mui/material";
import ModalWrapper from "components/feedback/ModalWrapper";

const Home = ({ activeStructure, activeGroup, name }) => {
  const [dataModal, setDataModal] = useState({
    open: false,
    type: "",
  });
  const [dataAlert, setDataAlert] = useState({
    open: false,
    text: "",
    severity: null,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const { currentPhase } = useContext(WorkflowContext);

  const handleClickAlert = (text, severity) => {
    setDataAlert({
      ...dataAlert,
      open: true,
      text,
      severity,
    });
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDataAlert({
      ...dataAlert,
      open: false,
    });
  };
  const handleOpen = (type) => {
    setDataModal({
      open: true,
      type,
    });
  };
  useEffect(() => {
    setDataModal(false);
  }, [activeStructure]);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return (
    <>
      {currentPhase === "settings" ? (
        <PlaceholderDashboard
          activeGroup={activeGroup}
          name={name}
          namePhase="Ongoing"
        />
      ) : (
        <Dashboard
          activeGroup={activeGroup}
          activeStructure={activeStructure}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
      )}
      {activeGroup !== "UserPoolGroupAdmin" && (
        <ModalWrapper
          activeStructure={activeStructure}
          activeGroup={activeGroup}
          isBoss={activeGroup === "UserPoolGroupBoss"}
          openModal={dataModal.open}
          type={dataModal.type}
          openAlert={dataAlert.open}
          textAlert={dataAlert.text}
          handleCloseAlert={handleCloseAlert}
          handleClickAlert={handleClickAlert}
          setDataModal={setDataModal}
          severityAlert={dataAlert.severity}
          handleOpen={handleOpen}
          noFilter={
            activeGroup === "UserPoolGroupCollaborator" ||
            (!isDesktop && !openFilters)
          }
        />
      )}
    </>
  );
};

Home.propTypes = {
  activeStructure: PropTypes.string,
  activeGroup: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default Home;
