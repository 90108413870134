import React, { useState, useEffect } from "react";
import { Hub } from "@aws-amplify/core";
import { Cache } from "aws-amplify";
import GetInitWorkflowData from "queries/workflow/GetInitWorkflowData";
import { API } from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import GetRating from "queries/rating/GetRating";
import onUpdateCurrentPhase from "queries/subscriptions/onUpdateCurrentPhase";
import { captureException } from "helpers/SentryHelper";
import ListWorkflows from "queries/workflow/ListWorkflows";

export const WorkflowContext = React.createContext({
  currentWorkflow: null,
  currentPhase: null,
  subPhase: null,
  rating: null,
  labels: null,
  thresholdRating: null,
  workflows: {},
  workflowsMoreInfo: {},
});

export const useWorkflow = (cognitoConfig, activeGroup, slugTenant) => {
  const [workflowInfo, setWorkflowInfo] = useState(null);
  const [workflows, setWorkflows] = useState({});
  const [workflowsMoreInfo, setWorkflowsMoreInfo] = useState({});

  const changeLabels = (newLabels) => {
    setWorkflowInfo({ ...workflowInfo, labels: newLabels });
  };
  const getWorkflowInfo = async () => {
    try {
      const result = await API.graphql({
        query: GetInitWorkflowData,
      });
      // 1 day
      if (result.data.getCurrentWorkflow && result.data.readCurrentPhase) {
        const expires = Date.now() + 1000 * 60 * 60 * 24;
        Cache.setItem(
          `${slugTenant}-currentWorkflow`,
          result.data.getCurrentWorkflow,
          {
            expires,
          },
        );
        Cache.setItem(
          `${slugTenant}-currentPhase`,
          result.data.readCurrentPhase.phase,
          {
            expires,
          },
        );
        Cache.setItem(
          `${slugTenant}-currentSubPhase`,
          result.data.readCurrentPhase.subPhase,
          {
            expires,
          },
        );
        const rating = result.data.getRating?.rating || [0];
        const labels = result.data.getRating?.labels || [""];
        const thresholdRating = result.data.getRating
          ? result.data.getRating.threshold
          : 0;
        Cache.setItem(`${slugTenant}-rating`, rating, { expires });
        Cache.setItem(`${slugTenant}-labels`, labels, { expires });
        Cache.setItem(`${slugTenant}-thresholdRating`, thresholdRating, {
          expires,
        });
        return {
          currentWorkflow: result.data.getCurrentWorkflow,
          currentPhase: result.data.readCurrentPhase.phase,
          subPhase: result.data.readCurrentPhase.subPhase,
          rating,
          thresholdRating,
          labels,
          changeLabels,
        };
      }
      return {
        currentWorkflow: false,
        currentPhase: false,
        subPhase: false,
        isLoaded: true,
      };
    } catch (error) {
      captureException("mutation", "send-feedback", error);
    }
  };

  const updateCurrentPhaseSubscription = (idTenant) =>
    API.graphql({
      query: onUpdateCurrentPhase,
      variables: { idTenant },
    }).subscribe({
      next: async (response) => {
        const { subPhase, phase } = response.value.data.onUpdateCurrentPhase;

        const result = await API.graphql({
          query: GetInitWorkflowData,
        });
        Hub.dispatch("onWorkflowChanged", {
          currentWorkflow: result.data.getCurrentWorkflow,
          currentPhase: phase,
          subPhase,
        });
      },
      error: (error) => {
        captureException(
          "subscription",
          "updateCurrentPhaseSubscription",
          error,
        );
      },
    });

  const updateWorkflow = async () => {
    let workflowInfo = {
      currentWorkflow: Cache.getItem(`${slugTenant}-currentWorkflow`),
      currentPhase: Cache.getItem(`${slugTenant}-currentPhase`),
      subPhase: Cache.getItem(`${slugTenant}-currentSubPhase`),
      rating: Cache.getItem(`${slugTenant}-rating`),
      labels: Cache.getItem(`${slugTenant}-labels`),
      thresholdRating: Cache.getItem(`${slugTenant}-thresholdRating`),
    };
    if (!workflowInfo.currentWorkflow) {
      workflowInfo = await getWorkflowInfo();
    }
    setWorkflowInfo(workflowInfo);
  };

  const updateHasCurrentWorkflow = async (initData) => {
    const response = await API.graphql({
      query: GetRating,
    });
    const { rating, threshold, labels } = response.data?.getRating ?? {};
    setWorkflowInfo({
      ...initData,
      rating,
      thresholdRating: threshold,
      labels,
    });
    const expires = Date.now() + 1000 * 60 * 60 * 24;
    Cache.setItem(`${slugTenant}-currentWorkflow`, initData.currentWorkflow, {
      expires,
    });
    Cache.setItem(`${slugTenant}-currentPhase`, initData.currentPhase, {
      expires,
    });
    Cache.setItem(`${slugTenant}-currentSubPhase`, initData.subPhase, {
      expires,
    });
    Cache.setItem(`${slugTenant}-rating`, rating, { expires });
    Cache.setItem(`${slugTenant}-labels`, labels, { expires });
    Cache.setItem(`${slugTenant}-thresholdRating`, threshold, { expires });
  };

  const getWorkflows = async (activeGroup) => {
    try {
      const [resultByDate, resultAll] = await Promise.all([
        API.graphql({
          query: ListWorkflows,
          variables: {
            activeGroup,
            filterByDate: true,
          },
        }),
        API.graphql({
          query: ListWorkflows,
          variables: {
            activeGroup,
          },
        }),
      ]);

      const processedWorkflows = resultByDate.data.listWorkflows.reduce(
        (acc, workflow) => ({
          ...acc,
          [workflow.id]: workflow.name,
        }),
        {},
      );
      const processedWorkflowsMoreInfo = resultAll.data.listWorkflows.reduce(
        (acc, workflow) => ({
          ...acc,
          [workflow.id]: {
            name: workflow.name,
            startDate: workflow.startDate,
            endDate: workflow.endDate,
          },
        }),
        {},
      );
      setWorkflows(processedWorkflows);
      setWorkflowsMoreInfo(processedWorkflowsMoreInfo);
    } catch (err) {
      captureException("query", "listWorkflow", err, { activeGroup });
    }
  };

  useEffect(() => {
    const onAuthEvent = async ({ channel, payload }) => {
      if (channel === "auth" && payload.event === "signIn") {
        await updateWorkflow();
      }
      if (payload.event === "signOut") {
        setWorkflowInfo({
          currentWorkflow: null,
          currentPhase: null,
          subPhase: null,
          rating: null,
          labels: null,
        });
      }
    };
    let subscriptionPhase;
    const initComponent = async () => {
      if (!cognitoConfig.aws_user_pools_id) {
        return;
      }
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        if (authUser) {
          if (activeGroup) {
            await getWorkflows(activeGroup);
          }
          if (activeGroup && activeGroup !== "UserPoolGroupAdmin") {
            let attributes = authUser.attributes;
            if (!attributes) {
              attributes = authUser.signInUserSession.getIdToken().payload;
            }
            const idTenant = attributes["custom:tenant_id"];
            subscriptionPhase = updateCurrentPhaseSubscription(idTenant);
          }
          await updateWorkflow();
        }
      } catch (error) {
        if (error !== "The user is not authenticated") {
          captureException("mutation", "update-workflow", error);
        }
      }
    };
    const onWorkflowChanged = ({ channel, payload }) => {
      if (channel === "onWorkflowChanged" && activeGroup) {
        updateHasCurrentWorkflow(payload);
        getWorkflows(activeGroup);
      }
    };
    initComponent();
    Hub.listen("auth", onAuthEvent);
    Hub.listen("onWorkflowChanged", onWorkflowChanged);
    return () => {
      if (subscriptionPhase) {
        subscriptionPhase.unsubscribe();
      }
    };
  }, [cognitoConfig, activeGroup]);
  return {
    ...workflowInfo,
    workflows,
    workflowsMoreInfo,
    changeLabels,
  };
};
