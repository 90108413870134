import { useState, useContext } from "react";
import { InfoContext } from "context/InfoContext";
import GetCdc from "queries/cdc/getCdc";
import { API } from "@aws-amplify/api";
import { Cache } from "aws-amplify";

export const getDataHook = ({
  userFilter,
  users,
  usersLoading,
  handleSubmitUserFilter,
  handleOpenFilter,
  handleSubmitFilter,
  disabledButton,
  numStructures,
}) => {
  // hook states
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openModalTeam, setOpenModalTeam] = useState(false);
  const [structures, setStructures] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [cdcAggregate, setCdcAggregate] = useState({});
  const [selectedCdcAggregate, setSelectedCdcAggregate] = useState({});
  const [cdcDetails, setCdcDetails] = useState({});
  const [selectedCdcDetails, setSelectedCdcDetails] = useState({});
  const [loadingCdc, setLoadingCdc] = useState(false);
  const [dataModalFilter, setDataModalFilter] = useState({
    items: [],
    selected: {},
    handleSelected: () => {},
    type: "",
  });
  const { levels } = useContext(InfoContext);

  /**
   * in base a userFilter viene preso cdcDetails o cdcAggregate
   * in questo modo ho 2 stati separati per i 2 tab diversi
   * gestiti in modo da ritornarne uno solo e avere una gestione molto semplice
   */
  const cdc = userFilter ? cdcDetails : cdcAggregate;
  const selectedCdc = userFilter ? selectedCdcDetails : selectedCdcAggregate;
  const setCdc = userFilter ? setCdcDetails : setCdcAggregate;
  const setSelectedCdc = userFilter
    ? setSelectedCdcDetails
    : setSelectedCdcAggregate;

  /**
   * funzione richiamata all'onchange delle checkbox degli utenti
   */
  const handleSelectedUsers = (selectedUsers, checked, id) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, id]);
      return;
    }
    const index = selectedUsers.findIndex((item) => item === id);
    const newArray = [...selectedUsers];
    newArray.splice(index, 1);
    setSelectedUsers(newArray);
  };

  /**
   * funzione richiamata all'onchange delle checkbox dei centri di costo
   */
  const handleSelectedCdc = (selectedCdcLevel, checked, id, level) => {
    if (checked) {
      setSelectedCdc({
        ...selectedCdc,
        [level]: [...selectedCdcLevel, id],
      });
      return;
    }
    const index = selectedCdcLevel.findIndex((item) => item === id);
    const newArray = [...selectedCdcLevel];
    newArray.splice(index, 1);
    setSelectedCdc({
      ...selectedCdc,
      [level]: newArray,
    });
  };

  /**
   * setta i dati della modale e apre la modale, in base al tipo apre la modale filtri normale
   * o la modale per i team
   * @param {Boolean} open
   * @param {String} type cdc||users||structures tipo di modale
   * @param {String} level livello cdc, presente solo se il tipo è cdc
   * @returns {null}
   */
  const handleOpenModal = (open, type, level) => {
    window.scrollTo(0, 0);
    if (["cdc", "users"].includes(type)) {
      const isCdc = type === "cdc";
      if (open) {
        setDataModalFilter({
          items: isCdc
            ? cdc[`${level}`].map((item) => ({ fullname: item, id: item }))
            : users.filter(({ role }) => role !== "UserPoolGroupBoss"),
          type,
          handleSelected: isCdc ? handleSelectedCdc : handleSelectedUsers,
          level,
        });
      }
      setOpenModal(open);
      return;
    }
    setOpenModalTeam(open);
  };

  const refreshUsers = () => {
    setDataModalFilter({
      ...dataModalFilter,
      items: users.filter(({ role }) => role !== "UserPoolGroupBoss"),
    });
  };

  /**
   * costruisce un array per i filtri da mostrare nella sidebar dei filtri,
   * l'array è sempre formato dal primo elemento che è il filtro struttura o team.
   * Gli elementi successivi sono facoltativi e riguardano i centri di costo.
   * @returns {Array}
   */
  const getDataFilterBox = () => {
    const dataFilterBox = [];
    dataFilterBox.push({
      disabled: userFilter ? users.length === 0 : disabledButton,
      type: userFilter ? "users" : "structures",
      copyAllSelected: userFilter ? "allUsers" : "allTeams",
      selected: userFilter
        ? selectedUsers.map((item) => ({
            id: item,
            name: users.find(({ id }) => item === id)?.fullname,
          }))
        : structures,
      otherNumbers: userFilter
        ? selectedUsers.length - 2
        : structures.length - 2,
      showChips:
        (userFilter &&
          selectedUsers.length > 0 &&
          selectedUsers.length < users.length - 1) ||
        (!userFilter &&
          structures.length > 0 &&
          structures.length < numStructures),
    });
    Object.keys(cdc).forEach((key) => {
      const items = cdc[`${key}`];
      if (items?.length) {
        dataFilterBox.push({
          disabled: false,
          type: "cdc",
          level: key,
          copyAllSelected: "allCdc",
          selected: selectedCdc[`${key}`]
            ? selectedCdc[`${key}`]?.map((item) => ({
                id: item,
                name: item,
              }))
            : [],
          otherNumbers: selectedCdc[`${key}`]?.length - 2,
          showChips:
            selectedCdc[`${key}`]?.length > 0 &&
            selectedCdc[`${key}`]?.length < items.length,
        });
      }
    });
    return dataFilterBox;
  };

  /**
   * seleziona tutti gli utenti, tranne il manager
   */
  const selectAllUser = () => {
    const ids = users
      .filter(({ role }) => role !== "UserPoolGroupBoss")
      .map(({ id }) => id);
    setSelectedUsers(ids);
  };
  const disabledUserFilterSubmit = usersLoading;
  /**
   * funzione richiamata al submit dei filtri
   * @returns {null}
   */
  const handleSubmit = () => {
    if (userFilter) {
      handleSubmitUserFilter(
        users.length === selectedUsers.length ? [] : selectedUsers,
        selectedCdc,
      );
      handleOpenFilter(false);
      return;
    }
    handleSubmitFilter(structures, selectedCdc);
  };

  /**
   * quando viene selezionato un centro di costo dalla select vengono recuperati
   * gli items del centro di costo selezionato.
   * Viene usata la cache per evitare chiamate evitabili, il dato non
   * è soggetto a cambiamenti ma è un dato statico.
   *
   * (al momento viene preso solo il nome anche se abbiamo anche il codice)
   * @param {Object} event
   * @returns {null}
   */
  const handleCdcFilter = async (event, slugTenant) => {
    const level = event.target.value;
    const cachedItems = Cache.getItem(`${slugTenant}-cdcItems${level}`);
    if (cachedItems) {
      setCdc({
        ...cdc,
        [level]: cachedItems,
      });
      return;
    }
    setLoadingCdc(true);
    const response = await API.graphql({
      query: GetCdc,
      variables: {
        level,
      },
    });
    const items = response.data.getCdc?.items?.map(({ name }) => name) ?? [];
    setCdc({
      ...cdc,
      [level]: items,
    });
    setLoadingCdc(false);
    Cache.setItem(`${slugTenant}-cdcItems${level}`, items);
  };

  /**
   * funzione per eliminare dagli stati cdc e selectedCdc
   * @param {String} level livello centro di costo
   */
  const handleDeleteFilter = (level) => {
    const cloneCdc = JSON.parse(JSON.stringify(cdc));
    const cloneSelectedCdc = JSON.parse(JSON.stringify(selectedCdc));
    delete cloneCdc[`${level}`];
    delete cloneSelectedCdc[`${level}`];
    setCdc(cloneCdc);
    setSelectedCdc(cloneSelectedCdc);
  };
  return {
    selectedUsers,
    openModalTeam,
    structures,
    openModal,
    loadingCdc,
    cdc,
    selectedCdc,
    dataModalFilter,
    levels,
    disabledUserFilterSubmit,
    setSelectedUsers,
    setStructures,
    handleOpenModal,
    getDataFilterBox,
    selectAllUser,
    handleSubmit,
    handleCdcFilter,
    handleDeleteFilter,
    refreshUsers,
  };
};

export default {
  getDataHook,
};
