import { palette } from "../globals/palette";

export const table = {
  MuiTable: {
    root: {
      border: `1px solid ${palette.grey["300"]}`,
    },
  },
  MuiTableCell: {
    root: {
      "&:not(:last-child)": {
        borderRight: `1px solid ${palette.grey["300"]}`,
      },
      borderBottom: `1px solid ${palette.grey["300"]}`,
    },
    head: {
      fontWeight: 400,
    },
  },
};
