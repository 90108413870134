import React from "react";
import { Box, Checkbox, Typography, Button } from "@mui/material";
import SortableTree from "@nosferatu500/react-sortable-tree";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  checkAllSelect,
  checkNoAllSelect,
} from "../../helper/modalFilterHelper";
const StructureTree = ({
  classes,
  treeData,
  setTreeData,
  structures,
  handleChangeSingle,
  handleSelectAll,
}) => {
  const { t } = useTranslation();

  const handleSelect = (checked, node) => {
    handleChangeSingle(checked, node);
    if (!checked) {
      checkNoAllSelect(treeData, node);
      return;
    }
    checkAllSelect(treeData, node, structures);
  };

  return (
    <Box className={classes.structuresContainer} height="calc(100% - 26px)">
      <SortableTree
        canDrag={() => false}
        treeData={treeData.data}
        isVirtualized={false}
        onChange={(data) =>
          setTreeData({
            ...treeData,
            data,
          })
        }
        generateNodeProps={(rowInfo) => ({
          className: "hierarchy-filter",
          subtitle: [],
          title: [
            <Box
              color="grey.400"
              key={rowInfo.node.title}
              display="flex"
              alignItems="center"
            >
              <Checkbox
                color="primary"
                checked={structures.some(({ id }) => id === rowInfo.node.id)}
                onChange={(event) =>
                  handleSelect(event.target.checked, rowInfo.node)
                }
                name="value"
                className={classes.checkBox}
              />
              <Box color="text.primary">
                <Typography variant="body1">{rowInfo.node.title}</Typography>
              </Box>
              {rowInfo.node.children.length > 0 && (
                <Button
                  color="primary"
                  onClick={() =>
                    handleSelectAll(
                      rowInfo.node,
                      undefined,
                      rowInfo.node.noAllSelected,
                    )
                  }
                  className={classes.buttonNoUpperCase}
                >
                  {rowInfo.node.noAllSelected
                    ? t("selectBelow")
                    : t("deselectBelow")}
                </Button>
              )}
            </Box>,
          ],
        })}
      />
    </Box>
  );
};

StructureTree.propTypes = {
  classes: PropTypes.object.isRequired,
  treeData: PropTypes.object.isRequired,
  setTreeData: PropTypes.func.isRequired,
  structures: PropTypes.array.isRequired,
  handleChangeSingle: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
};

export default StructureTree;
