import gql from "graphql-tag";

const GetStructure = gql`
  query getStructure(
    $activeStructure: String
    $activeGroup: String!
    $idStructure: String!
  ) {
    getStructure(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idStructure: $idStructure
    ) {
      name: nameStructure
      numKeepGoing
      numThinkAbout
      nameManager
      idManager
      emailManager
      professionManager
    }
  }
`;

export default GetStructure;
