import { useState } from "react";
import { API } from "@aws-amplify/api";
import ListWorkflowStructures from "queries/Structure/ListWorkflowStructures";
import { captureException } from "helpers/SentryHelper";
import GetStructureUsers from "queries/User/GetStructureUsers";
import DashboardAggregateAdmin from "queries/dashboard/DashboardAggregateAdmin";
import DashboardAggregateManager from "queries/dashboard/DashboardAggregateManager";
import DashboardAggregateCollaborator from "queries/dashboard/DashboardAggregateCollaborator";
import DashboardFinalReviewAdmin from "queries/dashboardFinalReview/DashboardFinalReviewAdmin";
import DashboardFinalReviewManager from "queries/dashboardFinalReview/DashboardFinalReviewManager";
import { useMediaQuery } from "@mui/material";

import { makeStyles } from "tss-react/mui";

export const getDataHook = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [periodAggregate, setPeriodAggregate] = useState("30days");
  const [periodDetail, setPeriodDetail] = useState("30days");
  const [structures, setStructures] = useState({
    loading: true,
    data: [],
  });
  const [selectedStructure, setSelectedStructure] = useState([]);
  const [singleStructure, setSingleStructure] = useState("");
  const [filterUsers, setFilterUsers] = useState([]);
  const [filterCdc, setFilterCdc] = useState([]);
  const [users, setUsers] = useState({
    loading: false,
    data: [],
    next: null,
  });
  const [treeData, setTreeData] = useState({
    loading: true,
    data: [],
    paths: [],
    nameStructures: {},
  });

  const [requestAggregate, setRequestAggregate] = useState({
    numRequests: 0,
    numCompiled: 0,
    numNotCompiled: 0,
  });
  const [feedbackAggregate, setFeedbackAggregate] = useState({
    thinkAboutOverTime: [],
    keepGoingOverTime: [],
    genericOverTime: [],
    goalOverTime: [],
    valorOverTime: [],
    skillOverTime: [],
    numFeedbacks: 0,
    numRead: 0,
    numNotRead: 0,
    numThinkAbout: 0,
    numKeepGoing: 0,
    numGeneric: 0,
    numGoal: 0,
    numValor: 0,
    numSkill: 0,
  });
  const [touchpointAggregate, setTouchpointAggregate] = useState({
    numTouchpoints: 0,
    numTouchpointsPending: 0,
    numTouchpointsEnded: 0,
  });

  const [requestDetail, setRequestDetail] = useState({
    numRequests: 0,
    numCompiled: 0,
    numNotCompiled: 0,
  });
  const [feedbackDetail, setFeedbackDetail] = useState({
    thinkAboutOverTime: [],
    keepGoingOverTime: [],
    genericOverTime: [],
    goalOverTime: [],
    valorOverTime: [],
    skillOverTime: [],
    numFeedbacks: 0,
    numRead: 0,
    numNotRead: 0,
    numThinkAbout: 0,
    numKeepGoing: 0,
    numGeneric: 0,
    numGoal: 0,
    numValor: 0,
    numSkill: 0,
  });
  const [touchpointDetail, setTouchpointDetail] = useState({
    numTouchpoints: 0,
    numTouchpointsPending: 0,
    numTouchpointsEnded: 0,
  });
  const [loadingData, setLoadingData] = useState(true);
  const [dataAlert, setDataAlert] = useState({
    open: false,
    text: "",
    severity: null,
  });

  // dati per dashboard di final review
  const [countersAggregate, setCountersAggregate] = useState({
    autovalutationCounters: {
      compiled: 0,
      partiallyCompiled: 0,
      locked: 0,
      notCompiled: 0,
    },
    valutationCounters: {
      compiled: 0,
      partiallyCompiled: 0,
      locked: 0,
      notCompiled: 0,
    },
  });
  const [countersDetail, setCountersDetail] = useState({
    autovalutationCounters: {
      compiled: 0,
      partiallyCompiled: 0,
      locked: 0,
      notCompiled: 0,
    },
    valutationCounters: {
      compiled: 0,
      partiallyCompiled: 0,
      locked: 0,
      notCompiled: 0,
    },
  });
  const [scoresOverRatingDetail, setScoresOverRatingDetail] = useState({
    scoresOverRating: [],
    scoresOverRatingAutovalutation: [],
  });
  const [scoresOverRatingAggregate, setScoresOverRatingAggregate] = useState({
    scoresOverRating: [],
    scoresOverRatingAutovalutation: [],
  });
  const [averageRatingsElementsAggregate, setAverageRatingsElementsAggregate] =
    useState({});
  const [averageRatingsElementsDetail, setAverageRatingsElementsDetail] =
    useState({});
  const [questionsAggregate, setQuestionsAggregate] = useState([]);
  const [questionsDetail, setQuestionsDetail] = useState([]);
  const [bossQuestionsAggregate, setBossQuestionsAggregate] = useState([]);
  const [bossQuestionsDetail, setBossQuestionsDetail] = useState([]);
  const [scoresSingleUser, setScoresUser] = useState({
    autovalutationScore: null,
    valutationScore: null,
  });

  const handleSetCdcFilter = (cdc) => {
    const arrayCdc = Object.keys(cdc).map((key) => ({
      level: key,
      items: cdc[`${key}`],
    }));
    setFilterCdc(arrayCdc);
  };

  const handleSubmitFilter = (structures, cdc) => {
    setSelectedStructure(structures);
    handleSetCdcFilter(cdc);
  };

  const handleChange = (event, index) => {
    setCurrentTab(index);
  };
  const handleSelectedPeriod = (selectedPeriod) => {
    if (currentTab === 0) {
      setPeriodAggregate(selectedPeriod);
      return;
    }
    setPeriodDetail(selectedPeriod);
  };

  const getStructures = async () => {
    try {
      const result = await API.graphql({
        query: ListWorkflowStructures,
      });
      const listStructures = result.data.listAllStructures;
      return { loading: false, data: listStructures };
    } catch (err) {
      captureException("query", "dashboard-structures", err);
      return { loading: false, data: [] };
    }
  };

  const handleSelectedTeam = (event, structure) => {
    setFilterUsers([]);
    setSingleStructure(structure ?? event.target.value);
  };

  const getUsers = async (
    singleStructure,
    activeGroup,
    activeStructure,
    nextToken,
  ) => {
    const variables = {
      activeGroup,
      activeStructure,
      idStructure: singleStructure,
      limit: 50,
      status: "enabled",
      ...(nextToken && {
        next: nextToken,
      }),
    };

    const response = await API.graphql({
      query: GetStructureUsers,
      variables,
    });

    const newNextToken = { ...response.data?.listStructureUsers?.next };
    delete newNextToken.sort2;

    return {
      loading: false,
      data: response.data?.listStructureUsers?.items,
      next: newNextToken,
    };
  };
  const handleSubmitUserFilter = (ids, cdc) => {
    setFilterUsers(ids);
    handleSetCdcFilter(cdc);
  };

  const getDataDashboard = async (variables, type, isDetail, activeGroup) => {
    try {
      const queries = {
        UserPoolGroupAdmin: DashboardAggregateAdmin,
        UserPoolGroupBoss: DashboardAggregateManager,
        UserPoolGroupCollaborator: DashboardAggregateCollaborator,
      };
      const objectResponse = {
        UserPoolGroupAdmin: "dashboardAggregateAdmin",
        UserPoolGroupBoss: "dashboardAggregateManager",
        UserPoolGroupCollaborator: "dashboardAggregateCollaborator",
      };
      const response = await API.graphql({
        query: queries[`${activeGroup}`],
        variables: {
          ...variables,
          type,
        },
      });
      const newType = `${type.toLowerCase()}_${
        isDetail ? "detail" : "aggregate"
      }`;
      const {
        numRequests,
        numCompiled,
        numNotCompiled,
        thinkAboutOverTime,
        keepGoingOverTime,
        genericOverTime,
        valorOverTime,
        numFeedbacks,
        numRead,
        numNotRead,
        numThinkAbout,
        goalOverTime,
        numKeepGoing,
        numGeneric,
        numGoal,
        numValor,
        numTouchpoints,
        numTouchpointsPending,
        numTouchpointsEnded,
        numSkill,
        skillOverTime,
      } = response.data[`${objectResponse[`${activeGroup}`]}`];

      const mapResponse = {
        request_detail: {
          setData: setRequestDetail,
          data: {
            numRequests,
            numCompiled,
            numNotCompiled,
          },
        },
        request_aggregate: {
          setData: setRequestAggregate,
          data: {
            numRequests,
            numCompiled,
            numNotCompiled,
          },
        },
        feedback_detail: {
          setData: setFeedbackDetail,
          data: {
            thinkAboutOverTime,
            keepGoingOverTime,
            genericOverTime,
            valorOverTime,
            numFeedbacks,
            numRead,
            numNotRead,
            numThinkAbout,
            goalOverTime,
            numKeepGoing,
            numGeneric,
            numGoal,
            numValor,
            numSkill,
            skillOverTime,
          },
        },
        feedback_aggregate: {
          setData: setFeedbackAggregate,
          data: {
            thinkAboutOverTime,
            keepGoingOverTime,
            genericOverTime,
            valorOverTime,
            numFeedbacks,
            numRead,
            numNotRead,
            numThinkAbout,
            goalOverTime,
            numKeepGoing,
            numGeneric,
            numGoal,
            numValor,
            numSkill,
            skillOverTime,
          },
        },
        touchpoint_detail: {
          setData: setTouchpointDetail,
          data: {
            numTouchpoints,
            numTouchpointsPending,
            numTouchpointsEnded,
          },
        },
        touchpoint_aggregate: {
          setData: setTouchpointAggregate,
          data: {
            numTouchpoints,
            numTouchpointsPending,
            numTouchpointsEnded,
          },
        },
      };
      return mapResponse[`${newType}`];
    } catch (err) {
      captureException("query", "data-dashboard", err);
      return {};
    }
  };

  const handleSetDataDashboard = ({
    isDetail,
    autovalutationCounters,
    valutationCounters,
    scoresOverRating,
    scoresOverRatingAutovalutation,
    macroareaRatings,
    macroskillRatings,
    priorityRating,
    questions,
    bossQuestions,
    autovalutationScore,
    valutationScore,
  }) => {
    if (isDetail) {
      setCountersDetail({ autovalutationCounters, valutationCounters });
      setScoresOverRatingDetail({
        scoresOverRating,
        scoresOverRatingAutovalutation,
      });
      setAverageRatingsElementsDetail({
        macroareaRatings,
        priorityRating,
        macroskillRatings,
      });
      setQuestionsDetail(questions);
      setBossQuestionsDetail(bossQuestions);
      setScoresUser({
        autovalutationScore,
        valutationScore,
      });
      return;
    }
    setCountersAggregate({ autovalutationCounters, valutationCounters });
    setScoresOverRatingAggregate({
      scoresOverRating,
      scoresOverRatingAutovalutation,
    });
    setAverageRatingsElementsAggregate({
      macroareaRatings,
      priorityRating,
      macroskillRatings,
    });
    setQuestionsAggregate(questions);
    setBossQuestionsAggregate(bossQuestions);
  };

  const getFinalReviewDashboardData = async (
    variables,
    isDetail,
    activeGroup,
  ) => {
    try {
      const queries = {
        UserPoolGroupAdmin: DashboardFinalReviewAdmin,
        UserPoolGroupBoss: DashboardFinalReviewManager,
      };
      const objectResponse = {
        UserPoolGroupAdmin: "dashboardFinalReviewAdmin",
        UserPoolGroupBoss: "dashboardFinalReviewManager",
      };
      if (activeGroup === "UserPoolGroupBoss") {
        delete variables.cdc;
      }
      const response = await API.graphql({
        query: queries[`${activeGroup}`],
        variables,
      });
      const {
        autovalutationCounters,
        valutationCounters,
        scoresOverRating,
        scoresOverRatingAutovalutation,
        macroareaRatings,
        macroskillRatings,
        priorityRating,
        questions,
        bossQuestions,
        autovalutationScore,
        valutationScore,
      } = response.data[`${objectResponse[`${activeGroup}`]}`];
      return {
        isDetail,
        autovalutationCounters,
        valutationCounters,
        scoresOverRating,
        scoresOverRatingAutovalutation,
        macroareaRatings,
        macroskillRatings,
        priorityRating,
        questions: questions ?? [],
        bossQuestions: bossQuestions ?? [],
        autovalutationScore,
        valutationScore,
      };
    } catch (err) {
      captureException("query", "data-dashboard", err);
      return {};
    }
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDataAlert({
      ...dataAlert,
      open: false,
    });
  };

  const handleAlert = (text, severity) => {
    setDataAlert({
      open: true,
      text,
      severity,
    });
  };

  const isMediumDesktop = useMediaQuery((theme) =>
    theme.breakpoints.only("md"),
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isSmartphone = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return {
    currentTab,
    periodAggregate,
    periodDetail,
    structures,
    selectedStructure,
    singleStructure,
    filterUsers,
    users,
    treeData,
    requestAggregate,
    feedbackAggregate,
    touchpointAggregate,
    requestDetail,
    feedbackDetail,
    touchpointDetail,
    loadingData,
    dataAlert,
    filterCdc,
    countersAggregate,
    countersDetail,
    scoresOverRatingAggregate,
    scoresOverRatingDetail,
    averageRatingsElementsAggregate,
    averageRatingsElementsDetail,
    questionsAggregate,
    questionsDetail,
    bossQuestionsAggregate,
    bossQuestionsDetail,
    isMediumDesktop,
    isMobile,
    isSmartphone,
    scoresSingleUser,
    setTreeData,
    setUsers,
    handleSubmitFilter,
    handleChange,
    handleSelectedPeriod,
    getStructures,
    handleSelectedTeam,
    getUsers,
    handleSubmitUserFilter,
    getDataDashboard,
    setLoadingData,
    setStructures,
    handleCloseAlert,
    handleAlert,
    getFinalReviewDashboardData,
    handleSetDataDashboard,
  };
};

export const useStyles = makeStyles()((theme) => ({
  buttonLink: {
    "& svg": {
      transform: "rotate(-45deg)",
      transition: "transform 0.5s",
    },
    "&:hover svg": {
      transform: "rotate(0)",
      fontSize: "1.5rem",
    },
  },
}));

export default {
  getDataHook,
  useStyles,
};
