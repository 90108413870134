import GetAllStructures from "queries/Structure/GetAllStructures";
import GetAllStructuresManager from "queries/Structure/GetAllStructuresManager";
import { API } from "@aws-amplify/api";
import { Cache } from "aws-amplify";

const getAllStructures = async ({
  getFlatDataFromTree,
  language,
  isBoss = false,
  activeGroup = null,
  activeStructure = null,
  hierarchy = true,
  slugTenant,
}) => {
  const result = await API.graphql({
    query: isBoss ? GetAllStructuresManager : GetAllStructures,
    variables: {
      language,
      ...(isBoss && { hierarchy, activeGroup, activeStructure }),
    },
  });

  const structures = JSON.parse(
    result.data[isBoss ? "getAllStructuresManager" : "getAllStructures"]
      .structures,
  );
  if (!hierarchy) {
    const structuresArray = structures.map(({ id, nameStructure }) => ({
      id,
      name: nameStructure,
    }));
    return { loading: false, data: structuresArray };
  }
  const arrayStructures =
    Object.keys(structures).length > 0 ? [structures] : [];
  const flatData = getFlatDataFromTree({
    treeData: arrayStructures,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  });
  const paths = {};
  const nameStructures = {};
  flatData.forEach((item) => {
    paths[`${item.node.id}`] = {
      name: item.node.title,
      path: item.parentNode
        ? `${item.parentNode.path}#${item.node.id}`
        : item.node.id,
      padre: item.parentNode ? item.parentNode.title : "",
    };
    nameStructures[(item.node.title ?? "").toLowerCase()] = item.node.manager;
  });
  const newTreeData = {
    loading: false,
    data: arrayStructures,
    paths,
    nameStructures,
  };
  Cache.setItem(`${slugTenant}-allStructures`, newTreeData);
  return newTreeData;
};

export default getAllStructures;
