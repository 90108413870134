import gql from "graphql-tag";

const DashboardAggregateAdmin = gql`
  query dashboardAggregateAdmin(
    $timeframe: DashboardTimeFrame!
    $structures: [String]
    $users: [String]
    $type: DashboardType!
  ) {
    dashboardAggregateAdmin(
      timeframe: $timeframe
      structures: $structures
      users: $users
      type: $type
    ) {
      numFeedbacks
      numRead
      numNotRead
      numThinkAbout
      thinkAboutOverTime {
        count
        date
      }
      numKeepGoing
      keepGoingOverTime {
        count
        date
      }
      numGeneric
      genericOverTime {
        count
        date
      }
      numGoal
      goalOverTime {
        count
        date
      }
      valorOverTime {
        count
        date
      }
      numRequests
      numCompiled
      numNotCompiled
      numTouchpoints
      numTouchpointsPending
      numTouchpointsEnded
      numValor
      numSkill
      skillOverTime {
        count
        date
      }
    }
  }
`;

export default DashboardAggregateAdmin;
