import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress } from "@mui/material";

const ActionForm = ({
  isSubmitting,
  handleClose,
  action,
  primaryText,
  isChanges,
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button color="primary" onClick={handleClose}>
        {t("back")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        type={action ? "button" : "submit"}
        onClick={action}
        disabled={isSubmitting || !isChanges}
      >
        {isSubmitting ? (
          <CircularProgress size={24} />
        ) : (
          <span>{t(`${primaryText}`)}</span>
        )}
      </Button>
    </Box>
  );
};

ActionForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func,
  primaryText: PropTypes.string,
  isChanges: PropTypes.bool,
};

ActionForm.defaultProps = {
  primaryText: "send",
  isChanges: true,
};

export default ActionForm;
