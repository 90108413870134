import React, { useContext } from "react";
import { Navigate, useLocation, Route, Routes } from "react-router-dom";
import { Container, Box, CircularProgress } from "@mui/material";
import queryString from "query-string";
import { AuthContext } from "components/auth/Authenticator";
import PropTypes from "prop-types";
import { createQueryString } from "./helper/authrouteUtils";

const SignIn = React.lazy(() => import("./SignIn"));
const ModifyPassword = React.lazy(() => import("./ModifyPassword"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const ResetPassword = React.lazy(() => import("./ResetPassword"));
const VerifySignUp = React.lazy(() => import("./VerifySignUp"));
const ThankYou = React.lazy(() => import("./ThankYou"));
const TenantLoading = React.lazy(() => import("./TenantLoading"));

const WrapperComponent = ({ children, auth }) => {
  return (
    <Box bgcolor="primary.dark">
      {auth.user ? (
        <Navigate to="/wait" />
      ) : (
        <Box component={Container} minHeight="100vh">
          {children}
        </Box>
      )}
    </Box>
  );
};

const NotFound = ({ auth }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  if (!auth.isLoaded) {
    return <CircularProgress />;
  }
  if (!auth.user) {
    const queryString = createQueryString(params);
    return <Navigate to={`/login${queryString}`} />;
  }
  return null;
};

const AuthRoute = ({ history, auth }) => {
  const { cognitoConfig, slugTenant, errorTenant } = useContext(AuthContext);
  const location = useLocation();

  const hasNormalLogin = (loginTypes) => {
    // Se è presente almeno un login type normal, allora abbiamo un'autenticazione normale
    const loginTypesCodes = loginTypes.map((loginType) => loginType.name);
    return (
      loginTypesCodes.length > 0 &&
      loginTypesCodes.some((code) => code === "normal")
    );
  };

  return (
    <>
      {!cognitoConfig.aws_user_pools_id && (
        <TenantLoading loading={!slugTenant && !errorTenant} />
      )}
      <Routes>
        {cognitoConfig.aws_user_pools_id && (
          <Route
            exact
            path="/login"
            element={
              <WrapperComponent auth={auth}>
                <SignIn
                  hasNormalLogin={hasNormalLogin(cognitoConfig.loginTypes)}
                  hasOAuth={cognitoConfig.hasOAuth}
                />
              </WrapperComponent>
            }
          />
        )}
        {cognitoConfig.aws_user_pools_id &&
          hasNormalLogin(cognitoConfig.loginTypes) && (
            <>
              <Route
                path="/modify-password"
                element={
                  <WrapperComponent auth={auth}>
                    <ModifyPassword />
                  </WrapperComponent>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <WrapperComponent auth={auth}>
                    <ForgotPassword />
                  </WrapperComponent>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <WrapperComponent auth={auth}>
                    <ResetPassword location={location} />
                  </WrapperComponent>
                }
              />
              <Route
                path="/confirm-signup"
                element={
                  <WrapperComponent auth={auth}>
                    <VerifySignUp />
                  </WrapperComponent>
                }
              />
              <Route
                path="/thank-you"
                element={
                  <WrapperComponent auth={auth}>
                    <ThankYou />
                  </WrapperComponent>
                }
              />
            </>
          )}
        <Route path="*" element={<NotFound auth={auth} />} />
      </Routes>
    </>
  );
};

AuthRoute.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

WrapperComponent.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.object.isRequired,
};

NotFound.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default AuthRoute;
