import React from "react";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import Icon from "@mui/material/Icon";
import { ReactComponent as AdoptionLogo } from "assets/icon/adoption.svg";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";

const GetIcon = (icon) => {
  switch (icon) {
    case "home":
      return <HomeTwoToneIcon />;
    case "feedbacks":
      return <ForumTwoToneIcon />;
    case "structures":
      return <BusinessTwoToneIcon />;
    case "users":
    case "team":
      return <PeopleAltTwoToneIcon />;
    case "evalutation-items":
      return <ListTwoToneIcon />;
    case "process":
      return <AccountTreeTwoToneIcon />;
    case "workflow":
      return <FlagTwoToneIcon />;
    case "settings":
      return <SettingsTwoToneIcon />;
    case "adoption":
      return (
        <Icon>
          <AdoptionLogo />
        </Icon>
      );
    case "finalReview":
      // TODO icona temporanea da sostituire
      return <AssessmentTwoToneIcon />;
    default:
      return <HomeTwoToneIcon />;
  }
};
export default GetIcon;
