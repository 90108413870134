import gql from "graphql-tag";

const ListValuesQuery = gql`
  query listValues(
    $activeStructure: String
    $activeGroup: String!
    $idUserRecipient: String
    $forRecipient: Boolean
  ) {
    listValues(
      activeStructure: $activeStructure
      activeGroup: $activeGroup
      idUserRecipient: $idUserRecipient
      forRecipient: $forRecipient
    ) {
      Items {
        id
        title: sort2
        numKeepGoing
        numThinkAbout
        description
        labels {
          value
          description
        }
        idMacroArea
        nameMacroArea
      }
    }
  }
`;

export default ListValuesQuery;
