import gql from "graphql-tag";

const GetRating = gql`
  query getRating {
    getRating {
      rating
      threshold
      labels
    }
  }
`;

export default GetRating;
