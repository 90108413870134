import { useState, useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";
import { palette } from "theme/globals/palette";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

const onHoverFunctions = {
  onHover: function (event) {
    event.native.target.style.cursor = "pointer";
  },
  onLeave: function (event) {
    event.native.target.style.cursor = "default";
  },
};

const getAverageValutation = (scores) => {
  const { sumValutations, sumItemsValutations } = scores.reduce(
    (accumulator, { numScores, rating }) => {
      accumulator.sumValutations += numScores * rating;
      accumulator.sumItemsValutations += numScores;
      return accumulator;
    },
    {
      sumValutations: 0,
      sumItemsValutations: 0,
    },
  );
  return sumValutations / sumItemsValutations;
};

export const useChartCountersValutation = ({
  counters,
  isMediumDesktop,
  isSmartphone,
}) => {
  const { t } = useTranslation();
  const countersValutationRef = useRef(null);
  const [chartCountersValutation, setChartCountersValutation] = useState(null);
  const { locked, notCompiled, compiled, partiallyCompiled } = counters;

  useEffect(() => {
    if (!countersValutationRef.current) {
      return;
    }
    if (chartCountersValutation) {
      chartCountersValutation.destroy();
    }
    const dataCounters = [compiled, partiallyCompiled, notCompiled, locked];
    const data = {
      labels: [
        `${t("compiled")} (${compiled})`,
        `${t("partiallyCompiled")} (${partiallyCompiled})`,
        `${t("notCompiled")} (${notCompiled})`,
        `${t("locked")} (${locked})`,
      ],
      datasets: [
        {
          data: dataCounters,
          backgroundColor: [
            palette.keepGoing.main,
            palette.secondary.dark,
            palette.grey[300],
            palette.thinkAbout.dark,
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: isMediumDesktop || isSmartphone ? "bottom" : "right",
          align: isMediumDesktop || isSmartphone ? "center" : "start",
          labels: {
            font: {
              size: 12,
            },
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: isMediumDesktop || isSmartphone ? 1 : 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(countersValutationRef.current, config);
    setChartCountersValutation(chart);
  }, [countersValutationRef, counters, isMediumDesktop, isSmartphone]);
  return { countersValutationRef };
};

export const useChartCountersAutovalutation = ({
  counters,
  isMediumDesktop,
  isSmartphone,
}) => {
  const { t } = useTranslation();
  const countersAutovalutationRef = useRef(null);
  const [chartCountersAutovalutation, setChartCountersAutovalutation] =
    useState(null);
  const { locked, notCompiled, compiled, partiallyCompiled } = counters;

  useEffect(() => {
    if (!countersAutovalutationRef.current) {
      return;
    }
    if (chartCountersAutovalutation) {
      chartCountersAutovalutation.destroy();
    }
    const dataCounters = [compiled, partiallyCompiled, notCompiled, locked];
    const data = {
      labels: [
        `${t("compiled")} (${compiled})`,
        `${t("partiallyCompiled")} (${partiallyCompiled})`,
        `${t("notCompiled")} (${notCompiled})`,
        `${t("locked")} (${locked})`,
      ],
      datasets: [
        {
          data: dataCounters,
          backgroundColor: [
            palette.keepGoing.main,
            palette.secondary.dark,
            palette.grey[300],
            palette.thinkAbout.dark,
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          ...onHoverFunctions,
          fullSize: true,
          maxWidth: 240,
          position: isMediumDesktop || isSmartphone ? "bottom" : "right",
          align: isMediumDesktop || isSmartphone ? "center" : "start",
          labels: {
            font: {
              size: 12,
            },
            boxWidth: 12,
            boxHeigth: 12,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: isMediumDesktop || isSmartphone ? 1 : 3,
      responsive: true,
    };
    const config = {
      type: "doughnut",
      data,
      options,
    };
    const chart = new Chart(countersAutovalutationRef.current, config);
    setChartCountersAutovalutation(chart);
  }, [countersAutovalutationRef, counters, isMediumDesktop, isSmartphone]);
  return { countersAutovalutationRef };
};

export const useChartScoresOverRating = ({
  scoresOverRatingData,
  rating,
  isMediumDesktop,
  activeAutovalutation,
  activeAutovalutationQuantity,
  isMobile,
  isSmartphone,
}) => {
  const { t } = useTranslation();
  const scoresOverRatingRef = useRef(null);
  const [chartScoresOverRating, setChartScoresOverRating] = useState(null);
  const { scoresOverRating, scoresOverRatingAutovalutation } =
    scoresOverRatingData;
  useEffect(() => {
    if (!scoresOverRatingRef.current) {
      return;
    }
    if (chartScoresOverRating) {
      chartScoresOverRating.destroy();
    }
    const dataScoresOverRating = rating.map(
      (item, index) =>
        scoresOverRating.find(({ rating }) => rating === index)?.numScores ?? 0,
    );
    const dataScoresOverRatingAutovalutation = rating.map(
      (item, index) =>
        scoresOverRatingAutovalutation.find(({ rating }) => rating === index)
          ?.numScores ?? 0,
    );
    const data = {
      labels: rating,
      datasets: [
        {
          label: t("valutations"),
          data: dataScoresOverRating,
          backgroundColor: palette.primary.lighter,
          borderColor: palette.primary.main,
          borderWidth: 1,
        },
      ],
    };
    const averageValutations = getAverageValutation(scoresOverRating);
    const averageAutovalutation = getAverageValutation(
      scoresOverRatingAutovalutation,
    );

    if (activeAutovalutationQuantity) {
      data.datasets.push({
        label: t("autovalutations"),
        data: dataScoresOverRatingAutovalutation,
        backgroundColor: palette.secondary.light,
        borderColor: palette.secondary.dark,
        borderWidth: 1,
      });
    }
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "start",
        },
        annotation: {
          annotations: {
            ...(!isNaN(parseInt(averageValutations, 10)) && {
              averageValutationLine: {
                type: "line",
                xMin: averageValutations,
                xMax: averageValutations,
                borderColor: palette.primary.main,
                borderWidth: 3,
                label: {
                  enabled: true,
                  content: Math.round(averageValutations * 10) / 10 + 1,
                  position: "end",
                  backgroundColor: "#fff",
                  color: palette.primary.main,
                  yAdjust: 40,
                },
              },
            }),
            ...(!isNaN(parseInt(averageAutovalutation, 10)) && {
              averageAutovalutationLine: {
                type: "line",
                xMin: averageAutovalutation,
                xMax: averageAutovalutation,
                borderColor: palette.secondary.dark,
                borderWidth: 3,
                label: {
                  enabled: true,
                  content: Math.round(averageAutovalutation * 10) / 10 + 1,
                  position: "end",
                  backgroundColor: "#fff",
                  color: palette.secondary.dark,
                  yAdjust: 40,
                },
              },
            }),
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0,
          },
        },
      },
      ...(!activeAutovalutation
        ? { aspectRatio: isMediumDesktop || isSmartphone ? 1 : 2 }
        : { aspectRatio: isSmartphone ? 1 : 2 }),
    };
    const config = {
      type: "bar",
      data,
      options,
    };
    const chart = new Chart(scoresOverRatingRef.current, config);
    setChartScoresOverRating(chart);
  }, [
    scoresOverRatingRef,
    scoresOverRating,
    scoresOverRatingAutovalutation,
    isMediumDesktop,
    isMobile,
    isSmartphone,
  ]);
  return { scoresOverRatingRef };
};

export default {
  useChartCountersValutation,
  useChartCountersAutovalutation,
  useChartScoresOverRating,
};
