import React from "react";
import { Container, Typography } from "@mui/material";
import { logout } from "../../helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
const NotAuthorized = () => {
  const navigation = useNavigate();
  return (
    <Container>
      <Typography variant="h5" component="h5" paragraph>
        Non sei autorizzato ad accedere a questa pagina
      </Typography>
      <button onClick={logout}>Logout</button>
      <button onClick={() => navigation("/")}>Torna alla home</button>
    </Container>
  );
};

export default NotAuthorized;
