import gql from "graphql-tag";

const GetUserForBoss = gql`
  query getUserForBoss(
    $activeStructure: String
    $activeGroup: String!
    $selectedIdUser: String!
  ) {
    getUserForBoss(
      selectedIdUser: $selectedIdUser
      activeGroup: $activeGroup
      activeStructure: $activeStructure
      selectedIdUser: $selectedIdUser
    ) {
      email
      name
      profession
      surname
    }
  }
`;

export default GetUserForBoss;
