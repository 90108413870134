import gql from "graphql-tag";

const onUpdateAlertStepFunction = gql`
  subscription onUpdateAlertStepFunction($idUser: String!) {
    onUpdateAlertStepFunction(idUser: $idUser) {
      numAlert
      alert
      idUser
      idValor
      idSkill
      idMacroArea
      idMacroSkill
      initWorkflow
    }
  }
`;

export default onUpdateAlertStepFunction;
