import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const Summary = ({
  category,
  titleSubCategory,
  descriptionSubCategory,
  isRequestFeedback,
}) => {
  const { t } = useTranslation();
  const categoryText =
    category &&
    (t(category) === t("generic") ? t(category) : `${t(category)}:`);
  return (
    <>
      <Box mt={6} mb={2}>
        <Typography component="p" variant="h5">
          {t("summary")}
        </Typography>
      </Box>
      <Box bgcolor="secondary.lighter" p={2}>
        <Box color="grey.500" mb={2}>
          <Typography component="p" variant="body2">
            {categoryText}
          </Typography>
        </Box>
        <Typography component="p" variant="h5">
          {titleSubCategory}
        </Typography>
        <Box color="grey.500" mt={4}>
          <Typography component="p" variant="body2">
            {descriptionSubCategory}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

Summary.propTypes = {
  category: PropTypes.string,
  titleSubCategory: PropTypes.string,
  descriptionSubCategory: PropTypes.string,
  isRequestFeedback: PropTypes.bool,
};

export default Summary;
