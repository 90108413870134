import gql from "graphql-tag";

const onUpdateStructuresWarnings = gql`
  subscription onUpdateStructuresWarnings(
    $idUser: String!
    $idStructure: String!
  ) {
    onUpdateStructuresWarnings(idUser: $idUser, idStructure: $idStructure) {
      usersGoalMissing
      idUser
      idStructure
    }
  }
`;

export default onUpdateStructuresWarnings;
