import React, { useContext } from "react";
import { AuthContext } from "../auth/Authenticator";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import sidebarItems from "./SidebarItems";
import WorkflowBox from "./WorkflowBox";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "components/base_components/Logo";
import { InfoContext } from "context/InfoContext";
import ItemMenu from "./ItemMenu";
import ItemsDashboard from "./ItemsDashboard";

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: theme.drawerWidth,
    // E' necessario aggiungere l'important per sovrascrivere il css di default
    zIndex: `${theme.zIndex.snackbar + 2} !important`,
  },
}));

const Sidebar = ({ handleDrawerOpen, handleDrawerClose, open, selected }) => {
  const { activeGroup, activeStructure, nameTenant } = useContext(AuthContext);
  const { info, settings } = useContext(InfoContext);
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobileAndTable = useMediaQuery((theme) =>
    theme.breakpoints.down("md"),
  );

  return (
    <>
      {isMobileAndTable && !open && (
        <Box
          bgcolor="primary.dark"
          position="fixed"
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
          color="primary.main"
          height={theme.navbarHeight}
          width={68}
          zIndex={theme.zIndex.snackbar + 2}
        >
          <IconButton color="inherit" onClick={handleDrawerOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Box>
      )}
      <Drawer
        anchor="left"
        variant={isMobileAndTable ? "temporary" : "permanent"}
        className={classes.drawer}
        classes={{ paper: classes.drawer }}
        onClose={handleDrawerClose}
        open={open}
      >
        <Box
          height={theme.navbarHeight}
          minHeight={theme.navbarHeight}
          px={5}
          bgcolor="primary.main"
          color="grey.100"
          display="flex"
          alignItems="center"
        >
          {/* Il nome azineda verrà recuperato assieme alle info del tenant */}
          <Typography variant="h5">{nameTenant}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          bgcolor="primary.dark"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box marginTop={2} component={List}>
              {!settings.loading &&
                sidebarItems.map((item, index) => {
                  if (item.type === "macroItem") {
                    let items = item.items;
                    if (item.code === "evalutation-items") {
                      items = items.filter(
                        ({ code }) =>
                          (code === "valors" && settings.valorActivated) ||
                          (code === "skills" && settings.skillActivated),
                      );
                    }
                    return items.length > 0 &&
                      item.authorizedRoles.includes(activeGroup) ? (
                      <ItemsDashboard
                        key={`${item.code}${index}`}
                        items={items}
                        selected={selected}
                        activeGroup={activeGroup}
                        title={item.text}
                        code={item.code}
                      />
                    ) : null;
                  }
                  return item.authorizedRoles.includes(activeGroup) ? (
                    <ItemMenu
                      key={`${item}${index}`}
                      item={item}
                      info={info}
                      activeGroup={activeGroup}
                      activeStructure={activeStructure}
                      isSelected={selected === item.text}
                    />
                  ) : null;
                })}
            </Box>
            <Box display="flex" paddingX={5} mb={4}>
              <Logo />
            </Box>
          </Box>
          <WorkflowBox />
        </Box>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selected: PropTypes.string.isRequired,
};

export default Sidebar;
