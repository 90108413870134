import gql from "graphql-tag";

const DashboardFinalReviewManager = gql`
  query dashboardFinalReviewManager(
    $structures: [String]
    $users: [String]
    $language: String
  ) {
    dashboardFinalReviewManager(
      structures: $structures
      users: $users
      language: $language
    ) {
      valutationCounters {
        compiled
        partiallyCompiled
        notCompiled
        locked
      }
      autovalutationCounters {
        compiled
        partiallyCompiled
        notCompiled
        locked
      }
      scoresOverRating {
        numScores
        rating
      }
      scoresOverRatingAutovalutation {
        numScores
        rating
      }
      priorityRating
      macroareaRatings {
        id
        macroarea
        ratingAverage
      }
      macroskillRatings {
        id
        macroskill
        ratingAverage
      }
      questions {
        id
        question
        answers {
          answer
          percentage
        }
      }
      bossQuestions {
        id
        question
        answers {
          answer
          percentage
        }
      }
      isSingleUser
      valutationScore
      autovalutationScore
    }
  }
`;

export default DashboardFinalReviewManager;
