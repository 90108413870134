import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const ModalFooter = ({
  onClose,
  action,
  closeText,
  actionText,
  showCloseButton,
  color,
  justifyContent,
}) => {
  const { t } = useTranslation();
  return (
    <Box px={4} py={6} display="flex" justifyContent={justifyContent}>
      {showCloseButton && (
        <Button
          onClick={onClose}
          color="primary"
          style={{ marginRight: "16px" }}
        >
          {t(closeText)}
        </Button>
      )}
      <Button onClick={action} variant="contained" color={color}>
        {t(actionText)}
      </Button>
    </Box>
  );
};

ModalFooter.propTypes = {
  onClose: PropTypes.func,
  action: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  actionText: PropTypes.string,
  showCloseButton: PropTypes.bool,
  color: PropTypes.string,
  justifyContent: PropTypes.string,
};

ModalFooter.defaultProps = {
  closeText: "back",
  actionText: "save",
  color: "secondary",
  justifyContent: "flex-end",
};

export default ModalFooter;
