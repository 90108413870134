import React from "react";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const HelpIcon = () => {
  const navigation = useNavigate();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box color={isDesktop ? "grey.100" : "primary.light"}>
      <IconButton
        color="inherit"
        onClick={() => navigation("/guidance")}
        size="large"
      >
        <HelpTwoToneIcon />
      </IconButton>
    </Box>
  );
};

export default React.memo(HelpIcon);
