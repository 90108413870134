import React, { useState, useEffect, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import InputField from "components/base_components/InputField";
import SelectField from "components/base_components/SelectField";
import reducerSubcategories from "components/feedback/reducerSubcategories";
import {
  listValues,
  listObjectives,
  listSkills,
} from "components/feedback/listCategories";
import NatureFeedback from "../NatureFeedback";
import RatingFeedback from "../RatingFeedback";
import { WorkflowContext } from "context/WorkflowContext";
import {
  useGetFormikSendFeedback,
  categories,
} from "../functions/formikHelper";
import SearchUserComponent from "../SearchUserComponent";
import Summary from "../Summary";
import ActionForm from "../ActionForm";
import { makeStyles } from "tss-react/mui";
import { InfoContext } from "context/InfoContext";
import { AuthContext } from "components/auth/Authenticator";
import ElementSelect from "../ElementSelect";

const useStyles = makeStyles()((theme) => ({
  invertRow: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
}));

const mapFunctionGetSubcategory = {
  goal: listObjectives,
  valor: listValues,
  skill: listSkills,
};

const FormSendFeedback = ({
  setDataModal,
  handleClickAlert,
  feedbackData,
  updateDataFeedbacks,
  isPrecompiledFeedback,
  isUpdate,
  activeStructure,
  activeGroup,
  isBoss,
  isGoalFeedback,
  handleClose,
  idSelectedUser,
  setDirty,
  dirty,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { rating, thresholdRating, subPhase } = useContext(WorkflowContext);
  const { slugTenant } = useContext(AuthContext);
  const { settings } = useContext(InfoContext);
  const [stateSubcategories, updateStateSubcategories] = useReducer(
    reducerSubcategories,
    {
      subCategories: [],
      hasSubcategories: false,
      valorsForSelect: {},
      skillsForSelect: {},
    },
  );

  const [loading, setLoading] = useState(!!feedbackData);
  const filteredCategories = categories.filter(
    (item) =>
      (settings.goalActivated || item !== "goal") &&
      (settings.valorActivated || item !== "valor") &&
      (settings.skillActivated || item !== "skill") &&
      (settings.genericFeedback || item !== "generic"),
  );
  const [allowedCategories, setAllowedCategories] =
    useState(filteredCategories);

  useEffect(() => {
    setAllowedCategories(filteredCategories);
  }, [settings]);

  let formValues = {
    recipient: "",
    object: "",
    category: "",
    subCategory: "",
    description: "",
    ratingFeedback: "",
    preCompiling: false,
  };
  let nature = "keepgoing";
  let initUsers = [];
  if (feedbackData) {
    const currentCategory = isGoalFeedback ? "goal" : feedbackData.category;
    formValues = {
      object: feedbackData.object,
      category: allowedCategories.findIndex((item) => item === currentCategory),
      subCategory: "",
      description: feedbackData.description ?? "",
      preCompiling: true,
      idRecipient: feedbackData.idUserRecipient,
      nameRecipient: feedbackData.nameUserRecipient,
      emailRecipient: feedbackData.emailRecipient,
      professionalFamilyRecipient: feedbackData.professionalFamilyRecipient,
      ...(feedbackData.requestDescription && {
        requestDescription: feedbackData.requestDescription,
      }),
      ...(feedbackData.rating && {
        ratingFeedback: rating[`${feedbackData.rating}`],
      }),
    };
    nature =
      feedbackData.nature || isPrecompiledFeedback ? "keepgoing" : "thinkabout";
    initUsers = [
      {
        idUser: feedbackData.idUserRecipient,
        fullname: feedbackData.nameUserRecipient,
        email: feedbackData.emailRecipient,
        professionalFamilyRecipient: feedbackData.professionalFamilyRecipient,
      },
    ];
  }
  const [selectedValue, setSelectedValue] = useState(nature);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChangeRating = (event) => {
    formik.setFieldValue("ratingFeedback", event.target.value);
  };

  const formik = useGetFormikSendFeedback({
    isBoss,
    selectedValue,
    initialValues: formValues,
    rating,
    stateSubcategories,
    thresholdRating,
    isUpdate,
    feedbackData,
    subPhase,
    isPrecompiledFeedback,
    updateDataFeedbacks,
    handleClickAlert,
    handleClose,
    allowedCategories,
    slugTenant,
  });

  useEffect(() => {
    formik.setFieldValue("subCategory", "");
    if (
      !["valor", "goal", "skill"].includes(
        allowedCategories[`${formik.values.category}`],
      )
    ) {
      updateStateSubcategories({ type: "resetSubcategories" });
      return;
    }
    updateStateSubcategories({
      type: "updateHasSubcategories",
      payload: {
        hasSubcategories: true,
      },
    });
    (async () => {
      setLoading(true);
      const nameCategory = allowedCategories[`${formik.values.category}`];
      const getSubcategories = mapFunctionGetSubcategory[`${nameCategory}`];

      const subCategoriesFetched = formik.values.idRecipient
        ? await getSubcategories({
            idUserRecipient: formik.values.idRecipient,
            forRecipient: true,
            activeStructure,
            activeGroup,
          })
        : [];

      let valorsForSelect = {};
      let skillsForSelect = {};

      const selectedCategory = formik.values.category;

      if (allowedCategories[`${selectedCategory}`] === "valor") {
        valorsForSelect = subCategoriesFetched.reduce(
          (acc, subcategory, index) => {
            if (!acc[subcategory.nameMacroArea]) {
              acc[subcategory.nameMacroArea] = [];
            }
            acc[subcategory.nameMacroArea].push({
              ...subcategory,
              selectIndex: index,
            });
            return acc;
          },
          {},
        );
      }

      if (allowedCategories[`${selectedCategory}`] === "skill") {
        skillsForSelect = subCategoriesFetched.reduce(
          (acc, subcategory, index) => {
            if (!acc[subcategory.nameMacroSkill]) {
              acc[subcategory.nameMacroSkill] = [];
            }
            acc[subcategory.nameMacroSkill].push({
              ...subcategory,
              selectIndex: index,
            });
            return acc;
          },
          {},
        );
      }

      updateStateSubcategories({
        type: "updateSubcategories",
        payload: {
          subCategories: subCategoriesFetched,
          valorsForSelect,
          skillsForSelect,
        },
      });
      if (feedbackData && formValues.preCompiling) {
        const subCategoryIndex = subCategoriesFetched.findIndex(
          (subCategory) => subCategory.title === feedbackData.subCategory,
        );
        formik.setFieldValue(
          "subCategory",
          subCategoryIndex === -1 ? "" : subCategoryIndex,
        );
      }
      setLoading(false);
    })();
  }, [formik.values.category, formik.values.idRecipient]);

  useEffect(() => {
    setDirty(formik.dirty && formik.isValid);
  }, [formik.dirty, formik.values.ratingFeedback, formik.isValid]);

  useEffect(() => {
    if (
      formValues.preCompiling &&
      (!isNaN(parseInt(formik.values.subCategory, 10)) ||
        allowedCategories[`${formik.values.category}`] === "generic") &&
      formik.values.nameRecipient
    ) {
      formik.resetForm({ values: formik.values });
      setLoading(false);
    }
  }, [formik.values.subCategory, formik.values.nameRecipient]);

  return (
    <>
      <Box overflow="hidden auto" height="100%" pt={4}>
        <Backdrop
          open={loading}
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
          <CircularProgress size={48} />
        </Backdrop>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={6} className={classes.invertRow}>
            <Grid item md={6} xs={12}>
              <Box mb={{ xs: 5, md: 9 }}>
                <SearchUserComponent
                  initUsers={initUsers}
                  activeStructure={activeStructure}
                  activeGroup={activeGroup}
                  initialValues={formValues}
                  feedbackData={feedbackData}
                  formik={formik}
                  isPrecompiledFeedback={isPrecompiledFeedback}
                  idSelectedUser={idSelectedUser}
                />
              </Box>
              <InputField
                label={t("object")}
                name="object"
                touched={formik.touched.object}
                error={formik.errors.object}
                formik={formik.getFieldProps("object")}
                disabled={
                  isPrecompiledFeedback ||
                  (feedbackData && feedbackData.fromRequest)
                }
                isFeedback={true}
              />
              <SelectField
                label={t("category")}
                name="category"
                touched={formik.touched.category}
                error={formik.errors.category}
                formik={formik.getFieldProps("category")}
                options={allowedCategories}
                disabled={
                  isPrecompiledFeedback ||
                  (feedbackData && feedbackData.fromRequest)
                }
                isFeedback={true}
              />
              {stateSubcategories.hasSubcategories &&
                (allowedCategories[formik.values.category] === "goal" ? (
                  <SelectField
                    label={t("subCategory")}
                    name="subCategory"
                    touched={formik.touched.subCategory}
                    error={formik.errors.subCategory}
                    formik={formik.getFieldProps("subCategory")}
                    options={stateSubcategories.subCategories}
                    disabled={
                      isPrecompiledFeedback ||
                      (feedbackData && feedbackData.fromRequest)
                    }
                    isFeedback={true}
                  />
                ) : (
                  <ElementSelect
                    selectableValors={
                      allowedCategories[formik.values.category] === "valor"
                        ? stateSubcategories.valorsForSelect
                        : stateSubcategories.skillsForSelect
                    }
                    touched={formik.touched.subCategory}
                    error={formik.errors.subCategory}
                    formik={formik.getFieldProps("subCategory")}
                    disabled={
                      isPrecompiledFeedback ||
                      (feedbackData && feedbackData.fromRequest)
                    }
                  />
                ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography component="p" variant="h5">
                  {t("feedbackNature")}
                </Typography>
              </Box>
              {isBoss ? (
                <RatingFeedback
                  selectedValue={formik.values.ratingFeedback}
                  handleChange={handleChangeRating}
                  rating={rating}
                  thresholdRating={thresholdRating}
                  selectedSubcategory={
                    stateSubcategories?.subCategories[formik.values.subCategory]
                  }
                  errorRating={formik.errors.ratingFeedback}
                />
              ) : (
                <NatureFeedback
                  selectedValue={selectedValue}
                  handleChange={handleChange}
                />
              )}
              <Box display={{ xs: "none", md: "block" }}>
                <Summary
                  category={
                    formik.values.category || formik.values.category === 0
                      ? allowedCategories[formik.values.category]
                      : ""
                  }
                  titleSubCategory={
                    formik.values.subCategory || formik.values.subCategory === 0
                      ? stateSubcategories.subCategories[
                          formik.values.subCategory
                        ].title
                      : ""
                  }
                  descriptionSubCategory={
                    formik.values.subCategory || formik.values.subCategory === 0
                      ? stateSubcategories.subCategories[
                          formik.values.subCategory
                        ].description
                      : ""
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {formik.values.requestDescription && (
              <InputField
                label={t("requestorNotes")}
                name="requestDescription"
                touched={formik.touched.requestDescription}
                error={formik.errors.requestDescription}
                formik={formik.getFieldProps("requestDescription")}
                other={{ rows: 4, multiline: true }}
                disabled
              />
            )}
            <InputField
              label={t("senderFeedbackNotes")}
              name="description"
              touched={formik.touched.description}
              error={formik.errors.description}
              formik={formik.getFieldProps("description")}
              other={{ rows: 4, multiline: true }}
            />
          </Grid>
          <Box display={{ xs: "block", md: "none" }}>
            <Summary
              category={
                formik.values.category || formik.values.category === 0
                  ? categories[formik.values.category]
                  : ""
              }
              titleSubCategory={
                formik.values.subCategory || formik.values.subCategory === 0
                  ? stateSubcategories.subCategories[formik.values.subCategory]
                      .title
                  : ""
              }
              descriptionSubCategory={
                formik.values.subCategory || formik.values.subCategory === 0
                  ? stateSubcategories.subCategories[formik.values.subCategory]
                      .description
                  : ""
              }
            />
          </Box>
        </form>
      </Box>
      <Box paddingY={2} height={48}>
        <ActionForm
          isSubmitting={formik.isSubmitting}
          handleClose={() => handleClose()}
          action={formik.submitForm}
          isChanges={dirty}
        />
      </Box>
    </>
  );
};

FormSendFeedback.propTypes = {
  setDataModal: PropTypes.func.isRequired,
  handleClickAlert: PropTypes.func.isRequired,
  feedbackData: PropTypes.object,
  updateDataFeedbacks: PropTypes.func,
  isPrecompiledFeedback: PropTypes.bool,
  isUpdate: PropTypes.bool,
  activeStructure: PropTypes.string,
  activeGroup: PropTypes.string.isRequired,
  isBoss: PropTypes.bool,
  isGoalFeedback: PropTypes.bool,
  handleClose: PropTypes.func,
  idSelectedUser: PropTypes.string,
  setDirty: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

export default FormSendFeedback;
